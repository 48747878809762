import { defineMessages } from 'react-intl';

export default defineMessages({
  refreshMessage: {
    id: 'stream.status.refreshMessage',
    defaultMessage: 'Please try refreshing the page.',
    description: 'Message telling user to refresh the page',
  },
  addCardButton: {
    id: 'stream.addCardButton',
    defaultMessage: 'Add Card',
    description: 'Button to navigate to /cards',
  },
  streamTitle: {
    id: 'stream.title',
    defaultMessage: 'Home',
    description: 'Page title for stream',
  },
});
