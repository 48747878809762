import { combineReducers } from 'redux';

import giftHistoryList from './egifts/gift-history-list';
import giftOrderDetails from './egifts/gift-order-details';
import activeGiftHistoryItem from './egifts/active-gift-history-item';
import historyList from './orders/history-list';
import historyReceipts from './orders/history-receipts';
import activeHistoryItem from './orders/active-history-item';
import pendingTips from './orders/pending-tips';

export default combineReducers({
  giftHistoryList,
  giftOrderDetails,
  activeGiftHistoryItem,
  historyList,
  historyReceipts,
  activeHistoryItem,
  pendingTips,
});
