/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cardNotActivated: {
    id: 'ordering.notifications.cardNotActivated',
    defaultMessage:
      "The card you're using is not activated. Please activate your card in-store, or by calling 800-782-7282.",
    description: "Notification that the user's selected card is not activated.",
  },
  insufficientBalance: {
    id: 'ordering.notifications.insufficientBalance',
    defaultMessage: 'Insufficient balance available.',
    description:
      "Notification that the user's selected card has insufficient balance for this order.",
  },
  cardHasProblem: {
    id: 'ordering.notifications.cardHasProblem',
    defaultMessage:
      "There is a problem with the card you're using. Please contact Customer Service at 800-782-7282.",
    description:
      "Notification that the user's selected card has a problem and that they should contact customer service in their region.",
  },
  // Svc card reload errors
  enterReloadAmount: {
    id: 'ordering.notifications.enterReloadAmount',
    defaultMessage: 'Enter a reload amount',
    description:
      'Notification message displayed when the user needs to enter a valid auto reload amount',
  },
  cardMaxBalanceExceeded: {
    id: 'ordering.notifications.cardMaxBalanceExceeded',
    defaultMessage: 'Your card’s total amount cannot exceed $500.',
    description:
      'Notification message displayed when the reload will result in a card balance higher than the limit',
  },
  cardInactive: {
    id: 'ordering.notifications.cardInactive',
    defaultMessage:
      'Activate this Starbucks Card with a barista at any Starbucks store.',
    description:
      'Notification message displayed when the user tries to transfer balance from a card that is inactive',
  },
  cardLostOrStolen: {
    id: 'ordering.notifications.cardLostOrStolen',
    defaultMessage:
      "There is a problem with the card you're using. Contact Customer Service at 800-782-7282.",
    description:
      'Notification message displayed when the user tries to transfer balance from a card that is reported lost or stolen',
  },
  fraudCardFailure: {
    id: 'ordering.notifications.fraudCardFailure',
    defaultMessage:
      'There is a problem with your card. Please contact your bank.',
    description:
      'Notification message displayed when the user reload fails the fraud check',
  },
  invalidReloadAmount: {
    id: 'ordering.notifications.invalidReloadAmount',
    defaultMessage: 'Invalid reload amount.',
    description:
      'Notification message displayed when the user needs to enter a valid auto reload amount',
  },
  cardIsClosed: {
    id: 'ordering.notifications.cardIsClosed',
    defaultMessage: 'This Starbucks Card is closed.',
    description: 'Notification message displayed when a card is closed',
  },
  paymentMethodInvalid: {
    id: 'ordering.notifications.paymentMethodInvalid',
    defaultMessage: 'Something went wrong with your payment method.',
    description:
      'Notification message displayed when the user’s payment method is invalid',
  },
  paymentMethodExpired: {
    id: 'ordering.notifications.paymentMethodExpired',
    defaultMessage: 'This credit/debit card may have expired.',
    description:
      'Notification message displayed when the user’s payment method is expired',
  },
  creditCardRejected: {
    id: 'ordering.notifications.creditCardRejected',
    defaultMessage:
      'Something went wrong on our end. Please select a different payment method and try again.',
    description:
      'Notification message displayed when the user’s credit card is rejected',
  },
  payPalError: {
    id: 'ordering.notifications.payPalError',
    defaultMessage:
      'Something went wrong on PayPal’s end. Please choose another payment method.',
    description: 'Notification message displayed when PayPal payment fails',
  },
  antiFraudViolation: {
    id: 'ordering.notifications.antiFraudViolation',
    defaultMessage:
      'There is a problem with the card you’re using. Contact Customer Service at 800-782-7282.',
    description:
      'Notification message displayed when card violates anti-fraud rules',
  },
  duplicateTransaction: {
    id: 'ordering.notifications.duplicateTransaction',
    defaultMessage: 'We already received your request, thanks!',
    description:
      'Notification message displayed when reload is a duplicate transaction',
  },
  americanExpressError: {
    id: 'ordering.notifications.americanExpressError',
    defaultMessage:
      'Something went wrong on Amex’s end. Please choose another payment method.',
    description:
      'Notification message displayed when American Express payment fails',
  },
});
