import React from 'react';
import { FormattedMessage } from 'react-intl';

import BarcodeRewardIcon from 'shared/app/components/barcode-reward-icon';

const RewardContent = () => (
  <div
    className="p3 flex relative color-textWhite bg-greenStarbucks justify-center items-center pr6 text-xxs"
    data-e2e="rewardContent"
  >
    <BarcodeRewardIcon iconOnly />
    <FormattedMessage
      defaultMessage="You have Rewards! Scan anytime and let the barista know which drink or food you’d like to apply your Stars toward."
      description="Shown on pay screen if you have a reward you could redeem"
      id="accountCards.payOverlay.successContent.rewardBanner"
    />
  </div>
);

export default RewardContent;
