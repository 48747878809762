import { defineMessages } from 'react-intl';

const messages = defineMessages({
  call: {
    id: 'expandedLocationCardDetails.call',
    defaultMessage: 'Call',
    description: 'Prefix to phone number',
  },
  directionsLink: {
    id: 'expandedLocationCardDetails.directionsLink',
    defaultMessage: 'Get Directions',
    description: 'Link text for getting directions to store',
  },
  distanceKmAway: {
    id: 'expandedLocationCardDetails.kmAway',
    description: 'Label for distance away from the user in kilometers',
    defaultMessage: '{distance} km away',
  },
  distanceMilesAway: {
    id: 'expandedLocationCardDetails.milesAway',
    description: 'Label for distance away from the user in miles',
    defaultMessage: '{distance, plural, one {# mile} other {# miles}} away',
  },
  phoneLabel: {
    id: 'expandedLocationCardDetails.phoneLabel',
    defaultMessage: 'Phone number:',
    description:
      'Accessibility label for phone number on expanded location card',
  },
  orderAndPickupOptionsHeading: {
    id: 'expandedLocationCardDetails.orderAndPickUpOptionsHeading',
    defaultMessage: 'Order & Pick Up Options',
    description:
      "Heading for list of store's pick up options inside of store details",
  },
  featuresHeading: {
    id: 'expandedLocationCardDetails.featuresHeading',
    defaultMessage: 'Amenities',
    description: "Heading for list of store's features inside of store details",
  },
  featuresNotAvailableLabel: {
    id: 'expandedLocationCardDetails.featuresNotAvailable',
    defaultMessage: 'Store features not available',
    description: 'Message within store details when features are not available',
  },
  scheduleHeading: {
    id: 'expandedLocationCardDetails.scheduleHeading',
    defaultMessage: 'Store Hours',
    description: 'Heading for store schedule times',
  },
  scheduleNotAvailable: {
    id: 'expandedLocationCardDetails.scheduleNotAvailable',
    defaultMessage: 'Schedule not available',
    description: 'Message within store details when schedule is not available',
  },
});

export default messages;
