import {
  UPDATE_LOCATIONS,
  REQUEST_NEAREST_LOCATION,
  REQUEST_NEAREST_LOCATION_ERROR,
  REQUEST_NEAREST_LOCATION_SUCCESS,
  NEAREST_LOCATION_NOT_FOUND,
} from '../actions/types';

const defaultState = {
  loading: false,
  nearestNotFound: false,
  nearestStoreCoordinates: null,
  hasSearchedNearest: false,
};

const nearestLocation = function (state = defaultState, action) {
  switch (action.type) {
    case REQUEST_NEAREST_LOCATION:
      return Object.assign({}, state, {
        error: null,
        nearestNotFound: false,
        loading: true,
        hasSearchedNearest: true,
      });
    case REQUEST_NEAREST_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        nearestStoreCoordinates: action.payload.nearestStoreCoordinates,
      });
    case NEAREST_LOCATION_NOT_FOUND:
      return Object.assign({}, state, {
        loading: false,
        nearestNotFound: true,
        nearestStoreCoordinates: null,
      });
    case REQUEST_NEAREST_LOCATION_ERROR:
      return Object.assign({}, state, {
        error: true,
        loading: false,
        nearestNotFound: false,
        nearestStoreCoordinates: null,
      });

    case UPDATE_LOCATIONS:
      // Reset nearest location interface every time we get new results.
      return Object.assign({}, defaultState);

    default:
      return state;
  }
};

export default nearestLocation;
