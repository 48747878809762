import {
  FETCH_STREAM,
  FETCH_STREAM_SUCCESS,
  FETCH_STREAM_ERROR,
} from './types';
import { fetchStreamUrlSelector } from '../selectors/stream';
import transformStreamItems from '../transform';
import { signedInSelector } from 'shared/app/bundles/user';

export const fetchStream =
  () =>
  (dispatch, getState, { apiFetch }) => {
    const state = getState();
    const isAuthenticated = signedInSelector(state);
    const url = fetchStreamUrlSelector(state);
    dispatch({
      type: FETCH_STREAM,
    });
    apiFetch(url, { includeResponse: true })
      .catch((error) => {
        if (error.httpStatus === 304) {
          /* The stream API has a non-standard approach to `If-Modified-Since` headers
              it responds by sending stuff that's changed since that date. This actually violates
              the HTTP protocol standards.
  
              Normally if the server responds with a `Last-Modified` header the browser will
              seamlessly treat the 304 as a "successful" `200` response and give your JavaScript
              the successful response from last time (the one it had cached). It actually hides this
              from your code and your code never sees the 304 code. This means you don't have to handle
              304s every time you want to build an app. You just handle success or error and
              everything works.
  
              But, in this case the 304 was not caused by the browser trying to do cache handling
              for us. So, it does get passed through and our fetch helper treats it as an error because
              it's not in the 2xx range.
  
              To accommodate this, we look for that scenario here and consider them an "additive"
              update just without any data to add. But it's important that it's treated as a "success"
              in order to properly update `lastFetched` state in the reducer. */
          const data = isAuthenticated ? { streamItems: [] } : [];
          return {
            data,
            response: {
              status: 304,
            },
          };
        }
        throw error;
      })
      .then(({ data, response }) => {
        // The 206 handling here is how we address the case where the API
        // returns a 417. On the server-side we re-map this to 206 because
        // it's not a full error, we still get a partial response
        // that we want to use to merge into our stream items without
        // updating "last successful fetch" metadata.
        const isPartialResponse = response.status === 206;
        dispatch({
          type: FETCH_STREAM_SUCCESS,
          payload: transformStreamItems(isAuthenticated, data),
          merge: true,
          partialSuccess: isPartialResponse,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_STREAM_ERROR, error });
      });
  };
