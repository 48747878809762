import {
  GIFT_INPUTS_KEY,
  setSessionStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
} from 'shared/app/utils/session-storage';

// exported for testing only
export const createSessionInputValues = (fields) => {
  const fieldKeys = Object.keys(fields);
  const sbuxGiftInputs = {};
  fieldKeys.forEach((key) => {
    return (sbuxGiftInputs[key] =
      fields[key]?.input?.value ?? fields[key] ?? null);
  });
  return sbuxGiftInputs;
};

export const setSessionGiftFormData = (fields) => {
  const sbuxGiftInputs = createSessionInputValues(fields);
  setSessionStorageItem(GIFT_INPUTS_KEY, sbuxGiftInputs);
};

export const getSessionGiftFormData = () =>
  getSessionStorageItem(GIFT_INPUTS_KEY);

export const getNumberOfRecipients = () => {
  const sessionGiftFormData = getSessionGiftFormData();
  const sessionKeys = Object.keys(sessionGiftFormData);
  let numOfRecips = 0;
  if (sessionKeys.length) {
    sessionKeys.forEach((fieldKey) => {
      if (fieldKey.includes('recipientEmail')) {
        numOfRecips++;
      }
    });
    return numOfRecips;
  }
  return 1;
};

export const removeSessionGiftFormData = () =>
  removeSessionStorageItem(GIFT_INPUTS_KEY);
