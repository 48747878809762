import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import chevronRight from '@starbucks-web/pattern-library/lib/icons/chevron-right';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import messages from './messages';
import styles from './styles.cssm';

/*
 * For options such as Curbside and Outdoor Pickup where you check in and
 * let the store know you have arrived.
 */
const CheckInOption = ({ optionName, icon, queryParams }) => (
  <div className="pb3">
    <Link
      className={`${styles.container} ${styles.checkInContainer} flex justify-between items-center my2`}
      to={`/pickup/${optionName}${queryParams}`}
    >
      <div className={styles.imageWrapper}>
        <Icon className="color-textBlackSoft" path={icon} />
      </div>
      <div className="pl2 flex-grow">
        <Heading className="pb1 text-semibold text-md" size="sm" tagName="h3">
          <FormattedMessage {...messages[`${optionName}OptionHeading`]} />
        </Heading>
        <p className="text-xxs color-textBlackSoft">
          <FormattedMessage {...messages[`${optionName}OptionMessage`]} />
        </p>
      </div>
      <Icon path={chevronRight} size="16px" />
    </Link>
  </div>
);

export default CheckInOption;
