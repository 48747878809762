import { defineMessages } from 'react-intl';

export default defineMessages({
  pickupLabel: {
    id: 'storeLocator.deliveryToggle.pickupLabel',
    defaultMessage: 'Pickup',
    description: 'Pickup label for delivery toggle',
  },
  deliveryLabel: {
    id: 'storeLocator.deliveryToggle.deliveryLabel',
    defaultMessage: 'Delivery',
    description: 'Delivery label for delivery toggle',
  },
  deliveryToggleLegend: {
    id: 'storeLocator.deliveryToggle.deliveryToggleLegend',
    defaultMessage: 'Choose an order type',
    description: 'Hidden legend for the delivery radio toggle component',
  },
});
