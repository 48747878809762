import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import RewardContent from './reward-content';
import StoredValueCardsSelect from 'shared/app/components/stored-value-cards-select';
import { svcCardsDataSelector as cardsDataSelector } from 'shared/app/bundles/svc-cards';

import SvcImage from 'shared/app/components/svc-image';

import splitIntoArray from './split-into-array';
import { setScanToPayCardId as setScanToPayCardIdAction } from '../../state/actions/cards-management';
import { svcMessages } from 'shared/app/messages';

import styles from './styles.cssm';

const messages = defineMessages({
  barcodeLabel: {
    id: 'accountCards.payOverlay.successContent.scannableBarcodeLabel',
    defaultMessage: 'Barcode image for barista to scan.',
    description: 'Alt text for barcode for screen readers',
  },
});

const SuccessContent = ({
  barcodeUri,
  card,
  hasRewardAvailable,
  intl,
  svcCards,
  setScanToPayCardId,
  setShowLoading = () => {},
}) => {
  const { cardId, cardImageUrl, cardNumber, nickname } = card;

  return (
    <div
      className={`flex-grow flex flex-column mx-auto pb6 size12of12 ${styles.base}`}
    >
      <div className="flex-grow">
        <div className={`${styles.successTitle} px4 pb4`}>
          <Heading className="mb4 text-bold" size="lg" tagName="h2">
            <FormattedMessage {...svcMessages.scanInStore} />
          </Heading>
          <div className="flex flex-row">
            <div className="size6of12 pr4">
              <SvcImage altText={nickname} src={cardImageUrl} />
            </div>
            <StoredValueCardsSelect
              className="size6of12"
              field={{
                input: {
                  value: cardId,
                  onChange: ({ target: { value } }) => {
                    setShowLoading(true);
                    setScanToPayCardId(value);
                  },
                  id: 'storeValueCardsSelect',
                },
              }}
              hideImage
              intl={intl}
              svcCards={svcCards}
            />
          </div>
        </div>
        {hasRewardAvailable && <RewardContent />}
      </div>

      <div className="mt3 text-center">
        <img
          alt={intl.formatMessage(messages.barcodeLabel)}
          data-e2e="barcode"
          src={barcodeUri}
        />
        <div className={styles.cardNumber}>
          <span className="hiddenVisually ">Barcode number</span>
          {splitIntoArray(cardNumber).map((string) => (
            <span className="speak-digits" key={string}>
              {string}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const select = (state) => ({
  svcCards: cardsDataSelector(state),
});

const actions = {
  setScanToPayCardId: setScanToPayCardIdAction,
};

export default injectIntl(connect(select, actions)(SuccessContent));
