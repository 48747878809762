import {
  ADD_MFA_PHONE,
  ADD_MFA_PHONE_SUCCESS,
  ADD_MFA_PHONE_ERROR,
  EDIT_MFA_PHONE,
  EDIT_MFA_PHONE_SUCCESS,
  EDIT_MFA_PHONE_ERROR,
} from '../../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_MFA_PHONE:
      return { ...state, addPhone: { loading: true } };
    case ADD_MFA_PHONE_SUCCESS:
      return { ...state, addPhone: { ...action.payload, loading: false } };
    case ADD_MFA_PHONE_ERROR:
      return { ...state, addPhone: { error: action.payload, loading: false } };
    case EDIT_MFA_PHONE:
      return { ...state, editPhone: { loading: true } };
    case EDIT_MFA_PHONE_SUCCESS:
      return { ...state, editPhone: { ...action.payload, loading: false } };
    case EDIT_MFA_PHONE_ERROR:
      return { ...state, editPhone: { error: action.payload, loading: false } };
    default:
      return state;
  }
};
