import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import HeaderCrate from '@starbucks-web/pattern-library/lib/components/header-crate';

import H1 from 'shared/app/components/h1';
import HeaderCrateWithLogo from 'shared/app/components/header-crate-with-logo';

import { isCoreAppUrlSelector } from 'shared/app/state/selectors/locales';

import messages from './messages';

const Header = ({ headerTitle, isCoreAppUrl, intl: { formatMessage } }) => {
  const children = (
    <H1 className="text-bold inline" size="lg">
      {headerTitle ? (
        <FormattedMessage {...headerTitle} />
      ) : (
        <FormattedMessage {...messages.pickupOptions} />
      )}
    </H1>
  );
  return (
    <Fragment>
      <Helmet title={formatMessage(headerTitle || messages.pickupOptions)} />
      {isCoreAppUrl ? (
        <HeaderCrate>
          <HeaderCrate.Inner>{children}</HeaderCrate.Inner>
        </HeaderCrate>
      ) : (
        <HeaderCrateWithLogo>{children}</HeaderCrateWithLogo>
      )}
    </Fragment>
  );
};

Header.displayName = Header;

const select = (state) => ({
  isCoreAppUrl: isCoreAppUrlSelector(state),
});

export default injectIntl(connect(select)(Header));
