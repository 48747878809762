import { createSelector } from 'reselect';
import { storeLocatorSelectorWithFallback } from './util';

export const filterStateSelector = createSelector(
  storeLocatorSelectorWithFallback,
  ({ filterState }) => filterState || {}
);

export const isFilterOverlayOpenSelector = createSelector(
  filterStateSelector,
  (filter) => filter.isOverlayOpen
);
