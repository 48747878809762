// cards
export const SET_SCAN_TO_PAY_CARD_ID = 'SET_SCAN_TO_PAY_CARD_ID';
export const SET_SELECTED_ADD_MONEY_PAYMENT_INSTRUMENT_ID =
  'SET_SELECTED_ADD_MONEY_PAYMENT_INSTRUMENT_ID';
export const SET_SELECTED_AUTO_RELOAD_PAYMENT_INSTRUMENT_ID =
  'SET_SELECTED_AUTO_RELOAD_PAYMENT_INSTRUMENT_ID';
// barcodes
export const FETCH_BARCODE = 'FETCH_BARCODE';
export const FETCH_BARCODE_SUCCESS = 'FETCH_BARCODE_SUCCESS';
export const FETCH_BARCODE_ERROR = 'FETCH_BARCODE_ERROR';
