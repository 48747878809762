import React from 'react';
import Button from '@starbucks-web/pattern-library/lib/components/button/';

import RedeemOptionsButton from 'shared/app/components/redeem-options/redeem-options-button';

const CallToAction = ({
  ctaEnabled,
  couponCode,
  couponStatus = '',
  onClick,
  redeemButtonClickHandler,
  text,
}) => {
  if (couponCode === 'BFB' && couponStatus.toLowerCase() === 'available') {
    return (
      <RedeemOptionsButton
        className="block mt2"
        clickHandler={redeemButtonClickHandler}
      />
    );
  }
  if (ctaEnabled && couponStatus.toLowerCase() === 'available') {
    return (
      <Button className="block mt2" onClick={onClick}>
        {text}
      </Button>
    );
  }
  return null;
};

export default CallToAction;
