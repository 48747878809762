import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Overlay from '@starbucks-web/pattern-library/lib/components/overlay';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

import { expandedStoreStateSelector } from '../../state/selectors';
import { collapseExpandedStore } from '../../state/actions/expanded-store';
import ExpandedLocationContent from '../expanded-location-content';

export const ExpandedLocationContainer = ({ className }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { expanded, store } = useSelector(expandedStoreStateSelector) ?? {};

  if (!expanded || !store?.storeNumber) {
    return null;
  }

  return (
    <Overlay
      alignment="leftCrate"
      className={`absolute ${className || ''}`}
      closeCallback={() =>
        dispatch(collapseExpandedStore({ intendToOrder: false }))
      }
      closeProps={{
        ariaLabel: formatMessage(sharedCallsToAction.closeLabel),
        'data-e2e': 'overlay-close-button',
      }}
      containerProps={{
        'data-e2e': 'overlay-container',
      }}
      showMask={false}
      style={{ zIndex: '2' }}
      useLegacyOverlay
    >
      <ExpandedLocationContent />
    </Overlay>
  );
};

export default ExpandedLocationContainer;
