import { createSelector } from 'reselect';
import { configSelector } from 'shared/app/shell/state/selectors/config';

export const giftAmountOptionsSelector = createSelector(
  configSelector,
  (config) => config.giftAmountOptions
);

export const defaultGiftAmountOptionSelector = createSelector(
  configSelector,
  (config) => config.defaultGiftAmountOption?.toString()
);

export const customAmountMinSelector = createSelector(
  configSelector,
  (config) => config.customAmountMin
);

export const customAmountMaxSelector = createSelector(
  configSelector,
  (config) => config.customAmountMax
);

export const giftConfigSelector = createSelector(
  giftAmountOptionsSelector,
  defaultGiftAmountOptionSelector,
  customAmountMinSelector,
  customAmountMaxSelector,
  (
    giftAmountOptions,
    defaultGiftAmountOption,
    customAmountMin,
    customAmountMax
    // eslint-disable-next-line max-params
  ) => ({
    giftAmountOptions,
    defaultGiftAmountOption,
    customAmountMin,
    customAmountMax,
  })
);

export const cardBalanceUrlSelector = createSelector(
  configSelector,
  (config) => config.cardBalanceUrl
);
