import { lazyContentCrate, lazyHeaderCrate } from 'shared/app/shell';

import {
  FETCH_PERSONAL,
  MUTATE_PERSONAL,
  MUTATE_PARTNER,
  MUTATE_BIRTHDATE,
} from './actions';
import reducer from './state/reducers';

const PersonalHeaderCrate = lazyHeaderCrate(
  () =>
    import(
      /* webpackChunkName: 'personal-header' */ './components/root/header-crate'
    )
);

export default {
  name: 'accountPersonal',
  routes: {
    '/account/personal/partner': {
      headerCrate: PersonalHeaderCrate,
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'starbucks-partner-content' */ './components/starbucks-partner'
          )
      ),
      authRequired: true,
    },
    '/account/personal': {
      headerCrate: PersonalHeaderCrate,
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'personal-content' */ './components/root/content-crate'
          )
      ),
      authRequired: true,
    },
  },
  reducer,
  asyncActions: [
    FETCH_PERSONAL,
    MUTATE_PERSONAL,
    MUTATE_PARTNER,
    MUTATE_BIRTHDATE,
  ],
};
