/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  updatePaymentMethod: {
    id: 'gift.notifications.updatePaymentMethod',
    defaultMessage: 'Something went wrong with your payment method.',
    description:
      'Notification message displayed when payment method does not work',
  },
  enterGiftMessage: {
    id: 'gift.notifications.enterGiftMessage',
    defaultMessage: 'Enter a Gift message.',
    description: 'Notification message displayed when Gift message is missing',
  },
  chooseGiftDesign: {
    id: 'gift.notifications.chooseGiftDesign',
    defaultMessage: 'Choose a Gift design.',
    description: 'Notification message displayed when Gift design is missing',
  },
  enterValidGiftAmount: {
    id: 'gift.notifications.enterValidGiftAmount',
    defaultMessage: 'Enter a Gift amount between $5 and $100.',
    description:
      'Notification message displayed when Gift amount is not a valid amount',
  },
  enterName: {
    id: 'gift.notifications.enterName',
    defaultMessage: 'Enter your name.',
    description:
      'Notification message displayed when Gift sender’s name is missing',
  },
  enterEmail: {
    id: 'gift.notifications.enterEmail',
    defaultMessage: 'Enter your email address.',
    description:
      'Notification message displayed when Gift sender’s email address is missing',
  },
  enterRecipientName: {
    id: 'gift.notifications.enterRecipientName',
    defaultMessage: 'Enter the recipient’s name.',
    description:
      'Notification message displayed when Gift recipient’s name is missing',
  },
  enterRecipientEmail: {
    id: 'gift.notifications.enterRecipientEmail',
    defaultMessage: 'Enter the recipient’s email address.',
    description:
      'Notification message displayed when Gift recipient’s email address is missing',
  },
  addPaymentMethod: {
    id: 'gift.notifications.addPaymentMethod',
    defaultMessage: 'Add a payment method.',
    description:
      'Notification message displayed when Gift payment method is missing',
  },
  invalidDeliveryMethod: {
    id: 'gift.notifications.invalidDeliveryMethod',
    defaultMessage:
      'Delivery method must be an email address or a Facebook account.',
    description:
      'Notification message displayed when Gift delivery method is invalid',
  },
  invalidRecipientInfo: {
    id: 'gift.notifications.invalidRecipientInfo',
    defaultMessage: 'Unable to send. Double check your recipient info.',
    description:
      'Notification message displayed when Gift recipient info is incorrect',
  },
  giftArtUnavailable: {
    id: 'gift.notifications.giftArtUnavailable',
    defaultMessage: 'This gift card art is no longer available.',
    description:
      'Notification message displayed when Gift card art is no longer available',
  },
  addressUnavailable: {
    id: 'gift.notifications.addressUnavailable',
    defaultMessage:
      'The address is not available. Double check the address and try again.',
    description:
      'Notification message displayed when Gift card art is no longer available',
  },
  billingAddressInvalid: {
    id: 'gift.notifications.billingAddressInvalid',
    defaultMessage: 'Something went wrong with your billing address.',
    description:
      'Notification message displayed when Gift billing address is invalid',
  },
  orderFailed: {
    id: 'gift.notifications.orderFailed',
    defaultMessage: 'Something went wrong with your order.',
    description: 'Notification message displayed when Gift order failed',
  },
  creditCardExpired: {
    id: 'gift.notifications.creditCardExpired',
    defaultMessage:
      'This credit/debit card may have expired. Double check your payment method.',
    description:
      'Notification message displayed when Gift credit/debit card expired',
  },
  placeOrderFailed: {
    id: 'gift.notifications.placeOrderFailed',
    defaultMessage: 'Something went wrong with placing this order.',
    description:
      'Notification message displayed when Gift order placement failed',
  },
  recipientNameTooLong: {
    id: 'gift.notifications.recipientNameTooLong',
    defaultMessage: 'Recipient name can be up to 25 characters.',
    description:
      'Notification message displayed when Gift recipient name is too long',
  },
  giftFetchError: {
    id: 'gift.notifications.giftFetchError',
    defaultMessage: 'Could not find the card you were looking for.',
    description:
      'Notification message displayed when a specific card is not found',
  },
  duplicateRecipientErrorHeading: {
    id: 'gift.notifications.duplicateRecipientErrorHeading',
    defaultMessage: 'Uh-oh! We found duplicate emails',
    description:
      'Notification message heading displayed when there are duplicate recipients that are not the sender',
  },
  duplicateRecipientErrorBody: {
    id: 'gift.notifications.duplicateRecipientErrorBody',
    defaultMessage:
      'Duplicate email addresses that do not match the sender email cannot be accepted. Please review and update your recipient detail information.',
    description:
      'Notification message body displayed when there are duplicate recipients that are not the sender',
  },

  // Calls to action
  tryAgainCTA: {
    id: 'gift.notifications.unexpectedErrorCTA',
    defaultMessage: 'Try again',
    description: 'Call to action to try again after Gift order fails',
  },
  updatePaymentMethodCTA: {
    id: 'gift.notifications.updatePaymentMethodCTA',
    defaultMessage: 'Update payment method',
    description: 'Call to action displayed to update Gift payment method',
  },
  addCTA: {
    id: 'gift.notifications.addCTA',
    defaultMessage: 'Add',
    description: 'Call to action displayed when Gift data is missing',
  },
  updateCTA: {
    id: 'gift.notifications.updateCTA',
    defaultMessage: 'Update',
    description: 'Call to action displayed when Gift data needs to be upated',
  },
  giftArtUnavailableCTA: {
    id: 'gift.notifications.giftArtUnavailableCTA',
    defaultMessage: 'Update card art',
    description:
      'Call to action displayed when Gift card art is no longer available',
  },
  addressUnavailableCTA: {
    id: 'gift.notifications.addressUnavailableCTA',
    defaultMessage: 'Edit address',
    description:
      'Call to action displayed when Gift card art is no longer available',
  },
});
