import { combineReducers } from 'redux';

import addressListReducer from './address-list';
import uiReducer from './ui';

const rootReducer = combineReducers({
  addressList: addressListReducer,
  ui: uiReducer,
});

export default rootReducer;
