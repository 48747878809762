import { defineMessages } from 'react-intl';

const messages = defineMessages({
  confirmStoreDetails: {
    id: 'storeLocator.storeList.confirmStoreDetails',
    defaultMessage: 'order here, {storeName}',
    description: 'Reinforces to screen reader which store to order from',
  },
  orderHere: {
    id: 'storeLocator.storeList.orderHere',
    defaultMessage: 'Order Here',
    description: 'Indicates that the user can start an order from this store',
  },
});

export default messages;
