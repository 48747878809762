import React from 'react';
import { useIntl } from 'react-intl';

import DottedUnderline from '../dotted-underline';

const SummaryTotal = ({ pricingSummary, tipAmount = '0' }) => {
  const { formatNumber, formats } = useIntl();
  const total = pricingSummary?.price + parseFloat(tipAmount);

  return (
    <div className="flex mt1 text-md text-bold">
      <div>{pricingSummary?.label}</div>
      <DottedUnderline className="color-textBlackSoft flex-grow" />
      <div data-e2e="total">{formatNumber(total, formats.number.money)}</div>
    </div>
  );
};

export default SummaryTotal;
