import createActionTypes from 'shared/app/state/create-action-types';

export const {
  // Submit Pickup Form
  SUBMIT_PICKUP_FORM,
  SUBMIT_PICKUP_FORM_ERROR,
  SUBMIT_PICKUP_FORM_SUCCESS,
} = createActionTypes(['SUBMIT_PICKUP_FORM']);

export const REFRESH_PICKUP_STORE = 'REFRESH_PICKUP_STORE';
