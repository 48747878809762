import { defineMessages } from 'react-intl';

export default defineMessages({
  onboardingCardsHeaderText: {
    id: 'stream.streamItems.onboarding.onboardingCardsHeaderText',
    description: 'Header text for onboarding cards',
    defaultMessage: 'Let’s get started',
  },
  onboardingCardPayInStoreContent: {
    id: 'stream.streamItems.onboarding.onboardingCardPayInStoreContent',
    description: 'Content text for the Pay in store onboarding card',
    defaultMessage: 'Choose how you want to scan and pay to earn Stars',
  },
  onboardingCardOrderAheadContent: {
    id: 'stream.streamItems.onboarding.onboardingCardOrderAheadContent',
    description: 'Content text for the Order ahead onboarding card',
    defaultMessage:
      'Customize, pay and earn Stars, then head straight to pickup',
  },
  payInStoreTitle: {
    id: 'stream.streamItems.onboarding.payInStoreTitle',
    description: 'Title for scan to pay onboarding card',
    defaultMessage: 'Pay in store',
  },
  orderAheadTitle: {
    id: 'stream.streamItems.onboarding.orderAheadTitle',
    description: 'Title for order ahead onboarding card',
    defaultMessage: 'Order ahead',
  },
});
