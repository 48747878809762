import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';

import { sharedCallsToAction } from 'shared/app/messages/ctas';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import externalLink from '@starbucks-web/pattern-library/lib/icons/external-link';
import externalLinkMessages from 'shared/app/components/external-link/messages.js';
import messages from './messages';

export const REBUILD_CART_DIALOG_ID = 'RebuildCartDialog';

const RebuildCartDialog = ({ doordashStorefrontUrl }) => {
  const { formatMessage } = useIntl();

  return (
    <DialogWithMessages
      cancelText={formatMessage(sharedCallsToAction.nevermind)}
      confirmButtonProps={{
        'aria-label': `${formatMessage(sharedCallsToAction.continue)}, ${formatMessage(externalLinkMessages.externalLinkAria)}`,
      }}
      confirmText={
        <span>
          <FormattedMessage {...sharedCallsToAction.continue} />
          <Icon path={externalLink} />
        </span>
      }
      onConfirm={() => window.open(doordashStorefrontUrl, '_blank')}
    >
      <Heading
        className="text-lg text-semibold mb3"
        id={REBUILD_CART_DIALOG_ID}
        tagName="h2"
      >
        {formatMessage(messages.rebuildCartDialogHeader)}
      </Heading>
      <p className="text-sm">{formatMessage(messages.rebuildCartDialogBody)}</p>
    </DialogWithMessages>
  );
};

export default RebuildCartDialog;
