import {
  REQUEST_EMAIL_VALIDATION_CODE,
  REQUEST_EMAIL_VALIDATION_CODE_ERROR,
} from '../../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case REQUEST_EMAIL_VALIDATION_CODE:
      return Object.assign({}, state, {
        proposedNewEmail: action.payload.proposedNewEmail,
      });
    case REQUEST_EMAIL_VALIDATION_CODE_ERROR:
      return Object.assign({}, state, {
        proposedNewEmail: '',
      });
    default:
      return state;
  }
};
