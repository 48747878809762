import { createSelector } from 'reselect';
import { isEmpty, groupBy } from 'lodash';

import { geolocationStateSelector } from 'shared/app/bundles/geolocation';
import { signedInSelector } from 'shared/app/bundles/user';

import { locationsToDisplaySelector } from 'store-locator/app/state/selectors';
import { SELECT_STORE_MENU } from 'store-locator/app/state/actions/types';
import {
  selectedStoreSelector,
  userDidClearStoreSelector,
} from 'shared/app/state/selectors/ordering';

export const shouldAutoselectStoreSelector = createSelector(
  signedInSelector,
  geolocationStateSelector,
  locationsToDisplaySelector,
  userDidClearStoreSelector,
  selectedStoreSelector,
  // eslint-disable-next-line max-params
  (signedIn, geolocation, locations, userDidClear, selectedStore) => {
    if (
      !signedIn ||
      !geolocation ||
      isEmpty(locations) ||
      userDidClear ||
      !isEmpty(selectedStore)
    ) {
      return null;
    }

    const groups = groupBy(locations, 'recommendationReason');
    if (isEmpty(groups)) {
      return null;
    }

    // Stores are already sorted by closest to the user
    const nearestFavorite = groups?.FAVORITE?.[0];
    const nearestUsual = groups?.USUAL?.[0];
    const nearestNearby = groups?.NEARBY?.[0];

    return nearestFavorite || nearestUsual || nearestNearby;
  }
);

export default [
  {
    selector: shouldAutoselectStoreSelector,
    actionCreator: (selectedStore) => ({
      type: SELECT_STORE_MENU,
      payload: {
        location: selectedStore,
        needsConfirmation: true,
      },
    }),
  },
];
