import React from 'react';
import { useSelector } from 'react-redux';
import PrivacyAndTerms from 'shared/app/components/privacy-and-terms';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import { signedInSelector } from 'shared/app/bundles/user';
import { storeLocatorErrorTypeSelector } from '../../state/selectors';

import LocationCardListTitle from '../location-card-list-title';
import LocatorErrorCard from '../locator-error-card';
import LocationList from './location-list';

import styles from './styles.cssm';

export const LocationCardList = () => {
  const storeLocatorErrorType = useSelector(storeLocatorErrorTypeSelector);
  const isUserSignedIn = useSelector(signedInSelector);
  const isMobileViewport = useSelector(isMobileViewportSelector);

  // Scroll padding is needed for the grouped list view
  const addScrollPadding =
    !storeLocatorErrorType && isUserSignedIn && isMobileViewport;

  return (
    <section
      className={`flex flex-column justify-between ${styles.base}`}
      data-e2e="locationCardList"
      style={{
        scrollPaddingTop: addScrollPadding ? '50px' : '0',
      }}
    >
      <LocationCardListTitle />

      <div className="flex-shrink-none flex-grow">
        {storeLocatorErrorType ? (
          <LocatorErrorCard
            data-e2e="notFoundCard"
            errorType={storeLocatorErrorType}
          />
        ) : (
          <LocationList />
        )}
      </div>
      <div className="px4 pt4 frapPadding lg-pl6">
        <PrivacyAndTerms />
      </div>
    </section>
  );
};

export default LocationCardList;
