import React from 'react';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

const IconTextBlock = ({ iconPath, text }) => {
  return (
    <div className="color-houseGreen text-center inline-block">
      <Icon path={iconPath} />
      <p>{text}</p>
    </div>
  );
};

export default IconTextBlock;
