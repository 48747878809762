import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  currentRouteSelector,
  previousRouteSelector,
} from 'shared/app/state/selectors/routes';
import { signedInSelector } from 'shared/app/bundles/user';

import OrderingUnavailableDialog, {
  ORDERING_UNAVAILABLE_DIALOG_ID,
} from 'shared/app/components/ordering-unavailable-dialog';
import {
  selectedStoreNumberSelector,
  locationsToDisplaySelector,
  inStandaloneStoreLocatorSelector,
} from '../../state/selectors';
import { confirmStore } from '../../state/actions/confirm-store';
import ConfirmStoreFrap from '../confirm-store-frap';

const ConfirmationButton = () => {
  const { openModal } = useModalContext();

  const selectedStoreNumber = useSelector(selectedStoreNumberSelector);
  const locationsToDisplay = useSelector(locationsToDisplaySelector);
  const inStandaloneStoreLocator = useSelector(
    inStandaloneStoreLocatorSelector
  );
  const currentRoute = useSelector(currentRouteSelector);
  const previousRoute = useSelector(previousRouteSelector);
  const isSignedIn = useSelector(signedInSelector);

  const dispatch = useDispatch();

  if (!selectedStoreNumber) {
    return null;
  }

  const selectedStore = locationsToDisplay.find(({ store }) => {
    return store.storeNumber === selectedStoreNumber;
  });

  if (!selectedStore.store) {
    return null;
  }

  const handleConfirmStore = () => {
    const {
      open: isStoreOpen,
      mobileOrdering: { availability, guestOrdering },
    } = selectedStore?.store ?? { mobileOrdering: {} };
    const isEligibleForOrdering = availability === 'READY';
    const isEligibleForGuestOrdering = isEligibleForOrdering && guestOrdering;
    const showAccountRequiredMessage =
      isStoreOpen && isEligibleForOrdering && !isEligibleForGuestOrdering;

    if (
      !isStoreOpen ||
      !isEligibleForOrdering ||
      (!isSignedIn && !isEligibleForGuestOrdering)
    ) {
      return openModal({
        component: OrderingUnavailableDialog,
        ariaLabelledBy: ORDERING_UNAVAILABLE_DIALOG_ID,
        componentProps: {
          selectedStore,
          isStoreOpen,
          showAccountRequiredMessage,
        },
      });
    }

    !inStandaloneStoreLocator
      ? dispatch(confirmStore(selectedStore, currentRoute, previousRoute))
      : (window.location.href = `/menu?storeNumber=${selectedStore?.store?.storeNumber}&distance=${selectedStore.distance}`);
  };

  return (
    <ConfirmStoreFrap
      isFrap={false}
      location={selectedStore}
      onClick={handleConfirmStore}
    />
  );
};

export default ConfirmationButton;
