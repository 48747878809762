/* eslint no-shadow: 0 */
import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@starbucks-web/pattern-library/lib/components/button/';
import { trackStreamCardClick } from '../../../state/track-event';
import CardBase from '../card-base';
import styles from './styles.cssm';

const AddBirthdayCard = (props) => {
  const {
    itemId,
    type,
    idx,
    imageUrl,
    imageAlt,
    title,
    body,
    callToActionText,
  } = props;
  return (
    <CardBase
      body={body}
      containerClasses="md-py5 lg-py3 flex justify-around"
      contentClasses="p3 lg-p4"
      imageAlt={imageAlt}
      imageClasses={`mr3 lg-mr6 ${styles.partyPopper}`}
      imagePosition="right"
      imageSize="sm"
      imageUrl={imageUrl}
      title={title}
    >
      <Button
        className="ml-auto"
        data-e2e="addBirthdayButton"
        onClick={() => {
          /* eslint-disable camelcase */
          trackStreamCardClick({
            stream_card_id: itemId,
            stream_card_type: type,
            stream_card_position: idx.toString(),
          });
          /* eslint-enable camelcase */
        }}
        tagName={Link}
        to="/account/personal"
      >
        {callToActionText}
      </Button>
    </CardBase>
  );
};

export default AddBirthdayCard;
