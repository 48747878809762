import createActionTypes from 'shared/app/state/create-action-types';

export const UPDATE_GIFT_FORM_DATA = 'UPDATE_GIFT_FORM_DATA';
export const ADD_GUEST_PAYMENT_TOKEN_FORM_INVALID =
  'ADD_GUEST_PAYMENT_TOKEN_FORM_INVALID';
export const CLEAR_GUEST_PAYMENT_TOKEN = 'CLEAR_GUEST_PAYMENT_TOKEN';
export const GUEST_PAYMENT_TOKEN_IS_EXPIRED = 'GUEST_PAYMENT_TOKEN_IS_EXPIRED';
export const SET_SELECTED_GIFT_PAYMENT_INSTRUMENT =
  'SET_SELECTED_GIFT_PAYMENT_INSTRUMENT';
export const UPDATE_NUMBER_OF_RECIPIENTS = 'UPDATE_NUMBER_OF_RECIPIENTS';
export const UPDATE_GIFT_PURCHASE_STATUS = 'UPDATE_GIFT_PURCHASE_STATUS';

// TODO clean up WG-12119
export const TEMP_HIDE_CONF_CARD = 'TEMP_HIDE_CONF_CARD';
export const {
  BUY_DIGITAL_GIFT,
  BUY_DIGITAL_GIFT_ERROR,
  BUY_DIGITAL_GIFT_SUCCESS,

  FETCH_DIGITAL_GIFTS_CATALOG,
  FETCH_DIGITAL_GIFTS_CATALOG_ERROR,
  FETCH_DIGITAL_GIFTS_CATALOG_SUCCESS,

  FETCH_GIFT_DETAILS,
  FETCH_GIFT_DETAILS_ERROR,
  FETCH_GIFT_DETAILS_SUCCESS,

  // Guest gifting
  ADD_GUEST_BILLING_ADDRESS,
  ADD_GUEST_BILLING_ADDRESS_ERROR,
  ADD_GUEST_BILLING_ADDRESS_SUCCESS,
  ADD_GUEST_PAYMENT_TOKEN,
  ADD_GUEST_PAYMENT_TOKEN_ERROR,
  ADD_GUEST_PAYMENT_TOKEN_SUCCESS,

  // unauthorized "guest" SVC balance checker
  UNAUTH_SVC_BALANCE_CHECK,
  UNAUTH_SVC_BALANCE_CHECK_ERROR,
  UNAUTH_SVC_BALANCE_CHECK_SUCCESS,
} = createActionTypes([
  'ADD_GUEST_BILLING_ADDRESS',
  'ADD_GUEST_PAYMENT_TOKEN',
  'BUY_DIGITAL_GIFT',
  'CLEAR_GUEST_PAYMENT_TOKEN',
  'FETCH_DIGITAL_GIFTS_CATALOG',
  'FETCH_GIFT_DETAILS',
  'GUEST_PAYMENT_TOKEN_IS_EXPIRED',
  'UNAUTH_SVC_BALANCE_CHECK',
]);
