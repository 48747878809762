import mapUtils from 'shared/app/utils/map-utils';

const pointMarkerZIndex = 20;
const bubbleMarkerZIndex = pointMarkerZIndex - 1;
const bubbleMarkerColor = '#00BDDF'; // Blue bubble marker where the user is geo-located

export const generateBubbleScale = (mapInstance, userGeolocation) => {
  if (!mapInstance || !userGeolocation) {
    return 0;
  }
  const numTiles = Math.pow(2, mapInstance?.getZoom());
  const destLatLng = mapUtils.getRadiusLatLng(
    userGeolocation,
    userGeolocation.accuracy
  );

  const worldDestPoint = mapUtils.getPointFromLatLng(destLatLng);
  const pixelDestPoint = new window.google.maps.Point(
    worldDestPoint.x * numTiles,
    worldDestPoint.y * numTiles
  );

  const worldCenterPoint = mapUtils.getPointFromLatLng(userGeolocation);
  const pixelCenterPoint = new window.google.maps.Point(
    worldCenterPoint.x * numTiles,
    worldCenterPoint.y * numTiles
  );

  const value = Math.abs(pixelDestPoint.y - pixelCenterPoint.y);

  if (Number.isNaN(value)) {
    return 0;
  }

  return value;
};

export const getMarkerIconConfig = (mapInstance, userGeolocation) => ({
  fillColor: bubbleMarkerColor,
  fillOpacity: 0.25,
  path: window.google.maps.SymbolPath.CIRCLE,
  scale: generateBubbleScale(mapInstance, userGeolocation),
  strokeColor: bubbleMarkerColor,
  strokeOpacity: 0.6,
  strokeWeight: 1,
});

export const getMarkerBaseConfig = (mapInstance, userGeolocation) => ({
  icon: getMarkerIconConfig(mapInstance, userGeolocation),
  clickable: false,
  flat: true,
  position: userGeolocation,
  map: mapInstance,
  zIndex: bubbleMarkerZIndex,
});

export const getPointConfig = (markerBaseConfig) => {
  const config = {
    ...markerBaseConfig,
    zIndex: pointMarkerZIndex,
  };
  config.icon.fillOpacity = 1;
  config.icon.scale = 5;
  config.icon.strokeWeight = 0;
  return config;
};
