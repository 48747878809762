/* eslint-disable complexity */

import { push } from 'redux-first-history';

import { messages, callsToAction } from './pricing-messages';
import {
  sharedCallsToAction,
  sharedNotificationMessages,
} from 'shared/app/messages';

const chooseStore = push('/menu/store-locator');

const clearPricing = {
  type: 'CLEAR_ORDER_PRICING',
};

const chooseItem = push('/menu');

export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
export const PRICING_RATE_LIMIT_EXCEEDED = 'PRICING_RATE_LIMIT_EXCEEDED';
export const REWARD_NOT_APPLIED = 'REWARD_NOT_APPLIED';

// https://docs.starbucks.com/pages/viewpage.action?pageId=374514123
const getErrorMessage = (errorCode, data) => {
  switch (errorCode) {
    case 'MISSING_COUNTRY':
    case 'UNEXPECTED_ERROR':
    case 'ITEMS_NOT_FOUND':
    case 'ITEMS_UNAVAILABLE':
      return {
        translatedMessage: data?.translatedMessage,
        callToAction: callsToAction.gotIt,
      };
    case 'STORE_NOT_FOUND':
    case 'HIGH_VOLUME_STORE':
    case 'STORE_UNAVAILABLE':
    case 'STORE_NOT_AVAILABLE':
    case 'WRONG_STORE_REGION':
    case 'MOP_UNAVAILABLE':
    case 'STORE_CLOSED':
    case 'MOP_NOT_ACCEPTED':
      return {
        translatedMessage: data?.translatedMessage,

        callToAction: callsToAction.chooseStore,
        confirmAction: chooseStore,
      };

    case 'CHOOSE_DIFFERENT_ITEM':
      return {
        translatedMessage: data?.translatedMessage,
        callToAction: callsToAction.chooseItem,
        confirmAction: chooseItem,
      };

    case 'ADD_IN_LIMIT':
      return {
        translatedMessage: data?.translatedMessage,
      };
    case REWARD_NOT_APPLIED:
      return {
        message: messages.offerNotApplied,
        callToAction: sharedCallsToAction.gotIt,
      };
    default:
      return {
        message: sharedNotificationMessages.unexpectedError,
        callToAction: callsToAction.tryAgain,
        confirmAction: clearPricing,
      };
  }
};
export const getNotificationForPricingError = (errorCode, data = {}) => {
  return getErrorMessage(errorCode, data);
};
