import { sharedNotificationMessages } from 'shared/app/messages';
import { messages } from './submit-order-messages';

export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
export const INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE';
export const DIGITAL_CARD_PROVISION_ERROR = 'DIGITAL_CARD_PROVISION_ERROR';

const errorCodeToMessageMap = {
  // Reload & Order error codes
  // JIRA/wiki/spaces/WNW/pages/143350963/Reload+Order+Error+Codes+and+Handling
  121005: {
    message: messages.enterReloadAmount,
  },
  121023: {
    message: messages.cardMaxBalanceExceeded,
  },
  121030: {
    message: messages.cardInactive,
  },
  121032: {
    message: messages.cardLostOrStolen,
  },
  121035: {
    message: messages.fraudCardFailure,
  },
  121036: {
    message: messages.invalidReloadAmount,
  },
  121037: {
    message: messages.cardIsClosed,
  },
  121043: {
    message: messages.paymentMethodInvalid,
  },
  121044: {
    message: messages.paymentMethodExpired,
  },
  121045: {
    message: messages.paymentMethodInvalid,
  },
  121046: {
    message: messages.paymentMethodInvalid,
  },
  121048: {
    message: messages.creditCardRejected,
  },
  121059: {
    message: messages.payPalError,
  },
  121060: {
    message: messages.payPalError,
  },
  121061: {
    message: messages.payPalError,
  },
  121062: {
    message: messages.antiFraudViolation,
  },
  121063: {
    message: messages.duplicateTransaction,
  },
  121065: {
    message: messages.paymentMethodInvalid,
  },
  121066: {
    message: messages.paymentMethodInvalid,
  },
  121069: {
    message: messages.americanExpressError,
  },
  121070: {
    message: messages.paymentMethodInvalid,
  },
  121071: {
    message: messages.payPalError,
  },
  121072: {
    message: messages.payPalError,
  },
  121075: {
    message: sharedNotificationMessages.unexpectedError,
  },
  121076: {
    message: messages.paymentMethodInvalid,
  },
  123000: {
    message: sharedNotificationMessages.unexpectedError,
  },
  [INSUFFICIENT_BALANCE]: {
    message: messages.insufficientBalance,
  },
  [DIGITAL_CARD_PROVISION_ERROR]: {
    message: sharedNotificationMessages.unexpectedError,
  },
  [UNEXPECTED_ERROR]: {
    message: sharedNotificationMessages.unexpectedError,
  },
};
export const getNotificationForSubmitError = (errorCode, data) => {
  if (errorCode && data?.translatedMessage) {
    return {
      translatedMessage: data.translatedMessage,
    };
  }
  return (
    errorCodeToMessageMap[errorCode] || errorCodeToMessageMap[UNEXPECTED_ERROR]
  );
};
