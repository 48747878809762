import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  locationsLoadingSelector,
  locationsToDisplaySelector,
  placeNameSelector,
} from '../../state/selectors/location';
import { hasGeolocationSelector } from 'shared/app/bundles/geolocation';
import skipLinkMap from 'shared/app/utils/skip-link-map';
import messages from './messages';
import { useSelector } from 'react-redux';

const renderText = ({
  locationsLoading,
  locationCount,
  userGeolocated,
  placeName,
}) => {
  if (locationsLoading) {
    return <FormattedMessage {...messages.searchResultsLoading} />;
  } else if (placeName) {
    return (
      <FormattedMessage
        {...messages.searchResultsPlacename}
        values={{
          locationCount,
          placeName,
        }}
      />
    );
  } else if (userGeolocated) {
    return (
      <FormattedMessage
        {...messages.SearchResultsGeolocated}
        values={{
          locationCount,
        }}
      />
    );
  }
  return <FormattedMessage {...messages.SearchResultsInitial} />;
};

const LocationCardListTitle = () => {
  const locationsLoading = useSelector(locationsLoadingSelector);
  const locationsToDisplay = useSelector(locationsToDisplaySelector);
  const placeName = useSelector(placeNameSelector);
  const userGeolocated = useSelector(hasGeolocationSelector);

  const text = renderText({
    locationsLoading,
    locationCount: locationsToDisplay?.length,
    userGeolocated,
    placeName,
  });

  return (
    <h2
      aria-atomic="true"
      aria-live="polite"
      className="hiddenVisually"
      id={skipLinkMap.locationCardListTitle}
      role="status"
      tabIndex="-1"
    >
      {text}
    </h2>
  );
};

export default LocationCardListTitle;
