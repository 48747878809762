import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const AccessibleDescription = ({ id, storeName }) => {
  if (!id || !storeName) {
    return null;
  }

  return (
    <p className="hiddenVisually" id={id}>
      <FormattedMessage
        defaultMessage="Expanded details for {storeName}"
        description="Accessible description for store details"
        id="expandedLocationcardDetails.expandedLabel"
        values={{
          storeName,
        }}
      />
    </p>
  );
};

AccessibleDescription.propTypes = {
  id: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
};

export default AccessibleDescription;
