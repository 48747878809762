import { defineMessages } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'locatorSearch.placeholder',
    defaultMessage: 'Find a store',
    description: 'Placeholder attribute for store locator search input',
  },
  formLabel: {
    id: 'locatorSearch.formLabel',
    defaultMessage: 'Search for Starbucks stores',
    description: 'Accessible label for store locator search form',
  },
  buttonLabel: {
    id: 'locatorSearch.buttonLabel',
    defaultMessage: 'Submit search term',
    description: 'Accessible label for store locator search submit button',
  },
  inputLabel: {
    id: 'locatorSearch.inputLabel',
    defaultMessage: 'Enter a location to search for Starbucks stores',
    description: 'Label for store locator search input',
  },
  autoCompleteListLabel: {
    id: 'locatorSearch.autoCompleteListLabel',
    defaultMessage: 'Suggestions will be displayed below as you type',
    description: 'Accessible label for store locator search input',
  },
});

export default messages;
