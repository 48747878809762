// NOTE: we never have to worry about stale barcodes
// because they're always the same for the same number
// if we have them, we have them.
import { createSelector } from 'reselect';
import { activeScanToPayCardSelector } from './cards';
export const barcodesStateSelector = (state) => state.accountCards.barcodes;

export const activeScanToPayBarcodeStateSelector = createSelector(
  activeScanToPayCardSelector,
  barcodesStateSelector,
  (activePaymentCard, barcodesState) => {
    if (!activePaymentCard) {
      return {};
    }

    const { cardId } = activePaymentCard;
    const activeCardBarcodeObject = barcodesState[cardId];

    if (activeCardBarcodeObject) {
      const { uri, cardNumber } = activeCardBarcodeObject.data ?? {};

      // this card's barcode is either being fetched or was fetched already
      return {
        uri,
        cardNumber,
        cardId,
        loading: activeCardBarcodeObject.loading ?? false,
      };
    }
    // we haven't fetched this yet
    return {};
  }
);
