import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ExEnv from 'exenv';

import {
  getMarkerBaseConfig,
  getMarkerIconConfig,
  getPointConfig,
} from './utils';
import {
  mapInstanceSelector,
  userGeolocationSelector,
} from '../../state/selectors';

// eslint-disable-next-line max-statements
const GeolocationMarker = () => {
  const userGeolocation = useSelector(userGeolocationSelector);
  const mapInstance = useSelector(mapInstanceSelector);

  const [pointMarker, setPointMarker] = useState(null);
  const [bubbleMarker, setBubbleMarker] = useState(null);
  const [noMarkersCreated, setNoMarkersCreated] = useState(true);
  const [mapZoomListener, setMapZoomListener] = useState(null);

  const destroyMarkers = () => {
    setPointMarker(null);
    setBubbleMarker(null);
  };

  const isValidPosition = Boolean(userGeolocation?.withinThreshold);

  const updateBubbleMarkerScale = () => {
    if (!bubbleMarker) {
      return;
    }

    bubbleMarker.setIcon(getMarkerIconConfig(mapInstance, userGeolocation));
  };

  const addMapListener = () => {
    if (!ExEnv.canUseDOM) {
      return null;
    }

    return window.google.maps.event.addListener(
      mapInstance,
      'zoom_changed',
      updateBubbleMarkerScale
    );
  };

  const createMarkers = () => {
    const markerBaseConfig = getMarkerBaseConfig(mapInstance, userGeolocation);

    setBubbleMarker(new window.google.maps.Marker(markerBaseConfig));
    setPointMarker(
      new window.google.maps.Marker(getPointConfig(markerBaseConfig))
    );
    setMapZoomListener(addMapListener());
  };

  const updateMarkers = () => {
    if (!pointMarker || !bubbleMarker || !isValidPosition) {
      return;
    }

    pointMarker.setPosition(userGeolocation);
    bubbleMarker.setPosition(userGeolocation);

    updateBubbleMarkerScale();
  };

  const removeMapListener = () => {
    if (!ExEnv.canUseDOM) {
      return;
    }

    // Without this check, an error can be thrown if the route changes before
    // the Google JS has asynchronously executed.
    if (window.google) {
      window.google.maps.event.removeListener(mapZoomListener);
    }
  };

  useEffect(() => {
    // set up 'unmount' handler
    return () => {
      destroyMarkers();
      removeMapListener();
    };
  }, []);

  useEffect(() => {
    if (ExEnv.canUseDOM && isValidPosition && mapInstance && noMarkersCreated) {
      setNoMarkersCreated(false);
      createMarkers();
    }
  }, [isValidPosition, mapInstance, noMarkersCreated]);

  useEffect(() => {
    updateMarkers();
  }, [userGeolocation]);

  return null;
};

export default GeolocationMarker;
