import React from 'react';
import { useIntl } from 'react-intl';

import FeatureItem from './feature-item';

import styles from './styles.cssm';
import messages from './messages';

export const FeatureListItem = ({ feature }) => (
  <li className={styles.featureListItem}>
    <FeatureItem code={feature.code} name={feature.name} />
  </li>
);

export const Features = ({ features }) => {
  const { formatMessage } = useIntl();

  if (features?.length === 0) {
    return (
      <p className="p4 lg-p6">
        {formatMessage(messages.featuresNotAvailableLabel)}
      </p>
    );
  }

  return (
    <ul data-e2e="store-features">
      {features.map((feature, i) => (
        <FeatureListItem feature={feature} key={i} />
      ))}
    </ul>
  );
};

export default Features;
