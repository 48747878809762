import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { isMenuRouteSelector } from 'shared/app/state/selectors/routes';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import { hasActiveFiltersSelector } from '../../state/selectors/selected-features';
import { nearestLocationNotFoundSelector } from '../../state/selectors/nearest-location';
import { locationsLoadingSelector } from '../../state/selectors/location';
import { fetchNearestLocation } from '../../state/actions/nearest-location';

import ErrorCard from './error-card';
import messages from './messages';

const getNoStoresMessage = ({
  nearestLocationNotFound,
  hasActiveFilters,
  isMenuRoute,
}) => {
  if (isMenuRoute) {
    return messages.noStoresFoundOrderingEnabled;
  }

  if (nearestLocationNotFound && hasActiveFilters) {
    return messages.noStoresMessageHasActiveFilters;
  }

  if (nearestLocationNotFound) {
    return messages.noStoresMessagenearestLocationNotFound;
  }

  return messages.noStoresMessageExpand;
};

const renderButton = ({
  isLocationsLoading,
  nearestLocationNotFound,
  isMenuRoute,
}) => {
  if (nearestLocationNotFound || isMenuRoute) {
    return null;
  }
  const dispatch = useDispatch();
  return (
    <Button
      className="mt4"
      data-e2e="noStoresCardExpandSearchButton"
      disabled={isLocationsLoading}
      onClick={() => dispatch(fetchNearestLocation())}
      type="submit"
      visualStyle="positive"
    >
      <FormattedMessage {...messages.expandSearchLabel} />
    </Button>
  );
};

const LocatorErrorNoStore = () => {
  const isMenuRoute = useSelector(isMenuRouteSelector);
  const hasActiveFilters = useSelector(hasActiveFiltersSelector);
  const nearestLocationNotFound = useSelector(nearestLocationNotFoundSelector);
  const isLocationsLoading = useSelector(locationsLoadingSelector);

  const errorMessages = [
    getNoStoresMessage({
      isMenuRoute,
      hasActiveFilters,
      nearestLocationNotFound,
    }),
  ];
  return (
    <ErrorCard
      errorHeading={messages.noStoresHeading}
      errorMessages={errorMessages}
      other={renderButton({
        nearestLocationNotFound,
        isMenuRoute,
        isLocationsLoading,
      })}
    />
  );
};

export default LocatorErrorNoStore;
