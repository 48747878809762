import {
  SET_SCAN_TO_PAY_CARD_ID,
  SET_SELECTED_ADD_MONEY_PAYMENT_INSTRUMENT_ID,
  SET_SELECTED_AUTO_RELOAD_PAYMENT_INSTRUMENT_ID,
} from '../../actions';
import {
  ADD_PAYMENT,
  REMOVE_PAYMENT_INSTRUMENT,
  SET_NEW_PAYMENT_INSTRUMENT,
} from 'shared/app/bundles/wallet';

const defaultState = {
  activeScanToPayCardId: null,
  selectedAddMoneyPaymentInstrumentId: null,
  selectedAutoReloadPaymentInstrumentId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SCAN_TO_PAY_CARD_ID:
      return {
        ...state,
        activeScanToPayCardId: action.payload,
      };
    case SET_SELECTED_ADD_MONEY_PAYMENT_INSTRUMENT_ID:
      return {
        ...state,
        selectedAddMoneyPaymentInstrumentId: action.payload,
      };
    case SET_SELECTED_AUTO_RELOAD_PAYMENT_INSTRUMENT_ID:
      return {
        ...state,
        selectedAutoReloadPaymentInstrumentId: action.payload,
      };
    case SET_NEW_PAYMENT_INSTRUMENT:
      if (state.selectedAddMoneyPaymentInstrumentId === ADD_PAYMENT) {
        return {
          ...state,
          selectedAddMoneyPaymentInstrumentId:
            action.payload.paymentInstrumentId,
        };
      }
      if (state.selectedAutoReloadPaymentInstrumentId === ADD_PAYMENT) {
        return {
          ...state,
          selectedAutoReloadPaymentInstrumentId:
            action.payload.paymentInstrumentId,
        };
      }
      return state;
    case REMOVE_PAYMENT_INSTRUMENT:
      if (state.selectedAddMoneyPaymentInstrumentId === action.payload) {
        return {
          ...state,
          selectedAddMoneyPaymentInstrumentId: null,
        };
      }
      if (
        state.selectedAutoReloadPaymentInstrumentId ===
        action.payload.paymentInstrumentId
      ) {
        return {
          ...state,
          selectedAutoReloadPaymentInstrumentId: null,
        };
      }
    default:
      return state;
  }
};
