import { createSelector } from 'reselect';
import { selectedOrPrimaryCardSelector } from './cards';
import {
  defaultOrFirstPaymentInstrumentSelector,
  selectedOrPrimaryWalletSVCSelector,
} from 'shared/app/bundles/wallet';
import {
  defaultSvcReloadOptionSelector,
  defaultTriggerAmountOptionSelector,
} from './config';

export const autoReloadProfileSelector = createSelector(
  selectedOrPrimaryCardSelector,
  (card) => {
    if (!card) {
      return null;
    }

    return card.autoReloadProfile || {};
  }
);

export const walletAutoReloadProfileSelector = createSelector(
  selectedOrPrimaryWalletSVCSelector,
  (card) => {
    if (!card) {
      return null;
    }

    return card?.autoReloadProfile ?? {};
  }
);

/* eslint-disable max-params */
export const autoReloadDefaultValuesSelector = createSelector(
  walletAutoReloadProfileSelector,
  defaultOrFirstPaymentInstrumentSelector,
  defaultSvcReloadOptionSelector,
  defaultTriggerAmountOptionSelector,
  (profile, defaultPaymentInstrument, defaultAmount, defaultTriggerAmount) => {
    if (!profile) {
      return {};
    }
    const profileActive = profile.status === 'active';
    return {
      amount: profileActive ? profile?.amount : defaultAmount,
      triggerAmount: profileActive
        ? profile?.triggerAmount
        : defaultTriggerAmount,
      paymentInstrumentId: profileActive
        ? profile?.paymentInstrumentId
        : defaultPaymentInstrument.paymentInstrumentId,
    };
  }
);
/* eslint-enable max-params */
