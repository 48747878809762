import {
  CLEAR_SELECTED_FEATURES,
  SET_FEATURES,
  SET_URL_STATE,
} from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case SET_URL_STATE: {
      const { features } = action.payload;

      return features || state;
    }
    case SET_FEATURES: {
      return [...action.payload];
    }
    case CLEAR_SELECTED_FEATURES: {
      return [];
    }
  }

  return state;
};
