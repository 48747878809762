import { createSelector } from 'reselect';
import { selectedOrderPaymentSelector } from 'ordering/app/state/selectors/bottom-sheet';
import { pricingSelector } from './cart';

const SVC = 'SVC';

export const isTippingEligibleSelector = createSelector(
  pricingSelector,
  selectedOrderPaymentSelector,
  (pricing, selectedPayment) => {
    return (
      pricing?.isTipEnabled &&
      pricing?.summary?.price > 0 &&
      selectedPayment?.orderPaymentType === SVC
    );
  }
);
