import { defineMessages } from 'react-intl';

export default defineMessages({
  legalDisclaimerLink: {
    id: 'stream.coupon.legalDisclaimerLinkText',
    defaultMessage: 'See Terms',
    description: 'The text of link to view legal disclaimer in the offers feed',
  },
  legalDisclaimerTitle: {
    id: 'stream.coupon.legalDisclaimerTitle',
    defaultMessage: 'Legal Disclaimer',
    description:
      'The heading of the pop-up regarding the Legal disclaimer in the offers feed',
  },
  redeemOfferSteps: {
    id: 'stream.coupon.redeemOfferSteps',
    defaultMessage:
      'Scan your card at the store and let your barista know about your free drink.',
    description:
      'Used to describe the steps for a user to redeem a coupon at the store',
  },
  seeLegalDisclaimer: {
    id: 'stream.coupon.seeLegalDisclaimer',
    defaultMessage: 'See legal disclaimer',
    description: 'Text for screenreader describing info button',
  },
  terms: {
    id: 'stream.info.terms',
    defaultMessage: 'Terms',
    description: 'Text for button that opens up legal footer information',
  },
});
