import {
  SELECT_STORE_MENU,
  CONFIRM_STORE_MENU,
  CLEAR_STORE_MENU,
  CLEAR_STORE_CONFIRMATION,
} from 'store-locator/app/state/actions/types';

import { SET_LOCATION_BY_NUMBER } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case SELECT_STORE_MENU:
      return {
        location: action.payload.location ?? action.payload,
        needsConfirmation: action.payload.needsConfirmation,
        storeConfirmationTimestamp: Date.now(),
        isLoading: false,
      };
    case SET_LOCATION_BY_NUMBER:
      return {
        ...state,
        isLoading: true,
      };
    case CONFIRM_STORE_MENU:
      return {
        ...state,
        needsConfirmation: false,
        storeConfirmationTimestamp: Date.now(),
        userConfirmed: true,
      };
    case CLEAR_STORE_MENU:
      return {
        storeConfirmationTimestamp: null,
        userConfirmed: false,
        userDidClear: action.payload.userDidClear,
      };
    case CLEAR_STORE_CONFIRMATION:
      return {
        ...state,
        storeConfirmationTimestamp: null,
        userConfirmed: false,
      };
  }
  return state;
};
