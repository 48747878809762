import { createSelector } from 'reselect';
import qs from 'query-string';

import { PseudoHttpError } from 'shared/universal/custom-errors';

import {
  currentRouteSelector,
  isMenuRouteSelector,
} from 'shared/app/state/selectors/routes';
import { showStandaloneAndPwaMenuSelector } from './config';
import { legacyBaseUrlSelector } from 'shared/app/state/selectors/locales';

/**
 * This selector derives five properties from the router:
 *
 * For the root menu page, the return will have a shape like this:
 * {
 *   category: null,
 *   formCode: null,
 *   menu: null,
 *   productNumber: null,
 *   subcategory: null
 *  }
 *
 * For a category page, the return will have a shape like this:
 * {
 *   category: "hot-coffees",
 *   formCode: null,
 *   menu: "drinks",
 *   productNumber: null,
 *   subcategory: null
 *  }
 *
 * For a subcategory page, the return will have a shape like this:
 * {
 *   category: "hot-coffees",
 *   formCode: null,
 *   menu: "drinks",
 *   productNumber: null,
 *   subcategory: "americanos"
 *  }
 *
 * For a product page, the return will have a shape like this:
 * {
 *   category: "hot-coffees",
 *   formCode: "hot",
 *   menu: "drinks",
 *   productNumber: "406",
 *   subcategory: "americanos"
 *  }
 *
 */
export const routeDerivedDataSelector = createSelector(
  currentRouteSelector,
  isMenuRouteSelector,
  (currentRoute, isMenuRoute) => {
    if (currentRoute && isMenuRoute && !currentRoute.route) {
      throw new PseudoHttpError({
        code: 404,
        message: `${currentRoute.pathname} did not match any installed routes`,
      });
    }

    return {
      category: null,
      formCode: null,
      menu: null,
      productNumber: null,
      subcategory: null,
      ...(currentRoute && currentRoute.params),
    };
  }
);

export const seoCanonicalUrlSelector = createSelector(
  currentRouteSelector,
  legacyBaseUrlSelector,
  showStandaloneAndPwaMenuSelector,
  (currentRoute, legacyBaseUrl, showStandaloneAndPwaMenu) => {
    const parentQuery = currentRoute?.query?.parent;
    const parentQueryString = `?${qs.stringify({ parent: parentQuery })}`;

    return showStandaloneAndPwaMenu
      ? `${legacyBaseUrl}${currentRoute.pathname}${
          parentQuery ? parentQueryString : ''
        }`
      : null;
  }
);
