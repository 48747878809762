import ms from 'milliseconds';
import { createSelector } from 'reselect';
import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { appTimeSelector } from 'shared/app/shell';
import { currentRouteSelector } from 'shared/app/state/selectors/routes';

// identity selectors
export const digitalGiftsCatalogSelector = (state) => {
  return state.gift.digitalGiftsCatalog;
};

export const digitalGiftsCatalogDataSelector = createSelector(
  digitalGiftsCatalogSelector,
  (digitalGifts) => digitalGifts.data
);

export const digitalGiftsCategoriesArraySelector = createSelector(
  digitalGiftsCatalogDataSelector,
  (digitalGifts) => {
    if (!digitalGifts || !digitalGifts.categories) {
      return [];
    }

    const giftCategories = digitalGifts.categories.filter(
      (giftCategory) => giftCategory.eGifts && giftCategory.eGifts.length > 0
    );

    return giftCategories;
  }
);

export const shouldfetchDigitalGiftsCatalogSelector = createSelector(
  currentRouteSelector,
  digitalGiftsCatalogSelector,
  appTimeSelector,
  (currentRoute, gifts, time) => {
    const { route } = currentRoute;
    const categoryRoute =
      route === '/gift' || route === '/gift/category/:categoryUrn';

    if (
      !categoryRoute ||
      !shouldUpdate(gifts, {
        staleTime: ms.minutes(15),
        now: time,
      })
    ) {
      return null;
    }
    return true;
  }
);

export const digitalGiftsCategorySelector = createSelector(
  currentRouteSelector,
  digitalGiftsCatalogDataSelector,
  (route, gifts) => {
    if (!gifts) {
      return null;
    }
    return gifts.categories?.find(
      (category) => category.urn === route.params.categoryUrn
    );
  }
);
