import React from 'react';
import { FormattedMessage } from 'react-intl';
import Heading from '@starbucks-web/pattern-library/lib/components/heading/';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import messages from './messages';

export const TIP_REMOVED_ID = 'TipRemovedId';

export const TipRemovedDialog = () => (
  <DialogWithMessages
    confirmButtonOnly
    confirmText={<FormattedMessage {...sharedCallsToAction.gotIt} />}
  >
    <Heading className="mb4" id={TIP_REMOVED_ID} tagName="h2">
      <FormattedMessage {...messages.tipRemovedDialogHeading} />
    </Heading>
    <FormattedMessage {...messages.tipRemovedDialogBody} />
  </DialogWithMessages>
);
