import React from 'react';
import { FormattedMessage } from 'react-intl';
import { push } from 'redux-first-history';
import { omit } from 'lodash';
import {
  addNotification,
  bottomSheetReturnUrlSelector,
  showUnexpectedErrorNotification,
} from 'shared/app/shell';
import runSequentially from 'shared/app/utils/run-sequentially';
import { profileDataSelector } from 'shared/app/bundles/user';
import { currentCountryCodeSelector } from 'shared/app/state/selectors/locales';
import { popBottomsheetUrlFromStorage } from 'shared/app/utils/session-storage-bottom-sheet';
import messages from '../messages';

import { gqlTargets } from 'shared/app/utils/create-gql-fetcher';

import {
  ADD_BILLING_ADDRESS,
  ADD_BILLING_ADDRESS_SUCCESS,
  ADD_BILLING_ADDRESS_ERROR,
  EDIT_BILLING_ADDRESS,
  EDIT_BILLING_ADDRESS_SUCCESS,
  EDIT_BILLING_ADDRESS_ERROR,
} from 'shared/app/bundles/wallet';

import {
  ADD_GUEST_BILLING_ADDRESS,
  ADD_GUEST_BILLING_ADDRESS_SUCCESS,
  ADD_GUEST_BILLING_ADDRESS_ERROR,
  ADD_GUEST_PAYMENT_TOKEN,
  ADD_GUEST_PAYMENT_TOKEN_SUCCESS,
  ADD_GUEST_PAYMENT_TOKEN_ERROR,
  ADD_GUEST_PAYMENT_TOKEN_FORM_INVALID,
} from 'gift/app/actions';

import {
  ADD_SINGLE_USE_BILLING_ADDRESS,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
} from 'shared/universal/gql-operation-ids';

import {
  FETCH_ADDRESS_LIST,
  FETCH_ADDRESS_LIST_SUCCESS,
  FETCH_ADDRESS_LIST_ERROR,
  SET_EDITING,
  CLEAR_EDITING,
  SET_SELECTED_BILLING_ADDRESS_ID,
} from '../actions';

import { GET_ADDRESS_LIST } from '../../../universal/gql-operation-ids';

export const setEditing = (paymentMethodId, paymentMethodIdHash) => ({
  type: SET_EDITING,
  payload: {
    paymentMethodId,
    paymentMethodIdHash,
  },
});

export const clearEditing = () => ({
  type: CLEAR_EDITING,
});

export const setSelectedBillingAddressId = (addressId) => ({
  type: SET_SELECTED_BILLING_ADDRESS_ID,
  payload: {
    addressId,
  },
});

export const fetchAddressList = () => {
  return (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_ADDRESS_LIST });
    return gqlFetch({
      operationId: GET_ADDRESS_LIST,
    })
      .then((data) => {
        const payload = data?.user?.addressList;
        dispatch({ type: FETCH_ADDRESS_LIST_SUCCESS, payload });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ADDRESS_LIST_ERROR, error });
      });
  };
};

const billingAddressSuccessMessage = (
  <FormattedMessage {...messages.addBillingAddressSuccess} />
);

export const addBillingAddress =
  (formData) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: ADD_BILLING_ADDRESS });

    const state = getState();
    const userProfile = profileDataSelector(state);
    const countryCode = currentCountryCodeSelector(state);

    gqlFetch({
      operationId: CREATE_ADDRESS,
      variables: {
        address: {
          type: 'Billing',
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          phoneNumber: formData.phoneNumber,
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          city: formData.city,
          postalCode: formData.postalCode,
          countrySubdivision: formData.countrySubdivision,
          country: countryCode,
        },
      },
    })
      .then((data) => {
        const payload = data.createAddress;

        if (!payload) {
          throw new Error('Failed to add address');
        }

        runSequentially(
          () =>
            dispatch({
              type: ADD_BILLING_ADDRESS_SUCCESS,
              payload,
            }),
          () => dispatch(push('/account/payment-method/add/credit-card')),
          () => dispatch(addNotification(billingAddressSuccessMessage))
        );
      })
      .catch((error) => {
        runSequentially(
          () => dispatch({ type: ADD_BILLING_ADDRESS_ERROR, error }),
          () => dispatch(showUnexpectedErrorNotification())
        );
      });
  };

export const editBillingAddress =
  ({ formData, addressId }) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: EDIT_BILLING_ADDRESS });

    const state = getState();
    const userProfile = profileDataSelector(state);
    const countryCode = currentCountryCodeSelector(state);

    gqlFetch({
      operationId: UPDATE_ADDRESS,
      variables: {
        address: {
          addressId,
          type: 'Billing',
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          phoneNumber: formData.phoneNumber,
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          city: formData.city,
          postalCode: formData.postalCode,
          countrySubdivision: formData.countrySubdivision,
          country: countryCode,
        },
      },
    })
      .then((data) => {
        const payload = data.updateAddress;

        if (!payload) {
          throw new Error('Failed to edit address');
        }

        const successMessage = (
          <FormattedMessage
            defaultMessage="Updated your billing address"
            description="Message displayed when a user successfully edits a billing address"
            id="accountPaymentMethod.notifications.editBillingAddressSuccess"
          />
        );

        runSequentially(
          () =>
            dispatch({
              type: EDIT_BILLING_ADDRESS_SUCCESS,
              payload,
            }),
          () => dispatch(push('/account/payment-method/edit/credit-card')),
          () => dispatch(addNotification(successMessage))
        );
      })
      .catch((error) => {
        runSequentially(
          () => dispatch({ type: EDIT_BILLING_ADDRESS_ERROR, error }),
          () => dispatch(showUnexpectedErrorNotification())
        );
      });
  };

// here
export const addGuestBillingAddress =
  (formData) =>
  (dispatch, getState, { gqlFetch }) => {
    const { WALLET } = gqlTargets;

    dispatch({ type: ADD_GUEST_BILLING_ADDRESS });

    const state = getState();
    const countryCode = currentCountryCodeSelector(state);

    return gqlFetch({
      operationId: ADD_SINGLE_USE_BILLING_ADDRESS,
      target: WALLET,
      variables: {
        singleUseBillingAddressInput: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: formData.phoneNumber,
          line1: formData.addressLine1,
          line2:
            formData.addressLine2.length < 1 ? null : formData.addressLine2,
          city: formData.city,
          postalCode: formData.postalCode,
          countrySubDivision: formData.countrySubdivision,
          country: countryCode,
        },
      },
    })
      .then((data) => {
        const { addressToken } = data.addSingleUseBillingAddress;
        if (!addressToken) {
          throw new Error('Failed to add address');
        }

        runSequentially(
          () =>
            dispatch({
              type: ADD_GUEST_BILLING_ADDRESS_SUCCESS,
              payload: addressToken,
            }),
          () =>
            dispatch(push('/account/payment-method/gift/guest/credit-card')),
          () => dispatch(addNotification(billingAddressSuccessMessage))
        );
      })
      .catch((error) => {
        dispatch({ type: ADD_GUEST_BILLING_ADDRESS_ERROR, error });
        throw error;
      });
  };

export const handleInvalidPaymentForm = () => (dispatch) => {
  dispatch({ type: ADD_GUEST_PAYMENT_TOKEN_FORM_INVALID });
};

export const addGuestPaymentToken =
  (paymentInstrument) => (dispatch, getState) => {
    const state = getState();
    const bottomSheetReturnUrl =
      bottomSheetReturnUrlSelector(state) || popBottomsheetUrlFromStorage();

    dispatch({ type: ADD_GUEST_PAYMENT_TOKEN });

    const paymentInstrumentToken = paymentInstrument?.paymentInstrumentToken;

    if (paymentInstrumentToken) {
      const payload = omit(paymentInstrument, ['action', 'errors']);
      // expired token determined against this timestamp
      payload.tokenAcquiredTimestamp = Date.now();

      runSequentially(
        () => dispatch({ type: ADD_GUEST_PAYMENT_TOKEN_SUCCESS, payload }),
        () => dispatch(push(bottomSheetReturnUrl))
      );
    } else {
      // Secure UI generated error codes 50001 & 50002 are handled here.
      // 500001 : The request was invalid and could not be processed
      // 500002 : Secure payment service request error
      // see: https://scm.starbucks.com/dpapi/SecureUI-V2/blob/develop/README.md#errors
      const error = paymentInstrument?.errors?.[0] ?? '';
      dispatch({ type: ADD_GUEST_PAYMENT_TOKEN_ERROR, error });
      dispatch(showUnexpectedErrorNotification());
    }
  };
