import { defineMessages } from 'react-intl';

export default defineMessages({
  removeCardAfterTransferHeading: {
    id: 'accountCards.removeCardAfterTransferDialog.heading',
    defaultMessage: 'Remove Card?',
    description:
      'Heading option prompting removal of a post transfer, zero balance card',
  },
  removeCardAfterTransferDetail: {
    id: 'accountCards.removeCardAfterTransferDialog.detail',
    defaultMessage:
      'Card ({ lastFourDigits }) now has a $0 balance after the transfer',
    description:
      'Dialog to indicate to the user the transfer of the balance from one card to another was successful and ask them if they want to remove the card that now has zero balance',
  },
  removeCardAfterTransferPhysicalCardNotes: {
    id: 'accountCards.removeCardAfterTransferDialog.physicalCardNotes',
    defaultMessage:
      'The card number and security code are required to add it back.',
    description:
      'Notes to help the user add the physical card in the future if they choose to',
  },
});
