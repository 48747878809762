import React from 'react';
import { useIntl } from 'react-intl';
import { isMetricSelector } from '../../state/selectors/config';
import messages from './messages';
import { useSelector } from 'react-redux';

export const StoreDetails = ({ location }) => {
  const isMetric = useSelector(isMetricSelector);

  const { store } = location;
  const { formatMessage } = useIntl();
  const { openStatusFormatted } = store;

  const getDistanceString = () => {
    const { distance } = location;
    const roundedDistance = Math.round(distance * 10) / 10;

    const distanceAwayTemplate = isMetric
      ? messages.distanceKmAway
      : messages.distanceMilesAway;

    return formatMessage(distanceAwayTemplate, {
      distance: roundedDistance,
    });
  };

  return (
    <React.Fragment>
      <p data-e2e="address">{store.address.singleLine}</p>
      <p>
        <span data-e2e="distance">{getDistanceString()}</span>
        {openStatusFormatted ? ` \u00b7 ` : null}
        {openStatusFormatted ? openStatusFormatted : null}
      </p>
    </React.Fragment>
  );
};

export default StoreDetails;
