import { defineMessages } from 'react-intl';

export default defineMessages({
  emptyMenus: {
    id: 'ordering.menus.empty',
    defaultMessage: 'We could not find any menus.',
    description: 'Message informing the user that the menus do not exist.',
  },
  errorMenus: {
    id: 'ordering.menus.error',
    defaultMessage: 'We ran into problems loading the menus.',
    description:
      'Message informing the user that loading the menus errored out.',
  },
  emptyCategory: {
    id: 'ordering.menuCategory.empty',
    defaultMessage: 'We could not find this category.',
    description:
      'Message informing the user that this category does not exist.',
  },
  emptyProduct: {
    id: 'ordering.product.empty',
    defaultMessage: 'We could not find this product.',
    description: 'Message informing the user that this product does not exist.',
  },
  errorProduct: {
    id: 'ordering.product.error',
    defaultMessage: 'We ran into problems loading this product.',
    description: 'Message informing the user that this product did not load.',
  },
});
