import { combineReducers } from 'redux';

import password from './password';
import mfaPhone from './mfa-phone-number';
import communicationPreferences from './communication-preferences';
import editEmail from './edit-email';
import enterEmailCode from './enter-email-code';

export default combineReducers({
  communicationPreferences,
  enterEmailCode,
  mfaPhone,
  password,
  editEmail,
});
