import { get } from 'lodash';
import { createSelector } from 'reselect';

import shouldUpdate from 'shared/app/stale-reducers/should-update';
import {
  pathnameSelector,
  queryParamsSelector,
} from 'shared/app/state/selectors/routes';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import { publicFeaturesSelector } from 'store-locator/app/state/selectors/config';
import featureIconMap from 'store-locator/app/utils/feature-icon-map';
import {
  IN_STORE_FEATURE_CODE,
  DRIVE_THRU_FEATURE_CODE,
  CURBSIDE_FEATURE_CODE,
  OUTDOOR_FEATURE_CODE,
} from 'shared/universal/pickup-feature-codes';

const CURBSIDE_STATE_AVAILABLE = 'available';
const DEFAULT_CURBSIDE_SPOTS = 4;

const configSelector = (state) => state.config;

const isOutdoorPageSelector = createSelector(pathnameSelector, (pathname) =>
  pathname.includes('outdoor')
);

export const storeSelector = (state) => {
  return get(state, 'pickup.store');
};

export const storeDataSelector = (state) => {
  return get(state, 'pickup.store.data');
};

export const hasFetchedStoreDetailsSelector = createSelector(
  storeSelector,
  (store) => store && !store.loading && store.lastFetch !== null
);

export const storeNameSelector = createSelector(storeDataSelector, (store) => {
  return get(store, 'name');
});

export const curbsidePickupAvailableSelector = createSelector(
  storeDataSelector,
  (store) => {
    return (
      get(store, 'mobileOrdering.curbsideAvailability', '').toLowerCase() ===
        CURBSIDE_STATE_AVAILABLE &&
      get(store, 'pickUpOptions', []).some(
        (el) => el.code === CURBSIDE_FEATURE_CODE
      )
    );
  }
);

export const outdoorPickupAvailableSelector = createSelector(
  storeDataSelector,
  (store) => {
    return (
      get(store, 'mobileOrdering.curbsideAvailability', '').toLowerCase() ===
        CURBSIDE_STATE_AVAILABLE &&
      get(store, 'pickUpOptions', []).some(
        (el) => el.code === OUTDOOR_FEATURE_CODE
      )
    );
  }
);

export const isOutdoorPickupStoreSelector = createSelector(
  storeDataSelector,
  (store) => {
    return get(store, 'pickUpOptions', []).some(
      (el) => el.code === OUTDOOR_FEATURE_CODE
    );
  }
);

export const isCheckInStoreSelector = createSelector(
  storeDataSelector,
  (store) => {
    return get(store, 'pickUpOptions', []).some(
      (el) =>
        el.code === OUTDOOR_FEATURE_CODE || el.code === CURBSIDE_FEATURE_CODE
    );
  }
);

export const isDriveThruStoreSelector = createSelector(
  storeDataSelector,
  (store) => {
    return get(store, 'pickUpOptions', []).some(
      (el) => el.code === DRIVE_THRU_FEATURE_CODE
    );
  }
);

export const isInStorePickupStoreSelector = createSelector(
  storeDataSelector,
  (store) => {
    return get(store, 'pickUpOptions', []).some(
      (el) => el.code === IN_STORE_FEATURE_CODE
    );
  }
);

export const stallQuantitySelector = createSelector(
  storeDataSelector,
  (store) => {
    return store?.mobileOrdering?.stallQuantity || DEFAULT_CURBSIDE_SPOTS;
  }
);

/* eslint-disable max-params */
export const pickupOptionsSelector = createSelector(
  isDriveThruStoreSelector,
  isInStorePickupStoreSelector,
  outdoorPickupAvailableSelector,
  curbsidePickupAvailableSelector,
  (driveThru, inStore, outdoor, curbside) => {
    return [
      inStore && IN_STORE_FEATURE_CODE,
      driveThru && DRIVE_THRU_FEATURE_CODE,
      outdoor && OUTDOOR_FEATURE_CODE,
      curbside && CURBSIDE_FEATURE_CODE,
    ].filter(Boolean);
  }
);
/* eslint-enable max-params */

export const currentRouteSearchSelector = (state) => {
  return get(state, 'routes.current.search', '');
};

export const shouldfetchStoreSelector = createSelector(
  queryParamsSelector,
  storeSelector,
  selectedStoreSelector,
  (params, store, selectedStore) => {
    const { storeNumber: selectedStoreNumber } = selectedStore?.store || {};

    if ((!params.store && !selectedStoreNumber) || !shouldUpdate(store)) {
      return false;
    }
    return params.store || selectedStoreNumber;
  }
);

/* eslint-disable max-params */
export const surveyInitCodeSelector = createSelector(
  configSelector,
  localeTagSelector,
  pickupOptionsSelector,
  isOutdoorPageSelector,
  queryParamsSelector,
  (config, localeTag, options, isOutdoor, queryParams) => {
    if (options.length <= 1) {
      return undefined; // survey requires > 1 available options
    }
    const feature = isOutdoor ? OUTDOOR_FEATURE_CODE : CURBSIDE_FEATURE_CODE;
    const platform = get(queryParams, 'platform', 'native');
    const survey = get(
      config,
      `pickupSurveyCodes.${localeTag}.${feature}.${platform}`
    );
    return survey;
  }
);

export const surveyAvailableSelector = createSelector(
  configSelector,
  surveyInitCodeSelector,
  (config, initCode) => {
    if (!config.pickupSurveyEnabled) {
      return false;
    }
    return typeof initCode === 'string' && initCode !== '';
  }
);

export const pickupOptionsForStoreSelector = createSelector(
  pickupOptionsSelector,
  publicFeaturesSelector,
  (pickupOptions, publicFeatures) =>
    publicFeatures.reduce((sum, { name, code }) => {
      if (pickupOptions.indexOf(code) !== -1) {
        sum.push({
          displayName: name,
          value: code,
          iconPath: featureIconMap[code],
        });
      }
      return sum;
    }, [])
);
