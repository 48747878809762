import React from 'react';

import ErrorCard from './error-card';
import messages from './messages';

export const LocatorErrorGeolocation = () => {
  return (
    <ErrorCard
      errorHeading={messages.geolocationErrorHeading}
      errorMessages={[messages.geolocationErrorBody]}
    />
  );
};

export const LocatorErrorOutsideZoom = () => {
  return (
    <ErrorCard
      errorHeading={messages.zoomOutTooFarHeading}
      errorMessages={[messages.zoomedOutWithResultsMessage]}
    />
  );
};

export const LocatorErrorZoomOrSearch = () => {
  return (
    <ErrorCard
      errorHeading={messages.zoomOutTooFarHeading}
      errorMessages={[messages.zoomOutWithoutResultsMessage]}
    />
  );
};
