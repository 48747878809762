import { defineMessages } from 'react-intl';

export default defineMessages({
  starsWillExpireSoon: {
    id: 'stream.rewardPointsExpiration.starsWillExpireSoon',
    defaultMessage:
      '{points, select, null {Some} other {{points}}} of your Stars are expiring soon',
    description: 'Title text specifying stars will be expiring soon',
  },
  starsWillFadeAway: {
    id: 'stream.rewardPointsExpiration.starsWillFadeAway',
    defaultMessage:
      'Use your Stars before they fade away. {date, select, null {} other { Stars expire on {date}.}}',
    description:
      'Alert text that specified number of stars will fade away on a specified date',
  },
});
