export const FETCH_PERSONAL = 'FETCH_PERSONAL';
export const FETCH_PERSONAL_SUCCESS = 'FETCH_PERSONAL_SUCCESS';
export const FETCH_PERSONAL_ERROR = 'FETCH_PERSONAL_ERROR';
export const MUTATE_PERSONAL = 'MUTATE_PERSONAL';
export const MUTATE_PERSONAL_SUCCESS = 'MUTATE_PERSONAL_SUCCESS';
export const MUTATE_PERSONAL_ERROR = 'MUTATE_PERSONAL_ERROR';
export const MUTATE_PARTNER = 'MUTATE_PARTNER';
export const MUTATE_PARTNER_SUCCESS = 'MUTATE_PARTNER_SUCCESS';
export const MUTATE_PARTNER_ERROR = 'MUTATE_PARTNER_ERROR';
export const MUTATE_BIRTHDATE = 'MUTATE_BIRTHDATE';
export const MUTATE_BIRTHDATE_SUCCESS = 'MUTATE_BIRTHDATE_SUCCESS';
export const MUTATE_BIRTHDATE_ERROR = 'MUTATE_BIRTHDATE_ERROR';
