import { assign } from 'lodash';

import {
  ADD_PRODUCT_TO_CART,
  ADD_OOS_RECOMMENDATION_TO_CART,
  AVAILABILITY_CORRECT,
  CHANGE_QUANTITY,
  CLEAR_ORDER_PRICING,
  FETCH_ORDER_PRICING,
  FETCH_ORDER_PRICING_SUCCESS,
  UPDATE_AVAILABILITY,
  SET_APPLY_CART_OFFER,
  SELECT_PICKUP_OPTION,
} from '../actions/types';
import {
  CLEAR_STORE_MENU,
  SELECT_STORE_MENU,
} from 'store-locator/app/state/actions/types';

/* eslint-disable complexity */

export default (
  state = {
    pricingNeedsToBeFetched: true,
    cartAvailabilityNeedsToBeUpdated: true,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_AVAILABILITY:
    case AVAILABILITY_CORRECT: {
      return assign({}, state, {
        cartAvailabilityNeedsToBeUpdated: false,
      });
    }

    case CLEAR_STORE_MENU:
    case SELECT_STORE_MENU:
    case ADD_PRODUCT_TO_CART:
    case ADD_OOS_RECOMMENDATION_TO_CART:
    case CHANGE_QUANTITY:
    case SELECT_PICKUP_OPTION: {
      return assign({}, state, {
        cartAvailabilityNeedsToBeUpdated: true,
        pricingNeedsToBeFetched: true,
      });
    }

    case SET_APPLY_CART_OFFER:
    case CLEAR_ORDER_PRICING: {
      return assign({}, state, {
        pricingNeedsToBeFetched: true,
      });
    }

    case FETCH_ORDER_PRICING_SUCCESS:
    case FETCH_ORDER_PRICING: {
      return assign({}, state, {
        pricingNeedsToBeFetched: false,
      });
    }

    default:
      return state;
  }
};
