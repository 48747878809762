import React from 'react';
import { variables } from '@starbucks-web/pattern-library/lib/shared-variables';

const style = {
  borderRadius: variables.svcRoundedCorners,
};

const CarouselItem = ({ card }) => {
  return (
    <React.Fragment>
      <img alt="" src={card.uri} style={style} />
      <span className="pt4 inline-block text-sm">{card.text}</span>
    </React.Fragment>
  );
};

CarouselItem.displayName = 'Carousel Item';

export default CarouselItem;
