import React from 'react';
import { useSelector } from 'react-redux';

import { orderAndPickupOptionsSelector } from '../../state/selectors';

import IconTextBlock from './icon-text-block';

import featureIconMap from '../../utils/feature-icon-map';

const StorePickUpOptionIconList = ({ pickUpOptions }) => {
  const orderAndPickupFeatures = useSelector(orderAndPickupOptionsSelector);
  const orderAndPickUpCodes = orderAndPickupFeatures.map(
    (feature) => feature.code
  );

  const listItems = pickUpOptions.reduce((sum, feature, idx) => {
    if (orderAndPickUpCodes.includes(feature.code)) {
      sum.push(
        <li className="inline-block pr3 pt3" key={`pick-up-option-icon-${idx}`}>
          <IconTextBlock
            iconPath={featureIconMap[feature.code]}
            text={feature.name}
          />
        </li>
      );
    }
    return sum;
  }, []);

  return listItems?.length ? <ul>{listItems}</ul> : <div />;
};

export default StorePickUpOptionIconList;
