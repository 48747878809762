import { includes } from 'lodash';

import { COLLAPSE_EXPANDED_STORE, SET_URL_STATE } from './types';
import { replace, goBack, push } from 'redux-first-history';
import { configSelector } from '../selectors/config';

export const closeStoreDetailsOnBackButtonPress = () => (dispatch) => {
  dispatch({ type: COLLAPSE_EXPANDED_STORE });
};

export const goBackAfterLocationChange = () => {
  return goBack();
};

export const setUrlState = (urlState) => (dispatch, getState) => {
  const { zoomThreshold } = configSelector(getState());
  dispatch({
    type: SET_URL_STATE,
    payload: Object.assign({}, urlState, {
      initialUrlZoomOutsideThreshold: urlState.zoomLevel <= zoomThreshold,
    }),
  });
};

export const updateStoreLocatorUrl = ({ pathname, search }) => {
  // paths for store details trigger a PUSH instead of REPLACE to allow
  // for browser back button navigation to return to store locations list
  const url = `${pathname}${search}`;
  if (includes(pathname, '/store/')) {
    return push(url);
  }
  return replace(url);
};
