import { SET_TIP_SUCCESS, DELETE_TIP_SUCCESS } from '../../../actions';

export default (state = {}, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case DELETE_TIP_SUCCESS:
    case SET_TIP_SUCCESS: {
      return {
        ...state,
        ...{
          ...state.pendingTips,
          ...{ [payload.historyId]: parseFloat(payload.tipAmount) },
        },
      };
    }
  }

  return state;
};
