import { combineReducers } from 'redux';

import cart from './cart';
import favorites from './favorite-products';
import modifierAvailability from './modifier-availability';
import notification from './notification';
import order from './order';
import previousOrders from './previous-orders';
import productDetails from './product-details';
import selectedPayment from './selected-payment';
import selectedReloadSourceId from './selected-reload-source-id';
import selectedStore from './selected-store';
import similarItems from './similar-items';
import variables from './variables';
import preOrderEstimate from './pre-order-pickup-estimate';
import orderPickupTime from './order-pickup-time';

export default combineReducers({
  cart,
  favorites,
  modifierAvailability,
  notification,
  order,
  preOrderEstimate,
  previousOrders,
  productDetails,
  selectedPayment,
  selectedReloadSourceId,
  selectedStore,
  similarItems,
  variables,
  orderPickupTime,
});
