import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import getSectionHeading from './get-section-heading';
import { shouldScrollSelector } from '../../state/selectors';

import styles from './styles.cssm';

const GroupedLocationList = ({
  locations,
  renderCard,
  selectedStoreNumber,
  scrollToActiveLocation,
}) => {
  const didClickMapMarker = useSelector(shouldScrollSelector);

  useEffect(() => {
    scrollToActiveLocation({
      storeNumber: selectedStoreNumber,
    });
  }, [selectedStoreNumber, didClickMapMarker]);

  const groups = {
    favorites: locations.filter(
      (location) => location.recommendationReason === 'FAVORITE'
    ),
    previous: locations.filter(
      (location) => location.recommendationReason === 'USUAL'
    ),
    nearby: locations.filter(
      (location) => location.recommendationReason === 'NEARBY'
    ),
  };

  return Object.keys(groups).map((categoryName, groupIndex) => {
    const group = groups[categoryName];

    if (isEmpty(group)) {
      return null;
    }

    return (
      <div data-e2e={`locationListGroup-${categoryName}`} key={groupIndex}>
        <Heading
          className={`sb-global-gutters text-upper text-bold color-textBlackSoft bg-black10 ${styles.heading}`}
          size="xs"
          tagName="h3"
        >
          {getSectionHeading(categoryName)}
        </Heading>
        {group.map((location, idx) => {
          const isActive = location.store.storeNumber === selectedStoreNumber;

          return renderCard({
            isActive,
            location,
            categoryName,
            listPosition: idx,
          });
        })}
      </div>
    );
  });
};

export default GroupedLocationList;
