import { push } from 'redux-first-history';
import {
  sharedCallsToAction,
  sharedNotificationMessages,
} from 'shared/app/messages';
import { messages } from './messages';

const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
const GIFT_FETCH_ERROR = 'giftFetchError';
export const DUPLICATE_RECIPIENT_EMAIL_ERROR = '170046';

// Error codes available at:
// OAPI_DOCS/index.php?title=Place_eGift_Order
export const getNotificationForErrorCode = (errorCode) => {
  const errorNotificationMap = {
    [GIFT_FETCH_ERROR]: {
      message: messages.giftFetchError,
      confirmAction: push('/gift'),
    },
    [DUPLICATE_RECIPIENT_EMAIL_ERROR]: {
      title: messages.duplicateRecipientErrorHeading,
      message: messages.duplicateRecipientErrorBody,
      callToAction: sharedCallsToAction.gotIt,
    },
    [UNEXPECTED_ERROR]: {
      title: sharedNotificationMessages.unexpectedError,
      message: sharedNotificationMessages.tryAgainLater,
      callToAction: sharedCallsToAction.gotIt,
    },
  };

  return (
    errorNotificationMap[errorCode] || errorNotificationMap[UNEXPECTED_ERROR]
  );
};
