import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import SessionCheck from 'shared/app/components/session-check';
import { pathnameSelector } from 'shared/app/state/selectors/routes';

import { transferCardBalance } from '../../state/actions/transfer-balance';
import {
  cardCountSelector,
  selectedOrPrimaryCardSelector,
} from '../../state/selectors/cards';
import TransferBalanceBottomSheetForm from './transfer-balance-bottom-sheet-form';

const TransferBalanceBottomSheet = ({ sourceSvcCard }) => {
  const dispatch = useDispatch();

  const cardCount = useSelector(cardCountSelector);
  const selectedCard =
    sourceSvcCard || useSelector(selectedOrPrimaryCardSelector);
  const currentPathname = useSelector(pathnameSelector);

  const canTransfer = cardCount > 1 && selectedCard?.balance?.amount > 0;

  if (selectedCard && !canTransfer) {
    // redirect to this card's manage page instead of transfer bottom sheet
    dispatch(push(currentPathname));
  }

  const { openModal } = useModalContext();
  const formContainerRef = useRef(null);

  const getFormFields = () => {
    return {
      sourceCardId: {
        input: {
          value: selectedCard?.cardId ?? '',
        },
      },
      targetCardId: {
        input: {
          value: '',
        },
      },
    };
  };

  const handleSubmit = ({ sourceCardId, targetCardId }) => {
    const { balance, isDigital, isPrimary } = selectedCard;

    const confirmRemoveAfterwards = cardCount > 1 && !isPrimary;

    dispatch(
      transferCardBalance({
        amount: balance?.amount,
        sourceCardId,
        targetCardId,
        confirmRemoveAfterwards,
        isDigital,
        openModal,
      })
    );
  };

  return (
    <SessionCheck>
      {selectedCard && (
        <div data-e2e="transferBalanceBottomSheet">
          <FormContainer
            fields={getFormFields()}
            onSubmit={handleSubmit}
            ref={formContainerRef}
          >
            <TransferBalanceBottomSheetForm
              amount={selectedCard?.balance?.amount}
              hideSourceSvcCard={Boolean(sourceSvcCard)}
            />
          </FormContainer>
        </div>
      )}
    </SessionCheck>
  );
};

export default TransferBalanceBottomSheet;
