import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import plusPath from '@starbucks-web/pattern-library/lib/icons/plus';
import minusPath from '@starbucks-web/pattern-library/lib/icons/minus';

import {
  targetMapCoordsSelector,
  mapInstanceSelector,
} from '../../state/selectors';

import styles from './styles.cssm';
import messages from './messages';

// should match settings in map-config.js
const MAP_CONFIG = {
  maxZoom: 21,
  minZoom: 3,
};

export const ZoomControls = () => {
  const { formatMessage } = useIntl();

  const mapInstance = useSelector(mapInstanceSelector);
  const { lat, lng, zoomLevel } = useSelector(targetMapCoordsSelector) || {};

  if (!mapInstance) {
    return null;
  }

  const getZoomHandler = (inOrOut) => {
    return (event) => {
      event.preventDefault();
      let zoom = zoomLevel;

      if (inOrOut === 'in') {
        zoom += 1;
      } else if (inOrOut === 'out') {
        zoom -= 1;
      }

      if (zoom <= MAP_CONFIG.maxZoom && zoom >= MAP_CONFIG.minZoom) {
        mapInstance.setOptions({
          zoom,
          center: { lat, lng },
        });
      }
    };
  };

  const zoomIn = getZoomHandler('in');
  const zoomOut = getZoomHandler('out');

  return (
    <div className="sb-global-gutters mb3">
      <div className={styles.buttonGroup}>
        <button
          aria-label={formatMessage(messages.zoomInLabel)}
          className={styles.zoomIn}
          data-e2e="zoomIn"
          onClick={zoomIn}
        >
          <Icon path={plusPath} />
        </button>
        <button
          aria-label={formatMessage(messages.zoomOutLabel)}
          className={styles.zoomOut}
          data-e2e="zoomOut"
          onClick={zoomOut}
        >
          <Icon path={minusPath} />
        </button>
      </div>
    </div>
  );
};

export default ZoomControls;
