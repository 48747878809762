// this reducer describes all individual barcodes
// we've fetched stored by SKU
import createAggregatorReducer from 'shared/app/stale-reducers/aggregator-reducer';

import { FETCH_BARCODE } from '../../actions';

const { reducer, initialState } = createAggregatorReducer({
  startAction: FETCH_BARCODE,
});

export default (state = initialState, action) => reducer(state, action);
