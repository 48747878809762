/*
 * action types
 */
import { SET_MAP_INSTANCE, MOVE_MAP } from './types';

/*
 * action creators
 */

export const setMapInstance = function (mapInstance) {
  return { type: SET_MAP_INSTANCE, mapInstance };
};

export const moveMap = function (payload) {
  return { type: MOVE_MAP, payload };
};
