import {
  FETCH_PREVIOUS_ORDERS,
  FETCH_PREVIOUS_ORDERS_SUCCESS,
  FETCH_PREVIOUS_ORDERS_ERROR,
  INVALIDATE_MOST_RECENT_ORDER,
} from './types';
import { GET_PREVIOUS_ORDERS } from '../../../universal/gql-operation-ids';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { selectedStoreShortNumberSelector } from 'shared/app/state/selectors/ordering';

export const fetchPreviousOrders =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    const locale = localeTagSelector(getState());
    const storeNumber = selectedStoreShortNumberSelector(getState());

    dispatch({ type: FETCH_PREVIOUS_ORDERS, payload: { locale, storeNumber } });

    return gqlFetch({
      operationId: GET_PREVIOUS_ORDERS,
      variables: { locale, storeNumber, limit: 10 },
    })
      .then((payload) => {
        dispatch({
          type: FETCH_PREVIOUS_ORDERS_SUCCESS,
          payload: payload.previousOrders,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_PREVIOUS_ORDERS_ERROR, error });
      });
  };

export const invalidateMostRecentOrder = () => (dispatch) => {
  dispatch({ type: INVALIDATE_MOST_RECENT_ORDER });
};
