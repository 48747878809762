import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import styles from './styles.cssm';
import CategoryHeading from '../category-heading';
import messages from './messages';

export const ScheduleItem = ({ dayData, index }) => (
  <li
    className={index === 0 ? styles.scheduleItemHighlight : styles.scheduleItem}
  >
    <span className={styles.scheduleDay}>{dayData.dayFormatted}</span>
    <span className={styles.scheduleHours}>
      {dayData.hoursFormatted}
      {dayData.holidayFormatted && (
        <span className="block">{dayData.holidayFormatted}</span>
      )}
    </span>
  </li>
);

const Schedule = ({ schedule, notAvailableStatus }) => {
  if (!schedule || schedule.length === 0) {
    return <p>{notAvailableStatus}</p>;
  }

  return (
    <ul className={styles.schedule} data-e2e="store-schedule">
      {schedule.map((dayData, i) => (
        <ScheduleItem dayData={dayData} index={i} key={i} />
      ))}
    </ul>
  );
};

const StoreSchedule = ({ schedule, hoursStatusFormatted }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <CategoryHeading>
        {formatMessage(messages.scheduleHeading)}
      </CategoryHeading>
      <div className="px4 lg-px6 pt4 pb5">
        <div className="mb4">{hoursStatusFormatted}</div>
        <Schedule
          notAvailableStatus={formatMessage(messages.scheduleNotAvailable)}
          schedule={schedule}
        />
      </div>
    </div>
  );
};

StoreSchedule.propTypes = {
  schedule: PropTypes.array,
};

export default StoreSchedule;
