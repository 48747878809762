import PropTypes from 'prop-types';
import React from 'react';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

const Header = ({ storeData }) => {
  const { name, openStatusFormatted } = storeData;

  return (
    <div className="pb3">
      <Heading
        className={'text-bold pr7 mb1'}
        size="lg"
        // padding right to allow space for close button
        tagName="h2"
      >
        {name}
      </Heading>
      <p className="color-textBlackSoft text-sm">{openStatusFormatted}</p>
    </div>
  );
};

Header.propTypes = {
  storeData: PropTypes.object.isRequired,
};

export default Header;
