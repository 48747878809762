import { getBffBaseUrl } from 'shared/app/utils/config-helper';
import { preOrderPickupEstimateTimeSelector } from 'shared/app/state/selectors/ordering';

import {
  GET_ORDER_PICKUP_TIME,
  GET_ORDER_PICKUP_TIME_SUCCESS,
  GET_ORDER_PICKUP_TIME_ERROR,
  PRE_ORDER_PICKUP_ESTIMATE,
} from './types';

export const getOrderPickupTime = ({ orderId, shortStoreNumber }) => {
  return (dispatch, getState, { apiFetch }) => {
    const url = `${getBffBaseUrl()}/bff/ordering/pickup-time/${orderId}/${shortStoreNumber}`;
    const orderTime = Date.now();

    const preOrderPickupEstimateTime =
      preOrderPickupEstimateTimeSelector(getState());
    console;
    dispatch({
      type: PRE_ORDER_PICKUP_ESTIMATE,
      payload: preOrderPickupEstimateTime,
    });

    dispatch({ type: GET_ORDER_PICKUP_TIME, payload: { orderTime } });

    return apiFetch(url)
      .then((response) => {
        dispatch({
          type: GET_ORDER_PICKUP_TIME_SUCCESS,
          payload: { ...response, orderTime },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ORDER_PICKUP_TIME_ERROR,
          error,
          payload: { orderTime },
        });
      });
  };
};
