import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce, round } from 'lodash';

import {
  mapInstanceSelector,
  targetMapCoordsSelector,
} from '../../state/selectors';
import { moveMap, setMapInstance } from '../../state/actions/map';
import mapConfig from '../../utils/map-config';

import { googleMapsAPIIsReadySelector } from 'shared/app/bundles/scripts';
import {
  addEventListener,
  removeEventListener,
} from 'shared/app/utils/event-listener';
import { getPositionFromMapInstance } from 'shared/app/utils/map-utils';

import styles from './styles.cssm';

let resizeCounter = 0;
const googleMapsMarginOfError = 0.000005;

export class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.debouncedOnWindowResize = debounce(this.onWindowResize, 200).bind(
      this
    );
  }

  componentDidMount() {
    this.attemptMapInitialization();
  }

  componentWillUnmount() {
    if (this.props.googleMapsAPIIsReady) {
      // avoid leaks
      window.google.maps.event.clearInstanceListeners(this.props.mapInstance);
    }
    this.props.setMapInstance(null);
    removeEventListener(window, 'resize', this.debouncedOnWindowResize);
  }

  componentDidUpdate() {
    this.attemptMapInitialization();
  }

  // Defer map initialization until location card list ref is available
  // because map positioning depends on location card list.
  attemptMapInitialization() {
    const { props } = this;

    if (props.googleMapsAPIIsReady && !props.mapInstance) {
      const mapInstance = this.initializeMap();
      props.setMapInstance(mapInstance);
      addEventListener(window, 'resize', this.debouncedOnWindowResize);
    }
  }

  initializeMap() {
    const { Map, event } = window.google.maps;
    const { getMapConfig } = mapConfig;

    // Create and insert a Google Map.
    // https://developers.google.com/maps/documentation/javascript/reference#Map
    const mapInstance = new Map(this.$mapElement, getMapConfig());

    event.addListener(mapInstance, 'idle', () => {
      this.handleIdle(mapInstance);
    });

    return mapInstance;
  }

  onWindowResize() {
    if (!this.props.mapInstance) {
      return;
    }

    // we do this to trigger a re-render of this component
    // on window resize to ensure it's recentered, etc.
    this.setState({ resizeCount: ++resizeCounter });
  }

  handleIdle() {
    const { mapInstance } = this.props;
    const hasPositionChanged = this.getPositionChanged();

    if (hasPositionChanged) {
      const position = getPositionFromMapInstance(mapInstance);
      this.props.moveMap({
        ...position,
        userOverride: true,
      });
    }
  }

  getPositionChanged() {
    const { mapInstance } = this.props;
    const center = mapInstance.getCenter();
    // Coordinates returned by `getCenter` do not wrap at 180 degrees,
    // so we initialize a new `LatLng` object to wrap them.
    const wrappedCenter = new window.google.maps.LatLng({
      lat: center.lat(),
      lng: center.lng(),
    });

    const actualMapPosition = {
      lat: round(wrappedCenter.lat(), 6),
      lng: round(wrappedCenter.lng(), 6),
      zoomLevel: mapInstance?.getZoom(),
    };

    const positionFromProps = {
      lat: round(this.props.lat, 6),
      lng: round(this.props.lng, 6),
      zoomLevel: this.props.zoomLevel,
    };

    for (const key in actualMapPosition) {
      if (
        Math.abs(actualMapPosition[key] - positionFromProps[key]) >
        googleMapsMarginOfError
      ) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { lat, lng, mapInstance, zoomLevel } = this.props;

    if (mapInstance) {
      if (mapInstance?.getZoom() !== zoomLevel) {
        mapInstance.setOptions({
          zoom: zoomLevel,
          center: { lat, lng },
        });
      } else {
        mapInstance.panTo({ lat, lng });
      }
    }

    return <div className={styles.map} ref={(el) => (this.$mapElement = el)} />;
  }
}

GoogleMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  mapInstance: PropTypes.object,
  moveMap: PropTypes.func.isRequired,
  setMapInstance: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number.isRequired,
};

const select = (state) => ({
  ...targetMapCoordsSelector(state),
  googleMapsAPIIsReady: googleMapsAPIIsReadySelector(state),
  mapInstance: mapInstanceSelector(state),
});

const actions = { moveMap, setMapInstance };

export default connect(select, actions)(GoogleMap);
