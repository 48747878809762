import arrowUpRight from '@starbucks-web/pattern-library/lib/icons/arrow-up-right';
import digitalRewardsPath from '@starbucks-web/pattern-library/lib/icons/digital-rewards';
import eveningsPath from '@starbucks-web/pattern-library/lib/icons/evenings';
import fizzioPath from '@starbucks-web/pattern-library/lib/icons/fizzio';
import laBoulangePath from '@starbucks-web/pattern-library/lib/icons/la-boulange';
import mercatoPath from '@starbucks-web/pattern-library/lib/icons/mercato';
import mobileOrderingPath from '@starbucks-web/pattern-library/lib/icons/mobile-ordering';
import mobileOrderPayPath from '@starbucks-web/pattern-library/lib/icons/mobile-order-pay';
import nitroBrewPath from '@starbucks-web/pattern-library/lib/icons/nitro-brew';
import oleatoPath from '@starbucks-web/pattern-library/lib/icons/oleato';
import openNowPath from '@starbucks-web/pattern-library/lib/icons/open-now';
import ovenWarmedPath from '@starbucks-web/pattern-library/lib/icons/oven-warmed';
import pickupCurbsidePath from '@starbucks-web/pattern-library/lib/icons/pickup-curbside';
import pickupDriveThruPath from '@starbucks-web/pattern-library/lib/icons/pickup-drive-thru';
import pickupInStorePath from '@starbucks-web/pattern-library/lib/icons/pickup-in-store';
import pickupOutdoorPath from '@starbucks-web/pattern-library/lib/icons/pickup-outdoor';
import redeemRewardsPath from '@starbucks-web/pattern-library/lib/icons/redeem-rewards';
import reservePath from '@starbucks-web/pattern-library/lib/icons/reserve';
import seatingCafe from '@starbucks-web/pattern-library/lib/icons/seating-cafe';
import seatingOutdoor from '@starbucks-web/pattern-library/lib/icons/seating-outdoor';
import twentyFourHoursPath from '@starbucks-web/pattern-library/lib/icons/twenty-four-hours';
import verismoPath from '@starbucks-web/pattern-library/lib/icons/verismo';
import wifiPath from '@starbucks-web/pattern-library/lib/icons/wifi';
import cup from '@starbucks-web/pattern-library/lib/icons/cup';

export default {
  16: pickupInStorePath,
  17: pickupOutdoorPath,
  hrs24: twentyFourHoursPath,
  BE: cup,
  CD: mobileOrderingPath,
  CL: reservePath,
  CS: seatingCafe,
  CX: pickupCurbsidePath,
  DR: redeemRewardsPath,
  DT: pickupDriveThruPath,
  EM: eveningsPath,
  FZ: fizzioPath,
  GC: mobileOrderPayPath,
  GO: wifiPath,
  LB: laBoulangePath,
  MC: mercatoPath,
  NB: nitroBrewPath,
  ON: openNowPath,
  OO: oleatoPath,
  OS: seatingOutdoor,
  RW: digitalRewardsPath,
  UP: arrowUpRight,
  VS: verismoPath,
  WA: ovenWarmedPath,
  WF: wifiPath,
  XO: mobileOrderPayPath,
};
