import { createSelector } from 'reselect';
import { DEFAULT_SEND_METHOD, MAX_RECIPIENTS } from '../../utils/gift-form';

export const giftFormSelector = (state) => state.gift.giftForm;

export const numberOfRecipientsSelector = createSelector(
  giftFormSelector,
  (giftForm) => giftForm.numberOfRecipients
);

export const giftFormDataSelector = createSelector(
  giftFormSelector,
  (giftForm) => giftForm.formData
);

export const hasRequiredGiftFormDataSelector = createSelector(
  giftFormDataSelector,
  (formData = {}) => {
    const formKeys = Object.keys(formData);
    const includesAtLeastOneRecipientEmail = formKeys?.some((key) =>
      key.includes('recipientEmail')
    );
    const includesAtLeastOneRecipientName = formKeys?.some((key) =>
      key.includes('recipientName')
    );
    return (
      Boolean(formData.productNumber) &&
      Boolean(formData.senderName) &&
      Boolean(formData.senderEmail) &&
      (Boolean(formData.amount) ||
        (formData.amount === 'custom' && Boolean(formData?.customAmount))) &&
      includesAtLeastOneRecipientEmail &&
      includesAtLeastOneRecipientName
    );
  }
);

export const expectingGiftResponseSelector = createSelector(
  giftFormSelector,
  (giftForm) => giftForm.expectingGiftResponse
);

export const giftAmountSelector = createSelector(
  giftFormDataSelector,
  (giftFormData) => {
    const amount = giftFormData?.amount;
    const customAmount = giftFormData?.customAmount;
    if (!amount && !customAmount) {
      return null;
    }
    return amount === 'custom' ? customAmount : amount;
  }
);

// preps egifts to be sent to API
export const egiftsSelector = createSelector(
  giftFormDataSelector,
  giftAmountSelector,
  (giftFormData, amount) => {
    const { productNumber, message } = giftFormData;
    const fields = Object.keys(giftFormData);
    const egifts = [];

    fields.forEach((fieldKey) => {
      if (fieldKey.includes('recipientEmail')) {
        const recipientId = fieldKey.slice(14);
        if (egifts.length >= MAX_RECIPIENTS) return;
        return egifts.push({
          recipientEmail: giftFormData[fieldKey],
          recipientName: giftFormData[`recipientName${recipientId}`],
          deliveryMethod: DEFAULT_SEND_METHOD,
          deliveryDate: new Date().toDateString(),
          product: productNumber,
          message,
          amount,
        });
      }
    });
    return egifts;
  }
);

export const giftAmountTotalSelector = createSelector(
  egiftsSelector,
  (egifts) => {
    const amount = parseInt(egifts[0]?.amount) || 0; // default to 0 instead of NaN
    const numberOfGifts = egifts.length;
    return numberOfGifts * amount;
  }
);

export const isGroupGiftSelector = createSelector(
  numberOfRecipientsSelector,
  (numberOfRecipients) => {
    return numberOfRecipients > 1;
  }
);
