import { FETCH_GIFT_HISTORY, CLEAR_GIFT_HISTORY } from '../../../actions';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_GIFT_HISTORY,
});

export default (state = initialState, action) => {
  state = reducer(state, action);
  if (action.type === CLEAR_GIFT_HISTORY) {
    return initialState;
  }
  return state;
};
