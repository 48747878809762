const currentYear = new Date().getFullYear();

export const seasonConfig = {
  autumnOne: {
    start: new Date(`${currentYear}-08-22T00:00:00`),
    end: new Date(`${currentYear}-09-15T23:59:59`),
    emoji: {
      morning: '🍂',
      afternoon: '☕️',
      evening: '🎃',
    },
  },
  autumnTwo: {
    start: new Date(`${currentYear}-09-16T00:00:00`),
    end: new Date(`${currentYear}-11-06T23:59:59`),
    emoji: {
      morning: '🍂',
      afternoon: '☕️',
      evening: '🧣',
    },
  },
  // had to hardcode holiday start/end date years bc the holiday season dates cross from 2024 to 2025, which if we use currentYear/currentYear+1, it will break when it becomes the new year
  holiday: {
    start: new Date(`2024-11-07T00:00:00`),
    end: new Date(`2025-01-02T23:59:59`),
    emoji: {
      morning: '✨',
      afternoon: '☕️',
      evening: '✨',
    },
  },
  spring: {
    start: new Date(`${currentYear}-03-06T00:00:00`),
    end: new Date(`${currentYear}-04-14T23:59:59`),
    emoji: {
      morning: '🌷',
      afternoon: '🌸',
      evening: '🌈',
    },
  },
  summerOne: {
    start: new Date(`${currentYear}-05-07T00:00:00`),
    end: new Date(`${currentYear}-06-24T23:59:59`),
    emoji: {
      morning: '😎',
      afternoon: '🏖️',
      evening: '🙌',
    },
  },
  summerTwo: {
    start: new Date(`${currentYear}-06-25T00:00:00`),
    end: new Date(`${currentYear}-08-26T23:59:59`),
    emoji: {
      morning: '☀️',
      afternoon: '🤩',
      evening: '😎',
    },
  },
  default: {
    emoji: {
      morning: '☕️',
      afternoon: '☀️',
      evening: '🌙',
    },
  },
};
