import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { defer } from 'lodash';
import classNames from 'classnames';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import searchPath from '@starbucks-web/pattern-library/lib/icons/search';

import Form from 'shared/app/components/form';
import { getFormattedPlaceName } from 'shared/app/utils/map-utils';

import styles from './styles.cssm';
import messages from './messages';

class LocatorSearch extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.state = {
      autocomplete: null,
      searchValue: props.defaultValue,
    };
  }

  componentDidMount() {
    if (this.props.mapInstance) {
      this.initAutocomplete();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.defaultValue && prevProps.defaultValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.clearSearch();
    }
    if (this.props.mapInstance && !this.state.autocomplete) {
      this.initAutocomplete();
    }
  }

  componentWillUnmount() {
    window?.google?.maps?.event?.clearListeners(
      this.state.autocomplete,
      'place_changed'
    );
  }

  clearSearch() {
    this.setState({
      searchValue: '',
    });
  }

  initAutocomplete() {
    const { mapInstance, handleSearch } = this.props;
    const autocomplete = new window.google.maps.places.Autocomplete(
      this.$locatorSearchInput
    );

    autocomplete.setTypes(['geocode']);
    autocomplete.bindTo('bounds', mapInstance);

    window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      const formattedAddress = getFormattedPlaceName(place);

      // condition handles user inputting their own search query
      // as opposed to selecting from the Google suggested options
      if (formattedAddress) {
        this.setState({
          searchValue: formattedAddress,
        });
        handleSearch(formattedAddress);
      }
    });
    this.setState({ autocomplete });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { searchValue } = this.state;

    if (searchValue === '') {
      return;
    }

    this.props.handleSearch(searchValue);

    // Hide the keyboard on android devices
    defer(() => this.$locatorSearchInput.blur());
  }

  handleChange(e) {
    this.setState({
      searchValue: e.target.value,
    });
  }

  render() {
    const { props } = this;
    const { formatMessage } = props.intl;

    return (
      <Form
        action="/store-locator"
        aria-label={formatMessage(messages.formLabel)}
        className={classNames(styles.form, props.className)}
        method="get"
        onSubmit={this.handleSubmit}
        role="search"
      >
        <input
          aria-describedby="autoCompleteList"
          aria-label={formatMessage(messages.inputLabel)}
          className={`text-sm pr7 ${styles.input}`}
          data-e2e="searchTermInput"
          name="place"
          onChange={this.handleChange}
          placeholder={formatMessage(messages.placeholder)}
          ref={(el) => (this.$locatorSearchInput = el)}
          type="search"
          value={this.state.searchValue}
        />
        <span className="hiddenVisually" id="autoCompleteList">
          <FormattedMessage {...messages.autoCompleteListLabel} />
        </span>
        <div className={classNames(styles.buttonContainer)}>
          <button
            aria-label={formatMessage(messages.buttonLabel)}
            className={styles.button}
            data-e2e="submitSearchTermButton"
          >
            <Icon className={styles.icon} path={searchPath} size="24px" />
          </button>
        </div>
      </Form>
    );
  }
}

LocatorSearch.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  mapInstance: PropTypes.object,
};

export default injectIntl(LocatorSearch);
