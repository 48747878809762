import { defineMessages } from 'react-intl';

const messages = defineMessages({
  linkLabel: {
    id: 'locationCard.linkLabel',
    defaultMessage: 'Store details for {storeName}',
    description: "Text of link to view a single store's details",
  },
  distanceKmAway: {
    id: 'locationCard.kmAway',
    description: 'Label for distance away from the user in kilometers',
    defaultMessage: '{distance} km away',
  },
  distanceMilesAway: {
    id: 'locationCard.milesAway',
    description: 'Label for distance away from the user in miles',
    defaultMessage: '{distance, plural, one {# mile} other {# miles}} away',
  },
  addFavoriteLabel: {
    id: 'locationCard.addFavoriteLabel',
    defaultMessage: 'Add {storeName} to your list of favorite stores',
    description: 'Text of button to save store as a favorite',
  },
  removeFavoriteLabel: {
    id: 'locationCard.removeFavoriteLabel',
    defaultMessage: 'Remove {storeName} from your list of favorite stores',
    description: 'Text of button to remove store from favorites',
  },
});

export default messages;
