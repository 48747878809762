import React from 'react';
import WarningBlackExclamation from '@starbucks-web/pattern-library/lib/components/icons/warning-black-exclamation';

const StoreWarnings = ({ store }) => {
  if (!store?.warningLabels?.length) {
    return null;
  }

  return (
    <ul data-e2e="store-warnings">
      {store.warningLabels.map((warning, idx) => {
        return (
          <li key={`store-warning-${idx}-${warning.code}`}>
            <div className="flex items-center mt2">
              <WarningBlackExclamation size="16px" />
              <span className="color-textBlackSoft ml1">{warning.label}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default StoreWarnings;
