import React from 'react';
import { connect } from 'react-redux';
import GlobalNav from 'shared/app/components/global-nav';
import { ThemeContext } from '@starbucks-web/pattern-library/lib/components/theme';

import { mainNavLinksConfigSelector } from '../state/ui-selectors';

const CoreAppGlobalNav = ({
  mainNavLinksConfig,
  theme,
  useFixedDesktopNav,
}) => {
  return (
    <ThemeContext.Provider value={theme}>
      <GlobalNav
        mainNavLinksConfig={mainNavLinksConfig}
        useFixedDesktopNav={useFixedDesktopNav}
      />
    </ThemeContext.Provider>
  );
};

const select = (state) => ({
  mainNavLinksConfig: mainNavLinksConfigSelector(state),
});

export default connect(select)(CoreAppGlobalNav);
