import { defineMessages } from 'react-intl';

export default defineMessages({
  selectReloadAmount: {
    id: 'accountCards.addMoneyBottomSheet.selectReloadAmount',
    defaultMessage: 'Load this amount',
    description: 'Label for dropdown with available reload amounts',
  },
  addMoneyHeading: {
    id: 'accountCards.addMoneyBottomSheet.addMoneyHeading',
    defaultMessage: 'Add money to your Starbucks Card',
    description: "Heading for the 'Add money to your card' screen",
  },
  autoReloadActive: {
    id: 'accountCards.addMoneyBottomSheet.autoReloadActive',
    defaultMessage: '{isActive, select, true {On} other {Off}}',
    description:
      'Label for auto reload active status in add money bottom sheet',
  },
  autoReloadStatusMessage: {
    id: 'accountCards.addMoneyBottomSheet.autoReloadStatusMessage',
    // eslint-disable-next-line max-len
    defaultMessage:
      '{amount, number, money} when balance falls below {triggerAmount, number, money}',
    description:
      'Label for auto reload status message in add money bottom sheet',
  },
  addMoneyButton: {
    id: 'accountCards.addMoneyBottomSheet.addMoneyButton',
    defaultMessage: 'Add {amount, number, money}',
    description: 'Label button which completes the action of adding money',
  },
});
