import { defineMessages } from 'react-intl';

export default defineMessages({
  searchResultsLoading: {
    defaultMessage: 'Loading Store Search Results',
    description: 'Loading title of store search results.',
    id: 'locationCardListTitle.loading',
  },
  searchResultsPlacename: {
    defaultMessage: `{
      locationCount, plural,
        =0 { No Stores }
        one { 1 Store }
        other {{locationCount} Stores}
      } near {placeName}`,
    description:
      'Title of store search results when user has searched for a place.',
    id: 'locationCardListTitle.placename',
  },
  SearchResultsGeolocated: {
    defaultMessage: `{
      locationCount, plural,
        =0 { No Stores }
        one { 1 Store }
        other {{locationCount} Stores}
      } near your location`,
    description:
      "Title of store search results when the user's location is known.",
    id: 'locationCardListTitle.geolocated',
  },
  SearchResultsInitial: {
    defaultMessage: 'Store Search Results',
    description: 'Initial title of store search results.',
    id: 'locationCardListTitle.initial',
  },
});
