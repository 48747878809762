import React from 'react';
import { useIntl } from 'react-intl';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import infoPath from '@starbucks-web/pattern-library/lib/icons/info';
import heartPath from '@starbucks-web/pattern-library/lib/icons/heart';
import heartSolidPath from '@starbucks-web/pattern-library/lib/icons/heart-solid';

import { buildStoreUrl } from '../../../universal/utils/expanded-store-url';

import messages from './messages';
import { trackStoreFinderDetailsView } from '../../state/actions/track-event';

const StoreActionIcons = ({
  location,
  handleFavoriteClick,
  handleInfoClick,
}) => {
  const { formatMessage } = useIntl();
  const { store } = location;

  const renderFavoriteButton = () => {
    const isFavorite = location.isFavorite;

    const label = formatMessage(
      isFavorite ? messages.removeFavoriteLabel : messages.addFavoriteLabel,
      { storeName: store.name }
    );

    return (
      <button
        aria-label={label}
        className="relative"
        data-e2e="favorite"
        onClick={(...args) => {
          handleFavoriteClick(...args);
        }}
      >
        <Icon
          className={isFavorite ? 'color-greenAccent' : 'color-textBlackSoft'}
          path={isFavorite ? heartSolidPath : heartPath}
          size="24px"
        />
      </button>
    );
  };

  const renderInfoButton = () => {
    return (
      <a
        aria-label={formatMessage(messages.linkLabel, {
          storeName: store.name,
        })}
        className="relative color-textBlackSoft ml4"
        data-e2e="cardLink"
        href={buildStoreUrl(store)}
        onClick={(...args) => {
          handleInfoClick(...args);
          /* eslint-disable camelcase */
          trackStoreFinderDetailsView({
            content_state:
              store.mobileOrdering?.guestOrdering === false
                ? 'gco not available'
                : '',
            store_id: store.id,
          });
          /* eslint-enable camelcase */
        }}
      >
        <Icon path={infoPath} size="24px" />
      </a>
    );
  };

  return (
    <div className="flex items-start">
      {renderFavoriteButton()}
      {renderInfoButton()}
    </div>
  );
};

export default StoreActionIcons;
