import { defineMessages } from 'react-intl';

export default defineMessages({
  filterToggleLabel: {
    id: 'locatorControls.filterToggleLabel',
    description:
      'Accessible hint for screenreaders on the toggle button for store search filters',
    defaultMessage: 'Show options for filtering store results',
  },
  activeFilters: {
    id: 'locatorControls.activeFilterCount',
    defaultMessage: `{filterCount, plural,
      zero {no filters}
      one {# filter}
      other {# filters}
    } active`,
    description:
      'Message indicating the number of active filter for the store search',
  },
  filterHeading: {
    defaultMessage: 'Search Controls',
    description: 'Store locator filter heading',
    id: 'locatorControls.filterHeading',
  },
  filterToggle: {
    defaultMessage: 'Filter',
    description: 'Store locator filter button',
    id: 'locatorControls.filterToggle',
  },
});
