import {
  FETCH_PERSONAL,
  MUTATE_PERSONAL,
  MUTATE_PARTNER,
  MUTATE_PARTNER_ERROR,
} from '../../actions';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_PERSONAL,
  mutateAction: MUTATE_PERSONAL,
});

export default (state = initialState, action) => {
  state = reducer(state, action);
  if (action.type === MUTATE_PARTNER) {
    return Object.assign({}, state, { editPartnerNumberError: null });
  }
  if (action.type === MUTATE_PARTNER_ERROR) {
    return Object.assign({}, state, { editPartnerNumberError: action.error });
  }
  return state;
};
