'use strict';

module.exports = {
  PRICE_ORDER: 'price-order',
  PRICE_ORDER_GUEST: 'price-order-guest',
  PLACE_ORDER: 'submit-order',
  PLACE_ORDER_GUEST: 'submit-order-guest',
  GET_FAVORITE_PRODUCTS: 'get-favorite-products',
  ADD_FAVORITE_PRODUCT: 'add-favorite-product',
  DELETE_FAVORITE_PRODUCT: 'delete-favorite-product',
  GET_PREVIOUS_ORDERS: 'get-previous-orders',
  GET_MENU: 'get-menu',
  GET_PRODUCT: 'get-product',
};
