import { defineMessages } from 'react-intl';

const messages = defineMessages({
  caTaxLabel: {
    id: 'ordering.pricingSummary',
    defaultMessage: 'CA prices of taxable food and beverage include sales tax.',
    description: 'Explains California sales tax',
  },
});

export default messages;
