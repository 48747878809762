import { filter } from 'lodash';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import {
  FETCH_FAVORITE_PRODUCTS,
  REMOVE_FAVORITE_PRODUCT_SUCCESS,
  SAVE_FAVORITE_PRODUCT_SUCCESS,
} from '../actions/types';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_FAVORITE_PRODUCTS,
});

export default (state = initialState, action) => {
  state = reducer(state, action);
  switch (action.type) {
    case FETCH_FAVORITE_PRODUCTS:
      const { locale, storeNumber } = action.payload;
      return {
        ...state,
        locale,
        storeNumber,
      };

    case REMOVE_FAVORITE_PRODUCT_SUCCESS:
      const favoriteProductId = action?.payload?.favoriteProductId;
      const favoritesData = state?.data ?? [];
      return {
        ...state,
        data: filter(
          favoritesData,
          (favorite) => favorite.id !== favoriteProductId
        ),
      };

    case SAVE_FAVORITE_PRODUCT_SUCCESS:
      const newFavoriteProduct = action?.payload;
      const favoritesList = state?.data ?? [];
      const newFavoriteAlreadyExists = Boolean(
        favoritesList.find(
          (favorite) => favorite?.id === newFavoriteProduct?.id
        )
      );
      return {
        ...state,
        data: !newFavoriteAlreadyExists
          ? favoritesList.concat(newFavoriteProduct)
          : favoritesList,
      };
  }
  return state;
};
