import { createSelector } from 'reselect';
import { defineMessages } from 'react-intl';

import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import { legacyBaseUrlSelector } from 'shared/app/state/selectors/locales';
import JoinRewardsLink from 'shared/app/components/join-rewards-link';
import { commonMessages, sharedCallsToAction } from 'shared/app/messages';

const messages = defineMessages({
  carouselCard1: {
    id: 'stream.rewardsBenefitsCarousel.rewardsStatic.carouselCard1',
    defaultMessage: 'Earn free food and drinks',
    description:
      'Caption below the 1st card of Starbucks Rewards benifits carousel',
  },
  carouselCard2: {
    id: 'stream.rewardsBenefitsCarousel.rewardsStatic.carouselCard2',
    defaultMessage: 'Enjoy a birthday treat',
    description:
      'Caption below the 2nd card of Starbucks Rewards benifits carousel',
  },
  carouselCard3: {
    id: 'stream.rewardsBenefitsCarousel.rewardsStatic.carouselCard3',
    defaultMessage: 'Free refill for brewed coffee and tea',
    description:
      'Caption below the 3rd card of Starbucks Rewards benifits carousel',
  },
  carouselCard4: {
    id: 'stream.rewardsBenefitsCarousel.rewardsStatic.carouselCard4',
    defaultMessage: 'Pay with your phone',
    description:
      'Caption below the 4th card of Starbucks Rewards benifits carousel',
  },
});

export const staticCarouselDataSelector = createSelector(
  configImagesUrlSelector,
  legacyBaseUrlSelector,
  (configImagesUrl, legacyBaseUrl) => {
    const carouselItems = [
      {
        text: messages.carouselCard1,
        uri: `${configImagesUrl}/rewards-benefits/rewards-card-1.jpg`,
      },
      {
        text: messages.carouselCard2,
        uri: `${configImagesUrl}/rewards-benefits/rewards-card-2.jpg`,
      },
      {
        text: messages.carouselCard3,
        uri: `${configImagesUrl}/rewards-benefits/rewards-card-3.jpg`,
      },
      {
        text: messages.carouselCard4,
        uri: `${configImagesUrl}/rewards-benefits/rewards-card-4.jpg`,
      },
    ];
    return {
      carouselItems,
      title: commonMessages.starbucksRewardsTrademark,
      buttons: [
        {
          component: JoinRewardsLink,
          enabled: true,
        },
        {
          enabled: true,
          href: `${legacyBaseUrl}/rewards`,
          text: sharedCallsToAction.learnMore,
          type: 'Link',
          external: true,
        },
      ],
    };
  }
);
