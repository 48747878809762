import streamReducer from './state/reducers/stream';
import { shouldFetchStreamSelector } from './state/selectors/stream';
import { fetchStream } from './state/actions/stream';
import Stream from './components';
import Greeting from './components/greeting';

import { FETCH_STREAM, FETCH_STREAM_SUCCESS } from './state/actions/types';

export default {
  name: 'stream',
  reducer: streamReducer,
  effects: [
    {
      selector: shouldFetchStreamSelector,
      actionCreator: fetchStream,
    },
  ],
  routes: {
    '/': {
      contentCrate: Stream,
      headerCrate: Greeting,
    },
  },
  asyncActions: [FETCH_STREAM],
  persistAfter: [FETCH_STREAM_SUCCESS],
};
