import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Expander from '@starbucks-web/pattern-library/lib/components/expander';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';
import BottomSheetHeading from 'shared/app/components/bottom-sheet-heading';
import Toggle from '@starbucks-web/pattern-library/lib/components/toggle';
import { connect } from 'react-redux';

import FrapContainer from 'shared/app/components/frap-container';
import MonetaryAmountSelect from 'shared/app/components/monetary-amount-select';
import PaymentInstrumentSelect from 'shared/app/components/payment-instrument-select';
import Form from 'shared/app/components/form';
import {
  allPaymentInstrumentsSelector,
  paymentServicesPendingSelector,
} from 'shared/app/bundles/wallet';

import { svcMessages } from 'shared/app/messages';
import messages from './messages';

export const ARIA_ID = 'bottomsheetLabel';

const AutoReloadForm = ({
  buttonMessage,
  fields,
  inProgress,
  intl: { formatMessage },
  paymentInstruments,
  onSubmit,
  paymentServicesPending,
  showPayPalButton,
  svcReloadOptions,
  triggerAmountOptions,
}) => (
  <Form onSubmit={onSubmit}>
    <BottomSheetHeading id={ARIA_ID}>
      <FormattedMessage {...messages.description} />
    </BottomSheetHeading>

    <label className="flex justify-between items-center mb4 pr1">
      <span className="text-sm ml8">
        <FormattedMessage {...svcMessages.autoReloadLabel} />
      </span>

      <Toggle
        {...fields?.enabled?.input}
        className="flex-shrink-none ml2"
        data-e2e="toggleAutoReload"
      />
    </label>

    <Expander isExpanded={fields.enabled.input.checked}>
      <div className="pl8 pr1">
        <MonetaryAmountSelect
          amounts={svcReloadOptions}
          label={formatMessage(messages.amountLabel)}
          {...fields?.amount?.input}
        />
        <MonetaryAmountSelect
          amounts={triggerAmountOptions}
          label={formatMessage(messages.triggerAmountLabel)}
          {...fields?.triggerAmount?.input}
        />
      </div>
      <div className="pr1">
        <PaymentInstrumentSelect
          disabled={paymentServicesPending}
          paymentInstruments={paymentInstruments}
          {...fields?.paymentInstrument?.input}
        />
      </div>
    </Expander>

    <FrapContainer
      addSpacingForMultipleChildren={false}
      animated={false}
      relativeAbove="alwaysRelative"
    >
      <div
        className={!showPayPalButton ? 'hidden' : ''}
        id="paypal-button-container"
      />
      {!showPayPalButton && (
        <Frap
          data-e2e="saveAutoReload"
          loading={inProgress || paymentServicesPending}
          type="submit"
        >
          <FormattedMessage {...buttonMessage} />
        </Frap>
      )}
    </FrapContainer>
  </Form>
);

AutoReloadForm.propTypes = {
  fields: PropTypes.object,
  onError: PropTypes.func,
  onFieldsStateChange: PropTypes.func,
  onSubmit: PropTypes.func,
  showPayPalButton: PropTypes.bool,
};

const select = (state) => ({
  paymentInstruments: allPaymentInstrumentsSelector(state),
  paymentServicesPending: paymentServicesPendingSelector(state),
});

export default injectIntl(connect(select)(AutoReloadForm));
