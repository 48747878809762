import { validateEmail } from 'shared/app/utils/validators';

export const setInitialRecipientStateWithSessionData = (
  sessionGiftFormData = {}
) => {
  const sessionKeys = Object.keys(sessionGiftFormData);
  const recipientPairs = [];

  if (sessionKeys.length) {
    sessionKeys.forEach((fieldKey) => {
      if (fieldKey.includes('recipientEmail')) {
        const recipientId = fieldKey.slice(14);
        recipientPairs.push({
          recipientEmail: sessionGiftFormData[fieldKey],
          recipientName: sessionGiftFormData[`recipientName${recipientId}`],
          id: parseInt(recipientId),
        });
      }
    });
  }
  if (recipientPairs.length === 0) {
    recipientPairs.push({
      recipientEmail: '',
      recipientName: '',
      id: parseInt(new Date().getTime()),
    });
  }
  return recipientPairs;
};

export const getUpdatedRecipientFields = ({
  recipients,
  includeFieldShape = false,
}) => {
  const updatedRecipientFields = {};
  recipients.forEach((recipient) => {
    const { recipientEmail = '', recipientName = '', id } = recipient;

    if (includeFieldShape) {
      updatedRecipientFields[`recipientEmail${id}`] = {
        input: {
          value: recipientEmail || '',
        },
        validator: validateEmail,
      };
      updatedRecipientFields[`recipientName${id}`] = {
        input: {
          value: recipientName || '',
        },
      };
      return;
    }
    updatedRecipientFields[`recipientEmail${id}`] = recipientEmail || '';
    updatedRecipientFields[`recipientName${id}`] = recipientName || '';
  });

  return updatedRecipientFields;
};
