import React, { useEffect, useRef, useState } from 'react';
import { push } from 'redux-first-history';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import OrderPaymentBottomSheetForm from './form';

import { setBottomSheetUrl } from 'shared/app/shell';

import { ADD_PAYMENT, PAYMENT_TYPE_SVC } from 'shared/app/bundles/wallet';
import {
  reloadAmountSelectedSelector,
  setReloadAmountSelected,
} from 'shared/app/bundles/svc-cards';
import { showErrorNotification } from 'shared/app/shell/state/action-creators/notifications';

import {
  svcLoadOptionsSelector,
  defaultSvcLoadOptionSelector,
} from '../../state/selectors/config';
import {
  orderInProgressSelector,
  orderPaymentsSelector,
  orderTotalSelector,
  pricingExpiredSelector,
  pricingSelector,
  selectedOrderReloadSourceIdSelector,
  selectedOrderPaymentSelector,
  selectedPaymentBalanceSelector,
} from '../../state/selectors';

import {
  submitOrder,
  loadAndSubmitOrder,
  fetchPricing,
} from '../../state/actions/cart';

import {
  trackMOPTippingSuccess,
  trackMOPOrderError,
  trackMOPInsufficientFundsError,
} from '../../state/actions/track-event';

import messages from './messages';
import { sharedCallsToAction, paymentMessages } from 'shared/app/messages';
import { NO_TIP_VALUE } from 'shared/app/components/tip-amount-select';

import styles from './styles.cssm';
import {
  SET_SELECTED_ORDER_RELOAD_SOURCE_ID,
  SET_SELECTED_PAYMENT,
} from '../../state/actions/types';

const PAYPAL_TYPE_NAME = 'PayPal';

/* eslint-disable max-statements */
/* eslint-disable complexity */
export const OrderPaymentBottomSheet = () => {
  const dispatch = useDispatch();
  // selectors
  const defaultLoadOption = useSelector(defaultSvcLoadOptionSelector);
  const orderInProgress = useSelector(orderInProgressSelector);
  const orderPayments = useSelector(orderPaymentsSelector);
  const orderTotal = useSelector(orderTotalSelector);
  const pricing = useSelector(pricingSelector);
  const pricingExpired = useSelector(pricingExpiredSelector);
  const reloadAmountSelected = useSelector(reloadAmountSelectedSelector);
  const selectedReloadSourceId = useSelector(
    selectedOrderReloadSourceIdSelector
  );
  const selectedPayment = useSelector(selectedOrderPaymentSelector);
  const selectedPaymentBalance = useSelector(selectedPaymentBalanceSelector);
  const svcLoadOptions = useSelector(svcLoadOptionsSelector);

  const { formatMessage } = useIntl();
  const { openModal } = useModalContext();
  const [tipAmount, setTipAmount] = useState(NO_TIP_VALUE);
  const formContainerRef = useRef(null);

  const getReloadAmounts = (paymentId) => {
    const svc = orderPayments?.find((item) => item.cardId === paymentId);
    const foundSVCBalance = svc?.balance?.amount ?? 0;
    const currentOrderTotal =
      pricing?.summary?.price + parseFloat(tipAmount ?? 0) ?? 0;
    const reloadAmountNeeded = (amt) =>
      amt >= currentOrderTotal - foundSVCBalance;
    const reloadAmounts = svcLoadOptions.filter(reloadAmountNeeded);
    return reloadAmounts.length ? reloadAmounts : [currentOrderTotal];
  };

  const getDefaultReloadAmount = (cardId) =>
    getReloadAmounts(cardId).find((amt) => amt >= defaultLoadOption) ?? 0;

  const [selectedReloadAmount, setSelectedReloadAmount] = useState(
    getDefaultReloadAmount(selectedPayment?.cardId)
  );
  const [hasInsufficientFunds, setHasInsufficientFunds] = useState(false);

  const getPaymentById = (paymentId) =>
    orderPayments.find(
      (payment) =>
        payment.cardId === paymentId ||
        payment.paymentInstrumentId === paymentId
    );

  const setTip = (amount) => {
    setTipAmount(amount);
    trackMOPTippingSuccess();
  };

  const getFormFields = (includeOptional) => {
    // TODO: WG-11346 PayPal Restriction solution - temp
    const selectedOrderPaymentType = selectedPayment?.paymentType ?? null;
    const substitutePaymentInstrumentId =
      selectedOrderPaymentType === PAYPAL_TYPE_NAME ? 'add-payment' : null;
    if (substitutePaymentInstrumentId) {
      dispatch({
        type: SET_SELECTED_PAYMENT,
        payload: {
          paymentType: 'add-payment',
          paymentInstrumentId: 'add-payment',
        },
      });
    }

    const selectedPaymentId =
      substitutePaymentInstrumentId ??
      (selectedPayment?.cardId || selectedPayment?.paymentInstrumentId);

    const fields = {
      payment: {
        input: {
          value: selectedPaymentId,
        },
      },
    };

    if (!includeOptional) {
      return fields;
    }

    const optionalFields = {
      amount: {
        input: {
          value:
            reloadAmountSelected ||
            getDefaultReloadAmount(selectedPaymentId).toString(),
        },
      },
      reloadSource: {
        input: {
          value: selectedReloadSourceId,
        },
      },
    };

    return { ...fields, ...optionalFields };
  };

  const getTenderType = (payment) =>
    payment?.orderPaymentType === PAYMENT_TYPE_SVC
      ? PAYMENT_TYPE_SVC
      : payment?.paymentType?.toUpperCase();

  const handleSubmit = () => {
    const selectedPaymentId =
      selectedPayment?.cardId || selectedPayment?.paymentInstrumentId;

    return (orderAndReloadFormData) => {
      if (pricingExpired) {
        dispatch(
          showErrorNotification({
            messageId: messages.pricingRefresh.id,
            confirmTextId: messages.confirmRefreshCTA.id,
            callback: () => dispatch(fetchPricing()),
          })
        );
        return;
      }

      const isReload =
        selectedPayment?.orderPaymentType === PAYMENT_TYPE_SVC &&
        hasInsufficientFunds;

      const addPaymentSelected =
        selectedPaymentId?.toLowerCase() === ADD_PAYMENT ||
        (selectedReloadSourceId?.toLowerCase() === ADD_PAYMENT && isReload);

      if (addPaymentSelected) {
        if (hasInsufficientFunds) {
          dispatch(setReloadAmountSelected(orderAndReloadFormData));
        }

        dispatch(setBottomSheetUrl());
        return dispatch(push('/account/payment-method'));
      }

      const { payment } = orderAndReloadFormData;

      const orderCallbacks = {
        onError: () => {
          trackMOPOrderError();
        },
      };

      // reload logic if SVC has insufficient balance to complete purchase
      if (hasInsufficientFunds) {
        return dispatch(
          loadAndSubmitOrder({
            formatMessage,
            formData: orderAndReloadFormData,
            openModal,
            tender: getTenderType(selectedPayment),
            tipAmount,
            ...orderCallbacks,
          })
        );
      }

      return dispatch(
        submitOrder({
          formatMessage,
          openModal,
          paymentId: payment,
          tender: getTenderType(selectedPayment),
          tipAmount,
          ...orderCallbacks,
        })
      );
    };
  };

  const handleSubmitError = ({ paymentInstrument }) => {
    const errorMessage = paymentInstrument
      ? paymentMessages.noPaymentMethodSelected
      : messages.insufficientFundsNotification;
    if (!paymentInstrument) {
      trackMOPInsufficientFundsError();
    }

    dispatch(
      showErrorNotification({
        messageId: errorMessage.id,
      })
    );
  };

  const handleFieldsStateChange = (fieldsState, oldFieldsState) => {
    const newPaymentId = fieldsState?.fields?.payment?.input?.value;
    const currentPaymentId = oldFieldsState?.fields?.payment?.input?.value;

    const currentReloadSourceId =
      oldFieldsState?.fields?.reloadSource?.input?.value;
    const newReloadSourceId = fieldsState?.fields?.reloadSource?.input?.value;

    const reloadAmount = fieldsState?.fields?.amount?.input?.value;
    const oldReloadAmount = oldFieldsState?.fields?.amount?.input?.value;

    if (newReloadSourceId && currentReloadSourceId !== newReloadSourceId) {
      dispatch({
        type: SET_SELECTED_ORDER_RELOAD_SOURCE_ID,
        payload: newReloadSourceId,
      });
    }

    if (newPaymentId && currentPaymentId !== newPaymentId) {
      const newPayment = getPaymentById(newPaymentId);
      if (newPayment) {
        dispatch({ type: SET_SELECTED_PAYMENT, payload: newPayment });
      }
    }

    if (reloadAmount !== oldReloadAmount) {
      setSelectedReloadAmount(reloadAmount);
    }
  };

  // effect hooks
  useEffect(() => {
    const total = orderTotal + parseFloat(tipAmount);

    if (selectedPayment?.orderPaymentType === PAYMENT_TYPE_SVC) {
      const balance = selectedPayment?.balance?.amount ?? 0;
      setHasInsufficientFunds(total > balance);
    }

    return () => {};
  }, []);

  // Selected Payment Instrument Effect
  useEffect(() => {
    if (hasInsufficientFunds) {
      formContainerRef.current.updateField({
        input: {
          name: 'reloadSource',
          value: selectedReloadSourceId,
        },
      });
    }
  }, [selectedReloadSourceId]);

  useEffect(() => {
    const selectedPaymentId =
      selectedPayment?.cardId || selectedPayment?.paymentInstrumentId;

    if (selectedPaymentId) {
      formContainerRef.current.updateField({
        input: {
          name: 'payment',
          value: selectedPaymentId,
        },
      });
    }
  }, [orderPayments]);

  useEffect(() => {
    const total = orderTotal + parseFloat(tipAmount);
    const selectedSvcId = selectedPayment?.cardId ?? '';
    const paymentBalance = selectedPayment?.balance?.amount ?? 0;

    if (selectedPayment?.orderPaymentType === PAYMENT_TYPE_SVC) {
      setHasInsufficientFunds(total > paymentBalance);
    } else {
      setHasInsufficientFunds(false);
    }

    // if user selected svc card with insufficient balance, treat it as an "impression" for reload/order
    if (hasInsufficientFunds) {
      let reloadAmount = selectedReloadAmount;
      const reloadIsInsufficient =
        paymentBalance + parseFloat(reloadAmount) < total;

      if (reloadIsInsufficient) {
        reloadAmount = getReloadAmounts(selectedSvcId)
          .find((amt) => amt + paymentBalance >= total)
          .toString();

        formContainerRef.current.updateField({
          input: {
            name: 'amount',
            value: reloadAmount,
          },
        });
      }
    }
  }, [orderTotal, selectedPayment, selectedPaymentBalance, tipAmount]);

  const selectedPaymentId =
    selectedPayment?.cardId || selectedPayment?.paymentInstrumentId || '';
  const fields = getFormFields(hasInsufficientFunds);
  const reloadAmounts = getReloadAmounts(selectedPaymentId);
  const showReload =
    selectedPayment?.orderPaymentType === PAYMENT_TYPE_SVC &&
    hasInsufficientFunds;
  const addPaymentSelected =
    selectedPaymentId?.toLowerCase() === ADD_PAYMENT ||
    (selectedReloadSourceId?.toLowerCase() === ADD_PAYMENT && showReload);
  let buttonMessage = showReload
    ? messages.submitLoadAndOrder
    : messages.submitOrder;
  if (addPaymentSelected) {
    buttonMessage = sharedCallsToAction.continue;
  }

  return (
    <div className={styles.contentContainer}>
      <div className={orderInProgress ? styles.loadingOverlay : ''} />
      <FormContainer
        fields={fields}
        focusOnSubmitError
        onError={handleSubmitError}
        onFieldsStateChange={handleFieldsStateChange}
        onSubmit={handleSubmit()}
        ref={formContainerRef}
      >
        <OrderPaymentBottomSheetForm
          buttonMessage={buttonMessage}
          inProgress={orderInProgress}
          pricing={pricing}
          reloadAmounts={reloadAmounts}
          setTipAmount={setTip}
          showReload={showReload}
          tipAmount={tipAmount}
        />
      </FormContainer>
    </div>
  );
};

/* eslint-enable complexity */
/* eslint-enable max-statements */

export default OrderPaymentBottomSheet;
