import { lazyFullPage } from 'shared/app/shell';

import reducer from './state/reducers';

import {
  FETCH_HISTORY,
  FETCH_RECEIPT,
  DELETE_TIP,
  SET_TIP,
  FETCH_GIFT_HISTORY,
  FETCH_GIFT_ORDER_DETAILS,
} from './actions';

const accountHistoryPage = {
  fullPageComponent: lazyFullPage(
    () => import(/* webpackChunkName: 'account-history' */ './components')
  ),
  authRequired: true,
  fullWidthBottomDrawer: true,
};

export default {
  name: 'accountHistory',
  reducer,
  routes: {
    '/account/history': accountHistoryPage,
    '/account/history/orders-rewards': accountHistoryPage,
    '/account/history/egift': accountHistoryPage,
  },
  asyncActions: [
    FETCH_HISTORY,
    FETCH_RECEIPT,
    DELETE_TIP,
    SET_TIP,
    FETCH_GIFT_HISTORY,
    FETCH_GIFT_ORDER_DETAILS,
  ],
};
