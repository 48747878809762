import { REQUEST_LOCATION_BY_NUMBER } from 'shared/app/state/action-creators/types';
import { fetchLocationByNumber } from 'shared/app/state/action-creators/fetch-location';

import Header from './components/header';
import PickUpOptions from './components/pickup-options';
import PickupCurbside from './components/curbside';
import CurbsideHeader from './components/curbside/curbside-header';
import PickupOutdoor from './components/outdoor';
import OutdoorHeader from './components/outdoor/outdoor-header';
import Success from './components/success';
import rootReducer from './state/reducers/root-reducer';
import { shouldfetchStoreSelector } from './state/selectors';

export default {
  name: 'pickup',
  reducer: rootReducer,
  routes: {
    '/pickup': {
      headerCrate: Header,
      contentCrate: PickUpOptions,
      authRequired: false,
    },
    '/pickup/curbside': {
      headerCrate: CurbsideHeader,
      contentCrate: PickupCurbside,
      authRequired: false,
    },
    '/pickup/curbside/success': {
      headerCrate: CurbsideHeader,
      contentCrate: Success,
    },
    '/pickup/outdoor': {
      headerCrate: OutdoorHeader,
      contentCrate: PickupOutdoor,
      authRequired: false,
    },
    '/pickup/outdoor/success': {
      headerCrate: OutdoorHeader,
      contentCrate: Success,
    },
  },
  effects: [
    {
      selector: shouldfetchStoreSelector,
      actionCreator: fetchLocationByNumber,
    },
  ],
  asyncActions: [REQUEST_LOCATION_BY_NUMBER],
};
