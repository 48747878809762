import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import externalLink from '@starbucks-web/pattern-library/lib/icons/external-link';
import externalLinkMessages from 'shared/app/components/external-link/messages.js';

import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import {
  doordashStorefrontUrlSelector,
  doordashFaqUrlSelector,
} from '../../state/selectors';
import { cartItemCountSelector } from 'ordering/app/state/selectors/cart';

import { FEATURES } from 'shared/universal/optimizely-keys';
import { Feature } from 'shared/app/components/optimizely';

import RebuildCartDialog, {
  REBUILD_CART_DIALOG_ID,
} from './rebuild-cart-dialog';
import styles from './styles.cssm';
import messages from './messages';

const DeliveryLandingPage = () => {
  const { formatMessage } = useIntl();
  const { openModal } = useModalContext();

  const isMobileViewport = useSelector(isMobileViewportSelector);
  const doordashStorefrontUrl = useSelector(doordashStorefrontUrlSelector);
  const doordashFaqUrl = useSelector(doordashFaqUrlSelector);

  // TO DO: MOVE ITEM COUNT SELECTOR TO SHARED FOR TRACKING
  const cartItemCount = useSelector(cartItemCountSelector);

  const configImagesUrl = useSelector(configImagesUrlSelector);
  const deliveryHeroImageUrl = `${configImagesUrl}/delivery/delivery-hero.png`;
  const doordashBannerImageUrl = `${configImagesUrl}/delivery/doordash-banner.png`;

  const handleGetStartedClick = () => {
    if (cartItemCount > 0) {
      openModal({
        component: RebuildCartDialog,
        ariaLabelledBy: REBUILD_CART_DIALOG_ID,
        componentProps: {
          doordashStorefrontUrl,
        },
      });
    } else {
      window.open(doordashStorefrontUrl, '_blank');
    }
  };

  return (
    <Feature name={FEATURES.IN_APP_DELIVERY}>
      {(optimizelyEnabled) => {
        return optimizelyEnabled ? (
          <div className={`flex flex-column height-100 ${styles.textWrapper}`}>
            <div className={`flex items-center ${styles.deliveryImage}`}>
              <ImageLoader
                alt={formatMessage(messages.deliveryHeroAlt)}
                className="mx-auto"
                height={isMobileViewport ? '218' : '417'}
                lazyLoad={{ enabled: false }}
                src={deliveryHeroImageUrl}
              />
            </div>
            <div
              className={`flex flex-column flex-grow justify-center ${styles.deliveryTextContent}`}
            >
              <div className={styles.headerWrapper}>
                {/* not using the H1 component so that it doesn't steal focus from the toggle at the top of the page */}
                <Heading
                  className="text-bold text-center mt3 mb2 lg-mb6 lg-mt9"
                  size="lg"
                  tagName="h1"
                >
                  <FormattedMessage {...messages.deliveryHeading} />
                </Heading>
                <div className="text-center my2">
                  <Frap
                    aria-label={
                      !cartItemCount
                        ? `${formatMessage(messages.getStartedFrap)}, ${formatMessage(externalLinkMessages.externalLinkAria)}`
                        : formatMessage(messages.getStartedFrap)
                    }
                    className="text-sm valign-middle"
                    onClick={handleGetStartedClick}
                  >
                    <FormattedMessage {...messages.getStartedFrap} />
                    {!cartItemCount ? <Icon path={externalLink} /> : null}
                  </Frap>
                </div>
                <div className="text-center mb2">
                  <Button
                    aria-label={`${formatMessage(messages.deliveryFaqButton)}, ${formatMessage(externalLinkMessages.externalLinkAria)}`}
                    href={doordashFaqUrl}
                    target="_blank"
                    visualStyle="textOnly"
                  >
                    <FormattedMessage {...messages.deliveryFaqButton} />
                    <Icon path={externalLink} />
                  </Button>
                </div>
              </div>
              <div className="text-center m3 lg-m6">
                <ImageLoader
                  alt={formatMessage(messages.doordashBannerAltText)}
                  lazyLoad={{ enabled: false }}
                  src={doordashBannerImageUrl}
                  width={isMobileViewport ? '220' : '244'}
                  wrapInFlex={{
                    enabled: true,
                    height: 22,
                    width: 244,
                    constrainWidth: true,
                    flexEmbedWrapperClassName: 'mx-auto',
                  }}
                />
                <p className="mt2 text-center text-xs color-textBlackSoft">
                  <FormattedMessage {...messages.doordashLegalText} />
                </p>
              </div>
            </div>
          </div>
        ) : null;
      }}
    </Feature>
  );
};

export default DeliveryLandingPage;
