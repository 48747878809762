import { createSelector } from 'reselect';

// TODO: WG-11346 selectors for temporary condition on "more" payment types (PayPal)
// TODO: WG-11346 BEGIN
// TODO: Condition #1  remove ordering when PayPal direct pay contract is in place
// TODO: Condition #2  remove gifting when PayPal for groups supported upstream

import { bottomSheetReturnUrlSelector } from 'shared/app/shell/state/selectors/app-ui';
import { routesSelector } from 'shared/app/state/selectors/routes';
import { isGroupGiftOrderSelector } from './group-gifting';
import { ADD_PAYMENT } from 'shared/app/bundles/wallet';

// Any disabling of More (3rd Party) Payments MUST emanate from a restricted bottomsheet
// ...either:  /menu/cart#payment-summary or /gift/00000271#payment
const PAYMENTS_PAGE_ROUTE = '/account/payment-method';
const ORDERING_BOTTOM_SHEET_ROUTE_PATTERN = /\/menu\/cart#payment-summary/;
const GIFTING_BOTTOM_SHEET_ROUTE_PATTERN = /\/gift\/[0-9]{8}#payment/;

export const selectedOrderingPaymentsSelector = (state) => state?.ordering;

// ordering: {
//   THIS:
//   selectedReloadSourceId: 'add-payment',
//   AND NOT THIS:
//   selectedPayment: { paymentType: 'add-payment', paymentInstrumentId: 'add-payment'},
// }
export const isOrderingAddPaymentReloadSelector = createSelector(
  selectedOrderingPaymentsSelector,
  (orderingPayments) => {
    const orderPayment = orderingPayments?.selectedPayment?.paymentType ?? null;
    const reloadSource = orderingPayments?.selectedReloadSourceId ?? null;
    return orderPayment !== ADD_PAYMENT && reloadSource === ADD_PAYMENT;
  }
);

export const isPathThirdPartyPaymentsRestricted = (
  path,
  isOrderingReload,
  isGroupGifting
) => {
  let restrictedOrdering = false;
  let restrictedGifting = false;
  const isOrderingRoute = ORDERING_BOTTOM_SHEET_ROUTE_PATTERN.test(path);
  if (isOrderingRoute) {
    if (!isOrderingReload) {
      // restrict if NOT a *Reload* Add Payment
      restrictedOrdering = true;
    }
  }
  const isGiftingRoute = GIFTING_BOTTOM_SHEET_ROUTE_PATTERN.test(path);
  if (isGiftingRoute) {
    if (isGroupGifting) {
      restrictedGifting = true;
    }
  }
  return restrictedOrdering || restrictedGifting;
};

export const isCurrentRoutePaymentsPageSelector = createSelector(
  routesSelector,
  (routes) => routes.current.pathname === PAYMENTS_PAGE_ROUTE
);

export const isSourceRouteThirdPartyPaymentRestrictedSelector = createSelector(
  routesSelector,
  isOrderingAddPaymentReloadSelector,
  isGroupGiftOrderSelector,
  (routes, isOrderingReload, isGroupGifting) => {
    const path = `${routes.previous.pathname}${routes.previous.hash}`;
    return isPathThirdPartyPaymentsRestricted(
      path,
      isOrderingReload,
      isGroupGifting
    );
  }
);

export const shouldDisallowThirdPartyPaymentOptionSelector = createSelector(
  isCurrentRoutePaymentsPageSelector,
  isSourceRouteThirdPartyPaymentRestrictedSelector,
  (isCurrentRoutePaymentsPage, isSourceRouteRestricted) =>
    isCurrentRoutePaymentsPage && isSourceRouteRestricted
);

export const shouldEnableMorePaymentsSelector = createSelector(
  shouldDisallowThirdPartyPaymentOptionSelector,
  (disableMorePaymentsForAddingPayment) => !disableMorePaymentsForAddingPayment
);

export const shouldAllowBottomSheetRedirectSelector = createSelector(
  bottomSheetReturnUrlSelector,
  isOrderingAddPaymentReloadSelector,
  isGroupGiftOrderSelector,
  (returnUrl, isOrderingReload, isGroupGifting) => {
    const path = returnUrl;
    return !isPathThirdPartyPaymentsRestricted(
      path,
      isOrderingReload,
      isGroupGifting
    );
  }
);

// TODO: WG-11346 END temp
