import exEnv from 'exenv';
import { lazyFullPage } from 'shared/app/shell';

import parseStoreLocatorUrl from '../universal/utils/parse-store-locator-url';
import { setUrlState } from './state/actions/application';
import stateOptions from './state-options';

export default {
  routes: {
    '/store-locator/*': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'store-locator-page' */ './components/root/store-locator'
          )
      ),
      usesFullPageFlexLayout: true,
      hideGlobalFooter: true,
    },
  },
  initialize: (store) => {
    if (!exEnv.canUseDOM) {
      // Set the starting store locator state for server-side requests to marketing Urls. i.e.
      // Place searches: `?place=Gävle, Sweden`
      // or store details `/store-locator/store/16859/holman-road-9999-holman-rd-nw-seattle-wa-981172041-us`
      const { pathname, search } = store.getState().routes.current;
      const urlState = parseStoreLocatorUrl.default(`${pathname}${search}`);
      if (urlState) {
        store.dispatch(setUrlState(urlState));
      }
    }
  },
  ...stateOptions,
};
