export const transformCarousel = (carousel) => {
  const { title, key, carouselItems } = carousel;
  const transformButton = (button) => {
    const isEnabled = carousel[`${button}IsEnabled`];
    if (!isEnabled) return null;

    const keys = {
      external: `${button}IsExternalLink`,
      href: `${button}Link`,
      text: `${button}Text`,
    };
    const props = Object.keys(keys).reduce((obj, item) => {
      const value = carousel[keys[item]];
      if (value) obj[item] = value;
      return obj;
    }, {});
    return {
      enabled: true,
      ...props,
    };
  };
  const buttons = ['callToAction', 'callToAction2'].reduce((arr, button) => {
    const newButton = transformButton(button);
    if (newButton) arr.push(newButton);
    return arr;
  }, []);

  return {
    title,
    key,
    carouselItems,
    buttons,
  };
};
