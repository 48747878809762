import {
  CONFIRM_EMAIL_CODE,
  CONFIRM_EMAIL_CODE_SUCCESS,
  CONFIRM_EMAIL_CODE_ERROR,
} from '../../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_EMAIL_CODE:
      return Object.assign({}, state, { confirmEmailCode: { loading: true } });
    case CONFIRM_EMAIL_CODE_SUCCESS:
      return Object.assign({}, state, {
        confirmEmailCode: Object.assign(
          {},
          { newEmail: action.payload.newEmail, loading: false }
        ),
      });
    case CONFIRM_EMAIL_CODE_ERROR:
      return Object.assign({}, state, {
        confirmEmailCode: { error: action.payload, loading: false },
      });
    default:
      return state;
  }
};
