import { customNewRelicReport } from 'shared/app/utils/custom-new-relic-report';

// Temporarily reporting the condition of a missing sku to troubleshoot
// product data. The reporting can be remove once the root cause of the
// missing skus has been identified.
const logOptionsMissingSkus = (selectedOptions) => {
  let loggedMissingSku = false;
  selectedOptions.map((option) => {
    const isMissingSkuPDP = !option.size?.sku;
    const isMissingSkuPreviousFavorites = !option.product?.form?.sizes?.[0].sku;

    if (isMissingSkuPDP && isMissingSkuPreviousFavorites) {
      customNewRelicReport('ProductOptionsMissingSkus', {
        optionFormCode: option.formCode || option.product?.form?.formCode,
        optionName: option.size?.name || option.product?.form?.name,
        optionProductNumber:
          option.optionProductNumber || option.product?.productNumber,
        optionSizeCode:
          option.size?.sizeCode || option.product?.form?.sizes?.[0].sizeCode,
        stringifiedOption: JSON.stringify(option),
      });
      loggedMissingSku = true;
    }
  });
  return loggedMissingSku;
};

export default logOptionsMissingSkus;
