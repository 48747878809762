import { SET_ACTIVE_HISTORY_ITEM_ID } from '../../../actions';

const initialState = {
  historyId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_HISTORY_ITEM_ID: {
      return { ...state, ...{ historyId: action.payload } };
    }
  }

  return state;
};
