import { find } from 'lodash';
import { push } from 'redux-first-history';

import {
  addPhysicalSvcCard,
  addDigitalSvcCard,
  primarySvcCardSelector,
  svcCardsDataSelector,
} from 'shared/app/bundles/svc-cards';
import { addNotification, showErrorNotification } from 'shared/app/shell';
import runSequentially from 'shared/app/utils/run-sequentially';

import {
  SET_SCAN_TO_PAY_CARD_ID,
  SET_SELECTED_ADD_MONEY_PAYMENT_INSTRUMENT_ID,
  SET_SELECTED_AUTO_RELOAD_PAYMENT_INSTRUMENT_ID,
} from '../../actions';
import { getNotificationForErrorCode } from '../errors';
import { showCodedErrorNotification } from 'shared/app/utils/show-coded-error-notification';

import RemoveCardOnZeroBalanceAddDialog, {
  REMOVE_CARD_ZERO_BALANCE_ADD_DIALOG_ID,
} from '../../components/remove-card-on-zero-balance-add-dialog';

import { trackBarcodeCardAddSuccess } from './track-event';

import messages from './notification-messages';

export const addCardSuccess =
  ({ status, transferCard, openModal }) =>
  (dispatch) => {
    const cardId = status?.cardId;
    const balanceAmount = status?.balance?.amount;
    // if new card requested transfer, and has balance, see add-card component for followup
    const redirectToCards =
      transferCard && cardId && balanceAmount > 0
        ? {}
        : dispatch(push('/account/cards'));

    runSequentially(
      () => redirectToCards,
      () =>
        dispatch(
          addNotification(null, {
            messageId: messages.addCardSuccess.id,
            messageValues: {
              amount: balanceAmount,
            },
            autoClose: false,
          })
        ),
      () => trackBarcodeCardAddSuccess()
    );

    // if new card requested transfer, but has zero balance (incompatible)
    if (transferCard && cardId && balanceAmount <= 0) {
      openModal({
        component: RemoveCardOnZeroBalanceAddDialog,
        ariaLabelledBy: REMOVE_CARD_ZERO_BALANCE_ADD_DIALOG_ID,
        componentProps: {
          cardId,
        },
      });
    }

    return status;
  };

const addCardError = (dispatch) =>
  runSequentially(
    () => dispatch(push('/')),
    () =>
      dispatch(
        showErrorNotification({
          messageId: messages.addCardError.id,
          useErrorLoadingIndicator: true,
        })
      )
  );

export const setScanToPayCardId = (cardId) => (dispatch, getState) => {
  const state = getState();
  const cards = svcCardsDataSelector(state);
  const primaryCard = primarySvcCardSelector(state);

  const cardIdForPay = cardId
    ? find(cards, { cardId })?.cardId
    : primaryCard?.cardId;

  dispatch({ type: SET_SCAN_TO_PAY_CARD_ID, payload: cardIdForPay });
};

export const addPhysicalCard =
  ({
    cardNumber,
    pin,
    makePrimaryCard = false,
    transferCard = false,
    openModal,
  }) =>
  (dispatch) => {
    return dispatch(addPhysicalSvcCard({ cardNumber, pin, makePrimaryCard }))
      .then((status) =>
        dispatch(addCardSuccess({ status, transferCard, openModal }))
      )
      .catch((error) => {
        const { code: errorCode } = error;
        if (errorCode === 'makePrimaryCardError') {
          dispatch(push(`/account/cards/${error.cardId}`));
        }
        dispatch(
          showCodedErrorNotification({ errorCode }, getNotificationForErrorCode)
        );
        throw error;
      });
  };

export const addDigitalCard = (openModal) => (dispatch) => {
  return dispatch(addDigitalSvcCard())
    .then((status) => dispatch(addCardSuccess({ status, openModal })))
    .catch(() => addCardError(dispatch));
};

export const setSelectedAddMoneyPaymentInstrumentId = (
  paymentInstrumentId
) => ({
  type: SET_SELECTED_ADD_MONEY_PAYMENT_INSTRUMENT_ID,
  payload: paymentInstrumentId,
});

export const setSelectedAutoReloadPaymentInstrumentId = (
  paymentInstrumentId
) => ({
  type: SET_SELECTED_AUTO_RELOAD_PAYMENT_INSTRUMENT_ID,
  payload: paymentInstrumentId,
});
