import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Select from '@starbucks-web/pattern-library/lib/components/select';

import { orderPaymentsSelector } from '../../state/selectors';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import { paymentMessages } from 'shared/app/messages';

import AccessibleStar from 'shared/app/components/accessible-star';
import SvcImage from 'shared/app/components/svc-image';

import {
  ADD_PAYMENT,
  PAYMENT_TYPE_GENERIC,
  PAYMENT_TYPE_SVC,
} from 'shared/app/bundles/wallet';
import {
  getGenericEarnRateSelector,
  getSVCEarnRateSelector,
} from 'shared/app/bundles/accrual-earn-rates/selectors';

// source for map keys: PaymentInstrument.paymentType
// https://docs.starbucks.com/display/OAD/StarPayPaymentInstrument
// VISA, Discover, Mastercard, AMEX, PayPal, Bakkt
const paymentMap = {
  'add-payment': paymentMessages.addPayment,
  amex: 'Amex',
  discover: 'Discover',
  mastercard: 'MasterCard',
  visa: 'Visa',
  paypal: 'PayPal',
  venmo: 'Venmo',
};

const PAYPAL = 'paypal';
const EARN_RATE_CONSEQUENT = '/$1';
const EARN_RATE_SUFFIX = `★${EARN_RATE_CONSEQUENT}`;

import styles from './styles.cssm';

/* eslint-disable max-statements */
export const OrderPaymentsSelect = ({
  className = '',
  field,
  hideImage,
  inputProps,
}) => {
  const { formatMessage, formatNumber, formats } = useIntl();
  const configImagesUrl = useSelector(configImagesUrlSelector);
  const genericEarnRate = useSelector(getGenericEarnRateSelector);
  const genericEarnRateText = `${genericEarnRate}${EARN_RATE_SUFFIX}`;
  const orderPayments = useSelector(orderPaymentsSelector);
  const svcEarnRate = useSelector(getSVCEarnRateSelector);
  const svcEarnRateText = `${svcEarnRate}${EARN_RATE_SUFFIX}`;

  const paymentInstrumentImgMap = {
    amex: `${configImagesUrl}/payment-instruments/amex.svg`,
    defaultCC: `${configImagesUrl}/payment-instruments/credit-card.svg`,
    discover: `${configImagesUrl}/payment-instruments/discover.svg`,
    mastercard: `${configImagesUrl}/payment-instruments/mastercard.svg`,
    visa: `${configImagesUrl}/payment-instruments/visa.svg`,
    paypal: `${configImagesUrl}/payment-instruments/paypal.png`,
    venmo: `${configImagesUrl}/payment-instruments/venmo.svg`,
  };

  const getSelectedOptionImgUrl = () => {
    const currentPayment = orderPayments?.find((orderPayment) => {
      const inputValue = field?.input?.value;

      return (
        orderPayment?.cardId === inputValue ||
        orderPayment?.paymentInstrumentId === inputValue
      );
    });

    return currentPayment?.orderPaymentType === PAYMENT_TYPE_SVC
      ? (currentPayment?.thumbImageUrl ?? '')
      : (paymentInstrumentImgMap[currentPayment?.paymentType?.toLowerCase()] ??
          paymentInstrumentImgMap.defaultCC);
  };

  const getSelectedOrderPayment = () => {
    return orderPayments?.find(
      (orderPayment) =>
        orderPayment.cardId === field.input.value ||
        orderPayment.paymentInstrumentId === field.input.value
    );
  };

  const getSelectedOrderPaymentType = () => {
    return getSelectedOrderPayment()?.orderPaymentType;
  };

  const getSelectedPaymentText = () => {
    const currentSelection = getSelectedOrderPayment();
    if (currentSelection?.orderPaymentType === PAYMENT_TYPE_SVC) {
      return formatNumber(
        currentSelection?.balance?.amount,
        formats.number.money
      );
    }

    const { accountNumberLastFour, paymentType } = currentSelection;
    const normalizedPaymentType = paymentType?.toLowerCase();

    if (paymentType === ADD_PAYMENT) {
      return formatMessage(paymentMap[normalizedPaymentType]);
    }

    if (paymentType === PAYPAL) {
      return paymentMap[normalizedPaymentType];
    }

    return accountNumberLastFour
      ? `x${accountNumberLastFour}`
      : `${paymentMap[normalizedPaymentType]}`;
  };

  const formatSVCOption = (payment) => {
    return `${formatNumber(payment.balance.amount, formats.number.money)},
    ${payment.nickname}, ${svcEarnRateText}`;
  };

  const formatPaymentInstrumentOption = (payment) => {
    const { accountNumberLastFour, paymentType } = payment;
    const normalizedPaymentType = paymentType?.toLowerCase();

    const optionText = accountNumberLastFour
      ? `x${accountNumberLastFour}`
      : `${paymentMap[normalizedPaymentType]}`;

    if (paymentType === ADD_PAYMENT) {
      return formatMessage(paymentMap[normalizedPaymentType]);
    }

    if (paymentType === PAYPAL) {
      return `${paymentMap[normalizedPaymentType]}, ${genericEarnRateText}`;
    }

    return `${optionText}, ${genericEarnRateText}`;
  };

  const renderSelectOption = (payment) => {
    const paymentId = payment.cardId || payment.paymentInstrumentId;
    const optionText =
      payment?.orderPaymentType === PAYMENT_TYPE_SVC
        ? formatSVCOption(payment)
        : formatPaymentInstrumentOption(payment);

    return (
      <option key={paymentId} value={paymentId}>
        {optionText}
      </option>
    );
  };

  const selectedEarnRateText = () => {
    const currentPaymentType = getSelectedOrderPaymentType();
    const earnRateFragment = (earnRate) => {
      return (
        <React.Fragment>
          <span>{`${formatMessage(
            paymentMessages.earnText
          )} ${earnRate}`}</span>
          <AccessibleStar className="color-gold" />
          <span>{EARN_RATE_CONSEQUENT}</span>
        </React.Fragment>
      );
    };
    if (currentPaymentType === PAYMENT_TYPE_SVC) {
      return earnRateFragment(svcEarnRate);
    } else if (currentPaymentType === PAYMENT_TYPE_GENERIC) {
      return earnRateFragment(genericEarnRate);
    }
  };

  const imageUrl = getSelectedOptionImgUrl();
  const paymentOptions = orderPayments.map(renderSelectOption);
  const selectedOrderPaymentType = getSelectedOrderPaymentType();

  return (
    <div className={`flex ${className}`}>
      {!hideImage && (
        <SvcImage
          altText={''}
          className={styles.fallbackImage}
          fallbackClassName={classNames(styles.fallbackImage, styles.svcImage)}
          flexEmbedWrapperClassName={classNames('mr3', styles.svcFlex)}
          imageClassName={classNames(styles.svcImage, {
            [styles.paymentInstrumentImage]:
              selectedOrderPaymentType !== PAYMENT_TYPE_SVC,
          })}
          lazyLoad={{ enabled: false }}
          mini
          src={imageUrl}
        />
      )}
      <div className="flex-grow relative">
        <Select
          label={formatMessage(paymentMessages.payment)}
          selectedOptionFormatter={getSelectedPaymentText}
          {...field.input}
          {...inputProps}
        >
          {paymentOptions}
        </Select>
        <div className={`absolute color-textBlackSoft ${styles.earnRateLabel}`}>
          {selectedEarnRateText()}
        </div>
      </div>
    </div>
  );
};
/* eslint-enable max-statements */

export default OrderPaymentsSelect;
