/**
 * Google Maps configuration
 *
 * **Note**: Returns a function `mapConfig()` to lazy-load the map object.
 * This is primarily so that we can have the mere _importing_ of this library
 * not cause _side-effects_ (in this case calling the Google API).
 */
import ExEnv from 'exenv';

let config = {};

if (!ExEnv.canUseDOM) {
  config = {
    getMapConfig() {
      return null;
    },
  };
} else {
  let _mapConfig = null;

  config = {
    getMapConfig() {
      // Memoize manually.
      if (!_mapConfig) {
        _mapConfig = {
          // Disabling the fullscreen button for couple of reasons
          // Mainly because it is not accessible (https://mlitzinger.com/blog/making-google-maps-controls-more-accessible/)
          // And iOS doesn't support the fullscreen feature. The fullscreen control is therefore not visible on iOS devices.
          fullscreenControl: false,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          // these should match what's in the reducers for
          // INCREMENT/DECREMENT_ZOOM_LEVEL
          minZoom: 3,
          maxZoom: 21,
          mapTypeControl: false,
          panControl: false,
          streetViewControl: false,
          zoomControl: false,
          styles: [
            {
              featureType: 'poi',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit.station.airport',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        };
      }

      return _mapConfig;
    },
  };
}

export default config;
