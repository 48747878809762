import { pickBy } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Coupon from './card-coupon';
import Info from './card-information';
import RewardPointsExpiration from './reward-points-expiration';
import ExpiringTier from './card-expiring-tier';
import AddBirthdayCard from './add-birthday-card';

import { signedInSelector, birthDateSelector } from 'shared/app/bundles/user';

import {
  streamDisplaySelector,
  unauthStreamEnabledSelector,
} from '../../state/selectors/stream';
import { trackStreamView } from '../../state/track-event';

import { addNotification as addNotificationAction } from 'shared/app/shell';
import StreamItem from './stream-item';

export const StreamItems = ({
  addNotification,
  hasBirthday,
  signedIn,
  streamItems,
  unauthStreamEnabled,
}) => {
  // This maps types of stream items to their relevant components
  const itemTypes = pickBy({
    addBirthDate: !hasBirthday && AddBirthdayCard,
    coupon: Coupon,
    information: Info,
    rewardPointsExpiration: RewardPointsExpiration,
    tierPointsExpiration: ExpiringTier,
  });

  useEffect(() => {
    trackStreamView({
      // eslint-disable-next-line camelcase
      authentication_state: signedIn,
    });
  }, []);

  if (!signedIn && !unauthStreamEnabled) {
    return null;
  }
  // If data doesn't match up with `itemTypes`, we don't have UI for it; don't render
  const dataFiltered = streamItems.filter((item) => itemTypes[item.type]);

  // Take the filtered data and match it with components
  const streamItemsArray = dataFiltered.map((item, idx) => {
    const component = itemTypes[item.type];
    return (
      <StreamItem
        addNotification={addNotification}
        component={component}
        idx={idx}
        item={item}
        key={`streamItem-${idx}`}
      />
    );
  });

  return [streamItemsArray];
};

const select = (state) => ({
  hasBirthday: birthDateSelector(state),
  signedIn: signedInSelector(state),
  streamItems: streamDisplaySelector(state),
  unauthStreamEnabled: unauthStreamEnabledSelector(state),
});

const actions = {
  addNotification: addNotificationAction,
};

export default connect(select, actions)(StreamItems);
