import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';
import { FETCH_ADDRESS_LIST } from '../actions';
import {
  ADD_BILLING_ADDRESS,
  ADD_BILLING_ADDRESS_SUCCESS,
  ADD_BILLING_ADDRESS_ERROR,
  EDIT_BILLING_ADDRESS,
  EDIT_BILLING_ADDRESS_SUCCESS,
  EDIT_BILLING_ADDRESS_ERROR,
} from 'shared/app/bundles/wallet';

const { reducer, initialState } = createSingleResourceReducer({
  startAction: FETCH_ADDRESS_LIST,
  markStaleWhen: [ADD_BILLING_ADDRESS_SUCCESS, EDIT_BILLING_ADDRESS_SUCCESS],
});

export default (state = initialState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case ADD_BILLING_ADDRESS:
    case EDIT_BILLING_ADDRESS:
      return { ...state, expectingAddAddressResponse: true };

    case ADD_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        expectingAddAddressResponse: false,
        newBillingAddressId: action.payload.addressId,
      };
    case ADD_BILLING_ADDRESS_ERROR:
    case EDIT_BILLING_ADDRESS_SUCCESS:
    case EDIT_BILLING_ADDRESS_ERROR:
      return { ...state, expectingAddAddressResponse: false };
  }

  return state;
};
