import {
  UPDATE_GIFT_FORM_DATA,
  BUY_DIGITAL_GIFT,
  BUY_DIGITAL_GIFT_ERROR,
  BUY_DIGITAL_GIFT_SUCCESS,
  UPDATE_NUMBER_OF_RECIPIENTS,
} from '../../actions';

import { getNumberOfRecipients } from '../../utils/session-storage-gift';

const defaultState = {
  expectingGiftResponse: false,
  formData: {},
  numberOfRecipients: getNumberOfRecipients(),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_GIFT_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case BUY_DIGITAL_GIFT:
      return {
        ...state,
        expectingGiftResponse: true,
      };
    case BUY_DIGITAL_GIFT_SUCCESS:
      return {
        ...state,
        expectingGiftResponse: false,
        formData: {},
        numberOfRecipients: 1,
      };
    case BUY_DIGITAL_GIFT_ERROR:
      return {
        ...state,
        expectingGiftResponse: false,
      };
    case UPDATE_NUMBER_OF_RECIPIENTS:
      return {
        ...state,
        numberOfRecipients: action.payload,
      };
    default:
      return state;
  }
};
