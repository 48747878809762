import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Card from '@starbucks-web/pattern-library/lib/components/card/';
import {
  ThemeContext,
  themes,
} from '@starbucks-web/pattern-library/lib/components/theme';

import AddCardContent from 'shared/app/components/add-card-reminder-card';
import { hasRewardAvailableSelector } from 'shared/app/bundles/user';
import { hasSvcCardSelector as hasCardSelector } from 'shared/app/bundles/svc-cards';
import LoyaltyStatus from 'shared/app/components/loyalty-status';
import RedeemOptionsButton from 'shared/app/components/redeem-options/redeem-options-button';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

import RewardsLevelsContainer from '../rewards-levels-container';

const RewardsMemberStatus = ({
  hasCards,
  hasRewardAvailable,
  startFadeOut,
  startFadeIn,
}) => {
  return (
    <ThemeContext.Provider value={themes.rewards}>
      {hasCards ? (
        <Card containerClasses="mb4" data-e2e="rewards-card-container">
          <div className="p3 pt7">
            <LoyaltyStatus
              startFadeIn={startFadeIn}
              startFadeOut={startFadeOut}
            />
            <div className="text-center">
              {hasRewardAvailable && <RedeemOptionsButton className="mr2" />}
              <Button
                data-e2e="rewardsDetailsButton"
                tagName={Link}
                to="/account/rewards"
              >
                <FormattedMessage {...sharedCallsToAction.details} />
              </Button>
            </div>
          </div>
          <RewardsLevelsContainer />
        </Card>
      ) : (
        <AddCardContent />
      )}
    </ThemeContext.Provider>
  );
};

RewardsMemberStatus.propTypes = {
  startFadeOut: PropTypes.func,
  startFadeIn: PropTypes.func,
};

const select = (state) => ({
  hasCards: hasCardSelector(state),
  hasRewardAvailable: hasRewardAvailableSelector(state),
});

export default connect(select)(RewardsMemberStatus);
