import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ImageState from '@starbucks-web/pattern-library/lib/components/image-state';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import { doLogin as doLoginAction } from 'shared/app/shell';
import {
  fetchSvcCards,
  setPayOverlayClosed as setPayOverlayClosedAction,
} from 'shared/app/bundles/svc-cards';
import { activeScanToPayCardSelector } from '../../state/selectors/cards';
import { fetchBarcodes as fetchBarcodesAction } from '../../state/actions/barcodes';
import { trackBarcodeView } from '../../state/actions/track-event';
import { activeScanToPayBarcodeStateSelector } from '../../state/selectors/barcodes';

import ContentCrateLoading from 'shared/app/components/content-crate-loading';
import FrapContainer from 'shared/app/components/frap-container';
import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import { useSessionCheckContext } from 'shared/app/shell/lib/session-check-provider';

import SuccessContent from './success-content';
import ErrorContent from './error-content';
import {
  hasRewardAvailableSelector,
  signedInSelector,
} from 'shared/app/bundles/user';
import messages from '../shared-messages';

export const PayOverlay = ({
  activeBarcodeState,
  activePaymentCard,
  doLogin,
  fetchBarcodes,
  hasRewardAvailable,
  setPayOverlayClosed,
  signedIn,
}) => {
  if (!signedIn) {
    doLogin();
    return null;
  }
  const { closeModal } = useModalContext();
  const [showLoading, setShowLoading] = useState(true);
  const {
    uri: activeBarcodeUri,
    loading: isBarcodeLoading,
    cardNumber: activeBarcodeCardNumber,
    cardId: activeBarcodeCardId,
  } = activeBarcodeState;
  // If cardNumber and cardId are available from barcode state,
  // this barcode has been fetched already and we can use them.
  // If not, we can grab from svc card data if available; if not we'll
  // handle the condition in the useEffect.
  const cardNumber = activeBarcodeCardNumber || activePaymentCard?.cardNumber;
  const cardId = activeBarcodeCardId || activePaymentCard?.cardId;

  const { checkReAuthRequired } = useSessionCheckContext();

  const getShowLoadingState = () => {
    if (!cardNumber) {
      // We don't have this barcode yet. If the cardNumber is not available from
      // svc card state, it means cards were fetched without cardNumbers
      // (user was in an extendedSession only when cards were cached).
      // Have to sign in to trigger a load of cards with cardNumbers.
      checkReAuthRequired?.({
        nextAction: () => fetchSvcCards(),
        onCancelRedirectUrl: '/account/cards',
      });
      return true;
    }

    if (!activeBarcodeUri && !isBarcodeLoading) {
      fetchBarcodes({
        cardNumber,
        cardId,
      });
      return true;
    }

    if (isBarcodeLoading) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // eslint-disable-next-line camelcase
    trackBarcodeView({ payment_method_type: 'SVC' });
  }, []);

  useEffect(() => {
    setShowLoading(getShowLoadingState());
  }, [activeBarcodeUri, cardId, cardNumber]);

  return (
    <OverlayWithCloseMessage closeCallback={setPayOverlayClosed}>
      {showLoading ? (
        <ContentCrateLoading />
      ) : (
        <div
          className="mx-auto flex flex-column flex-grow size12of12"
          data-e2e="pay-overlay"
        >
          <ImageState src={activeBarcodeUri}>
            {({ empty, failed, completed }) => {
              if (empty || failed) {
                return <ErrorContent cardNumber={cardNumber} />;
              } else if (completed) {
                return (
                  <SuccessContent
                    barcodeUri={activeBarcodeUri}
                    card={{
                      ...activePaymentCard,
                      cardId,
                      cardNumber,
                    }}
                    hasRewardAvailable={hasRewardAvailable}
                    setShowLoading={setShowLoading}
                  />
                );
              }

              return <ContentCrateLoading />;
            }}
          </ImageState>
          <FrapContainer>
            <Frap mini onClick={closeModal} tagName={Link} to="/account/cards">
              <FormattedMessage {...messages.manageCard} />
            </Frap>
            <Frap onClick={closeModal} tagName={Link} to="#add-money">
              <FormattedMessage {...messages.addMoney} />
            </Frap>
          </FrapContainer>
        </div>
      )}
    </OverlayWithCloseMessage>
  );
};

const select = (state) => ({
  signedIn: signedInSelector(state),
  activePaymentCard: activeScanToPayCardSelector(state),
  activeBarcodeState: activeScanToPayBarcodeStateSelector(state),
  hasRewardAvailable: hasRewardAvailableSelector(state),
});

const actions = {
  doLogin: doLoginAction,
  fetchBarcodes: fetchBarcodesAction,
  setPayOverlayClosed: setPayOverlayClosedAction,
};

export default connect(select, actions)(PayOverlay);
