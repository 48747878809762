import React from 'react';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import messages from './messages';
import styles from './styles.cssm';

/*
 * For options such as drive thru and in store pickup where a user
 * does not check-in to the store.
 */
const PickUpOption = ({ optionName, icon }) => (
  <div className="pb3">
    <div
      className={`${styles.container} flex justify-between items-center my2`}
    >
      <div className={styles.imageWrapper}>
        <Icon className="color-textBlackSoft" path={icon} />
      </div>
      <div className="pl2 flex-grow">
        <Heading className="pb1 text-semibold text-md" size="sm" tagName="h3">
          <FormattedMessage {...messages[`${optionName}OptionHeading`]} />
        </Heading>
        <p className="text-xxs color-textBlackSoft">
          <FormattedMessage {...messages[`${optionName}OptionMessage`]} />
        </p>
      </div>
    </div>
  </div>
);

export default PickUpOption;
