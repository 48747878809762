import { defineMessages } from 'react-intl';

export default defineMessages({
  notFoundHeading: {
    id: 'locatorErrorCard.notFoundHeading',
    defaultMessage: 'Whoops!',
    description: 'Heading when there was an error locating a specific store.',
  },
  notFoundMessage: {
    id: 'locatorErrorCard.notFoundMessage',
    defaultMessage: `We couldn't find that store.`,
    description: 'Message when there was an error locating a specific store.',
  },
  notFoundSuggestion: {
    id: 'locatorErrorCard.notFoundSuggestion',
    defaultMessage: 'Please search again.',
    description:
      'Secondary message when there was an error locating a specific store.',
  },
  noStoresHeading: {
    id: 'locatorErrorCard.noStoresHeading',
    defaultMessage: 'No stores were found.',
    description: 'Heading when there were no stores found for a location.',
  },
  noStoresFoundOrderingEnabled: {
    id: 'locatorErrorCard.noStoresFoundOrderingEnabled',
    defaultMessage: 'Try moving the map or zooming out.',
    description:
      'Message when there were no stores found and store locator hosted in menu.',
  },
  noStoresMessageHasActiveFilters: {
    id: 'locatorErrorCard.noStoresMessageHasActiveFilters',
    defaultMessage: 'Try disabling filters to see more.',
    description:
      'Message when there were no stores found and filters have been applied.',
  },
  noStoresMessageNearestNotFound: {
    id: 'locatorErrorCard.noStoresMessageNearestNotFound',
    defaultMessage: `We can't find any nearby stores.`,
    description:
      'Message when there were no stores found for an expanded location search',
  },
  noStoresMessageExpand: {
    id: 'locatorErrorCard.noStoresMessageExpand',
    defaultMessage: 'Do you want to look further away?',
    description:
      'Message when there were no stores found for a location search',
  },
  placeNameNotFoundHeading: {
    id: 'locatorErrorCard.placeNameNotFoundHeading',
    defaultMessage: 'Whoops!',
    description: 'Heading when there were no places matching a search.',
  },
  placeNameNotFoundMessage: {
    id: 'locatorErrorCard.placeNameNotFoundMessage',
    defaultMessage: `We couldn't find that location.`,
    description: 'Message when there were no places matching a search.',
  },
  placeNameNotFoundMessageSuggestion: {
    id: 'locatorErrorCard.placeNameNotFoundMessageSuggestion',
    defaultMessage: 'Please search again.',
    description:
      'Secondary message when there were no locations matching a search.',
  },
  zoomOutTooFarHeading: {
    id: 'locatorErrorCard.zoomOutTooFarHeading',
    defaultMessage: 'Zoomed out too far',
    description: 'Heading for when user is zoomed out too far to see results',
  },
  zoomedOutWithResultsMessage: {
    id: 'locatorErrorCard.zoomedOutWithResultsMessage',
    defaultMessage: 'Zoom in to see results.',
    description: 'Message when user is zoomed too far out to see results',
  },
  zoomOutWithoutResultsMessage: {
    id: 'locatorErrorCard.zoomOutWithoutResultsMessage',
    defaultMessage:
      'Try searching for a location or zooming in to see results.',
    description:
      'Message when user is zoomed out and does not see any messages',
  },
  expandSearchLabel: {
    id: 'locatorErrorCard.expandSearchLabel',
    defaultMessage: 'Expand Search',
    description:
      'Button text for expanding the radius of a store location search.',
  },
  geolocationErrorHeading: {
    id: 'locatorErrorCard.geolocationError.heading',
    description: 'Heading for geolocation error state',
    defaultMessage: 'We are unable to access your exact location',
  },
  geolocationErrorBody: {
    id: 'locatorErrorCard.geolocationError.body',
    description: 'Body for geolocation error state',
    defaultMessage:
      'To find a Starbucks store, use the search feature, navigate using the map, or turn on location services.',
  },
  noPreviousStoresHeading: {
    id: 'locatorErrorCard.noPreviousStoresHeading',
    defaultMessage: 'No previous stores near you',
    description: 'Heading for no previous stores found error.',
  },
  noPreviousStoresMessage: {
    id: 'locatorErrorCard.noPreviousStoresMessage',
    defaultMessage:
      'When you order from a store in this area, it will appear here for you to choose from.',
    description: 'Message for no previous stores found error.',
  },
  noFavoriteStoresHeading: {
    id: 'locatorErrorCard.noFavoriteStoresHeading',
    defaultMessage: 'No favorite stores near you',
    description: 'Heading for no favorite stores found error.',
  },
  noFavoriteStoresMessage: {
    id: 'locatorErrorCard.noFavoriteStoresMessage',
    defaultMessage:
      'When you tap on the heart to favorite a store in this area, it will appear here for you to choose from.',
    description: 'Message for no favorite stores found error.',
  },
});
