import { defineMessages } from 'react-intl';

export default defineMessages({
  deliveryHeading: {
    id: 'storeLocator.deliveryLandingPage.deliveryHeading',
    defaultMessage: 'Today deserves delivery',
    description: 'Delivery landing page heading',
  },
  deliveryHeroAlt: {
    id: 'storeLocator.deliveryLandingPage.deliveryHeroAlt',
    defaultMessage: 'Starbucks bag and cups in carrier.',
    description: 'Alt text for hero image',
  },
  getStartedFrap: {
    id: 'storeLocator.deliveryLandingPage.getStartedFrap',
    defaultMessage: 'Get started',
    description: 'get started frap label',
  },
  deliveryFaqButton: {
    id: 'storeLocator.deliveryLandingPage.deliveryFaqButton',
    defaultMessage: 'Delivery FAQs',
    description: 'faq button label',
  },
  doordashBannerAltText: {
    id: 'storeLocator.deliveryLandingPage.doordashBannerAltText',
    defaultMessage: 'Powered by DoorDash',
    description: 'Alt text for doordash label',
  },
  doordashLegalText: {
    id: 'storeLocator.deliveryLandingPage.doordashLegalText',
    defaultMessage:
      'Menu limited. Menu pricing for delivery may be higher than posted in stores or as marked. Additional fees may apply. Delivery orders are not eligible for Starbucks® Rewards benefits at this time.',
    description: 'Legal text for doordash delivery',
  },
  rebuildCartDialogHeader: {
    id: 'storeLocator.deliveryLandingPage.rebuildCartDialogHeader',
    description:
      'Header for dialog that alerts customer they will need to rebuild cart on doordash',
    defaultMessage: 'You’ll need to rebuild your order for delivery',
  },
  rebuildCartDialogBody: {
    id: 'storeLocator.deliveryLandingPage.rebuildCartDialogBody',
    description:
      'Text for dialog that alerts customer they will need to rebuild cart on doordash',
    defaultMessage: `Items in your bag for pickup won’t be transferred to your delivery order. Some items may not be available for delivery.`,
  },
});
