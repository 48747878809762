import { createSelector } from 'reselect';

export const configSelector = (state) => state.config;

export const maximumItemsSelector = createSelector(
  configSelector,
  (config) => config?.maximumItemAmount
);

export const maximumModifierAmountSelector = createSelector(
  configSelector,
  (config) => config?.maximumModifierAmount
);

export const svcLoadOptionsSelector = createSelector(
  configSelector,
  (config) => config?.svcLoadOptions
);

export const defaultSvcLoadOptionSelector = createSelector(
  configSelector,
  (config) => config?.defaultSvcLoadOption
);

export const showStandaloneAndPwaMenuSelector = createSelector(
  configSelector,
  (config) => config?.showStandaloneAndPwaMenu
);

export const showCaloriesFromFatSelector = createSelector(
  configSelector,
  (config) => config?.showCaloriesFromFat
);

export const showOutOfStockSimilarItemsCtaSelector = createSelector(
  configSelector,
  (config) => config?.showOutOfStockSimilarItemsCta
);
