import { defineMessages } from 'react-intl';

export default defineMessages({
  toAddCardStarbucksMember: {
    id: 'stream.streamItems.guest.joinRewardsSigninSheet.toAddCardStarbucksMember',
    defaultMessage: 'To add a Starbucks Card, create an account or sign in',
    description:
      'Message to inform user they need to login or create an account to add a card',
  },
  startOrderTitle: {
    id: 'stream.streamItems.guest.startOrderTitle',
    defaultMessage: 'Order on the way',
    description: 'Title for guests educational card to start an order',
  },
  startOrderBody: {
    id: 'stream.streamItems.guest.startOrderBody',
    defaultMessage:
      'Order ahead and pick up your order at the counter when you arrive.',
    description: 'Body for guests educational card to start an order',
  },
  addCardTitle: {
    id: 'stream.streamItems.guest.addCardTitle',
    defaultMessage: 'Have a Starbucks Card?',
    description:
      'Title for guests educational card to add a card to their account',
  },
  addCardBody: {
    id: 'stream.streamItems.guest.addCardBody',
    defaultMessage:
      'Add a Starbucks Card to order ahead and start earning more.',
    description:
      'Body for guests educational card to add a card to their account',
  },
  addCardCTA: {
    id: 'stream.streamItems.guest.addCardCTA',
    defaultMessage: 'Register card',
    description: 'Button text for guests to add a SVC to their account',
  },
});
