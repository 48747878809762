import { defineMessages } from 'react-intl';

export default defineMessages({
  subtotal: {
    id: 'gift.giftPaymentBottomSheet.subtotalLabel',
    defaultMessage: 'Subtotal',
    description:
      'Label describing the "subtotal" field when making a gift purchase',
  },
  tax: {
    id: 'gift.giftPaymentBottomSheet.taxLabel',
    defaultMessage: 'Tax N/A',
    description:
      'Label describing the "tax" field, which is non-applicable, when making a gift purchase',
  },
  selectPaymentMethod: {
    id: 'gift.giftPaymentBottomSheet.selectPaymentMethod',
    defaultMessage: 'Select payment method',
    description: 'Label describing the payment method dropdown',
  },
  sendGift: {
    id: 'gift.giftPaymentBottomSheet.sendGift',
    defaultMessage:
      'Buy and send {numberOfRecipients, plural, one {eGift} other {eGifts}}',
    description: 'Label for Send Gift, which completes purchase of gift card',
  },
  paymentMethodHeading: {
    id: 'gift.giftPaymentBottomSheet.paymentMethodsHeading',
    defaultMessage: 'Choose payment',
    description: "Heading for the 'Choose payment' screen",
  },
  loadingPaymentMethods: {
    id: 'gift.guestPaymentTokenExpired.loadingPaymentMethods',
    defaultMessage: 'Loading payment methods ...',
    description: 'Label describing a loading animation for payment methods',
  },
  doubleCheckRecipients: {
    id: 'gift.giftPaymentBottomSheet.doubleCheckRecipients',
    defaultMessage: 'Double-check all recipient emails before buying',
    description:
      'Reminder to users to check the recipient emails before sending gifts',
  },
});
