import { createSelector } from 'reselect';

import { mapDefaultsSelector } from './config';
import { storeLocatorSelectorWithFallback } from './util';

export const mapStateSelector = createSelector(
  storeLocatorSelectorWithFallback,
  ({ mapState }) => mapState || {}
);

export const listHeightSelector = createSelector(
  mapStateSelector,
  (mapState) => mapState.listHeight
);

export const locatorControlsHeightSelector = createSelector(
  mapStateSelector,
  (mapState) => mapState.locatorControlsHeight
);

export const locatorPageHeightSelector = createSelector(
  mapStateSelector,
  ({ locatorPageRectangle }) =>
    locatorPageRectangle ? locatorPageRectangle.height : 0
);

export const userOverrideSelector = createSelector(
  mapStateSelector,
  (mapState) => mapState.userOverride
);

export const mapInstanceSelector = createSelector(
  mapStateSelector,
  (mapState) => mapState.mapInstance
);

export const mapPositionSelector = createSelector(
  mapStateSelector,
  mapDefaultsSelector,
  (mapState, mapDefaults) => ({
    lng: parseFloat(mapState.lng || mapDefaults.lng),
    lat: parseFloat(mapState.lat || mapDefaults.lat),
    zoomLevel: parseInt(mapState.zoomLevel || mapDefaults.zoom, 10),
  })
);

export const initialUrlZoomOutsideThresholdSelector = createSelector(
  mapStateSelector,
  (mapState) => Boolean(mapState.initialUrlZoomOutsideThreshold)
);
