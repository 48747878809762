import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import { signedInSelector } from 'shared/app/bundles/user';

import LocationCardContainer from '../location-card-container';
import LocationCardDetails from '../location-card-details';

import {
  highlightLocation,
  unhighlightLocation,
  makeLocationFavorite,
  selectLocation,
} from '../../state/actions/location';

import clickModifiers from '../../utils/click-modifiers';

import SignInRequiredDialog, {
  SIGN_IN_REQUIRED_DIALOG_ID,
} from '../sign-in-required-dialog';

import styles from './styles.cssm';
import messages from './messages';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line max-statements
const LocationCard = forwardRef(
  ({ location, isActive = false, orderAndPickupFeatures }, ref) => {
    const { formatMessage } = useIntl();
    const { openModal } = useModalContext();
    const dispatch = useDispatch();

    const isUserSignedIn = useSelector(signedInSelector);

    const { store } = location;

    const showSignInDialog = () => {
      openModal({
        component: SignInRequiredDialog,
        ariaLabelledBy: SIGN_IN_REQUIRED_DIALOG_ID,
      });
    };

    const handleClick = (e) => {
      if (clickModifiers(e)) {
        return;
      }
      e.preventDefault();
      dispatch(
        selectLocation(store.storeNumber, {
          location,
          userOverride: false,
        })
      );
    };

    const handleInfoClick = (e) => {
      e.preventDefault();
      dispatch(
        selectLocation(store.storeNumber, {
          location,
          expanded: true,
        })
      );
    };

    const handleFavoriteClick = (e) => {
      e.preventDefault();
      if (!isUserSignedIn) {
        showSignInDialog();
        return;
      }

      if (location.favoritePending) {
        return;
      }

      dispatch(makeLocationFavorite(location));
    };

    const handleOnMouseEnter = () => {
      if (isActive) {
        return;
      }
      dispatch(highlightLocation(store.storeNumber));
    };

    const handleOnMouseLeave = () => {
      if (isActive) {
        return;
      }
      dispatch(unhighlightLocation());
    };

    return (
      <LocationCardContainer
        className={classNames(
          'linkOverlay sb-global-gutters py3 relative',
          isActive && `${styles.isActive} isActive`
        )}
        data-e2e={isActive ? 'activeCard' : null}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        ref={ref}
      >
        <button
          aria-label={formatMessage(
            isActive
              ? messages.storeCardActiveButtonLabel
              : messages.storeCardInactiveButtonLabel,
            {
              storeName: store.name,
            }
          )}
          className={`linkOverlay__primary ${styles.overlayLink}`}
          data-e2e="cardButton"
          disabled={isActive}
          onClick={handleClick}
        />
        <LocationCardDetails
          handleFavoriteClick={handleFavoriteClick}
          handleInfoClick={handleInfoClick}
          location={location}
          orderAndPickupFeatures={orderAndPickupFeatures}
        />
      </LocationCardContainer>
    );
  }
);

export default LocationCard;
