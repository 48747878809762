import { themes } from '@starbucks-web/pattern-library/lib/components/theme';

import { lazyContentCrate, lazyHeaderCrate } from 'shared/app/shell';
import {
  hasRewardAvailableSelector,
  starBalanceSelector,
} from 'shared/app/bundles/user';

import { FETCH_EXPIRATIONS, FETCH_EXPIRATIONS_SUCCESS } from './actions';
import reducer from './state/reducers';
import { shouldFetchExpirationsSelector } from './state/selectors';
import { fetchExpirations } from './state/action-creators';

const RewardsAppBar = lazyHeaderCrate(
  () =>
    import(
      /* webpackChunkName: 'rewards-app-bar-header' */ './components/rewards-app-bar'
    )
);

export default {
  name: 'rewards',
  routes: {
    '/account/rewards/how-it-works': {
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'rewards-how-it-works-content' */ './components/how-it-works'
          )
      ),
      headerCrate: RewardsAppBar,
      contentTheme: themes.rewards,
      authRequired: true,
    },
    '/account/rewards': {
      contentCrate: lazyContentCrate(
        () =>
          import(/* webpackChunkName: 'rewards-content' */ './components/root')
      ),
      headerCrate: RewardsAppBar,
      authRequired: true,
      contentTheme: themes.rewards,
    },
  },
  reducer,
  selectors: {
    hasRewardAvailableSelector,
    starBalanceSelector,
  },
  effects: [
    {
      selector: shouldFetchExpirationsSelector,
      actionCreator: fetchExpirations,
    },
  ],
  asyncActions: [FETCH_EXPIRATIONS],
  persistAfter: [FETCH_EXPIRATIONS_SUCCESS],
};
