import React from 'react';
import SegmentedControl from '@starbucks-web/pattern-library/lib/components/segmented-control';
import PickupForm from '../pickup-form';
import messages from './messages';

const CurbsideForm = ({
  loading,
  onSubmit,
  selectedStall,
  setSelectedStall,
  totalSpots,
}) => {
  return (
    <PickupForm
      imageName="curbside.svg"
      loading={loading}
      onSubmit={onSubmit}
      pickupMessage={messages.parkedInSpot}
    >
      <SegmentedControl
        className="size12of12 mb4"
        inputs={Array.from({ length: totalSpots }, (_, idx) => ({
          displayName: `${idx + 1}`,
          value: `${idx + 1}`,
        }))}
        onValueChange={(stall) => setSelectedStall(stall)}
        selectedValue={selectedStall}
      />
    </PickupForm>
  );
};

export default CurbsideForm;
