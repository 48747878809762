import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { push as pushAction } from 'redux-first-history';
import Card from '@starbucks-web/pattern-library/lib/components/card/';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Expander from '@starbucks-web/pattern-library/lib/components/expander';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import chevronRightPath from '@starbucks-web/pattern-library/lib/icons/chevron-right';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import { ONBOARDING_COMPLETED_TASK_SEEN } from '../../../state/actions/types';
import { seenCompletedTasksSelector } from '../../../state/selectors/stream';
import { trackStreamCardView } from '../../../state/track-event';

import styles from './styles.cssm';

const OnboardingItem = (props) => {
  // show relevant onboarding card based on user's onboarding status
  // returned in the onboarding stream card.
  const {
    calltoactionlink,
    description,
    headingTitle,
    idx,
    imgUrl,
    imagePath,
    markCompletedTaskSeen,
    onClick,
    push,
    seenCompletedTasks,
    task,
  } = props;

  const complete = Boolean(task.completedOn);

  const cardContentClasses = classNames({
    [styles.cardCompleteContent]: Boolean(complete),
    'px3 xl-px2 flex': !complete,
  });
  const headingClasses = classNames('text-left text-bold md-mb2', {
    'px3 xl-px4 justify-center': Boolean(complete),
  });

  const seenCompletedTask = Boolean(seenCompletedTasks?.includes(task.name));

  useEffect(() => {
    /* eslint-disable camelcase */
    trackStreamCardView({
      authentication_state: true,
      stream_card_type: 'onboarding',
      stream_card_position: idx.toString(),
    });
    /* eslint-enable camelcase */
  }, []);

  // if the task is complete, we'll show a completed state for 5 seconds, then remove it.
  if (complete && !seenCompletedTask)
    setTimeout(() => {
      markCompletedTaskSeen(task.name);
    }, 5000);

  return (
    <Expander
      expandEffect="pushdown"
      isExpanded={!complete ? true : !seenCompletedTask}
    >
      <button
        className="size12of12"
        onClick={() => {
          onClick();
          // using button onclick and push here as
          // Redux Little Router's Link component will ignore hashes
          push(calltoactionlink);
        }}
      >
        <Card
          containerClasses={`mb3 flex flex-column justify-center ${styles.card}`}
          contentClasses={`flex items-center ${cardContentClasses}`}
          data-e2e="onboardingCard"
        >
          <ImageLoader alt="" src={`${imgUrl}${imagePath}`} width="48px" />
          <div className="mx2 flex-grow">
            <Heading className={headingClasses} size="xs" tagName="h3">
              {!complete && headingTitle}
            </Heading>
            {!complete && (
              <p className="text-left color-textBlack">{description}</p>
            )}
          </div>
          <Icon path={chevronRightPath} />
        </Card>
      </button>
    </Expander>
  );
};

const select = (state) => ({
  seenCompletedTasks: seenCompletedTasksSelector(state),
  imgUrl: configImagesUrlSelector(state),
});

const actions = (dispatch) => ({
  markCompletedTaskSeen: (name) => {
    dispatch({ type: ONBOARDING_COMPLETED_TASK_SEEN, name });
  },
  push: (route) => dispatch(pushAction(route)),
});

export default connect(select, actions)(OnboardingItem);
