import {
  ADD_PAYMENT,
  REMOVE_PAYMENT_INSTRUMENT,
  SET_NEW_PAYMENT_INSTRUMENT,
} from 'shared/app/bundles/wallet';
import { SET_SELECTED_GIFT_PAYMENT_INSTRUMENT } from '../../actions';

export default (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_GIFT_PAYMENT_INSTRUMENT:
      return action.payload;
    case REMOVE_PAYMENT_INSTRUMENT:
      if (action?.payload === state) {
        return null;
      }
      return state;
    case SET_NEW_PAYMENT_INSTRUMENT:
      if (state === ADD_PAYMENT) {
        return action.payload?.paymentInstrumentId;
      }
      return state;
    default:
      return state;
  }
};
