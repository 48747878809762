import {
  ADD_PAYMENT,
  // REMOVE_PAYMENT_INSTRUMENT_SUCCESS,
  SET_NEW_PAYMENT_INSTRUMENT,
} from 'shared/app/bundles/wallet';

import { SET_SELECTED_ORDER_RELOAD_SOURCE_ID } from '../actions/types';

// eslint-disable-next-line max-statements
export default (state = null, action) => {
  switch (action.type) {
    case SET_NEW_PAYMENT_INSTRUMENT:
      if (state === ADD_PAYMENT) {
        return action.payload.paymentInstrumentId;
      }
      return state;
    // TODO: Does removing this create any bugs?
    // case REMOVE_PAYMENT_INSTRUMENT_SUCCESS:
    //   // return null;
    //   return state;
    case SET_SELECTED_ORDER_RELOAD_SOURCE_ID:
      return action.payload;
    default: {
      return state;
    }
  }
};
