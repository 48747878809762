import { composeApp } from 'shared/app/shell';
import GlobalNav from './nav';

import accrualEarnRatesBundle from 'shared/app/bundles/accrual-earn-rates';
import geolocationBundle from 'shared/app/bundles/geolocation';
import menuBundle from 'shared/app/bundles/menu';
import mfaBundle from 'shared/app/bundles/mfa';
import scriptsBundle from 'shared/app/bundles/scripts';
import svcCardsBundle from 'shared/app/bundles/svc-cards';
import userBundle from 'shared/app/bundles/user';
import viewportBundle from 'shared/app/bundles/viewport';
import walletBundle from 'shared/app/bundles/wallet';

import accountCardsApp from 'account-cards/app';
import accountHistoryApp from 'account-history/app';
import accountPaymentMethodApp from 'account-payment-method/app/payment-method-sub-app';
import accountPersonalApp from 'account-personal/app';
import accountProfileApp from 'account-profile/app';
import accountSettingsApp from 'account-settings/app/settings-subapp';
import giftApp from 'gift/app/gift-sub-app';
import orderingApp from 'ordering/app/ordering-sub-app';
import pickupApp from 'pickup/app/pickup-sub-app';
import rewardsApp from 'account-rewards/app';
import storeLocatorApp from 'store-locator/app/store-locator-subapp';
import streamApp from 'stream/app';

const apps = [
  accountCardsApp,
  accountHistoryApp,
  accountPaymentMethodApp,
  accountPersonalApp,
  accountProfileApp,
  accountSettingsApp,
  accrualEarnRatesBundle,
  geolocationBundle,
  giftApp,
  menuBundle,
  mfaBundle,
  orderingApp,
  pickupApp,
  rewardsApp,
  scriptsBundle,
  storeLocatorApp,
  streamApp,
  svcCardsBundle,
  userBundle,
  viewportBundle,
  walletBundle,
];

export default composeApp({
  apps,
  nav: {
    globalNav: {
      component: GlobalNav,
    },
  },
});
