import { FETCH_MODIFIER_AVAILABILITY_SUCCESS } from 'shared/app/state/action-creators/types';

import { CLEAR_STORE_MENU } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case CLEAR_STORE_MENU:
      return null;
    case FETCH_MODIFIER_AVAILABILITY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
