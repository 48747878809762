import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import curbsideIcon from '@starbucks-web/pattern-library/lib/icons/pickup-curbside';
import inStoreIcon from '@starbucks-web/pattern-library/lib/icons/pickup-in-store';
import outdoorIcon from '@starbucks-web/pattern-library/lib/icons/pickup-outdoor';
import driveThruIcon from '@starbucks-web/pattern-library/lib/icons/pickup-drive-thru';

import ContentCrateLoading from 'shared/app/components/content-crate-loading';
import LegacyPaddedContentColumn from 'shared/app/components/legacy-padded-content-column';

import CheckInOption from './checkin-option';
import PickUpOption from './pickup-option';
import CheckInClosedBanner from './closed-banner';
import messages from './messages';
import styles from './styles.cssm';

import { currentRouteSearchSelector } from 'shared/app/state/selectors/routes';

import {
  curbsidePickupAvailableSelector,
  outdoorPickupAvailableSelector,
  isOutdoorPickupStoreSelector,
  isCheckInStoreSelector,
  hasFetchedStoreDetailsSelector,
  isDriveThruStoreSelector,
} from '../../state/selectors';

const PickUpOptions = ({
  curbsidePickupAvailable,
  outdoorPickupAvailable,
  isOutdoorPickupStore,
  isCheckInStore,
  hasFetchedStoreDetails,
  currentRouteSearchString,
  isDriveThruStore,
}) => {
  if (!hasFetchedStoreDetails) {
    return (
      <div className="height-100 bg-white pt5">
        <ContentCrateLoading />
      </div>
    );
  }

  const checkInClosedMessageId = isOutdoorPickupStore
    ? 'outdoorOptionClosed'
    : 'curbsideOptionClosed';

  const LABEL_NEW_ID = 'labelNew';
  return (
    <Fragment>
      <LegacyPaddedContentColumn size="md">
        {isCheckInStore && (
          <Fragment>
            {!curbsidePickupAvailable && !outdoorPickupAvailable ? (
              <CheckInClosedBanner checkInMessageId={checkInClosedMessageId} />
            ) : (
              <Fragment>
                <div className="flex items-center">
                  <Heading
                    aria-describedby={LABEL_NEW_ID}
                    className="ml3 pb1 text-semibold text-md inline"
                    tagName="h2"
                  >
                    <FormattedMessage {...messages.checkInHeading} />
                  </Heading>
                  <span className="hiddenVisually" id={LABEL_NEW_ID}>
                    <FormattedMessage {...messages.newLabel} />
                  </span>
                  <span className={styles.newLabel}>
                    <FormattedMessage {...messages.newLabel} />
                  </span>
                </div>
                {outdoorPickupAvailable && (
                  <CheckInOption
                    icon={outdoorIcon}
                    optionName="outdoor"
                    queryParams={currentRouteSearchString}
                  />
                )}
                {curbsidePickupAvailable && (
                  <CheckInOption
                    icon={curbsideIcon}
                    optionName="curbside"
                    queryParams={currentRouteSearchString}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
        <Heading className="ml3 pb1 text-semibold text-md" tagName="h2">
          {curbsidePickupAvailable || outdoorPickupAvailable ? (
            <FormattedMessage {...messages.pickupOptionsHeading} />
          ) : (
            <FormattedMessage {...messages.pickupOnlyOptionsHeading} />
          )}
        </Heading>
        <PickUpOption icon={inStoreIcon} optionName="inStore" />
        {isDriveThruStore && (
          <PickUpOption icon={driveThruIcon} optionName="driveThru" />
        )}
      </LegacyPaddedContentColumn>
    </Fragment>
  );
};

const select = (state) => ({
  curbsidePickupAvailable: curbsidePickupAvailableSelector(state),
  outdoorPickupAvailable: outdoorPickupAvailableSelector(state),
  isOutdoorPickupStore: isOutdoorPickupStoreSelector(state),
  currentRouteSearchString: currentRouteSearchSelector(state),
  isCheckInStore: isCheckInStoreSelector(state),
  hasFetchedStoreDetails: hasFetchedStoreDetailsSelector(state),
  isDriveThruStore: isDriveThruStoreSelector(state),
});

export default connect(select)(PickUpOptions);
