import { createSelector } from 'reselect';

import { giftDetailsCardSelector } from './gift-details';
import { digitalGiftsCatalogDataSelector } from './digital-gifts-catalog';
import { routeParamsSelector } from 'shared/app/state/selectors/routes';
// Returns svcCard data if it's available or the card overview data if not,
// allowing us to partially render card details during the request.
export const currentCardSelector = createSelector(
  giftDetailsCardSelector,
  digitalGiftsCatalogDataSelector,
  routeParamsSelector,
  (giftDetails, digitalGiftsData, routeParams) => {
    // If we have giftDetails data ready to go (or if it's loading and we don't
    // have full data to fall back to) use it.
    if (giftDetails && (!giftDetails.loading || !digitalGiftsData)) {
      return giftDetails;
    }

    let result;

    if (digitalGiftsData) {
      digitalGiftsData.categories.forEach((category) => {
        if (result) {
          return;
        }

        result = category?.eGifts?.find(
          (card) => card.productNumber === routeParams.productNumber
        );
      });
    }

    // If we have backup data but don't have the current card within it (because the data is
    // incomplete, or we don't have that particular category loaded), use the giftDetails data,
    // even though it's still loading (we'll show placeholder content).
    if (!result && giftDetails) {
      return giftDetails;
    }

    // If we did find a result in our current data,
    // use it until the full data has loaded
    return result ? { data: result } : null;
  }
);
