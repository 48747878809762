/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export const callsToAction = defineMessages({
  tryAgain: {
    id: 'accountCards.notifications.tryAgain',
    defaultMessage: 'Try again',
    description:
      'Call to action asking the user to try the previous action again.',
  },
  update: {
    id: 'accountCards.notifications.update',
    defaultMessage: 'Update',
    description: 'Call to action asking the user to update their action.',
  },
  chooseDifferentCard: {
    id: 'accountCards.notifications.chooseDifferentCard',
    defaultMessage: 'Choose different card',
    description:
      'Call to action asking the user to use a different card for their action.',
  },
  registerCard: {
    id: 'accountCards.notifications.registerCard',
    defaultMessage: 'Register this card',
    description: 'Call to action asking the user to register a card.',
  },
  addItBack: {
    id: 'accountCards.notifications.addItBack',
    defaultMessage: 'Add it back',
    description: 'Call to action asking the user to re-register a card.',
  },
  updatePaymentMethod: {
    id: 'accountCards.notifications.updatePaymentMethod',
    defaultMessage: 'Update payment method',
    description:
      'Call to action asking the user to update their payment method.',
  },
  manageCard: {
    id: 'accountCards.notifications.manageCard',
    defaultMessage: 'Manage card',
    description: 'Call to action that directs the user to manage their card.',
  },
});

export const messages = defineMessages({
  unexpectedErrorWithTransfer: {
    id: 'accountCards.notifications.unexpectedErrorWithTransfer',
    defaultMessage:
      'Something went wrong with your transfer. Try transferring your balance again.',
    description:
      'Notification message displayed on unexpected errors during the transfer card process',
  },
  makePrimaryCardError: {
    id: 'accountCards.notifications.makePrimaryCardError',
    defaultMessage: 'Something went wrong with making this card primary.',
    description:
      'Notification message displayed when this card cannot be made primary',
  },
  enterCorrectCardNumberSecurityCode: {
    id: 'accountCards.notifications.enterCorrectCardNumberSecurityCode',
    defaultMessage: 'That card number or security code doesn’t look right.',
    description:
      'Notification message displayed when user enters invalid card number',
  },
  cardRegisteredToSomeoneElse: {
    id: 'accountCards.notifications.cardRegisteredToSomeoneElse',
    defaultMessage: 'This Starbucks Card is already registered.',
    description:
      'Notification message displayed when the card user tries to register is registered to someone else',
  },
  cardMaxBalanceExceeded: {
    id: 'accountCards.notifications.cardMaxBalanceExceeded',
    defaultMessage: 'Your card’s total amount cannot exceed $500.',
    description:
      'Notification message displayed when the user’s balance tranfer will result in a card balance higher than the limit',
  },
  cardHasInsufficientFunds: {
    id: 'accountCards.notifications.cardHasInsufficientFunds',
    defaultMessage: 'Your card’s balance is too low to transfer that amount.',
    description:
      'Notification message displayed when the user tries to transfer balance from a card with no balance',
  },
  cardInactive: {
    id: 'accountCards.notifications.cardInactive',
    defaultMessage:
      'To pay with this card, have a Starbucks barista activate it for you.',
    description:
      'Notification message displayed when the user tries to transfer balance from a card that is inactive',
  },
  cardLostOrStolen: {
    id: 'accountCards.notifications.cardLostOrStolen',
    defaultMessage:
      'This Starbucks Card has been reported lost or stolen. We’re unable to register the card.',
    description:
      'Notification message displayed when the user tries to transfer balance from a card that is reported lost or stolen',
  },
  cardDifferentMarket: {
    id: 'accountCards.notifications.cardDifferentMarket',
    defaultMessage: 'International Starbucks Cards not currently supported.',
    description:
      'Notification message displayed when the user tries to interact from a card registered in a different market',
  },
  cardUnregistered: {
    id: 'accountCards.notifications.cardUnregistered',
    defaultMessage: 'This Starbucks Card is not registered on our website.',
    description:
      'Notification message displayed when the user tries to transfer balance from a card that is not registered',
  },
  cardDeactivated: {
    id: 'accountCards.notifications.cardDeactivated',
    defaultMessage: 'This Starbucks Card has been deactivated.',
    description:
      'Notification message displayed when the user tries to transfer balance from a card that is deactivated',
  },
  enterReloadAmount: {
    id: 'accountCards.notifications.enterReloadAmount',
    defaultMessage: 'Enter an Auto reload amount.',
    description:
      'Notification message displayed when the user needs to enter a valid auto reload amount',
  },
  serviceRecoveryCard: {
    id: 'accountCards.notifications.serviceRecoveryCard',
    defaultMessage:
      'Redeem this Store Credit card with a barista at any participating Starbucks store.',
    description:
      'Notification message displayed when the user tries to load a service recovery card',
  },
  fraudPaymentFailure: {
    id: 'accountCards.notifications.fraudPaymentFailure',
    defaultMessage:
      'There is a problem with your card. Please contact your bank.',
    description:
      'Notification message displayed when the user reload fails the fraud check',
  },
  paymentMethodInvalid: {
    id: 'accountCards.notifications.paymentMethodInvalid',
    defaultMessage: 'Something went wrong with your payment method.',
    description:
      'Notification message displayed when the user’s payment method is invalid',
  },
  paymentMethodExpired: {
    id: 'accountCards.notifications.paymentMethodExpired',
    defaultMessage: 'This credit/debit card may have expired.',
    description:
      'Notification message displayed when the user’s payment method is expired',
  },
  creditCardRejected: {
    id: 'accountCards.notifications.creditCardRejected',
    defaultMessage:
      'Something went wrong on our end. Please select a different payment method and try again.',
    description:
      'Notification message displayed when the user’s credit card is rejected',
  },
  payPalError: {
    id: 'accountCards.notifications.payPalError',
    defaultMessage:
      'Something went wrong on PayPal’s end. Please choose another payment method.',
    description: 'Notification message displayed when PayPal payment fails',
  },
  antiFraudViolation: {
    id: 'accountCards.notifications.antiFraudViolation',
    defaultMessage:
      'There is a problem with the card you’re using. Contact Customer Service at 800-782-7282.',
    description:
      'Notification message displayed when card violates anti-fraud rules',
  },
  duplicateTransaction: {
    id: 'accountCards.notifications.duplicateTransaction',
    defaultMessage: 'We already received your request, thanks!',
    description:
      'Notification message displayed when reload is a duplicate transaction',
  },
  americanExpressError: {
    id: 'accountCards.notifications.americanExpressError',
    defaultMessage:
      'Something went wrong on Amex’s end. Please choose another payment method.',
    description:
      'Notification message displayed when American Express payment fails',
  },
});
