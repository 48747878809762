import { push } from 'redux-first-history';

import { messages, callsToAction } from './messages';
import { sharedNotificationMessages } from 'shared/app/messages';

const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';

// Card, Payment, and Barcode Error Codes:
// JIRA/wiki/spaces/WNW/pages/137322543/Card%2C+Payment+and+Barcode+Error+Codes
//
// Transfer Balance Error Codes:
// JIRA/wiki/spaces/WNW/pages/139672819
export const getNotificationForErrorCode = ({
  errorCode,
  isTransfer = null,
}) => {
  const errorNotificationMap = {
    makePrimaryCardError: {
      message: messages.makePrimaryCardError,
    },
    121000: {
      message: messages.enterCorrectCardNumberSecurityCode,
      callToAction: callsToAction.tryAgain,
    },
    121001: {
      message: messages.enterCorrectCardNumberSecurityCode,
      callToAction: callsToAction.tryAgain,
    },
    121002: {
      message: messages.enterCorrectCardNumberSecurityCode,
      callToAction: callsToAction.tryAgain,
    },
    121017: {
      message: messages.enterCorrectCardNumberSecurityCode,
      callToAction: callsToAction.tryAgain,
    },
    121009: {
      message: messages.enterReloadAmount,
    },
    121010: {
      message: messages.enterReloadAmount,
    },
    121023: {
      message: messages.cardMaxBalanceExceeded,
    },
    121024: {
      message: messages.serviceRecoveryCard,
    },
    121025: {
      message: messages.cardHasInsufficientFunds,
      callToAction: callsToAction.chooseDifferentCard,
    },
    121030: {
      message: messages.cardInactive,
    },
    121032: {
      message: messages.cardLostOrStolen,
    },
    121033: {
      message: messages.cardDifferentMarket,
    },
    121034: {
      message: messages.cardUnregistered,
      callToAction: callsToAction.registerCard,
      confirmAction: push('/account/cards/add'),
    },
    121035: {
      message: messages.fraudPaymentFailure,
    },
    121037: {
      message: messages.cardDeactivated,
      callToAction: callsToAction.addItBack,
      confirmAction: push('/account/cards/add'),
    },
    121043: {
      message: messages.paymentMethodInvalid,
    },
    121045: {
      message: messages.paymentMethodInvalid,
    },
    121046: {
      message: messages.paymentMethodInvalid,
    },
    121065: {
      message: messages.paymentMethodInvalid,
    },
    121066: {
      message: messages.paymentMethodInvalid,
    },
    121068: {
      message: messages.paymentMethodInvalid,
    },
    121070: {
      message: messages.paymentMethodInvalid,
    },
    121044: {
      message: messages.paymentMethodExpired,
    },
    121048: {
      message: messages.creditCardRejected,
    },
    121059: {
      message: messages.payPalError,
    },
    121060: {
      message: messages.payPalError,
    },
    121061: {
      message: messages.payPalError,
    },
    121067: {
      message: messages.payPalError,
    },
    121071: {
      message: messages.payPalError,
    },
    121072: {
      message: messages.payPalError,
    },
    121062: {
      message: messages.antiFraudViolation,
    },
    121063: {
      message: messages.duplicateTransaction,
    },
    121069: {
      message: messages.americanExpressError,
    },
    122000: {
      message: messages.cardRegisteredToSomeoneElse,
      callToAction: callsToAction.tryAgain,
    },
    [UNEXPECTED_ERROR]: isTransfer
      ? {
          message: messages.unexpectedErrorWithTransfer,
          callToAction: callsToAction.manageCard,
          confirmAction: push(`/account/cards/${isTransfer.cardId}`),
        }
      : {
          message: sharedNotificationMessages.unexpectedError,
          callToAction: callsToAction.tryAgain,
        },
  };

  return (
    errorNotificationMap[errorCode] || errorNotificationMap[UNEXPECTED_ERROR]
  );
};
