import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { FormattedMessage } from 'react-intl';

import checkPath from '@starbucks-web/pattern-library/lib/icons/check';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import featureIconMap from '../../utils/feature-icon-map';

import styles from './styles.cssm';

export const LocatorFilterItem = ({ action, active = false, code, name }) => {
  const onClick = (e) => {
    e.preventDefault();
    action(code);
  };

  const buttonClass = cs(
    'px4 sm-px6 flex items-center text-left width-100',
    styles.filterButton,
    {
      [`color-textBlack ${styles.isActive}`]: active,
      'color-textBlackSoft': !active,
    }
  );

  return (
    <li
      className={styles.listItem}
      data-e2e={active ? 'activeAmenityFilter' : null}
    >
      <button
        className={buttonClass}
        data-e2e={`locatorFilterItem--${code}`}
        onClick={onClick}
      >
        <Icon
          className={styles.filterTypeIcon}
          path={featureIconMap[code]}
          size="28px"
        />
        <div className="flex-grow">
          <span className="hiddenVisually">
            <FormattedMessage
              defaultMessage={`{ active, select, true {Deactivate} other {Activate} } filter: `}
              description={
                'Action label for activating/deactivating filters in the store search panel.'
              }
              id="locatorFilterItem.labelVerb"
              values={{ active }}
            />
          </span>
          {name}
        </div>
        <Icon
          className={`color-greenAccent ${styles.activeIcon}`}
          path={checkPath}
          size="21px"
        />
      </button>
    </li>
  );
};

LocatorFilterItem.propTypes = {
  action: PropTypes.func.isRequired,
  active: PropTypes.bool,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default LocatorFilterItem;
