import { createSelector } from 'reselect';

import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { menuProductsByNumberAndFormSelector } from 'shared/app/bundles/menu';
import { configSelector } from 'shared/app/shell/state/selectors/config';

import getFormId from 'shared/app/bundles/menu/util/get-menu-product-form-id';

import { routeDerivedDataSelector } from './router';

import messages from './messages';

const productDetailsSelector = (state) => state?.ordering?.productDetails;

export const productSelector = createSelector(
  routeDerivedDataSelector,
  productDetailsSelector,
  menuProductsByNumberAndFormSelector,
  (routeDerivedData, productDetails, menuProducts) => {
    // figure out what product is being viewed in the pdp from the url
    const { productNumber, formCode } = routeDerivedData;
    const formId = getFormId({ productNumber, formCode });
    if (!formId) {
      return null;
    }

    const productState = productDetails?.[formId];
    const fullProductData = productState?.data;
    const menuProductData = menuProducts?.[formId];
    let errorData = {};
    if (productState?.error) {
      errorData = {
        error: true,
        // If we don't have this product in the menu either, we show a generic
        // 'not found' type of message.
        // If we have the menu data for this product but the product details did not load,
        // we show a 'we could not load this product' type of message, but the product
        // name and image should be still be able to show in the UI.
        errorMessage: !menuProductData
          ? messages.emptyProduct
          : messages.errorProduct,
      };
    }

    return {
      ...menuProductData,
      ...fullProductData,
      ...{
        id: formId,
        loading: Boolean(productState?.loading),
      },
      ...errorData,
    };
  }
);

export const shouldFetchProductDetailsSelector = createSelector(
  routeDerivedDataSelector,
  productDetailsSelector,
  configSelector,
  (routeDerivedData, productDetails, config) => {
    const { productNumber, formCode } = routeDerivedData;
    const formId = getFormId({ productNumber, formCode });
    if (!formId) {
      return false;
    }

    const { productStaleAfter: staleTime, productRetryAfter: retryAfter } =
      config;
    const product = productDetails[formId];
    const isUpdateNeeded = shouldUpdate(product, {
      staleTime,
      retryAfter,
    });
    if (isUpdateNeeded) {
      return formId;
    }
    return false;
  }
);
