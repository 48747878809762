/**
 * Splits a string into specified chunks.
 * @param {String} str - the string to split.
 * @param {Integer} groupSize - chunk size to split the string.
 * @returns {Array.<String>} - an array of strings of specified chunk size.
 */
const splitIntoArray = (str, groupSize = 4) => {
  let startIndex = 0;
  let endIndex = groupSize;
  const arr = [];

  if (!str || str.length === 0 || groupSize <= 0) {
    return arr;
  }

  if (endIndex > str.length) {
    return [str];
  }

  while (startIndex < str.length) {
    arr.push(str.slice(startIndex, endIndex));

    startIndex += groupSize;
    endIndex += groupSize;
  }

  return arr;
};

export default splitIntoArray;
