import { camelCase } from 'lodash';
import { isExternalLink } from 'shared/app/utils/is-external-link';
import rewriteHttps from 'shared/universal/replace-http-with-https';

import { transformOnboardingContent } from './onboarding';
import rewardPointsExpirationTransform from './reward-points-expiration';

const getFillType = (item) => {
  const string = item.fill && item.fill.type;
  if (!string) {
    return '';
  }
  return camelCase(string);
};

const isCallToActionEnabled = (item) => {
  return Boolean(
    item.enablecalltoaction &&
      (item.calltoactionlink || item.calltoactiondeeplink) &&
      item.calltoactiontext
  );
};

// deepLink overrides link.
const getCallToActionLink = (item) =>
  item.calltoactiondeeplink || item.calltoactionlink;

// This only applies to Info cards
export const getSegmentationTiers = (item) => {
  const segmentationTiers = item?.information?.segmentation?.tiers ?? null;
  if (segmentationTiers) {
    return segmentationTiers.map((tier) => parseInt(tier.tierId));
  }
  return segmentationTiers;
};

export const authTransform = (item) => {
  const contentItem = item?.content?.item ?? {};

  const callToActionIsEnabled = isCallToActionEnabled(contentItem);
  const callToActionLink = callToActionIsEnabled
    ? getCallToActionLink(contentItem)
    : null;
  const onboardingStatus = item.onboardingStatus;

  return {
    authState: true,
    itemId: item.streamItemId,
    key: item.streamItemId,
    type: getFillType(item) || camelCase(item.streamItemType),
    body: contentItem.body,
    title: contentItem.title,
    callToActionType: contentItem.calltoactiontype,
    callToActionlinkAltText: contentItem.calltoactionlinkalttext,
    callToActionIsEnabled,
    callToActionIsExternalLink: isExternalLink(callToActionLink),
    callToActionText: contentItem.calltoactiontext,
    callToAction2Text: contentItem.calltoaction2text,
    callToActionLink,
    rewardCouponCode: item?.rewardsCoupon?.coupon?.couponCode,
    rewardCouponStatus: item?.rewardsCoupon?.coupon?.status,
    expires: item?.endDate,
    imageUrl: rewriteHttps(contentItem.image),
    imageLink: contentItem.imagelink,
    isVideo: Boolean(contentItem.imagelinkisvideo),
    legalFooter: item?.legalfooter || contentItem.legalfooter,
    rank: item?.rank,
    rewardPointsExpirationInfo: rewardPointsExpirationTransform(item),
    segmentationTiers: getSegmentationTiers(item),
    onboardingStatus,
    onboardingContent:
      camelCase(item.streamItemType) === 'onboarding'
        ? transformOnboardingContent(contentItem, onboardingStatus.tasks)
        : null,
  };
};
