import React, { useEffect, useRef, useState } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';

import {
  addNotification,
  hideBottomSheet,
  showErrorNotification,
  setBottomSheetUrl,
} from 'shared/app/shell';
import SessionCheck from 'shared/app/components/session-check';
import {
  autoReloadTriggerAmountSelectedSelector,
  reloadAmountSelectedSelector,
  setReloadAmountSelected,
  expectingAutoReloadResponseSelector,
} from 'shared/app/bundles/svc-cards';
import {
  svcReloadOptionsSelector,
  triggerAmountOptionsSelector,
  autoReloadDefaultValuesSelector,
} from '../../state/selectors';
import { selectedAutoReloadPaymentInstrumentIdSelector } from '../../state/selectors/ui';
import { setSelectedAutoReloadPaymentInstrumentId } from '../../state/actions/cards-management';
import {
  ADD_PAYMENT,
  addPayPalPaymentInstrument,
  allPaymentInstrumentsSelector,
  getPaymentInstrumentById,
  getPayPalBillingAgreementToken,
  PAY_PAL_LOADING_OFF,
  PAY_PAL_LOADING_ON,
  selectedOrPrimaryWalletSVCSelector,
  setPayPalLoadingState,
} from 'shared/app/bundles/wallet';
import { updateAutoReload } from '../../state/actions/card';
import {
  loadScript,
  payPalScriptLoadedSelector,
} from 'shared/app/bundles/scripts';
import { payPalScriptUrlSelector } from 'shared/app/state/selectors/pay-pal';

import {
  trackBarcodeEditAutoReloadView,
  trackBarcodeEditAutoReloadSuccess,
} from '../../state/actions/track-event';
import AutoReloadForm from './form';
import { sharedCallsToAction, paymentMessages } from 'shared/app/messages';

const PAYPAL = 'paypal';

/* eslint-disable max-statements */
export const AutoReloadBottomSheet = () => {
  const dispatch = useDispatch();

  const autoReloadDefaultValues = useSelector(autoReloadDefaultValuesSelector);
  const autoReloadTriggerAmountSelected = useSelector(
    autoReloadTriggerAmountSelectedSelector
  );
  const inProgress = useSelector(expectingAutoReloadResponseSelector);
  const paymentInstruments = useSelector(allPaymentInstrumentsSelector);
  const payPalScriptLoaded = useSelector(payPalScriptLoadedSelector);
  const payPalScriptUrl = useSelector(payPalScriptUrlSelector);
  const reloadAmountSelected = useSelector(reloadAmountSelectedSelector);
  const selectedPaymentInstrumentId = useSelector(
    selectedAutoReloadPaymentInstrumentIdSelector
  );
  const selectedWalletSVC = useSelector(selectedOrPrimaryWalletSVCSelector);
  const svcReloadOptions = useSelector(svcReloadOptionsSelector);
  const triggerAmountOptions = useSelector(triggerAmountOptionsSelector);

  // Local Variables
  const autoReloadActive =
    selectedWalletSVC?.autoReloadProfile?.status === 'active';
  const { formatMessage } = useIntl();
  const formContainerRef = useRef(null);

  // Local State
  const [optionalFieldsActive, setOptionalFieldsActive] = useState(true);
  const [showPayPalButton, setShowPayPalButton] = useState(false);

  const renderPayPal = () => {
    let payPalTransactionId = null;
    /* eslint-disable no-undef, new-cap */
    paypal
      .Buttons({
        createBillingAgreement: () => {
          return dispatch(getPayPalBillingAgreementToken()).then(
            (agreement) => {
              // put reload frap in loading state prior to removing PayPal button
              dispatch(setPayPalLoadingState(PAY_PAL_LOADING_ON));
              payPalTransactionId = agreement.transactionId;
              return agreement.billingToken;
            }
          );
        },
        onApprove: (data) => {
          setShowPayPalButton(false);
          dispatch(
            addPayPalPaymentInstrument(data.billingToken, payPalTransactionId)
          )
            .then((newPaymentInstrument) => {
              dispatch(setPayPalLoadingState(PAY_PAL_LOADING_OFF));
              dispatch(
                setSelectedAutoReloadPaymentInstrumentId(
                  newPaymentInstrument?.paymentInstrumentId
                )
              );
              dispatch(
                addNotification(
                  formatMessage(paymentMessages.payPalPaymentMethodAdded)
                )
              );
              dispatch(setPayPalLoadingState(PAY_PAL_LOADING_OFF));
            })
            .catch(() => {
              dispatch(setPayPalLoadingState(PAY_PAL_LOADING_OFF));
            });
        },
        onCancel: () => {
          dispatch(setPayPalLoadingState(PAY_PAL_LOADING_OFF));
        },
        style: {
          layout: 'horizontal',
          color: 'gold',
          shape: 'pill',
          label: 'paypal',
          tagline: false,
          height: 55,
        },
      })
      .render('#paypal-button-container');
    /* eslint-enable no-undef, new-cap */
  };

  useEffect(() => {
    if (!payPalScriptLoaded) {
      dispatch(loadScript(payPalScriptUrl));
    }
    if (payPalScriptLoaded) {
      renderPayPal();
    }
  }, [payPalScriptLoaded]);

  // Selected Payment Instrument Effect
  useEffect(() => {
    formContainerRef.current.updateField({
      input: {
        name: 'paymentInstrument',
        value: selectedPaymentInstrumentId,
      },
    });
    setShowPayPalButton(selectedPaymentInstrumentId?.toLowerCase() === PAYPAL);
  }, [selectedPaymentInstrumentId]);

  // Selected Wallet SVC Effect
  useEffect(() => {
    const triggerAmount =
      selectedWalletSVC?.autoReloadProfile?.triggerAmount ||
      autoReloadDefaultValues?.triggerAmount;
    const amount =
      selectedWalletSVC?.autoReloadProfile?.amount ||
      autoReloadDefaultValues?.amount;

    formContainerRef.current.updateField({
      input: {
        name: 'triggerAmount',
        value: `${triggerAmount}`,
      },
    });
    formContainerRef.current.updateField({
      input: {
        name: 'amount',
        value: `${amount}`,
      },
    });
  }, [selectedWalletSVC]);

  const handleFieldsStateChange = (fieldsState, oldFieldsState) => {
    const enabled = fieldsState?.fields?.enabled?.input?.checked;
    const oldEnabled = oldFieldsState?.fields?.enabled?.input?.checked ?? null;
    const newPaymentInstrumentId =
      fieldsState?.fields?.paymentInstrument?.input?.value;

    if (
      newPaymentInstrumentId &&
      selectedPaymentInstrumentId !== newPaymentInstrumentId
    ) {
      dispatch(
        setSelectedAutoReloadPaymentInstrumentId(newPaymentInstrumentId)
      );
      const currentPayment = getPaymentInstrumentById({
        paymentInstrumentId: newPaymentInstrumentId,
        paymentInstruments,
      });

      trackBarcodeEditAutoReloadView({
        // eslint-disable-next-line camelcase
        payment_method_type: currentPayment?.paymentType,
      });
    }

    if (enabled === oldEnabled) {
      return;
    }

    if (!enabled) {
      formContainerRef.current.updateField({
        input: {
          name: 'paymentInstrument',
          value: autoReloadDefaultValues?.paymentInstrumentId || ADD_PAYMENT,
        },
      });
    }

    setOptionalFieldsActive(enabled);
  };

  const trackSubmitSuccess = (formData) => {
    const { enabled, paymentInstrument } = formData;
    const initialSelectedPaymentId =
      autoReloadDefaultValues?.paymentInstrumentId || ADD_PAYMENT;

    // only make tracking call if user has enabled auto-reload or
    // if it was already on but they have changed the payment when they click "Save"
    if (
      enabled &&
      (!autoReloadActive ||
        initialSelectedPaymentId !== selectedPaymentInstrumentId)
    ) {
      const { paymentType } = paymentInstruments.find(
        (instrument) => instrument.paymentInstrumentId === paymentInstrument
      );

      trackBarcodeEditAutoReloadSuccess({
        // eslint-disable-next-line camelcase
        payment_method_type: paymentType,
      });
    }
  };

  // eslint-disable-next-line max-statements
  const handleSubmit = (formData) => {
    const addPaymentSelected =
      selectedPaymentInstrumentId?.toLowerCase() === ADD_PAYMENT;

    if (addPaymentSelected) {
      // if "Add payment" is the selected payment method, there is no autoReloadDefault because we change
      // the selected card to the default when toggling auto-reload off. Close the bottom sheet upon
      // toggling it off and clicking "Continue" in this case
      if (!optionalFieldsActive) {
        dispatch(hideBottomSheet());
        return;
      }

      if (
        (formData.amount &&
          formData.amount !== autoReloadDefaultValues?.amount?.toString()) ||
        (formData.triggerAmount &&
          formData.triggerAmount !==
            autoReloadDefaultValues?.triggerAmount?.toString())
      ) {
        dispatch(setReloadAmountSelected(formData));
      }
      dispatch(setBottomSheetUrl());
      return dispatch(push('/account/payment-method'));
    }

    dispatch(
      updateAutoReload({
        formData,
        card: selectedWalletSVC,
        onSuccess: trackSubmitSuccess,
      })
    );
  };

  const handleSubmitError = ({ paymentInstrument }) => {
    const errorMessage = paymentInstrument
      ? paymentMessages.noPaymentMethodSelected
      : sharedCallsToAction.tryAgain;

    dispatch(
      showErrorNotification({
        messageId: errorMessage.id,
      })
    );
  };

  const getFormFields = () => {
    const fieldsConfig = {
      enabled: {
        required: false,
        input: {
          checked: true,
        },
      },
    };

    return !optionalFieldsActive
      ? fieldsConfig
      : {
          ...fieldsConfig,
          amount: {
            input: {
              value:
                reloadAmountSelected || `${autoReloadDefaultValues?.amount}`,
            },
          },
          triggerAmount: {
            input: {
              value:
                autoReloadTriggerAmountSelected ||
                `${autoReloadDefaultValues?.triggerAmount}`,
            },
          },

          paymentInstrument: {
            input: {
              value:
                autoReloadDefaultValues?.paymentInstrumentId || ADD_PAYMENT,
            },
          },
        };
  };

  const frapText =
    selectedPaymentInstrumentId === ADD_PAYMENT
      ? sharedCallsToAction.continue
      : sharedCallsToAction.save;

  return (
    <SessionCheck>
      {selectedWalletSVC && (
        <div>
          <FormContainer
            fields={getFormFields()}
            onError={handleSubmitError}
            onFieldsStateChange={handleFieldsStateChange}
            onSubmit={handleSubmit}
            ref={formContainerRef}
          >
            <AutoReloadForm
              buttonMessage={frapText}
              hideBottomSheet={() => dispatch(hideBottomSheet())}
              inProgress={inProgress}
              showPayPalButton={showPayPalButton}
              svcReloadOptions={svcReloadOptions}
              triggerAmountOptions={triggerAmountOptions}
            />
          </FormContainer>
        </div>
      )}
    </SessionCheck>
  );
};
/* eslint-enable max-statements */

export default AutoReloadBottomSheet;
