import { camelCase } from 'lodash';
import { isExternalLink } from 'shared/app/utils/is-external-link';
import rewriteHttps from 'shared/universal/replace-http-with-https';

const isCallToActionEnabledUnauth = ({
  enableCallToAction,
  callToActionType,
  callToActionLink,
  callToActionText,
}) =>
  Boolean(
    enableCallToAction &&
      callToActionType === 'Link' &&
      callToActionLink &&
      callToActionText
  );

const isCallToAction2EnabledUnauth = ({
  enableCallToAction2,
  callToAction2Type,
  callToAction2Link,
  callToAction2Text,
}) =>
  Boolean(
    enableCallToAction2 &&
      callToAction2Type === 'Link' &&
      callToAction2Link &&
      callToAction2Text
  );

export const carouselItemsUnauth = (carouselItem) => {
  if (!carouselItem) return null;

  return carouselItem.map((item) => ({
    text: item.text,
    uri: item.image.url,
  }));
};

export const unAuthTransform = (item) => {
  const content = item.content || {};
  const metadata = item.metadata || {};
  const imageUrl = rewriteHttps(content.image?.url);
  const callToActionIsEnabled = isCallToActionEnabledUnauth(content);
  const callToActionIsExternalLink = isExternalLink(content.callToActionLink);
  const callToAction2IsEnabled = isCallToAction2EnabledUnauth(content);
  const callToAction2IsExternalLink = isExternalLink(content.callToAction2Link);
  const callToActionLink = callToActionIsEnabled
    ? content.callToActionLink
    : null;
  const callToAction2Link = callToAction2IsEnabled
    ? content.callToAction2Link
    : null;
  const carouselItems = carouselItemsUnauth(content.carouselItem);
  return {
    authState: false,
    itemId: item.id,
    key: item.id,
    type:
      camelCase(item.typeName) === 'nonSrInformationCard'
        ? 'information'
        : camelCase(item.typeName),
    body: content.body,
    title: content.title,
    callToActionIsEnabled,
    callToActionType: content.callToActionType,
    callToActionText: content.callToActionText,
    callToActionLink,
    callToActionIsExternalLink,
    callToAction2IsEnabled,
    callToAction2Type: content.callToAction2Type,
    callToAction2Text: content.callToAction2Text,
    callToAction2Link,
    callToAction2IsExternalLink,
    expires: metadata.endDate,
    imageUrl,
    imageLink: imageUrl,
    carouselItems,
    rank: metadata.overrideRank,
  };
};
