import React from 'react';

import ErrorCard from './error-card';
import messages from './messages';

export const LocatorErrorNoFavorite = () => {
  return (
    <ErrorCard
      errorHeading={messages.noFavoriteStoresHeading}
      errorMessages={[messages.noFavoriteStoresMessage]}
    />
  );
};

export const LocatorErrorNoPrevious = () => {
  return (
    <ErrorCard
      errorHeading={messages.noPreviousStoresHeading}
      errorMessages={[messages.noPreviousStoresMessage]}
    />
  );
};

export const LocatorErrorNotFound = () => {
  return (
    <ErrorCard
      errorHeading={messages.notFoundHeading}
      errorMessages={[messages.notFoundMessage, messages.notFoundSuggestion]}
    />
  );
};

export const LocatorErrorPlaceNameNotFound = () => {
  return (
    <ErrorCard
      errorHeading={messages.placeNameNotFoundHeading}
      errorMessages={[
        messages.placeNameNotFoundMessage,
        messages.placeNameNotFoundMessageSuggestion,
      ]}
    />
  );
};
