import { defineMessages } from 'react-intl';

export default defineMessages({
  amountError: {
    id: 'accountCards.transferBalanceBottomSheetForm.amountError',
    defaultMessage: 'Enter an amount greater than 0',
    description:
      'Error message displayed when the amount is missing or invalid',
  },
  targetCardError: {
    id: 'accountCards.transferBalanceBottomSheetForm.targetCardError',
    defaultMessage: 'Select a card',
    description: 'Error message displayed when no target card is selected',
  },
  transferBalanceHeading: {
    defaultMessage: 'Transfer Starbucks Card balance',
    id: 'accountCards.transferBalanceBottomSheetForm.heading',
    description: 'Heading for the transfer balance bottom sheet',
  },
  transferBalanceFromCardLabel: {
    defaultMessage: 'From this card',
    id: 'accountCards.transferBalanceBottomSheetForm.fromCardLabel',
    description: 'Label for transfer from this card input',
  },
  transferBalanceToCardLabel: {
    defaultMessage: 'To this card',
    id: 'accountCards.transferBalanceBottomSheetForm.toCardLabel',
    description: 'Label for transfer to this card input',
  },
  transferBalanceFromCardAriaLabel: {
    defaultMessage: 'From this Starbucks Card',
    id: 'accountCards.transferBalanceBottomSheetForm.fromCardAriaLabel',
    description: 'Aria label for transfer from this card input',
  },
  transferBalanceToCardAriaLabel: {
    defaultMessage: 'To this Starbucks Card',
    id: 'accountCards.transferBalanceBottomSheetForm.toCardAriaLabel',
    description: 'Aria label for transfer to this card input',
  },
  transferBalanceAmountLabel: {
    id: 'accountCards.transferBalanceBottomSheetForm.amountLabel',
    defaultMessage: 'Transfer dollar amount',
    description: 'Label for amount field in transfer balance bottom sheet',
  },
  transferBalanceMakeTransferWithAmount: {
    id: 'accountCards.transferBalanceBottomSheetForm.makeTransferButtonWithAmount',
    defaultMessage: 'Make { amount, number, money } transfer',
    description:
      'Button to transfer card balance including amount being transferred',
  },
  transferBalanceMakeTransfer: {
    id: 'accountCards.transferBalanceBottomSheetForm.makeTransferButton',
    defaultMessage: 'Make transfer',
    description: 'Button to transfer card balance',
  },
  destinationCard: {
    id: 'accountCards.transferBalanceBottomSheetForm.destinationCard',
    defaultMessage: 'Choose a destination Starbucks Card',
    description:
      'Message for selecting which Starbucks Card the funds are being sent to',
  },
});
