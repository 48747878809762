import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'geolocateButton.label',
    defaultMessage: 'Find locations near me',
    description:
      'Accessible hint for screenreaders on the user geolocation button',
  },
});
