import { defineMessages } from 'react-intl';

export default defineMessages({
  guestPaymentTokenExpiredHeader: {
    id: 'gift.guestPaymentTokenExpired.header',
    defaultMessage:
      "We're sorry to say this session expired. Add your payment info again.",
    description:
      'text indicating single-use payment token expired, prompting retry',
  },
  addGuestPaymentAgain: {
    id: 'gift.guestPaymentTokenExpired.tryAgain',
    defaultMessage: 'Add payment',
    description: 'button text prompting user to try again',
  },
});
