import React from 'react';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import featureIconMap from '../../utils/feature-icon-map';

import styles from './feature-item.cssm';

const FeatureItem = ({ code, name }) => {
  return (
    <div
      className={`px4 sm-px6 flex items-center color-textBlackSoft ${styles.item}`}
    >
      <Icon className={styles.icon} path={featureIconMap[code]} size="28px" />
      <div className="flex-grow">{name}</div>
    </div>
  );
};

export default FeatureItem;
