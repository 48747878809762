import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { variables } from '@starbucks-web/pattern-library/lib/shared-variables';
import { configImagesUrlSelector } from '../../state/selectors/config';

const iconProperties = {
  width: 48,
  height: 59,
  anchorX: 24,
  anchorY: 45,
};
const selectedIcon = Object.assign({}, iconProperties, {
  imagePath: 'location-marker.svg',
});
const highlightedIcon = Object.assign({}, iconProperties, {
  imagePath: 'card-hover-pin.svg',
});
const iconZIndices = {
  default: 10,
  selected: 11,
  highlighted: 12,
};

export class LocatorMarker extends Component {
  constructor(props) {
    super(props);

    this.handleMarkerClick = this.handleMarkerClick.bind(this);

    this.state = { instance: this.createMarker(props) };
  }

  handleMarkerClick() {
    this.props.selectLocation(this.props.locationNumber);
  }

  createMarker(props = this.props) {
    const {
      grayedOut,
      mapInstance,
      position,
      selectable,
      selected,
      storeName,
    } = props;
    const { LatLng } = google.maps;

    const markerPosition = new LatLng(position.latitude, position.longitude);
    const markerOptions = {
      map: mapInstance,
      flat: true,
      position: markerPosition,
      icon: this.getIcon({ grayedOut, selectable, selected }),
      optimized: false,
      zIndex: iconZIndices[selected ? 'selected' : 'default'],
      title: storeName,
    };

    const marker = new google.maps.Marker(markerOptions);

    if (selectable) {
      google.maps.event.addListener(marker, 'click', this.handleMarkerClick);
    }

    return marker;
  }

  componentWillUnmount() {
    const { instance } = this.state;

    if (instance) {
      instance.setMap(null);
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (
      props.selected !== prevProps.selected ||
      props.highlighted !== prevProps.highlighted
    ) {
      this.updateIcon({
        grayedOut: props.grayedOut,
        selectable: props.selectable,
        selected: props.selected,
        highlighted: props.highlighted,
      });
    }
  }

  getIcon(iconType) {
    if (iconType?.selected) {
      return this.getSelectedIcon();
    } else if (iconType?.highlighted) {
      return this.getHighlightedIcon();
    }
    return this.getDefaultIcon(iconType);
  }

  getDefaultIcon({ grayedOut, selectable }) {
    const markerIconColor = variables.colorGreenAccent;
    const disabledMarkerIconColor = variables.colorBlack50;
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor:
        grayedOut || !selectable ? disabledMarkerIconColor : markerIconColor,
      fillOpacity: 1,
      scale: 8,
      strokeColor: '#ffffff',
      strokeOpacity: 1,
      strokeWeight: 1,
    };
  }

  composeSVGIcon(svgInfo) {
    const { imagesUrl } = this.props;
    return {
      scaledSize: new window.google.maps.Size(svgInfo.width, svgInfo.height),
      size: new window.google.maps.Size(svgInfo.width, svgInfo.height),
      // let the icon be anchored to the map at the correct point in the image
      anchor: new window.google.maps.Point(svgInfo.anchorX, svgInfo.anchorY),
      // Join images root
      url: `${imagesUrl}/${svgInfo.imagePath}`,
    };
  }

  getHighlightedIcon() {
    return this.composeSVGIcon(highlightedIcon);
  }

  getSelectedIcon() {
    return this.composeSVGIcon(selectedIcon);
  }

  updateIcon(iconType) {
    if (!this.state.instance) {
      return;
    }

    let zIndex = iconZIndices.default;

    if (iconType.selected) {
      zIndex = iconZIndices.selected;
    } else if (iconType.highlighted) {
      zIndex = iconZIndices.highlighted;
    }

    this.state.instance.setOptions({
      icon: this.getIcon(iconType),
      zIndex,
    });
  }

  render() {
    return null;
  }
}

LocatorMarker.propTypes = {
  grayedOut: PropTypes.bool,
  highlighted: PropTypes.bool,
  locationNumber: PropTypes.string.isRequired,
  mapInstance: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  selectLocation: PropTypes.func.isRequired,
  storeName: PropTypes.string.isRequired,
};

LocatorMarker.defaultProps = {
  grayedOut: false,
  highlighted: false,
  selectable: true,
  selected: false,
};

const select = (state) => ({
  imagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(LocatorMarker);
