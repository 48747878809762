import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';

import { legacyBaseUrlSelector } from 'shared/app/state/selectors/locales';
import { routeSelector } from 'shared/app/state/selectors/routes';

import messages from '../../messages';

export const subNavLinksSelector = createSelector(
  routeSelector,
  legacyBaseUrlSelector,
  (route, legacyBaseUrl) => [
    {
      active:
        route === '/menu' ||
        route === '/menu/:menu/:category' ||
        route === '/menu/:menu/:category/:subcategory',
      to: '/menu',
      text: messages.allProductsLink,
      tagName: Link,
      className: 'mr3 sm-mr5',
    },
    {
      href: `${legacyBaseUrl}/menu/featured`,
      text: messages.featuredTitle,
      className: 'mr3 sm-mr5',
    },
    {
      active: route === '/menu/previous',
      to: '/menu/previous',
      text: messages.previousTitle,
      tagName: Link,
      className: 'mr3 sm-mr5',
    },
    {
      active: route === '/menu/favorites',
      to: '/menu/favorites',
      text: messages.favoritesTitle,
      tagName: Link,
      className: 'mr3 sm-mr5',
    },
  ]
);
