import React from 'react';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import { useIntl } from 'react-intl';

export const ErrorCard = ({ errorHeading, errorMessages = [], other }) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <Heading className="mb2 lg-mb3 text-semibold text-lg" tagName="h3">
        {formatMessage(errorHeading)}
      </Heading>
      {errorMessages.filter(Boolean).map((errorMessage, idx) => {
        return (
          <p
            className="color-textBlackSoft text-xs"
            key={`errorMessage-${idx}`}
          >
            {formatMessage(errorMessage)}
          </p>
        );
      })}
      {other}
    </div>
  );
};

export default ErrorCard;
