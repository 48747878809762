import { combineReducers } from 'redux';
import { push } from 'redux-first-history';

import IS_BROWSER from 'shared/app/shell/utils/is-browser';
import { lazyContentCrate, lazyHeaderCrate } from 'shared/app/shell';
import { svcMessages } from 'shared/app/messages';

import barcodesReducer from './state/reducers/barcodes';
import uiReducer from './state/reducers/ui';
import AddMoneyBottomSheet from './components/add-money-bottom-sheet';
import AutoReloadBottomSheet from './components/auto-reload-bottom-sheet';
import TransferBalanceBottomSheet from './components/svc-card-transfer/transfer-balance-bottom-sheet';
import PayOverlay from './components/pay-overlay';

import { FETCH_BARCODE_SUCCESS, FETCH_BARCODE } from './actions';

const CardsAppBar = lazyHeaderCrate(
  () =>
    import(
      /* webpackChunkName: 'cards-app-bar-header' */ './components/cards-app-bar'
    )
);

export default {
  name: 'accountCards',
  reducer: combineReducers({
    barcodes: barcodesReducer,
    ui: uiReducer,
  }),
  initialize: (store) => {
    if (IS_BROWSER) {
      import(/* webpackChunkName: 'shake-to-pay' */ 'shake.js').then(
        ({ default: Shake }) => {
          new Shake().start();
          window.addEventListener('shake', () => store.dispatch(push('#pay')));
        }
      );
    }
  },
  routes: {
    '/account/cards': {
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'svc-cards-list-content' */ './components/root'
          )
      ),
      headerCrate: CardsAppBar,
    },
    '/account/cards/add': {
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'add-card-content' */ './components/add-card'
          )
      ),
      headerCrate: CardsAppBar,
    },
    '/account/cards/:cardId': {
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'svc-card-manage-content' */ './components/svc-card-manage'
          )
      ),
      headerCrate: CardsAppBar,
    },
  },
  bottomSheetRoutes: [
    {
      sheet: AddMoneyBottomSheet,
      name: 'add-money',
    },
    {
      sheet: AutoReloadBottomSheet,
      name: 'auto-reload',
    },
    {
      sheet: TransferBalanceBottomSheet,
      name: 'transfer-balance',
    },
    {
      sheet: TransferBalanceBottomSheet,
      name: 'transfer-balance-remove',
    },
  ],
  modalsOpenedByUrlHash: [
    {
      component: PayOverlay,
      ariaLabelMessage: svcMessages.scanInStore,
      hash: '#pay',
    },
  ],
  asyncActions: [FETCH_BARCODE],
  persistAfter: [FETCH_BARCODE_SUCCESS],
};
