import { defineMessages } from 'react-intl';

export default defineMessages({
  addCardError: {
    defaultMessage:
      'There was an error adding your card, please try again later',
    description:
      "Message received by the user when there's an error while adding a card",
    id: 'accountCards.notifications.addCardError',
  },
  addCardSuccess: {
    defaultMessage: '{amount, number, money} card added',
    description:
      'Message received by the user when they successfully add a card',
    id: 'accountCards.notifications.addCardSuccess',
  },
  autoReloadSuccess: {
    defaultMessage:
      'Auto reload turned {autoReloadActivated, select, true {on} other {off}}',
    description:
      'Notification message displayed on successful stored value card auto reload settings update',
    id: 'accountCards.notifications.autoReloadSuccess',
  },
  removeCardError: {
    defaultMessage: 'Failed to remove card',
    description: 'Message displayed when a card removal fails',
    id: 'accountCards.notifications.removeCardError',
  },
  removeCardSuccess: {
    defaultMessage: 'Card ({lastFourDigits}) removed',
    description: 'Message displayed when a user successfully removes a card',
    id: 'accountCards.notifications.removeCardSuccess',
  },
});
