import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Frap from '@starbucks-web/pattern-library/lib/components/frap';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import BottomSheetHeading from 'shared/app/components/bottom-sheet-heading';
import StoredValueCardsSelect from 'shared/app/components/stored-value-cards-select';
import StaticSvcField from 'shared/app/components/static-svc-field';
import FrapContainer from 'shared/app/components/frap-container';
import Form from 'shared/app/components/form';
import {
  svcCardsDataSelector as cardsDataSelector,
  expectingTransferResponseSelector,
} from 'shared/app/bundles/svc-cards';

import messages from './messages';

const TransferBalanceBottomSheetForm = ({
  fields,
  onSubmit,
  isValid,
  amount,
  hideSourceSvcCard,
}) => {
  const { formatMessage } = useIntl();

  const svcCards = useSelector(cardsDataSelector);
  const expectingTransferResponse = useSelector(
    expectingTransferResponseSelector
  );

  const sourceCard = svcCards.filter(
    (card) => card.cardId === fields.sourceCardId.input.value
  );

  const targetCardOptions = svcCards.filter(
    (card) => card.cardId !== fields.sourceCardId.input.value
  );

  return (
    <Form onSubmit={onSubmit}>
      <BottomSheetHeading>
        {formatMessage(
          hideSourceSvcCard
            ? messages.destinationCard
            : messages.transferBalanceHeading
        )}
      </BottomSheetHeading>

      {!hideSourceSvcCard && (
        <Fragment>
          <p className="text-xl ml8">
            {formatMessage(messages.transferBalanceFromCardLabel)}
          </p>

          <StaticSvcField
            inputProps={{
              'aria-label': formatMessage(
                messages.transferBalanceFromCardAriaLabel
              ),
              'data-e2e': 'transferBalanceSourceCardField',
            }}
            svcCard={sourceCard?.[0]}
          />

          <p className="text-xl mt8 ml8">
            {formatMessage(messages.transferBalanceToCardLabel)}
          </p>
        </Fragment>
      )}

      <StoredValueCardsSelect
        field={fields.targetCardId}
        inputProps={{
          'aria-label': formatMessage(messages.transferBalanceToCardAriaLabel),
          'data-e2e': 'transferBalanceTargetCardSelect',
          error: shouldDisplayError(fields.targetCardId),
          errorMessage: formatMessage(messages.targetCardError),
        }}
        svcCards={targetCardOptions}
      />

      <FrapContainer relativeAbove="alwaysRelative">
        <Frap
          data-e2e="transferBalanceConfirm"
          disabled={!isValid}
          loading={expectingTransferResponse}
        >
          {isValid
            ? formatMessage(messages.transferBalanceMakeTransferWithAmount, {
                amount,
              })
            : formatMessage(messages.transferBalanceMakeTransfer)}
        </Frap>
      </FrapContainer>
    </Form>
  );
};

TransferBalanceBottomSheetForm.propTypes = {
  fields: PropTypes.object,
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func,
  amount: PropTypes.number,
  hideSourceSvcCard: PropTypes.bool,
};

export default TransferBalanceBottomSheetForm;
