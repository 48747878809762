export const mostRecentPurchaseSelector = (state) => state.gift.purchaseStatus;

/**
 *    amount ""
      message ""
      numberOfRecipients (num)
      orderNumber
      product ""
      purchaseStatus ""
        possible values: "success", "pending", "error"
      requestId ""
      recipients [{recipientEmail: "", recipientName: ""}]
      totalAmount (num)
*/
