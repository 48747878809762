import React from 'react';
import Card from '@starbucks-web/pattern-library/lib/components/card/';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

const CardBase = ({
  body,
  children,
  containerClasses,
  imageUrl,
  imageAlt = '',
  title,
  ...otherProps
}) => {
  const imageChild = (
    <ImageLoader
      alt={imageAlt}
      lazyLoad={{
        enabled: true,
        yThreshold: 50,
      }}
      src={imageUrl}
    />
  );
  return (
    <Card
      containerClasses={`${containerClasses || ''} mt3`}
      contentClasses="p3 lg-p4"
      data-e2e="streamCard"
      imageChild={imageChild}
      {...otherProps}
    >
      <Heading className="mb2 text-semibold" size="sm" tagName="h2">
        {title}
      </Heading>
      <p>{body}</p>
      <div className="mt3">{children}</div>
    </Card>
  );
};

export default CardBase;
