import { isEmpty } from 'lodash';

import { getCartProductAvailabilitySelector } from '../../state/selectors';

import { UPDATE_AVAILABILITY, AVAILABILITY_CORRECT } from './types';

export const dispatchUpdateCartAvailability = () => (dispatch, getState) => {
  const itemsToUpdate = getCartProductAvailabilitySelector(getState());

  if (isEmpty(itemsToUpdate)) {
    // If the availability is already correct, we don't need to correct the cart,
    // but we do need to update the cart's variables.
    return dispatch({
      type: AVAILABILITY_CORRECT,
      payload: {
        message: 'No update needed',
      },
    });
  }
  return dispatch({
    type: UPDATE_AVAILABILITY,
    payload: {
      itemsToUpdate,
    },
  });
};
