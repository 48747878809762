import { assign } from 'lodash';

import {
  ADD_PRODUCT_TO_CART,
  ADD_OOS_RECOMMENDATION_TO_CART,
  HIDE_ADDED_PRODUCT_NOTIFICATION,
} from '../actions/types';
import { CONFIRM_ORDERING_UNAVAILABLE } from 'shared/app/state/action-creators/types';

// eslint-disable-next-line max-statements
export default (state = {}, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
    case ADD_OOS_RECOMMENDATION_TO_CART: {
      const { productImage } = action.payload;
      return assign({}, state, {
        productImage,
        displayNotification: true,
      });
    }

    case HIDE_ADDED_PRODUCT_NOTIFICATION: {
      return assign({}, state, {
        productImage: '',
        displayNotification: false,
      });
    }

    case CONFIRM_ORDERING_UNAVAILABLE:
      return {
        ...state,
        storeConfirmedUnavailable: action.payload?.storeNumber,
      };

    default: {
      return state;
    }
  }
};
