import { range, sortBy } from 'lodash';

// This is used to pass a unique key with every item to the price order call. The key is later used to match the pricing response data to the clients cart. In order to create a 1:1 mapping, all items need to be split up and be given a unique key.
export const flattenCartItems = ({ quantity, item, id, itemId }) => {
  const addItemId = itemId && { itemId };

  const itemsToAdd = range(0, quantity).map((index) => {
    return { ...item, key: `${id}-${index}`, ...addItemId };
  });

  return itemsToAdd;
};

export const sortSelectedOptions = (optionArray) =>
  sortBy(
    optionArray,
    (option) =>
      option?.product?.productNumber ||
      option?.productNumber ||
      option?.optionProductNumber
  );
