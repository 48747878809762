import { lazyContentCrate, lazyHeaderCrate } from '../../shared/app/shell';

export default {
  name: 'accountProfile',
  routes: {
    '/account/profile': {
      headerCrate: lazyHeaderCrate(
        () =>
          import(
            /* webpackChunkName: 'profile-header' */ './components/header-crate'
          )
      ),
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'profile-nav-content' */ './components/profile-nav'
          )
      ),
      authRequired: true,
    },
  },
};
