'use strict';

/* eslint-disable max-statements */
const qs = require('query-string');
const parseStoreNumber = require('./expanded-store-url').parseStoreNumber;

const zoomRE = /^\d+[zZ]$/;
const latLngRE = /^(\-?\d+(\.\d+)?)$/;
const placeRE = /[<>]/;

/* eslint-disable complexity */
const parseUrl = (url) => {
  const urlParts = url.split('?');
  const pathName = urlParts[0];
  const query = urlParts[1];

  let parsed;
  try {
    parsed = qs.parse(query);
  } catch (err) {
    // treat this error as 400 bad request, rather than a 500
    err.statusCode = 400;
    throw err;
  }
  const expandedStoreNumber = parseStoreNumber(pathName);

  if (
    !expandedStoreNumber &&
    !parsed.map &&
    !parsed.features &&
    !parsed.place
  ) {
    return null;
  }

  const result = {};

  if (expandedStoreNumber) {
    result.expandedStoreNumber = expandedStoreNumber;
    return result;
  }

  if (parsed.features) {
    // split, trim, and filter out empty strings
    const features = parsed.features
      .split(',')
      .map((feature) => feature.trim())
      .filter((feature) => /^[a-zA-Z0-9]+$/.test(feature));

    if (features.length > 0) {
      result.features = features;
    }
  }

  if (parsed.place && !parsed.place.match(placeRE)) {
    result.place = parsed.place;

    // Exit early if route contains placeName, that takes precedence over map state.
    return result;
  }

  if (parsed.map) {
    const latLngZoom = parsed.map.split(',');
    const lat = latLngZoom[0];
    const lng = latLngZoom[1];
    const zoom = latLngZoom[2];

    if (!lat || !lng || !zoom) {
      return null;
    }
    if (!zoom.match(zoomRE) || !lat.match(latLngRE) || !lng.match(latLngRE)) {
      return null;
    }

    result.lat = lat;
    result.lng = lng;
    result.zoomLevel = parseInt(zoom.slice(0, -1), 10);
  }

  return result;
};
/* eslint-enable complexity */

module.exports.default = parseUrl;
