import { get } from 'lodash';
import { goBack, push } from 'redux-first-history';
import { SELECT_STORE_MENU, CLEAR_STORE_MENU } from './types';

export const confirmStore =
  (selectedStore, currentRoute, previousRoute) => (dispatch) => {
    dispatch({
      type: SELECT_STORE_MENU,
      payload: {
        location: selectedStore,
      },
    });
    const shouldSendUserToCart = Boolean(get(currentRoute, 'query.toCart'));
    const shouldSendUserToMenu = get(currentRoute, 'pathname').startsWith(
      '/store-locator'
    );
    const isThereAPreviousRoute = Boolean(previousRoute);

    if (shouldSendUserToCart) {
      dispatch(push('/menu/cart'));
    } else if (shouldSendUserToMenu) {
      dispatch(push(`/menu`));
    } else {
      dispatch(isThereAPreviousRoute ? goBack() : push('/menu'));
    }
  };

export const clearStore = () => ({
  type: CLEAR_STORE_MENU,
  payload: {
    userDidClear: true,
  },
});
