import { lazyFullPage } from 'shared/app/shell';
import { combineReducers } from 'redux';
import giftDetailsReducer from './state/reducers/gift-details';
import digitalGiftsCatalogReducer from './state/reducers/digital-gifts-catalog';
import giftFormReducer from './state/reducers/gift-form';
import guestPaymentInstrumentReducer from './state/reducers/guest-payment-instrument';
import purchaseStatusReducer from './state/reducers/purchase-status';
import selectedPaymentInstrumentIdReducer from './state/reducers/selected-payment-instrument-id';
import {
  fetchDigitalGiftsCatalog,
  fetchGiftDetails,
} from './state/action-creators';
import {
  shouldfetchDigitalGiftsCatalogSelector,
  shouldfetchGiftDetailsSelector,
} from './state/selectors';
import {
  FETCH_DIGITAL_GIFTS_CATALOG,
  FETCH_DIGITAL_GIFTS_CATALOG_SUCCESS,
  FETCH_GIFT_DETAILS,
  FETCH_GIFT_DETAILS_SUCCESS,
  BUY_DIGITAL_GIFT,
  BUY_DIGITAL_GIFT_SUCCESS,
  BUY_DIGITAL_GIFT_ERROR,
  UPDATE_GIFT_PURCHASE_STATUS,
} from './actions';

import GiftPaymentBottomSheet from './components/gift-payment-bottom-sheet';

export default {
  name: 'gift',
  reducer: combineReducers({
    giftDetails: giftDetailsReducer,
    digitalGiftsCatalog: digitalGiftsCatalogReducer,
    giftForm: giftFormReducer,
    purchaseStatus: purchaseStatusReducer,
    guestPaymentInstrument: guestPaymentInstrumentReducer,
    selectedPaymentInstrumentId: selectedPaymentInstrumentIdReducer,
  }),
  routes: {
    '/gift/confirmation': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'giftcard-cart-content' */ './components/confirmation'
          )
      ),
      fullWidthBottomDrawer: true,
      hideGlobalFooter: true,
    },
    '/gift/category/:categoryUrn': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'giftcard-category-content' */ './components/category'
          )
      ),
      fullWidthBottomDrawer: true,
    },
    '/gift/:productNumber': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'giftcard-detail-content' */ './components/card-detail'
          )
      ),
      fullWidthBottomDrawer: true,
    },
    '/gift': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'giftcard-list-content' */ './components/root'
          )
      ),
      fullWidthBottomDrawer: true,
    },
  },
  bottomSheetRoutes: [
    {
      sheet: GiftPaymentBottomSheet,
      name: 'payment',
      props: { crateWidth: false },
    },
  ],

  effects: [
    {
      selector: shouldfetchDigitalGiftsCatalogSelector,
      actionCreator: fetchDigitalGiftsCatalog,
    },
    {
      selector: shouldfetchGiftDetailsSelector,
      actionCreator: fetchGiftDetails,
    },
  ],
  asyncActions: [
    FETCH_DIGITAL_GIFTS_CATALOG,
    FETCH_GIFT_DETAILS,
    BUY_DIGITAL_GIFT,
  ],
  persistAfter: [
    {
      action: FETCH_DIGITAL_GIFTS_CATALOG_SUCCESS,
      include: ['digitalGiftsCatalog'],
    },
    {
      action: FETCH_GIFT_DETAILS_SUCCESS,
      include: ['giftDetails'],
    },
    {
      action: BUY_DIGITAL_GIFT_SUCCESS,
      include: ['purchaseStatus'],
    },
    {
      action: BUY_DIGITAL_GIFT_ERROR,
      include: ['purchaseStatus'],
    },
    {
      action: UPDATE_GIFT_PURCHASE_STATUS,
      include: ['purchaseStatus'],
    },
  ],
};
