import { OPEN_FILTER_OVERLAY, CLOSE_FILTER_OVERLAY } from '../actions/types';

const defaultState = {
  isOverlayOpen: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_FILTER_OVERLAY:
      return { isOverlayOpen: true };
    case CLOSE_FILTER_OVERLAY:
      return { isOverlayOpen: false };
    default:
      return state;
  }
};
