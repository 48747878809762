import { SET_ACTIVE_GIFT_ORDER_ID } from '../../../actions';

const initialState = {
  orderId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_GIFT_ORDER_ID: {
      return { ...state, ...{ orderId: action.payload } };
    }
  }

  return state;
};
