import ms from 'milliseconds';
import { createSelector } from 'reselect';

import { appTimeSelector } from 'shared/app/shell';
import { routeSelector } from 'shared/app/state/selectors/routes';
import { signedInSelector } from 'shared/app/bundles/user';
import shouldUpdate from 'shared/app/stale-reducers/should-update';

export const configSelector = (state) => state.config;
export const uiSelector = (state) => state.accountPaymentMethod.ui;

const isRegistrationAddress = (address = {}) =>
  address.type && address.type.toLowerCase() === 'registration';

export const addressListSelector = (state) =>
  state.accountPaymentMethod.addressList;

export const hasAddressListSelector = createSelector(
  addressListSelector,
  (addressList) => Boolean(addressList?.data)
);

export const addressListDataSelector = createSelector(
  addressListSelector,
  (addressList) => addressList?.data ?? []
);

export const newBillingAddressIdSelector = createSelector(
  addressListSelector,
  (addressList) => addressList?.newBillingAddressId ?? null
);

export const selectedBillingAddressIdSelector = createSelector(
  uiSelector,
  (ui) => ui.selectedBillingAddressId
);

// The Single Billing Address Token has a 5min TTL
export const singleBillingAddressTokenSelector = createSelector(
  uiSelector,
  (ui) => ui.singleBillingAddressToken
);

export const billingAddressListSelector = createSelector(
  addressListSelector,
  (addressList) => {
    const list = addressList.data || [];

    return list.filter((address) => address.type.toLowerCase() === 'billing');
  }
);

export const successRedirectUrlSelector = createSelector(
  configSelector,
  (config) => config.successRedirectUrl
);

/* eslint-disable max-params */
export const shouldFetchAddressListSelector = createSelector(
  routeSelector,
  addressListSelector,
  appTimeSelector,
  signedInSelector,
  (route, addressList, time, signedIn) => {
    const routeList = [
      '/account/payment-method/add/billing-address',
      '/account/payment-method/edit/billing-address',
    ];

    if (
      !signedIn ||
      routeList.indexOf(route) === -1 ||
      !shouldUpdate(addressList, {
        staleTime: ms.weeks(1),
        now: time,
      })
    ) {
      return null;
    }
    return true;
  }
);
/* eslint-enable max-params */

export const registrationAddressSelector = createSelector(
  addressListDataSelector,
  (addressList) => addressList.find((address) => isRegistrationAddress(address))
);

export const isRegistrationAddressOnlySelector = createSelector(
  addressListDataSelector,
  (addressList) =>
    addressList.length === 1 && isRegistrationAddress(addressList[0])
);

export const hasBillingAddressesSelector = createSelector(
  addressListDataSelector,
  isRegistrationAddressOnlySelector,
  (addressList, isRegistrationAddressOnly) =>
    addressList.length > 0 && !isRegistrationAddressOnly
);

export const expectingAddAddressResponseSelector = createSelector(
  addressListSelector,
  (addressList) => Boolean(addressList.expectingAddAddressResponse)
);
