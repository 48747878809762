import {
  CLEAR_EDITING,
  SET_EDITING,
  SET_SELECTED_BILLING_ADDRESS_ID,
} from '../actions';

import {
  ADD_GUEST_BILLING_ADDRESS,
  ADD_GUEST_BILLING_ADDRESS_ERROR,
  ADD_GUEST_BILLING_ADDRESS_SUCCESS,
  ADD_GUEST_PAYMENT_TOKEN,
  ADD_GUEST_PAYMENT_TOKEN_ERROR,
  ADD_GUEST_PAYMENT_TOKEN_FORM_INVALID,
  ADD_GUEST_PAYMENT_TOKEN_SUCCESS,
} from 'gift/app/actions';

const defaultState = {
  editingPaymentMethodId: null,
  editingPaymentMethodIdHash: null,
  selectedBillingAddressId: null,
  expectPaymentTokenResponse: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDITING:
      return {
        ...state,
        editingPaymentMethodId: action.payload.paymentMethodId,
        editingPaymentMethodIdHash: action.payload.paymentMethodIdHash,
      };

    case CLEAR_EDITING:
      return {
        ...state,
        editingPaymentMethodId: null,
        editingPaymentMethodIdHash: null,
      };

    case SET_SELECTED_BILLING_ADDRESS_ID:
      return {
        ...state,
        selectedBillingAddressId: action.payload.addressId,
      };

    case ADD_GUEST_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        singleBillingAddressToken: action.payload,
      };

    case ADD_GUEST_BILLING_ADDRESS:
      return {
        ...state,
        expectPaymentTokenResponse: true,
      };

    case ADD_GUEST_BILLING_ADDRESS_ERROR:
      return {
        ...state,
        expectPaymentTokenResponse: false,
      };

    case ADD_GUEST_PAYMENT_TOKEN:
      return {
        ...state,
        expectPaymentTokenResponse: true,
      };

    case ADD_GUEST_PAYMENT_TOKEN_SUCCESS:
      return {
        ...state,
        expectPaymentTokenResponse: false,
      };

    case ADD_GUEST_PAYMENT_TOKEN_ERROR:
      return {
        ...state,
        expectPaymentTokenResponse: false,
      };

    case ADD_GUEST_PAYMENT_TOKEN_FORM_INVALID:
      return {
        ...state,
        expectPaymentTokenResponse: false,
      };

    default:
      return state;
  }
};
