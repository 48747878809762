import { createSelector } from 'reselect';

import { configSelector } from 'shared/app/shell/state/selectors/config';

export const defaultSvcReloadOptionSelector = createSelector(
  configSelector,
  (config) => config.defaultSvcReloadOption
);

export const svcReloadOptionsSelector = createSelector(
  configSelector,
  (config) => config.svcReloadOptions
);

export const triggerAmountOptionsSelector = createSelector(
  configSelector,
  (config) => config.triggerAmountOptions
);

export const defaultTriggerAmountOptionSelector = createSelector(
  configSelector,
  (config) => config.defaultTriggerAmountOption
);
