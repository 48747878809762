const rewardPointsExpirationItemTransform = (streamItem) => {
  const pointsEvent =
    streamItem?.rewardPointsExpiration?.nextRewardPointsExpirationEvent
      ?.rewardPointsExpirationEvent;

  if (!pointsEvent) {
    return null;
  }

  const rewardPointsTotal =
    streamItem?.rewardPointsExpiration?.rewardPointsTotal;

  return {
    totalPoints: Math.floor(rewardPointsTotal),
    expiringPoints: Math.floor(pointsEvent.rewardPoints),
    expirationDate: pointsEvent.expirationDate,
  };
};

export default rewardPointsExpirationItemTransform;
