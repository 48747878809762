import React, { useEffect, useState } from 'react';
import { isEmpty, sortBy, findKey, find } from 'lodash';
import { useSelector } from 'react-redux';

import Tabs, {
  TabList,
  Tab,
  TabPanelList,
  TabPanel,
} from '@starbucks-web/pattern-library/lib/components/tabs';

import {
  shouldScrollSelector,
  selectedFeaturesStateSelector,
} from '../../state/selectors';
import {
  trackStoreFinderNearbyClick,
  trackStoreFinderPreviousClick,
  trackStoreFinderFavoritesClick,
} from '../../state/actions/track-event';

import LocatorErrorCard from '../locator-error-card';
import getSectionHeading from './get-section-heading';
import styles from './styles.cssm';

let hasSelectedNewStore = false;
const tabCategoryMap = {
  nearby: 0,
  previous: 1,
  favorites: 2,
};

const tabErrorMap = {
  nearby: 'noStores',
  previous: 'noPrevious',
  favorites: 'noFavorite',
};

const byFavorites = (loc) => loc.isFavorite;
const byPrevious = (loc) => loc.isPrevious;

const trackTabClick = (tabIndex, locations = [], selectedFeatures) => {
  /* eslint-disable camelcase */
  if (tabIndex === 0) {
    trackStoreFinderNearbyClick({
      count_of_stores_shown: locations.length,
      store_finder_filter_type: selectedFeatures,
    });
    return;
  }
  if (tabIndex === 1) {
    trackStoreFinderPreviousClick({
      count_of_stores_shown: locations.filter(byPrevious).length,
      store_finder_filter_type: selectedFeatures,
    });
    return;
  }
  if (tabIndex === 2) {
    trackStoreFinderFavoritesClick({
      count_of_stores_shown: locations.filter(byFavorites).length,
      store_finder_filter_type: selectedFeatures,
    });
  }
  /* eslint-enable camelcase */
};

const getInitialActiveTab = (locations, selectedStoreNumber) => {
  const selectedStore = find(
    locations,
    ({ store }) => store.storeNumber === selectedStoreNumber
  );
  if (selectedStore?.isFavorite) {
    return parseInt(tabCategoryMap.favorites);
  }
  if (selectedStore?.isPrevious) {
    return parseInt(tabCategoryMap.previous);
  }
  return parseInt(tabCategoryMap.nearby);
};

const TabbedLocationList = ({
  locations: nearbyLocations,
  scrollToActiveLocation,
  selectedStoreNumber,
  renderCard,
}) => {
  if (isEmpty(nearbyLocations)) {
    return null;
  }

  const didClickMapMarker = useSelector(shouldScrollSelector);
  const selectedFeatures = useSelector(selectedFeaturesStateSelector);

  const [activeTabState, setActiveTabState] = useState(() =>
    getInitialActiveTab(nearbyLocations, selectedStoreNumber)
  );

  const setScrollAndTab = ({
    tab = null,
    shouldScroll = false,
    categoryToScrollIn,
  }) => {
    if (tab !== null && tab !== activeTabState) {
      setActiveTabState(tab);
    }
    if (shouldScroll) {
      scrollToActiveLocation({
        storeNumber: selectedStoreNumber,
        categoryToScrollIn:
          categoryToScrollIn ||
          findKey(tabCategoryMap, (idx) => idx === activeTabState),
      });
    }
  };

  // If a new store is selected via map, set active tab to nearby, and scroll to location
  useEffect(() => {
    setScrollAndTab({
      tab: hasSelectedNewStore ? tabCategoryMap.nearby : null,
      shouldScroll: !(
        hasSelectedNewStore && activeTabState !== tabCategoryMap.nearby
      ),
    });

    hasSelectedNewStore = true;
  }, [selectedStoreNumber, didClickMapMarker]);

  // If a new tab is selected, scroll the active store into view, if it exists in that tab
  useEffect(() => {
    setScrollAndTab({ shouldScroll: true });
    trackTabClick(activeTabState, nearbyLocations, selectedFeatures);
  }, [activeTabState]);

  // If new locations are loaded, swap to the highest priority tab that's available
  //    priority order: favorites > usuals > nearby
  useEffect(() => {
    const newInitialActiveTab = getInitialActiveTab(
      nearbyLocations,
      selectedStoreNumber
    );
    setScrollAndTab({
      tab: newInitialActiveTab,
      shouldScroll: true,
      categoryToScrollIn: findKey(
        tabCategoryMap,
        (idx) => idx === newInitialActiveTab
      ),
    });
  }, [nearbyLocations]);

  const previousLocations = nearbyLocations.filter(byPrevious);
  const favoritesLocations = nearbyLocations.filter(byFavorites);

  const renderTabPanel = (locations, categoryName) => {
    if (isEmpty(locations)) {
      return (
        <TabPanel>
          <LocatorErrorCard
            activeTab={activeTabState}
            errorType={tabErrorMap[categoryName]}
          />
        </TabPanel>
      );
    }

    return (
      <TabPanel data-e2e={`${categoryName}Panel`}>
        {locations.map((location, idx) => {
          const isActive = location.store.storeNumber === selectedStoreNumber;

          return renderCard({
            isActive,
            location,
            categoryName,
            listPosition: idx,
          });
        })}
      </TabPanel>
    );
  };

  // written declaratively to preserve tab ordering
  return (
    <Tabs
      activeTab={activeTabState}
      inactiveTabClassName="color-textBlackSoft"
      onTabChange={setActiveTabState}
    >
      <TabList className={styles.tablist}>
        <Tab className="p3" data-e2e="nearbyTab">
          {getSectionHeading('nearby')}
        </Tab>
        <Tab className="p3" data-e2e="previousTab">
          {getSectionHeading('previous')}
        </Tab>
        <Tab className="p3" data-e2e="favoritesTab">
          {getSectionHeading('favorites')}
        </Tab>
      </TabList>
      <TabPanelList className="overflow-hidden">
        {renderTabPanel(sortBy(nearbyLocations, ['distance']), 'nearby')}
        {renderTabPanel(previousLocations, 'previous')}
        {renderTabPanel(favoritesLocations, 'favorites')}
      </TabPanelList>
    </Tabs>
  );
};

export default TabbedLocationList;
