import { createSelector } from 'reselect';
import {
  ADD_PAYMENT,
  defaultOrFirstPaymentInstrumentSelector,
} from 'shared/app/bundles/wallet';

const cardsUiSelector = (state) => state.accountCards.ui;

export const activeScanToPayCardIdSelector = createSelector(
  cardsUiSelector,
  (ui) => ui.activeScanToPayCardId || null
);

export const selectedAddMoneyPaymentInstrumentIdSelector = createSelector(
  cardsUiSelector,
  defaultOrFirstPaymentInstrumentSelector,
  (ui, defaultPaymentInstrument) =>
    ui.selectedAddMoneyPaymentInstrumentId ||
    defaultPaymentInstrument?.paymentInstrumentId ||
    ADD_PAYMENT
);

export const selectedAutoReloadPaymentInstrumentIdSelector = createSelector(
  cardsUiSelector,
  defaultOrFirstPaymentInstrumentSelector,
  (ui, defaultPaymentInstrument) =>
    ui.selectedAutoReloadPaymentInstrumentId ||
    defaultPaymentInstrument?.paymentInstrumentId ||
    ADD_PAYMENT
);
