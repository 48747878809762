import { createSelector } from 'reselect';

import { storeLocatorSelectorWithFallback } from './util';
/*
input selectors (not memoized)
these just abstract store structure
and give us functions reselect can compose
*/
export const expandedStoreStateSelector = createSelector(
  storeLocatorSelectorWithFallback,
  ({ expandedStoreState }) => expandedStoreState
);

export const expandedStoreActiveSelector = createSelector(
  expandedStoreStateSelector,
  (expandedStore) => expandedStore?.expanded
);

export const expandedStoreNumberSelector = createSelector(
  expandedStoreStateSelector,
  (expandedStore) => expandedStore?.storeNumber ?? null
);
