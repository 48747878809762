import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'storeLocator.title',
    defaultMessage: 'Store Locator',
    description: 'Page title for store locator',
  },
  description: {
    id: 'storeLocator.metaDescription',
    defaultMessage:
      'Our Starbucks store locator will help you find locations near you where you can enjoy great beverages and wi-fi. Find a Starbucks now.',
    description: 'Page description for store locator',
  },
});

export default messages;
