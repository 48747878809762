import React from 'react';
import { FormattedMessage } from 'react-intl';

import runSequentially from 'shared/app/utils/run-sequentially';
import { addNotification, hideBottomSheet } from 'shared/app/shell';

import {
  autoReloadTriggerAmountSelectedSelector,
  clearReloadAmountSelected,
  reloadAmountSelectedSelector,
  reloadSvcCardBalance,
} from 'shared/app/bundles/svc-cards';

import {
  UPDATE_AUTO_RELOAD,
  UPDATE_AUTO_RELOAD_ERROR,
  UPDATE_AUTO_RELOAD_SUCCESS,
  GET_CARD_INFO_FROM_TOKEN,
  GET_CARD_INFO_FROM_TOKEN_SUCCESS,
  GET_CARD_INFO_FROM_TOKEN_ERROR,
} from 'shared/app/bundles/svc-cards/actions';
import {
  CREATE_AUTO_RELOAD,
  UPDATE_AUTO_RELOAD as UPDATE_AUTO_RELOAD_ID,
  DISABLE_AUTO_RELOAD,
} from '../../../universal/gql-operation-ids';

import { getNotificationForErrorCode } from '../errors';
import { showCodedErrorNotification } from 'shared/app/utils/show-coded-error-notification';
import { getBffBaseUrl } from 'shared/app/utils/config-helper';

import messages from './notification-messages';

const getAutoReloadMutation = ({
  card,
  amount,
  paymentMethod,
  triggerAmount,
  enabled,
}) => {
  const createAndUpdateVariables = {
    cardId: card.cardId,
    amount: parseFloat(amount) || undefined,
    paymentMethodId: paymentMethod,
    triggerAmount: parseFloat(triggerAmount) || undefined,
  };

  const createRequestOptions = {
    operationId: CREATE_AUTO_RELOAD,
    variables: createAndUpdateVariables,
    includeRisk: true,
  };

  const updateRequestOptions = {
    operationId: UPDATE_AUTO_RELOAD_ID,
    variables: createAndUpdateVariables,
    includeRisk: true,
  };

  const disableRequestOptions = {
    operationId: DISABLE_AUTO_RELOAD,
    variables: { cardId: card.cardId },
    includeRisk: true,
  };

  const { autoReloadProfile } = card;

  if (!enabled && !autoReloadProfile) {
    return null;
  }
  if (!enabled) {
    return disableRequestOptions;
  }
  if (!autoReloadProfile) {
    return createRequestOptions;
  }
  return updateRequestOptions;
};

const getAutoReloadSuccessPayload = (data) => {
  const response =
    data.updateAutoReload || data.disableAutoReload || data.createAutoReload;

  if (!response) {
    throw new Error();
  }

  return response;
};

const isValidAutoReloadUpdate = ({ enabled, card }) => {
  const { autoReloadProfile } = card;
  if (!enabled && !autoReloadProfile) {
    // When a card does not have an auto reload profile but a user attempts to disable it.
    return false;
  }
  return true;
};

export const updateAutoReload =
  ({ formData, card, onSuccess = () => {}, onError = () => {} }) =>
  (dispatch, getState, { gqlFetch }) => {
    const state = getState();
    const reloadAmountSelected = reloadAmountSelectedSelector(state);
    const autoReloadTriggerAmountSelected =
      autoReloadTriggerAmountSelectedSelector(state);

    const { cardId } = card;
    const { enabled, amount, paymentInstrument, triggerAmount } = formData;

    if (!isValidAutoReloadUpdate({ enabled, card })) {
      dispatch(hideBottomSheet());
      return;
    }

    dispatch({ type: UPDATE_AUTO_RELOAD, payload: cardId });

    const mutation = getAutoReloadMutation({
      card,
      amount,
      paymentMethod: paymentInstrument,
      triggerAmount,
      enabled,
    });

    return gqlFetch(mutation)
      .then(
        (cardData) => {
          const payload = {
            cardId,
            ...getAutoReloadSuccessPayload(cardData),
          };

          runSequentially(
            () => dispatch(hideBottomSheet()),
            () =>
              dispatch({
                payload,
                type: UPDATE_AUTO_RELOAD_SUCCESS,
              }),
            () => onSuccess(formData),
            () =>
              dispatch(
                addNotification(null, {
                  messageId: messages.autoReloadSuccess.id,
                  messageValues: {
                    autoReloadActivated: Boolean(
                      payload.autoReloadProfile.isActive
                    ),
                  },
                })
              ),
            () =>
              (reloadAmountSelected || autoReloadTriggerAmountSelected) &&
              dispatch(clearReloadAmountSelected())
          );
        },
        (error) => {
          const { code: errorCode } = error;
          runSequentially(
            () =>
              dispatch({
                error,
                type: UPDATE_AUTO_RELOAD_ERROR,
                payload: cardId,
              }),
            () => onError(error),
            () =>
              dispatch(
                showCodedErrorNotification(
                  { errorCode },
                  getNotificationForErrorCode
                )
              )
          );
        }
      )
      .catch((error) => {
        dispatch({
          error,
          type: UPDATE_AUTO_RELOAD_ERROR,
          payload: cardId,
        });
      });
  };

export const reloadCardBalance =
  ({ formData, onSuccess = () => {}, onError = () => {} }) =>
  (dispatch, getState) => {
    const { amount } = formData;
    const reloadAmountSelected = reloadAmountSelectedSelector(getState());
    const reloadPayload = {
      ...formData,
      paymentMethod: formData?.paymentInstrument,
    };

    dispatch(reloadSvcCardBalance(reloadPayload))
      .then(() => {
        const successMessage = (
          <FormattedMessage
            defaultMessage="{amount, number, money} added to your card"
            description="Message displayed when a user successfully reloads a card balance"
            id="accountCards.notifications.reloadCardSuccess"
            values={{
              amount,
            }}
          />
        );

        runSequentially(
          () => dispatch(hideBottomSheet()),
          () => onSuccess(reloadPayload),
          () => dispatch(addNotification(successMessage)),
          () => reloadAmountSelected && dispatch(clearReloadAmountSelected())
        );
      })
      .catch((error) => {
        const { code: errorCode } = error;
        runSequentially(
          () => dispatch(hideBottomSheet()),
          () => onError(error),
          () =>
            dispatch(
              showCodedErrorNotification(
                { errorCode },
                getNotificationForErrorCode
              )
            )
        );
      });
  };

export const getCardInfoFromToken = (cashstarToken) => {
  return (dispatch, getState, { apiFetch }) => {
    dispatch({ type: GET_CARD_INFO_FROM_TOKEN });
    return apiFetch(
      `${getBffBaseUrl()}/bff/account/cards/get-card-info-from-token`,
      {
        method: 'post',
        body: {
          cashstarToken,
        },
      }
    )
      .then((data) => {
        dispatch({ type: GET_CARD_INFO_FROM_TOKEN_SUCCESS });
        return data;
      })
      .catch(() => {
        dispatch({ type: GET_CARD_INFO_FROM_TOKEN_ERROR });
      });
  };
};
