/* eslint-disable max-statements */
/* eslint-disable complexity */
import { assign, omit, toPairs, pickBy } from 'lodash';

import {
  ADD_OOS_RECOMMENDATION_TO_CART,
  ADD_PRODUCT_TO_CART,
  CLEAR_CURRENT_CART,
  CHANGE_QUANTITY,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_GUEST_SUCCESS,
  INVALIDATE_MOST_RECENT_ORDER,
  UPDATE_AVAILABILITY,
} from '../actions/types';

import { slugifyCustomizedProduct } from '../../util/product-id-util';

export default (
  state = {
    current: {},
    previous: {},
    mostRecentOrderTime: null,
  },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART: {
      const { product, size, selectedOptions, quantity, ...rest } =
        action.payload;
      const { sizeCode } = size;

      const id = slugifyCustomizedProduct(action.payload);
      const productToAdd = {
        product,
        size,
        sizeCode,
        selectedOptions,
        quantity:
          (state.current[id] ? state?.current?.[id]?.quantity : 0) + quantity,
        timeAdded: Date.now(),
        ...rest,
      };

      return { ...state, current: { ...state.current, [id]: productToAdd } };
    }

    case ADD_OOS_RECOMMENDATION_TO_CART: {
      const { product, size, selectedOptions, quantity, ...rest } =
        action.payload;
      const { sizeCode } = size;

      const id = slugifyCustomizedProduct(action.payload);
      const productToAdd = {
        product,
        size,
        sizeCode,
        selectedOptions,
        quantity:
          (state.current[id] ? state?.current?.[id]?.quantity : 0) + quantity,
        timeAdded: Date.now(),
        ...rest,
      };

      // The reducer returns state.current as an object with ids of each item
      // in the cart.  When a recommended similar item is added to the cart we
      // want to iterate over the existing cart (using an array of the cart's
      // values), find the id that matches the out of stock item's id that
      // recommended the similar item, then place that recommended item
      // right after the out of stock item in the cart.  We then return
      // the newly constructed cart as an object.
      const newCart = Object.values(state?.current).reduce(
        (newCartObject, currentCartItem) => {
          const slugIdCurrentCartItem =
            slugifyCustomizedProduct(currentCartItem);
          const match =
            slugIdCurrentCartItem === action.payload.outOfStockItemKey;
          if (match) {
            return {
              ...newCartObject,
              [slugIdCurrentCartItem]: { ...currentCartItem },
              [id]: { ...productToAdd },
            };
          } else if (slugIdCurrentCartItem === id) {
            return { ...newCartObject };
          }
          return {
            ...newCartObject,
            [slugIdCurrentCartItem]: { ...currentCartItem },
          };
        },
        {}
      );
      return { ...state, current: { ...newCart } };
    }

    case CHANGE_QUANTITY: {
      const { delta, itemId } = action.payload;
      const oldItem = state.current[itemId];
      const newQuantity = oldItem?.quantity + delta;

      return newQuantity === 0
        ? assign({}, state, {
            current: omit(state.current, [itemId]),
          })
        : assign({}, state, {
            current: assign({}, state.current, {
              [itemId]: assign({}, oldItem, {
                quantity: newQuantity,
              }),
            }),
          });
    }

    case SUBMIT_ORDER_SUCCESS:
    case SUBMIT_ORDER_GUEST_SUCCESS: {
      // Filter out unavailable items for previous orders
      const itemsPurchased = pickBy(state.current, (item) => {
        const productAvailability =
          item?.product?.availability ||
          item?.product?.forms?.[0]?.availability;
        return productAvailability === 'Available';
      });
      return {
        current: {},
        previous: itemsPurchased,
      };
    }

    case INVALIDATE_MOST_RECENT_ORDER: {
      return {
        ...state,
        previous: {},
      };
    }

    case UPDATE_AVAILABILITY: {
      const { itemsToUpdate } = action.payload;
      const newState = toPairs(state.current).reduce((acc, itemPair) => {
        const id = itemPair[0];
        const item = itemPair[1];
        const productAvailability = itemsToUpdate?.[id]?.availability;
        const updatedItem = Object.assign({}, item, {
          product: Object.assign({}, item?.product, {
            availability: productAvailability,
          }),
        });
        // Check for product availability so if a cart item has
        // correct availability, it doesn't get changed.
        acc[id] = productAvailability ? updatedItem : item;
        return acc;
      }, {});

      return assign({}, state, {
        current: assign({}, state.current, newState),
      });
    }
    case CLEAR_CURRENT_CART: {
      return {
        ...state,
        current: {},
      };
    }

    default: {
      return state;
    }
  }
};
