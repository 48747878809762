import { defineMessages } from 'react-intl';

const messages = defineMessages({
  overlayCloseButton: {
    id: 'storeLocator.filterOverlay.closeButton',
    description:
      'Accessible hint for screenreaders on the close filter overlay',
    defaultMessage: 'Hide options for filtering store results',
  },
  applyButtonAria: {
    id: 'storeLocator.filterOverlay.applyAria',
    description:
      'Accessible hint for screenreaders on the "apply filters button"',
    defaultMessage: 'Apply chosen filters',
  },
  clearButtonAria: {
    id: 'storeLocator.filterOverlay.clearAria',
    description: 'Accessible hint for screenreaders on the "clear all filters"',
    defaultMessage: 'Clear all filters and go to results',
  },
  filterOverlayHeading: {
    defaultMessage: 'Filters',
    description:
      'Heading of overlay showing filters available to narrow store search results',
    id: 'storeLocator.filterOverlay.heading',
  },
  clearFiltersLabel: {
    id: 'storeLocator.filterOverlay.clearFiltersButton',
    defaultMessage: 'Clear All',
    description: 'Text of button to remove filters for store locator search',
  },
  applyLabel: {
    defaultMessage: 'Apply',
    description:
      'Button asking user to verify applying filters for stores shown in search results.',
    id: 'storeLocator.filterOverlay.applyButton',
  },
});

export default messages;
