import { defineMessages } from 'react-intl';

export default defineMessages({
  parkedInSpot: {
    id: 'pickup.parkedInSpot',
    defaultMessage: `Park in a dedicated curbside spot and let us know which spot you're in`,
    description: 'Message to know where the customer parked in',
  },
  noStallSelectedError: {
    id: 'pickup.noStallSelectedError',
    defaultMessage: 'Select your parking spot',
    description: 'Error message displayed when no stall selected',
  },
  curbsideHeading: {
    id: 'pickup.curbsideHeading',
    defaultMessage: 'Curbside pickup',
    description: 'Heading for pickup page',
  },
});
