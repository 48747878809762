import {
  SAVE_PASSWORD,
  SAVE_PASSWORD_SUCCESS,
  SAVE_PASSWORD_ERROR,
  SHOW_GENERIC_PASSWORD_ERROR,
  HIDE_GENERIC_PASSWORD_ERROR,
} from '../../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case SAVE_PASSWORD:
      return Object.assign({}, state, {
        loading: true,
        showGenericError: false,
      });
    case SAVE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        showGenericError: false,
      });
    case SAVE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    case SHOW_GENERIC_PASSWORD_ERROR:
      return Object.assign({}, state, {
        showGenericError: true,
      });
    case HIDE_GENERIC_PASSWORD_ERROR:
      return Object.assign({}, state, {
        showGenericError: false,
      });
    default:
      return state;
  }
};
