import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import LegacyPaddedContentColumn from 'shared/app/components/legacy-padded-content-column';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import messages from './messages';

const PickupUnavailable = ({ configImagesUrl, pickupOption = 'curbside' }) => (
  <div className="height-100">
    <LegacyPaddedContentColumn size="sm">
      <div className="flex flex-column pt6 height-100">
        <img
          alt=""
          className="self-center"
          src={`${configImagesUrl}/pickup/${pickupOption}-unavailable.png`}
        />
        <p className="py5 px3 text-sm">
          <FormattedMessage {...messages[`${pickupOption}Unavailable`]} />
        </p>
      </div>
    </LegacyPaddedContentColumn>
  </div>
);

PickupUnavailable.propTypes = {
  pickupOption: PropTypes.oneOf(['curbside', 'outdoor']),
};

const select = (state) => ({
  configImagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(PickupUnavailable);
