/*
 * action types
 */
import { SET_FEATURES, CLEAR_SELECTED_FEATURES } from './types';

/*
 * action creators
 */
export const setFeatures = (featureCodes) => {
  return { type: SET_FEATURES, payload: featureCodes };
};
export const clearSelectedFeatures = () => {
  return { type: CLEAR_SELECTED_FEATURES };
};
