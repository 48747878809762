import { push } from 'redux-first-history';

import { removeSvcCard } from 'shared/app/bundles/svc-cards';
import {
  addNotification,
  showErrorNotification,
  hideDialog,
} from 'shared/app/shell';
import runSequentially from 'shared/app/utils/run-sequentially';

import messages from './notification-messages';

export const removeCard = (cardId) => (dispatch) => {
  runSequentially(
    () => dispatch(push('/account/cards')),
    () => dispatch(hideDialog())
  );

  return dispatch(removeSvcCard(cardId))
    .then((removedCard) => {
      dispatch(
        addNotification(null, {
          messageId: messages.removeCardSuccess.id,
          messageValues: {
            lastFourDigits: removedCard.cardNumber.slice(-4),
          },
        })
      );
    })
    .catch(() => {
      dispatch(
        showErrorNotification({
          messageId: messages.removeCardError.id,
          useErrorLoadingIndicator: true,
        })
      );
    });
};
