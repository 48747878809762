import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import {
  ThemeContext,
  themes,
} from '@starbucks-web/pattern-library/lib/components/theme';

import CarouselWithMessages from 'shared/app/components/carousel-with-messages';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import {
  streamCarouselSelector,
  isStaticCarouselSelector,
} from '../../state/selectors/stream';
import CarouselItem from './carousel-item';

const RewardsBenefitsCarousel = ({ streamCarousel, isStatic, intl }) => {
  const { title, carouselItems, buttons = [] } = streamCarousel;
  if (!carouselItems) return null;

  const textSelector = (text) => {
    if (!text) return null;
    return isStatic ? intl.formatMessage(text) : text;
  };

  const buttonComponents = () => {
    const buttonItems = buttons.map((button = {}, index) => {
      const firstButton = Boolean(buttons.length > 1 && index === 0);
      const props = {
        className: firstButton ? 'mr3' : null,
        [button.external ? 'href' : 'to']: button.href,
        tagName: !button.external ? Link : null,
        visualStyle: firstButton ? 'positive' : 'default',
        key: index,
      };

      return button.component ? (
        <button.component {...props} />
      ) : (
        <Button {...props}>{textSelector(button.text)}</Button>
      );
    });
    return <div className="container">{buttonItems}</div>;
  };

  const cards = carouselItems.map((card, index) => {
    const transformedCard = Object.assign({}, card, {
      text: textSelector(card.text),
    });
    return (
      <div
        className="px2 gridItem size12of12 sm-size6of12 md-size5of12 lg-size4of12"
        key={`rewards-carousel-${index}`}
      >
        <CarouselItem card={transformedCard} />
      </div>
    );
  });

  const heading = textSelector(title);

  return (
    <ThemeContext.Provider value={themes.rewards}>
      <div
        className="relative pt1 pb4 md-pt2 md-pb4 bg-white"
        data-e2e="carouselContainer"
      >
        <div className="container pt3 pb2 md-mb3 md-pt4 flex items-baseline justify-between">
          <Heading className="text-upper text-bold" size="xxs" tagName="h2">
            {heading}
          </Heading>
        </div>
        <CarouselWithMessages
          carouselClassName="container"
          containerClassName="pb4"
          data-e2e="reward-benefits-carousel"
          heading={heading}
          id="RewardsBenefitsCarousel"
          key={'Starbucks Rewards Onboarding'}
          snapPagersToImages
          variableWidth
        >
          {cards}
        </CarouselWithMessages>
        {buttonComponents()}
      </div>
    </ThemeContext.Provider>
  );
};

RewardsBenefitsCarousel.displayName = 'Rewards Carousel';

const select = (state) => ({
  streamCarousel: streamCarouselSelector(state),
  isMobileViewport: isMobileViewportSelector(state),
  isStatic: isStaticCarouselSelector(state),
});

export default injectIntl(connect(select)(RewardsBenefitsCarousel));
