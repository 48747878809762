import React from 'react';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import checkInClosedIcon from '@starbucks-web/pattern-library/lib/icons/moon-zzz';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import messages from './messages';
import styles from './styles.cssm';

/*
 * Notification Banner the displays in place of Check In Option when store Check In is closed.
 */
const CheckInClosedBanner = ({ checkInMessageId }) => (
  <div className="pb3">
    <div
      className={`${styles.container} ${styles.closedBanner} flex justify-between items-center my2`}
    >
      <Icon
        className={styles.closedBannerIcon}
        path={checkInClosedIcon}
        size="48px"
      />
      <div className="pl2 flex-grow">
        <Heading className="pb1 text-semibold text-md" size="sm" tagName="h3">
          <FormattedMessage {...messages.checkInClosedHeading} />
        </Heading>
        <p className="text-xxs color-textBlackSoft">
          <FormattedMessage {...messages[checkInMessageId]} />
        </p>
      </div>
    </div>
  </div>
);

export default CheckInClosedBanner;
