export const transformOnboardingContent = (onboardingItem, tasks) => {
  if (!onboardingItem) return null;

  /*
    The onboarding stream item has 2 major properties:
    the "content" (passed as onboardingItem) and the "onboardingStatus" (passed as tasks).

    currently there 3 different onboarding task types:
    "mop", "purchase" and "svcReload"

    **NOTE** In the content property, "svcReload" type is being referenced as "zero"
  */
  const types = ['mop', 'purchase', 'zero'];
  const properties = ['title', 'body', 'icon', 'calltoactionlink'];
  const complete = 'done';
  const now = Date.now();

  const items = types
    .map((type) => {
      const propertiesObject = properties.reduce((obj, prop) => {
        const property = `${prop}${type}`;
        return {
          ...obj,
          [prop]: onboardingItem[property],
          [`${prop}Complete`]: onboardingItem[`${property}${complete}`],
        };
      }, {});

      const taskName = type === 'zero' ? 'svcreload' : type;
      const task = tasks.find((tsk) => tsk.name.toLowerCase() === taskName);

      return { ...propertiesObject, task };
    })
    .filter((item) => {
      const taskCompletedOn = item.task.completedOn
        ? new Date(item.task.completedOn)
        : null;
      const taskCompleteInMs = taskCompletedOn
        ? taskCompletedOn.setDate(taskCompletedOn.getDate() + 2)
        : null;
      return Boolean(
        !taskCompleteInMs || taskCompleteInMs === 0 || taskCompleteInMs > now
      );
    })
    .sort((x, y) => x.task.displayOrder - y.task.displayOrder);

  const { cardheader } = onboardingItem;

  if (items.length === 0) return null;

  return {
    cardheader,
    items,
  };
};
