'use strict';

const { flattenCartItems } = require('../../app/util/cart-content-util');

const transformCart = (cart) => {
  return Object.keys(cart).reduce((memo, id) => {
    const { quantity, selectedOptions = [], size } = cart[id];
    const { sku } = size;
    const item = {
      quantity: 1,
      commerce: {
        sku,
      },
      childItems: selectedOptions.map((option) => {
        const { value, product } = option;
        const optionSku = option?.size?.sku || product?.form?.sizes?.[0]?.sku;
        const optionQuantity = option?.quantity ?? value;
        const optionsCommerce = { commerce: { sku: optionSku } };
        return {
          quantity: optionQuantity > 0 ? optionQuantity : 1,
          ...optionsCommerce,
        };
      }),
    };
    const itemsToAdd = flattenCartItems({ quantity, item, id });

    return [...memo, ...itemsToAdd];
  }, []);
};

module.exports = {
  transformCart,
};
