import React from 'react';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import styles from './styles.cssm';

const CategoryHeading = ({ children = null, ...headingProps }) => (
  <Heading
    className={`text-bold text-upper px4 sm-px6 bg-black10 color-textBlackSoft ${styles.heading}`}
    size="xs"
    tagName="h3"
    {...headingProps}
  >
    {children}
  </Heading>
);

export default CategoryHeading;
