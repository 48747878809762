import React from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { FEATURES } from 'shared/universal/optimizely-keys';
import { Feature } from 'shared/app/components/optimizely';

import RadioToggle from 'shared/app/components/radio-toggle';

import { pathnameSelector } from 'shared/app/state/selectors/routes';
import { inMenuStoreLocatorSelector } from '../../state/selectors';

import messages from './messages';

const DeliveryToggle = () => {
  const dispatch = useDispatch();
  const pathname = useSelector(pathnameSelector);
  const inMenuStoreLocator = useSelector(inMenuStoreLocatorSelector);
  const { formatMessage } = useIntl();

  const orderOptions = [
    {
      displayName: formatMessage(messages.pickupLabel),
      value: 'pickup',
      defaultOption: !pathname.includes('/delivery'),
    },
    {
      displayName: formatMessage(messages.deliveryLabel),
      value: 'delivery',
      defaultOption: pathname.includes('/delivery'),
    },
  ];

  const onChange = (e) => {
    const rootPath = inMenuStoreLocator
      ? '/menu/store-locator'
      : '/store-locator';
    if (e.target.value === 'delivery') {
      dispatch(push(`${rootPath}/delivery`));
    }
    if (e.target.value === 'pickup') {
      dispatch(push(rootPath));
    }
  };

  return (
    <Feature name={FEATURES.IN_APP_DELIVERY}>
      {(optimizelyEnabled) => {
        return optimizelyEnabled ? (
          <RadioToggle
            onChange={onChange}
            radioLegend={formatMessage(messages.deliveryToggleLegend)}
            radioName="orderType"
            radioOptions={orderOptions}
          />
        ) : null;
      }}
    </Feature>
  );
};

export default DeliveryToggle;
