import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FEATURES } from 'shared/universal/optimizely-keys';
import { Feature } from 'shared/app/components/optimizely';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';

import messages from './messages';

const CaliTaxLabel = () => {
  const { formatMessage } = useIntl();
  const selectedStore = useSelector(selectedStoreSelector);
  const isCaliforniaStore =
    selectedStore?.store?.address?.countrySubdivisionCode === 'CA';
  return (
    <Feature name={FEATURES.CA_TAX_LABEL}>
      {(optimizelyEnabled) =>
        optimizelyEnabled && isCaliforniaStore ? (
          <p className="pt3 color-textBlackSoft text-xxs">
            {formatMessage(messages.caTaxLabel)}
          </p>
        ) : null
      }
    </Feature>
  );
};

export default CaliTaxLabel;
