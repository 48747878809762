import { defineMessages } from 'react-intl';

const messages = defineMessages({
  zoomInLabel: {
    id: 'locatorMapZoomControls.zoomInLabel',
    defaultMessage: 'Zoom map in',
    description: 'Label for zoom in button',
  },
  zoomOutLabel: {
    id: 'locatorMapZoomControls.zoomOutLabel',
    defaultMessage: 'Zoom map out',
    description: 'Label for zoom out button',
  },
});

export default messages;
