import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@starbucks-web/pattern-library/lib/components/icon-button/';
import infoPath from '@starbucks-web/pattern-library/lib/icons/info';
import { FormattedDate, injectIntl } from 'react-intl';
import { rewardsTermsOfUseUrlSelector } from 'shared/app/state/selectors/privacy-and-terms';
import { trackStreamCardClick } from '../../state/track-event';

import CardBase from './card-base';
import CallToAction from './card-coupon-cta';
import messages from './messages';

export const CouponStreamItem = (props) => {
  const {
    addNotification,
    itemId,
    type,
    body,
    callToActionIsEnabled,
    callToActionText,
    expires,
    idx,
    imageUrl,
    intl: { formatMessage },
    rewardCouponCode,
    rewardCouponStatus,
    rewardsTermsOfUseUrl,
    title,
  } = props;
  const formattedExpires = expires && <FormattedDate value={expires} />;
  const redeemOfferOnClick = () => {
    /* eslint-disable camelcase */
    trackStreamCardClick({
      stream_card_id: itemId,
      stream_card_type: type,
      stream_card_position: idx.toString(),
    });
    /* eslint-enable camelcase */
    props.addNotification(formatMessage(messages.redeemOfferSteps));
  };

  const notification = {
    type: 'confirm',
    autoClose: false,
    body: formatMessage(messages.legalDisclaimerTitle),
    confirmText: formatMessage(messages.legalDisclaimerLink),
    bodyClassNames: 'text-bold',
    callback: () => window.open(rewardsTermsOfUseUrl, '_blank'),
  };

  return (
    <CardBase body={body} imagePosition="top" imageUrl={imageUrl} title={title}>
      <div className="flex items-center">
        {formattedExpires && (
          <span
            className="color-textBlackSoft"
            data-e2e="stream-coupon-card-expiration"
          >
            Expires {formattedExpires}
          </span>
        )}
        <IconButton
          ariaLabel={formatMessage(messages.seeLegalDisclaimer)}
          className="ml3 color-textBlackSoft"
          data-e2e="stream-coupon-card-icon-button"
          onClick={() => {
            addNotification('', notification);
          }}
          path={infoPath}
          size="20px"
        />
      </div>
      <CallToAction
        couponCode={rewardCouponCode}
        couponStatus={rewardCouponStatus}
        ctaEnabled={callToActionIsEnabled}
        onClick={redeemOfferOnClick}
        redeemButtonClickHandler={() => {
          /* eslint-disable camelcase */
          trackStreamCardClick({
            stream_card_id: itemId,
            stream_card_type: type,
            stream_card_position: idx.toString(),
          });
          /* eslint-enable camelcase */
        }}
        text={callToActionText}
      />
    </CardBase>
  );
};

CouponStreamItem.propTypes = {
  addNotification: PropTypes.func.isRequired,
};

const select = (state) => ({
  rewardsTermsOfUseUrl: rewardsTermsOfUseUrlSelector(state),
});

export default connect(select)(injectIntl(CouponStreamItem));
