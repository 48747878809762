/* eslint-disable max-params */
import { isEqual } from 'lodash';
import ms from 'milliseconds';
import qs from 'query-string';
import { createSelector } from 'reselect';

import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { appTimeSelector, configSelector } from 'shared/app/shell';
import { pathnameSelector } from 'shared/app/state/selectors/routes';
import { orderingEnabledSelector } from 'shared/app/state/selectors/config';

import {
  svcCardsLoadingSelector,
  hasSvcCardSelector as hasCardSelector,
  isPrimarySvcCardDigitalSelector,
} from 'shared/app/bundles/svc-cards';

import {
  signedInSelector,
  sessionExpiredSelector,
} from 'shared/app/bundles/user';

import { localeTagSelector } from 'shared/app/state/selectors/locales';

import {
  onboardingFlagsSelector,
  hasFetchedOnboardingFlagsSelector,
} from 'shared/app/bundles/user/state/selectors/onboarding-flags';
import { joinDateSelector } from 'shared/app/bundles/user/state/selectors/profile';

import { mostRecentOrderSelector } from 'ordering/app/state/selectors';

import { transformCarousel } from '../transform/carousel';
import { staticCarouselDataSelector } from './static-carousel';

const streamSelector = (state) => state?.stream ?? {};

export const streamStatusesToFetch = 'active';

export const enabledStreamTypesSelector = createSelector(
  configSelector,
  (config) => config.enabledStreamItemTypes
);

export const unauthStreamEnabledSelector = createSelector(
  configSelector,
  (config) => config.unauthStreamEnabled
);

export const shouldFetchStreamSelector = createSelector(
  signedInSelector,
  unauthStreamEnabledSelector,
  pathnameSelector,
  streamSelector,
  appTimeSelector,
  sessionExpiredSelector,
  (signedIn, unauthStreamEnabled, pathname, stream, time, sessionExpired) => {
    if ((!signedIn && !unauthStreamEnabled) || (signedIn && sessionExpired)) {
      return null;
    }
    if (
      pathname !== '/' ||
      !shouldUpdate(stream, {
        staleTime: ms.minutes(5),
        now: time,
      })
    ) {
      return null;
    }
    return true;
  }
);

export const lastFetchStreamSelector = createSelector(
  streamSelector,
  (streamRaw) => streamRaw.lastFetch
);

// only exported for simple testability
export const streamSortRule = (a, b) => {
  if (a.rank < b.rank) {
    return 1;
  }
  if (a.rank > b.rank) {
    return -1;
  }
  if (a.expires > b.expires) {
    return 1;
  }
  if (a.expires < b.expires) {
    return -1;
  }
  return 0;
};

// This only applies to Info cards
export const displayForUserTier = (
  segmentationTiers,
  userTierNumber,
  streamItemType
) => {
  if (streamItemType !== 'information' || !segmentationTiers) {
    return true;
  }
  return segmentationTiers.includes(userTierNumber);
};

export const isInactiveCoupon = (type, status) => {
  return type === 'coupon' && status !== 'Available';
};

const inflateExpirationDate = (item) =>
  Object.assign({}, item, {
    expires: item.expires ? new Date(item.expires) : null,
  });

export const streamDataSelector = createSelector(
  streamSelector,
  enabledStreamTypesSelector,
  signedInSelector,
  (stream, enabledStreamItemTypes) => {
    if (!stream.data) return [];
    return stream.data
      .map(inflateExpirationDate)
      .filter(
        (item) =>
          enabledStreamItemTypes[item.type] &&
          displayForUserTier(
            item.segmentationTiers,
            2, // Always Gold level
            item.type
          ) &&
          !isInactiveCoupon(item.type, item.rewardCouponStatus)
      )
      .sort(streamSortRule);
  }
);

export const streamDisplaySelector = createSelector(
  streamDataSelector,
  appTimeSelector,
  (data, appTime) => {
    return data.filter(
      (item) =>
        (item.expires === null || item.expires > appTime) &&
        ((item.title && item.body) || item.onboardingContent)
    );
  }
);

export const streamItemTypeQuerySelector = createSelector(
  enabledStreamTypesSelector,
  (enabled) => {
    const selected = [];
    for (const type in enabled) {
      if (enabled[type]) {
        selected.push(`${type.charAt(0).toUpperCase()}${type.slice(1)}`);
      }
    }
    return selected.join(',');
  }
);

export const isStreamLoadingSelector = createSelector(
  streamSelector,
  (stream) =>
    stream.loading || (stream.lastFetch === null && stream.lastError === null)
);

export const streamStatusSelector = createSelector(
  streamSelector,
  isStreamLoadingSelector,
  (stream, isStreamLoading) => {
    const result = {
      showError: false,
      showLoading: false,
      complete: false,
    };

    if (isStreamLoading) {
      result.showLoading = true;
    } else if (stream.lastError) {
      result.showError = true;
    } else {
      result.complete = true;
    }
    return result;
  }
);

export const dynamicCarouselSelector = createSelector(
  unauthStreamEnabledSelector,
  streamSelector,
  (unauthStreamEnabled, stream) => {
    const dynamicCarousel = {};
    if (unauthStreamEnabled && stream.data) {
      Object.assign(
        dynamicCarousel,
        stream.data.sort(streamSortRule).reduce((obj, item) => {
          if (item.carouselItems && !obj.key)
            Object.assign(obj, transformCarousel(item));
          return obj;
        }, {})
      );
    }
    return dynamicCarousel;
  }
);

export const isStaticCarouselSelector = createSelector(
  unauthStreamEnabledSelector,
  streamStatusSelector,
  dynamicCarouselSelector,
  (unauthStreamEnabled, streamStatus, dynamicCarousel) => {
    return Boolean(
      !unauthStreamEnabled ||
        (streamStatus.showError && unauthStreamEnabled) ||
        (streamStatus.complete && !dynamicCarousel.carouselItems)
    );
  }
);

export const streamCarouselSelector = createSelector(
  staticCarouselDataSelector,
  dynamicCarouselSelector,
  isStaticCarouselSelector,
  (staticCarousel, dynamicCarousel, isStatic) => {
    return isStatic ? staticCarousel : dynamicCarousel;
  }
);

export const fetchStreamUrlSelector = createSelector(
  configSelector,
  localeTagSelector,
  streamItemTypeQuerySelector,
  streamSelector,
  signedInSelector,
  unauthStreamEnabledSelector,
  (
    config,
    locale,
    streamItemTypes,
    streamRaw,
    signedIn,
    unauthStreamEnabled
  ) => {
    const query = {
      locale,
      streamItemTypes,
      streamItemStatus: streamStatusesToFetch,
    };
    if (streamRaw.lastSuccess) {
      query.modifiedSince = new Date(streamRaw.lastSuccess).toISOString();
    }
    return !signedIn && unauthStreamEnabled
      ? `/bff/unauth/streamItems?${qs.stringify(query)}`
      : `/bff/streamItems?${qs.stringify(query)}`;
  }
);

export const onboardingStatusSelector = createSelector(
  streamDataSelector,
  (streamData) => {
    const onboardingCard = streamData?.find(
      (item) => item.type === 'onboarding'
    );
    if (onboardingCard) {
      return onboardingCard.onboardingStatus;
    }
    return null;
  }
);

export const hasOrderedSelector = createSelector(
  onboardingStatusSelector,
  mostRecentOrderSelector,
  signedInSelector,
  (onboardingStatus, mostRecentOrder, signedIn) => {
    if (!signedIn) return false;
    if (mostRecentOrder && (mostRecentOrder?.basket?.items?.length ?? false))
      return true;
    if (onboardingStatus) {
      const orderTask = onboardingStatus?.tasks?.find(
        (task) => task.name === 'MOP'
      );
      return Boolean(orderTask.completedOn);
    }
    return false;
  }
);

export const initialRegisteredCardBalanceSelector = createSelector(
  onboardingStatusSelector,
  (onboardingStatus) => onboardingStatus?.initialRegisteredCardBalance ?? null
);

export const showReloadCardSelector = createSelector(
  hasCardSelector,
  initialRegisteredCardBalanceSelector,
  (hasCard, initialRegisteredCardBalance) => {
    // User who starts their account with a balance will not be shown the reload card
    // any balance lower than $5.00 will need to be reloaded.
    return Boolean(hasCard && initialRegisteredCardBalance < 5);
  }
);

export const userJoinedTodaySelector = createSelector(
  joinDateSelector,
  (joinDate) =>
    Boolean(joinDate && joinDate.toDateString() === new Date().toDateString())
);

export const shouldShowWelcomeOverlaySelector = createSelector(
  hasFetchedOnboardingFlagsSelector,
  onboardingFlagsSelector,
  configSelector,
  userJoinedTodaySelector,
  svcCardsLoadingSelector,
  initialRegisteredCardBalanceSelector,
  (
    hasFetchedOnboardingFlags,
    onboardingFlags,
    config,
    userJoinedToday,
    svcCardsLoading,
    initialRegisteredCardBalance
  ) => {
    if (
      !hasFetchedOnboardingFlags ||
      svcCardsLoading ||
      initialRegisteredCardBalance === null ||
      !userJoinedToday
    ) {
      return false;
    }
    const haveShown = onboardingFlags?.data?.didShowWelcomeOverlay ?? false;
    const welcomeOverlayEnabled = config?.welcomeOverlayEnabled;

    return !haveShown && welcomeOverlayEnabled;
  }
);

export const welcomeOverlaySelector = createSelector(
  /*
  1. for digital cards show "loadCardOverlay" for both mobile or desktop view
  2. for physcial cards show:
     * "loadCardOverlay" if balance less than $5
     * "orderOverlay" with only "Order ahead" CTA for desktop view
     * "orderOverlay" with "Order ahead" AND "Pay in store" CTAs for mobile view
*/
  orderingEnabledSelector,
  showReloadCardSelector,
  hasOrderedSelector,
  shouldShowWelcomeOverlaySelector,
  isPrimarySvcCardDigitalSelector,
  (
    startOrderEnabled,
    showReloadCard,
    hasOrdered,
    shouldShowWelcomeOverlay,
    isPrimarySvcCardDigital
  ) => {
    if (shouldShowWelcomeOverlay) {
      if (showReloadCard) return 'loadCardOverlay';
      else if (!hasOrdered && startOrderEnabled && !isPrimarySvcCardDigital)
        return 'orderOverlay';
      return null;
    }
    return null;
  }
);

export const seenCompletedTasksSelector = createSelector(
  streamSelector,
  (stream) => stream.onboardingTasksSeenCompleted
);

export const onboardingTasksSelector = createSelector(
  streamSelector,
  (stream) => stream.onboardingTasks
);

export const allTasksCompletedSelector = createSelector(
  onboardingTasksSelector,
  seenCompletedTasksSelector,
  (onboardingTasks, onboardingTasksSeenCompleted) => {
    if (onboardingTasksSeenCompleted && onboardingTasks) {
      return isEqual(onboardingTasks, onboardingTasksSeenCompleted);
    }
    return false;
  }
);
