import { defer, find } from 'lodash';

import { geocodeLocation } from 'shared/app/utils/geocode-location';
import { getBffBaseUrl } from 'shared/app/utils/config-helper';
import { getPositionFromMapInstance } from 'shared/app/utils/map-utils';
import { parseResponseOrThrow } from 'shared/app/utils/fetch-utils';
import { showUnexpectedErrorNotification } from 'shared/app/shell';

import {
  expandedStoreActiveSelector,
  expandedStoreNumberSelector,
  fetchParametersSelector,
  fetchUrlSelector,
  inMenuStoreLocatorSelector,
  locationStateSelector,
  mapInstanceSelector,
} from '../selectors';

import {
  REQUEST_LOCATIONS,
  REQUEST_LOCATIONS_SUCCESS,
  REQUEST_LOCATIONS_ERROR,
  UPDATE_LOCATIONS,
  UPDATE_LOCATIONS_NEAR_STORE,
  SELECT_LOCATION,
  FETCH_ERROR,
  SET_PLACE_NAME,
  PLACE_NAME_NOT_FOUND,
  HIGHLIGHT_LOCATION,
  UNHIGHLIGHT_LOCATION,
  FETCH_AT_PLACENAME,
  FAVORITE_LOCATION_PENDING,
  FAVORITE_LOCATION_SUCCESS,
  FAVORITE_LOCATION_FAILURE,
} from './types';

export const setPlaceName = (placeName) => {
  return (dispatch) => {
    geocodeLocation({
      location: placeName,
      success: (coordinates) => {
        dispatch({
          type: FETCH_AT_PLACENAME,
          payload: { placeName, coordinates },
        });
      },
      error: (err) => {
        if (err.placeNameNotFound) {
          dispatch({ type: PLACE_NAME_NOT_FOUND });
        }
      },
      fallback: () => {
        dispatch({ type: SET_PLACE_NAME, payload: { placeName } });
      },
    });
  };
};

// The map should never move when the user selects a store by clicking on a
// marker, this works along with 'override' to keep the map in its place.
export const selectLocation = (
  locationNumber,
  {
    location,
    expanded = false,
    shouldScroll = false,
    preventMovement = false,
  } = {
    expanded: false,
    shouldScroll: false,
    preventMovement: false,
  }
) => {
  return (dispatch, getState) => {
    const mapInstance = mapInstanceSelector(getState());
    const mapPosition = getPositionFromMapInstance(mapInstance);

    dispatch({
      type: SELECT_LOCATION,
      payload: {
        locationNumber,
        mapPosition,
        location,
        expanded,
        shouldScroll,
        preventMovement,
      },
    });
  };
};

// APIs used:
//   delete: OAPI_DOCS/index.php?title=Delete_Favorite_Store
//   add/update: OAPI_DOCS/index.php?title=Add/Update_Favorite_Store
export const makeLocationFavorite = (location) => {
  return (dispatch, getState, { apiFetch }) => {
    const baseUrl = getBffBaseUrl();
    const isFavorite = !location?.isFavorite;
    const storeFacilityNumber = location.store.storeNumber;
    const storeNumber =
      storeFacilityNumber?.indexOf('-') > 0
        ? storeFacilityNumber.substring(0, storeFacilityNumber.indexOf('-'))
        : storeFacilityNumber;

    dispatch({
      type: FAVORITE_LOCATION_PENDING,
      payload: {
        storeNumber: storeFacilityNumber,
        isFavorite,
      },
    });

    const url = isFavorite
      ? `/bff/stores/favorites`
      : `/bff/stores/favorites/${storeNumber}`;
    const options = {
      method: isFavorite ? 'POST' : 'DELETE',
      body: isFavorite ? { storeNumber } : null,
    };

    return apiFetch(`${baseUrl}${url}`, options)
      .then(() =>
        dispatch({
          type: FAVORITE_LOCATION_SUCCESS,
          payload: {
            storeNumber: storeFacilityNumber,
            isFavorite,
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: FAVORITE_LOCATION_FAILURE,
          error,
          payload: {
            storeNumber: storeFacilityNumber,
            isFavorite: !isFavorite,
          },
        })
      );
  };
};

export const highlightLocation = (locationNumber) => {
  return { type: HIGHLIGHT_LOCATION, locationNumber };
};

export const unhighlightLocation = () => {
  return { type: UNHIGHLIGHT_LOCATION };
};

const resolveSelectedStoreNumber = ({
  stores,
  inMenuStoreLocator,
  queryStoreNumber,
}) => {
  const firstStoreNumber = stores?.[0]?.store?.storeNumber;

  if (queryStoreNumber) {
    return queryStoreNumber;
  }

  if (inMenuStoreLocator) {
    const firstMopEligibleStore = find(stores.store, {
      open: true,
      mobileOrdering: { availability: 'READY' },
    });
    return firstMopEligibleStore
      ? firstMopEligibleStore.storeNumber
      : firstStoreNumber;
  }
  return firstStoreNumber;
};

export const fetchLocations = () => {
  return (dispatch, getState, { fetch }) => {
    const baseUrl = getBffBaseUrl();
    const state = getState();
    const locationQuery = fetchParametersSelector(state);
    const url = fetchUrlSelector(state);
    const expandedStoreNumber = expandedStoreNumberSelector(state);
    const isExpanded = expandedStoreActiveSelector(state);

    if (!url) {
      return null;
    }

    dispatch({ type: REQUEST_LOCATIONS, payload: { url, locationQuery } });

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        // Add xhr header so bff/locations can attempt to limit requests to "local" ones.
        'X-Requested-With': 'XMLHttpRequest',
      },
      credentials: 'same-origin',
    };

    return fetch(`${baseUrl}${url}`, options)
      .then(parseResponseOrThrow)
      .then((stores) => {
        const newState = getState();

        dispatch({ type: REQUEST_LOCATIONS_SUCCESS });

        // check whether this is the latest query
        // if not, it means there's a newer query
        // pending. By stopping here, we avoid
        // unnecessary thrashing of the UI.
        const currentQueryUrl =
          locationStateSelector(newState).locationQueryUrl;

        if (url !== currentQueryUrl) {
          return;
        }

        const inMenuStoreLocator = inMenuStoreLocatorSelector(newState);
        const selectedStoreNumber = resolveSelectedStoreNumber({
          stores,
          inMenuStoreLocator,
          queryStoreNumber: locationQuery.storeNumber,
        });

        dispatch({
          type: UPDATE_LOCATIONS,
          payload: {
            stores,
            selectedStoreNumber,
            expandedStoreNumber: isExpanded ? expandedStoreNumber : null,
          },
        });
      })
      .catch((err) => {
        const error = {
          status: err.status,
          statusText: err.message,
        };
        dispatch({ type: REQUEST_LOCATIONS_ERROR });
        dispatch({ type: FETCH_ERROR, err: error });
        if (error.status >= 500) {
          defer(() => dispatch(showUnexpectedErrorNotification()));
        }
      });
  };
};

// Important notes regarding this rather obscure feature and code path
// example request that will result in a call to this Action:
// (www) /store-locator/store/5340-1014/mill-ave-and-5th-street-420-s-mill-avenue-104-tempe-az-852812828-us
// requests of this type are generated by expanded-store-url utilities of store locator
// store number and "slug" are included in the location (store) payloads returned from Orchestra
// these request URLs are associated with the expanded store info button

export const fetchLocationsNearStore = (storeNumber) => {
  return (dispatch, getState, { fetch }) => {
    const baseUrl = getBffBaseUrl();
    const fetchUrl = `/bff/locations/near-store/${storeNumber}`;

    dispatch({
      type: REQUEST_LOCATIONS,
      payload: { fetchUrl, locationQuery: {} },
    });

    return fetch(`${baseUrl}${fetchUrl}`)
      .then(parseResponseOrThrow)
      .then((stores) => {
        dispatch({
          type: UPDATE_LOCATIONS_NEAR_STORE,
          payload: {
            stores,
            selectedStoreNumber: storeNumber,
          },
        });
      })
      .catch((err) => {
        const error = {
          status: err.status,
          statusText: err.message,
        };
        dispatch({ type: REQUEST_LOCATIONS_ERROR });
        dispatch({ type: FETCH_ERROR, err: error });
        if (error.status >= 500) {
          defer(() => dispatch(showUnexpectedErrorNotification()));
        }
      });
  };
};
