import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy } from 'lodash';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import { signedInSelector } from 'shared/app/bundles/user';
import {
  locationsToDisplaySelector,
  hideLocationsSelector,
  selectedStoreNumberSelector,
  shouldScrollSelector,
} from '../../state/selectors';

import { selectLocation } from '../../state/actions/location';

import TabbedLocationList from './tabbed-location-list';
import GroupedLocationList from './grouped-location-list';

import LocationCard from '../location-card';

// eslint-disable-next-line max-statements
const LocationList = () => {
  const locationRefs = {};
  const dispatch = useDispatch();

  const locationsToDisplay = useSelector(locationsToDisplaySelector);
  const hideLocations = useSelector(hideLocationsSelector);
  const didClickMapMarker = useSelector(shouldScrollSelector);
  const isUserSignedIn = useSelector(signedInSelector);
  const selectedStoreNumber = useSelector(selectedStoreNumberSelector);
  const isMobileViewport = useSelector(isMobileViewportSelector);

  const foundSelectedStore = locationsToDisplay.find(
    (storeLocation) => storeLocation.store.storeNumber === selectedStoreNumber
  );

  // If no Selected Store
  // Or when amenities are filtered out, the previously selected store may no longer appear in the list.
  // Therefore, we need to select the first store from the "filtered list".
  if (!foundSelectedStore && locationsToDisplay.length > 0) {
    const groups = groupBy(locationsToDisplay, 'recommendationReason');
    const firstFavoriteLocation = groups?.FAVORITE?.[0];
    const firstNearbyLocation = groups?.NEARBY?.[0];
    const newSelectedLocation = firstFavoriteLocation ?? firstNearbyLocation;

    if (newSelectedLocation?.store?.storeNumber) {
      dispatch(
        selectLocation(newSelectedLocation.store.storeNumber, {
          location: locationsToDisplay[0],
          userOverride: false,
        })
      );
    }
  }

  const scrollToActiveLocation = ({ storeNumber, categoryToScrollIn }) => {
    const listItemRef = Object.keys(locationRefs).find((locationRefKey) =>
      locationRefKey.includes(`${categoryToScrollIn || ''}-${storeNumber}`)
    );

    // Adding a delay to prevent a racing condition when switching between tabs
    setTimeout(() => {
      locationRefs[listItemRef]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 500);
  };

  useEffect(() => {
    // The below segment is only used when rendering the flatList view
    if (!isUserSignedIn) {
      scrollToActiveLocation({
        storeNumber: selectedStoreNumber,
      });
    }
  }, [selectedStoreNumber, didClickMapMarker]);

  const renderCard = (options) => {
    const { location, isActive, categoryName } = options;

    const refName = `storeCard-${categoryName ? `${categoryName}-` : ''}${
      location.store.storeNumber
    }`;

    return (
      <LocationCard
        isActive={isActive}
        key={location.store.storeNumber}
        location={location}
        ref={(el) => Object.assign(locationRefs, { [refName]: el })}
      />
    );
  };

  const renderTabbedLocationList = () => {
    return (
      <TabbedLocationList
        locations={locationsToDisplay}
        renderCard={renderCard}
        scrollToActiveLocation={scrollToActiveLocation}
        selectedStoreNumber={selectedStoreNumber}
      />
    );
  };

  const renderGroupedLocationList = () => {
    return (
      <GroupedLocationList
        locations={locationsToDisplay}
        renderCard={renderCard}
        scrollToActiveLocation={scrollToActiveLocation}
        selectedStoreNumber={selectedStoreNumber}
      />
    );
  };

  const renderFlatLocationList = () => {
    return locationsToDisplay.map((location, idx) => {
      const isActive = location.store.storeNumber === selectedStoreNumber;

      return renderCard({
        isActive,
        location,
        listPosition: idx,
      });
    });
  };

  const groupedRenderMethod = isMobileViewport
    ? renderTabbedLocationList
    : renderGroupedLocationList;

  const listItems = isUserSignedIn
    ? groupedRenderMethod()
    : renderFlatLocationList();

  if (locationsToDisplay.length === 0 || hideLocations) {
    return null;
  }

  return <div data-e2e="locationList">{listItems}</div>;
};

export default LocationList;
