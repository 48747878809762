import React from 'react';
import { useIntl } from 'react-intl';

import LocatorMap from '../locator-map';
import LocatorControls from '../locator-controls';
import LocationCardList from '../location-card-list';

import styles from './styles.cssm';
import messages from './messages';

const StoreLocatorPage = () => {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <h1 className="hiddenVisually">{formatMessage(messages.title)}</h1>

      <div className={`${styles.locatorControls}`}>
        <LocatorControls />
      </div>

      <div
        className={`flex position-relative overflow-hidden flex-grow ${styles.base}`}
      >
        <div className={`flex flex-column ${styles.mapContainer}`}>
          <LocatorMap />
        </div>

        <div
          className={`flex flex-column height-100 lg-ml0 overflow-hidden
    ${styles.locationsContainer}`}
        >
          <LocationCardList />
        </div>
      </div>
    </React.Fragment>
  );
};

export default StoreLocatorPage;
