import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import { doLogin } from 'shared/app/shell';

import messages from '../../messages';

export const SIGN_IN_REQUIRED_DIALOG_ID = 'SignInRequiredDialogId';

const SignInRequiredDialog = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return (
    <DialogWithMessages
      confirmText={formatMessage(sharedCallsToAction.signin)}
      onConfirm={() => dispatch(doLogin())}
    >
      <Heading id={SIGN_IN_REQUIRED_DIALOG_ID} tagName="h2">
        {formatMessage(messages.signInRequired)}
      </Heading>
    </DialogWithMessages>
  );
};

export default SignInRequiredDialog;
