import {
  GET_PRE_ORDER_PICKUP_ESTIMATE,
  GET_PRE_ORDER_PICKUP_ESTIMATE_SUCCESS,
  GET_PRE_ORDER_PICKUP_ESTIMATE_ERROR,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PRE_ORDER_PICKUP_ESTIMATE:
      return {
        loading: true,
      };

    case GET_PRE_ORDER_PICKUP_ESTIMATE_SUCCESS:
      const { locationId: shortStoreNumber, ...other } = action.payload;
      return {
        ...other,
        shortStoreNumber,
        loading: false,
      };
    case GET_PRE_ORDER_PICKUP_ESTIMATE_ERROR:
      return {
        loading: false,
      };
  }
  return state;
};
