import React, { forwardRef } from 'react';

import styles from './styles.cssm';

const LocationCardContainer = forwardRef(
  ({ className, children, ...otherProps }, ref) => {
    return (
      <article
        className={`${styles.base} ${className || ''}`}
        ref={ref}
        {...otherProps}
      >
        {children}
      </article>
    );
  }
);

export default LocationCardContainer;
