import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import CategoryHeading from '../category-heading';
import Features from './features';
import messages from './messages';

const StorePickUpOptions = ({ pickUpOptions }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <CategoryHeading>
        {formatMessage(messages.orderAndPickupOptionsHeading)}
      </CategoryHeading>
      <Features features={pickUpOptions} />
    </div>
  );
};

StorePickUpOptions.propTypes = {
  pickUpOptions: PropTypes.array,
};

export default StorePickUpOptions;
