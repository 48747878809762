import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { useIntl } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import SessionCheck from 'shared/app/components/session-check';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import { removeCard } from '../../state/actions/cards-management-remove-card';

import messages from './messages';

export const REMOVE_CARD_POST_TRANSFER_DIALOG_ID =
  'RemoveCardAfterTransferDialogId';

const RemoveCardAfterTransferDialog = ({
  cardNumber,
  isDigital,
  sourceCardId,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  return (
    <SessionCheck>
      <DialogWithMessages
        cancelText={formatMessage(sharedCallsToAction.remove)}
        confirmButtonProps={{ 'data-e2e': 'removeCardConfirmButton' }}
        confirmText={formatMessage(sharedCallsToAction.keep)}
        onCancel={() => dispatch(removeCard(sourceCardId))}
        onConfirm={() => {
          dispatch(push('/account/cards'));
        }}
      >
        <Heading
          id={REMOVE_CARD_POST_TRANSFER_DIALOG_ID}
          size="lg"
          tagName="h2"
        >
          {formatMessage(messages.removeCardAfterTransferHeading)}
        </Heading>
        <p className="pt3" data-e2e="remove-card-after-transfer-detail">
          {formatMessage(messages.removeCardAfterTransferDetail, {
            lastFourDigits: cardNumber.slice(-4),
          })}
        </p>
        {!isDigital && (
          <p className="pt3" data-e2e="remove-card-physical-notes">
            {formatMessage(messages.removeCardAfterTransferPhysicalCardNotes)}
          </p>
        )}
      </DialogWithMessages>
    </SessionCheck>
  );
};

RemoveCardAfterTransferDialog.propTypes = {
  cardNumber: PropTypes.string.isRequired,
  isDigital: PropTypes.bool.isRequired,
  sourceCardId: PropTypes.string.isRequired,
};

export default RemoveCardAfterTransferDialog;
