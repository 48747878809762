import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';

import {
  REMOVE_FAVORITE_PRODUCT,
  REMOVE_FAVORITE_PRODUCT_ERROR,
  REMOVE_FAVORITE_PRODUCT_SUCCESS,
  SAVE_FAVORITE_PRODUCT,
  SAVE_FAVORITE_PRODUCT_ERROR,
  SAVE_FAVORITE_PRODUCT_SUCCESS,
  FETCH_FAVORITE_PRODUCTS,
  FETCH_FAVORITE_PRODUCTS_SUCCESS,
  FETCH_FAVORITE_PRODUCTS_ERROR,
} from './types';
import {
  ADD_FAVORITE_PRODUCT,
  DELETE_FAVORITE_PRODUCT,
  GET_FAVORITE_PRODUCTS,
} from '../../../universal/gql-operation-ids';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { selectedStoreShortNumberSelector } from 'shared/app/state/selectors/ordering';
import {
  addNotification,
  showUnexpectedErrorNotification,
} from 'shared/app/shell';
import { favoriteProductsSelector } from '../selectors';
import { transformToFavoritingShape } from '../../util/transform-to-favoriting-shape';

export const fetchFavorites =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    const locale = localeTagSelector(getState());
    const storeNumber = selectedStoreShortNumberSelector(getState());
    dispatch({
      type: FETCH_FAVORITE_PRODUCTS,
      payload: { locale, storeNumber },
    });

    return gqlFetch({
      operationId: GET_FAVORITE_PRODUCTS,
      variables: { locale, storeNumber },
    })
      .then((payload) => {
        dispatch({
          type: FETCH_FAVORITE_PRODUCTS_SUCCESS,
          payload: payload.favoriteProducts,
        });
        return payload;
      })
      .catch((error) => {
        dispatch({ type: FETCH_FAVORITE_PRODUCTS_ERROR, error });
      });
  };

export const saveFavoriteProduct =
  ({ item, name }) =>
  (dispatch, getState, { gqlFetch }) => {
    const locale = localeTagSelector(getState());
    const favoritesList = favoriteProductsSelector(getState());
    const foundFavoriteItem = favoritesList.find((favorite) => {
      const transformedFavorite = transformToFavoritingShape(favorite);
      return isEqual(item, transformedFavorite);
    });

    const addFavoriteSuccess = (
      <FormattedMessage
        defaultMessage="{name} added to favorites"
        description="Message displayed when a user adds a new favorite product"
        id="ordering.notifications.addFavoriteProduct"
        values={{
          name,
        }}
      />
    );
    if (foundFavoriteItem) {
      dispatch(addNotification(addFavoriteSuccess));
      return Promise.resolve({
        createFavoriteProduct: {
          id: foundFavoriteItem?.id,
        },
      });
    }
    dispatch({ type: SAVE_FAVORITE_PRODUCT });
    return gqlFetch({
      operationId: ADD_FAVORITE_PRODUCT,
      variables: {
        locale,
        item,
      },
    })
      .then((result) => {
        dispatch({
          type: SAVE_FAVORITE_PRODUCT_SUCCESS,
          payload: result.createFavoriteProduct,
        });
        dispatch(addNotification(addFavoriteSuccess));
        return result;
      })
      .catch((error) => {
        dispatch({
          type: SAVE_FAVORITE_PRODUCT_ERROR,
          payload: error,
        });

        dispatch(
          showUnexpectedErrorNotification({
            useErrorLoadingIndicator: true,
          })
        );
        // re-throw error so action-creator consumer can react to this failing
        throw error;
      });
  };

export const deleteFavoriteProduct =
  ({ favoriteProductId, name }) =>
  (dispatch, getState, { gqlFetch }) => {
    const removeFavoriteSuccess = (
      <FormattedMessage
        defaultMessage="{name} removed from favorites"
        description="Message displayed when a user removes a favorite product"
        id="ordering.notifications.removeFavoriteProduct"
        values={{
          name,
        }}
      />
    );
    dispatch({ type: REMOVE_FAVORITE_PRODUCT });
    return gqlFetch({
      operationId: DELETE_FAVORITE_PRODUCT,
      variables: {
        favoriteProductId,
      },
    })
      .then((result) => {
        dispatch({
          type: REMOVE_FAVORITE_PRODUCT_SUCCESS,
          payload: result.removeFavoriteProduct,
        });
        dispatch(addNotification(removeFavoriteSuccess));
      })
      .catch((error) => {
        dispatch({
          type: REMOVE_FAVORITE_PRODUCT_ERROR,
          payload: error,
        });

        dispatch(
          showUnexpectedErrorNotification({
            useErrorLoadingIndicator: true,
          })
        );
        // re-throw error so action-creator consumer can react to this failing
        throw error;
      });
  };
