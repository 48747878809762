import {
  FETCH_STREAM,
  FETCH_STREAM_SUCCESS,
  ONBOARDING_COMPLETED_TASK_SEEN,
} from '../actions/types';
import {
  RELOAD_SVC_CARD_BALANCE_SUCCESS,
  ADD_SVC_CARD_SUCCESS,
  CLOSE_PAY_OVERLAY,
} from 'shared/app/bundles/svc-cards/actions';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import {
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_GUEST_SUCCESS,
} from 'ordering/app/state/actions/types';

const { initialState, reducer } = createSingleResourceReducer({
  idProp: 'key',
  startAction: FETCH_STREAM,
  markStaleWhen: [
    RELOAD_SVC_CARD_BALANCE_SUCCESS,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_GUEST_SUCCESS,
    ADD_SVC_CARD_SUCCESS,
    CLOSE_PAY_OVERLAY,
  ],
});

const streamReducer = (state = initialState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case ONBOARDING_COMPLETED_TASK_SEEN: {
      const seenTasks = state.onboardingTasksSeenCompleted || [];
      let onboardingTasksSeenCompleted;
      if (seenTasks.indexOf(action.name) < 0) {
        onboardingTasksSeenCompleted = [...seenTasks];
        onboardingTasksSeenCompleted.push(action.name);
        onboardingTasksSeenCompleted.sort();
      }
      return {
        ...state,
        onboardingTasksSeenCompleted: onboardingTasksSeenCompleted || seenTasks,
      };
    }

    case FETCH_STREAM_SUCCESS: {
      // Since stream "success" actions always merge values it would potentially grow indefinitely
      // unless we clear out the expired ones. So we do that on success as well.
      const now = Date.now();
      state.data = state.data.filter((streamItem) => {
        const { expires } = streamItem;
        if (expires) {
          return new Date(streamItem.expires) > now;
        }
        return true;
      });
      state.data.forEach((streamItem) => {
        if (streamItem.type === 'onboarding' && streamItem.onboardingContent) {
          state.onboardingTasks = streamItem.onboardingContent.items.map(
            (item) => {
              return item.task.name;
            }
          );
          state.onboardingTasks.sort();
        }
      });
    }
  }
  return state;
};

export default streamReducer;
