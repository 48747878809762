import setInitialFieldValues from '@starbucks-web/pattern-library/lib/components/form-container/set-initial-field-values';
import { validateEmail, getValidateLength } from 'shared/app/utils/validators';

import { getValidateAmount } from './validators';
import { getSessionGiftFormData } from './session-storage-gift';
import { getUpdatedRecipientFields } from './recipients';

export const MAX_MESSAGE_LENGTH = 160;
export const DEFAULT_SEND_METHOD = 'email';
export const MAX_RECIPIENTS = 10;

// exported for tests only
export const getGiftBaseFields = ({
  amount,
  defaultGiftAmountOption,
  message,
  profileData,
  senderName,
  senderEmail,
}) => {
  return {
    message: {
      input: {
        value: message || '',
      },
      required: false,
      validator: getValidateLength({
        max: MAX_MESSAGE_LENGTH,
        validateExistence: false,
      }),
    },
    amount: {
      input: {
        value: amount || defaultGiftAmountOption,
      },
    },
    senderName: {
      input: {
        value: senderName || profileData?.firstName || '',
      },
      validator: null,
    },
    senderEmail: {
      input: {
        value: senderEmail || profileData?.email || '',
      },
      validator: validateEmail,
    },
  };
};

// eslint-disable-next-line max-statements, max-params
export const getFormFields = ({
  giftFormData,
  defaultGiftAmountOption,
  customAmountMin,
  customAmountMax,
  updateGiftFormData,
  profileData,
  isCustomFieldActive,
  recipients,
}) => {
  const sessionGiftFormData = getSessionGiftFormData();

  const currentGiftFormData = {
    ...sessionGiftFormData,
    ...giftFormData,
  };

  const { customAmount, senderName, senderEmail, amount, message } =
    currentGiftFormData;

  if (!giftFormData && currentGiftFormData) {
    updateGiftFormData(currentGiftFormData);
  }

  const giftBaseFields = getGiftBaseFields({
    message,
    amount,
    defaultGiftAmountOption,
    senderName,
    senderEmail,
    profileData,
  });

  const customAmountField = {
    customAmount: {
      input: {
        value: customAmount || '',
      },
      validator: getValidateAmount(customAmountMin, customAmountMax),
    },
  };

  const recipientBaseFields = getUpdatedRecipientFields({
    recipients,
    includeFieldShape: true,
  });

  const fields = {
    ...giftBaseFields,
    ...(isCustomFieldActive ? customAmountField : {}),
    ...recipientBaseFields,
  };

  return setInitialFieldValues(fields, {
    amount,
    message,
  });
};
