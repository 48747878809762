export const generateProductOptionsMap = (productDetailsPayload) => {
  const { productOptions } = productDetailsPayload ?? {};
  if (!productOptions) {
    return null;
  }
  const mapByProductNumber = productOptions.reduce((acc, category) => {
    if (category.products) {
      category.products.forEach((product) => {
        return (acc[product.productNumber] = {
          category,
          parentCategory: null,
          product,
          unitOfMeasure: category?.attributes?.unitOfMeasure,
        });
      });
    }
    if (category.children) {
      category.children.forEach((childCategory) => {
        childCategory.products.forEach((product) => {
          return (acc[product.productNumber] = {
            category: childCategory,
            parentCategory: {
              categoryNumber: category.categoryNumber,
              name: category.name,
            },
            product,
            unitOfMeasure: childCategory?.attributes?.unitOfMeasure,
          });
        });
      });
    }
    return acc;
  }, {});
  return mapByProductNumber;
};
