/* MOP TRANSACTION HISTORY */

// order history items
export const FETCH_HISTORY = 'FETCH_HISTORY';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_ERROR = 'FETCH_HISTORY_ERROR';

// set active history item (receipt in overlay)
export const SET_ACTIVE_HISTORY_ITEM_ID = 'SET_ACTIVE_HISTORY_ITEM_ID';

// history receipt
export const FETCH_RECEIPT = 'FETCH_RECEIPT';
export const FETCH_RECEIPT_SUCCESS = 'FETCH_RECEIPT_SUCCESS';
export const FETCH_RECEIPT_ERROR = 'FETCH_RECEIPT_ERROR';

// clear everything
export const CLEAR_HISTORY = 'CLEAR_HISTORY';

/* TIPPING */

export const SET_TIP = 'SET_TIP';
export const SET_TIP_SUCCESS = 'SET_TIP_SUCCESS';
export const SET_TIP_ERROR = 'SET_TIP_ERROR';

export const DELETE_TIP = 'DELETE_TIP';
export const DELETE_TIP_SUCCESS = 'DELETE_TIP_SUCCESS';
export const DELETE_TIP_ERROR = 'DELETE_TIP_ERROR';

/* GIFT HISTORY */

// gift history items
export const FETCH_GIFT_HISTORY = 'FETCH_GIFT_HISTORY';
export const FETCH_GIFT_HISTORY_SUCCESS = 'FETCH_GIFT_HISTORY_SUCCESS';
export const FETCH_GIFT_HISTORY_ERROR = 'FETCH_GIFT_HISTORY_ERROR';

// set active gift history orderId (details in overlay)
export const SET_ACTIVE_GIFT_ORDER_ID = 'SET_ACTIVE_GIFT_ORDER_ID';

// gift order details
export const FETCH_GIFT_ORDER_DETAILS = 'FETCH_GIFT_ORDER_DETAILS';
export const FETCH_GIFT_ORDER_DETAILS_SUCCESS =
  'FETCH_GIFT_ORDER_DETAILS_SUCCESS';
export const FETCH_GIFT_ORDER_DETAILS_ERROR = 'FETCH_GIFT_ORDER_DETAILS_ERROR';

// clear everything gift
export const CLEAR_GIFT_HISTORY = 'CLEAR_GIFT_HISTORY';
