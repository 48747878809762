import { createSelector } from 'reselect';

import { configSelector } from 'shared/app/shell';
import { orderingEnabledSelector } from 'shared/app/state/selectors/config';

export const mainNavLinksConfigSelector = createSelector(
  configSelector,
  orderingEnabledSelector,
  (config, orderingEnabled) => {
    return config?.mainNavLinks?.filter((mainNavItem) => {
      if (orderingEnabled === false && mainNavItem.url === '/menu') {
        return null;
      }
      return mainNavItem;
    });
  }
);
