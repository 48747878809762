import { createSelector } from 'reselect';
import ms from 'milliseconds';

import { routeSelector } from 'shared/app/state/selectors/routes';
import { signedInSelector } from 'shared/app/bundles/user';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { selectedStoreShortNumberSelector } from 'shared/app/state/selectors/ordering';
import { orderingEnabledSelector } from 'shared/app/state/selectors/config';
import { appTimeSelector } from 'shared/app/shell';

import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { mostRecentOrderStatusSelector } from './cart';
import { previousCartSelector } from 'shared/app/state/selectors/ordering';

const MOP = '141';

const getOrderTokenUtil = (previousOrderItem) =>
  previousOrderItem.inStoreOrder?.expressOrder?.orderToken;

const orderingSelector = (state) => state.ordering;
const priorActionSelector = (state) => state.previousAction;

export const previousOrdersStateSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.previousOrders
);

/* eslint-disable max-params */
export const shouldFetchPreviousSelector = createSelector(
  signedInSelector,
  orderingEnabledSelector,
  routeSelector,
  localeTagSelector,
  selectedStoreShortNumberSelector,
  priorActionSelector,
  previousOrdersStateSelector,
  appTimeSelector,
  (
    signedIn,
    orderingEnabled,
    route,
    locale,
    storeNumber,
    priorAction,
    previousState,
    appTime
  ) => {
    const isOnPrevious = route?.includes('/menu/previous');
    const variablesChanged =
      locale !== previousState?.locale ||
      storeNumber !== previousState?.storeNumber;

    const updateNeeded = shouldUpdate(previousState, {
      staleTime: ms.hours(2),
      now: appTime,
    });
    const orderInvalidated =
      priorAction.type === 'INVALIDATE_MOST_RECENT_ORDER';
    return (
      signedIn &&
      orderingEnabled &&
      isOnPrevious &&
      (orderInvalidated || variablesChanged || updateNeeded)
    );
  }
);

// this will be null unless something was just purchased through the web app
/* eslint-enable max-params */
export const justPurchasedOrderSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.order?.previous?.pricing ?? null
);

// this will be undefined unless something was just purchased through the web app
export const localMostRecentOrderTokenSelector = createSelector(
  justPurchasedOrderSelector,
  (justPurchasedOrder) =>
    justPurchasedOrder?.transaction?.orderId ?? justPurchasedOrder?.orderId
);

// this will be undefind unless something was just purchased through the web app
export const localMostRecentOrderAmountSelector = createSelector(
  justPurchasedOrderSelector,
  (justPurchasedOrder) => justPurchasedOrder?.summary?.price
);

// this will be undefined unless something was just purchased through the web app
export const localMostRecentOrderStoreSelector = createSelector(
  justPurchasedOrderSelector,
  (justPurchasedOrder) => justPurchasedOrder?.store
);

// wraps previous cart (cart items after purchase) in a basket object
export const localMostRecentOrderSelector = createSelector(
  previousCartSelector,
  (items) => ({ basket: { items } })
);

// filter out any items in an order with invalid product options
// (i.e. productNumber for the option is null or 81 - something only possible in-store)
// or have a form availability of 'NotOrderable'
const filterUnpurchaseableProductOptions = (items = []) => {
  return items.filter((item) => {
    let hasUnpurchaseableProductOption = false;
    if (item.childItems.length > 0) {
      const invalidChildItem = item.childItems.find((childItem) => {
        return (
          childItem?.product?.productNumber === null ||
          childItem?.product?.productNumber === 81 ||
          childItem?.product?.form?.availability === 'NotOrderable'
        );
      });
      hasUnpurchaseableProductOption = Boolean(invalidChildItem);
    }
    return !hasUnpurchaseableProductOption;
  });
};

// list of all fetched previous orders that have at least one basket item
// filtering out unpurchaseable product options
export const remotePreviousOrdersSelector = createSelector(
  previousOrdersStateSelector,
  (previousState) =>
    previousState?.data
      ?.map((orderItem) => {
        const items = filterUnpurchaseableProductOptions(
          orderItem?.basket?.items
        );

        return {
          ...orderItem,
          ...{
            basket: {
              ...orderItem.basket,
              items,
            },
          },
        };
      })
      .filter((orderItem) => Boolean(orderItem?.basket?.items?.length)) ?? []
);

// returns a fetched recent order if it exists (i.e. matches the order token of something just ordered)
// otherwise undefined; it should only return non-in-store orders
export const remoteMostRecentOrderSelector = createSelector(
  remotePreviousOrdersSelector,
  localMostRecentOrderTokenSelector,
  (remotePreviousOrders, localMostRecentOrderToken) =>
    remotePreviousOrders.find((order) => {
      return (
        order.orderType === MOP &&
        getOrderTokenUtil(order) === localMostRecentOrderToken
      );
    })
);

// returns a fetched recent order if it exists, or, if not, something just ordered if it exists,
// otherwise undefined
export const mostRecentOrderSelector = createSelector(
  localMostRecentOrderSelector,
  remoteMostRecentOrderSelector,
  (localMostRecentOrder, remoteMostRecentOrder) => {
    return remoteMostRecentOrder || localMostRecentOrder;
  }
);

// returns all fetched previous orders, de-duping anything recently ordered
// that still needs the separate UI treatment
export const previousOrdersSelector = createSelector(
  remotePreviousOrdersSelector,
  remoteMostRecentOrderSelector,
  (remotePreviousOrders, remoteMostRecentOrder) =>
    (remoteMostRecentOrder &&
      remotePreviousOrders.filter((order) => {
        return (
          order.orderType === MOP &&
          getOrderTokenUtil(order) !== getOrderTokenUtil(remoteMostRecentOrder)
        );
      })) ||
    remotePreviousOrders
);

export const shouldShowOrderHistorySelector = createSelector(
  previousOrdersSelector,
  signedInSelector,
  mostRecentOrderStatusSelector,
  (previousOrders, signedIn, mostRecentOrderStatus) =>
    (previousOrders?.length > 0 || Boolean(mostRecentOrderStatus)) &&
    !mostRecentOrderStatus?.guest &&
    signedIn
);
