import { createSelector } from 'reselect';

import { storeLocatorSelectorWithFallback } from './util';

export const selectedFeaturesStateSelector = createSelector(
  storeLocatorSelectorWithFallback,
  (storeLocator) => storeLocator.selectedFeaturesState || []
);

export const hasActiveFiltersSelector = createSelector(
  selectedFeaturesStateSelector,
  (selectedFeaturesState) => Boolean(selectedFeaturesState.length)
);
