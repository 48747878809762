import { goBack } from 'redux-first-history';
import { getPositionFromMapInstance } from '../../../../shared/app/utils/map-utils';
import { inMenuStoreLocatorSelector, mapInstanceSelector } from '../selectors';
import { previousRouteSelector } from 'shared/app/state/selectors/routes';
/*
 * action types
 */
import { SELECT_STORE, COLLAPSE_EXPANDED_STORE } from './types';

/*
 * action creators
 */
export const selectStore = ({ store, expanded = false }) => ({
  type: SELECT_STORE,
  payload: { store, expanded },
});

// Whenever a user collapses the expanded store card they're saying they
// want to see the current map view so we consider this a scenario where
// the user is overriding target map positon. In order to be able to access
// current data to use in that override, we need to have it available to
// the reducer, so we pass it along with the action.
export const collapseExpandedStore =
  ({ intendToOrder = false }) =>
  (dispatch, getState) => {
    const state = getState();
    const inMenuStoreLocator = inMenuStoreLocatorSelector(state);
    const previousRoute = previousRouteSelector(state);
    const mapInstance = mapInstanceSelector(state);
    const mapPosition = getPositionFromMapInstance(mapInstance);
    dispatch({ type: COLLAPSE_EXPANDED_STORE, payload: mapPosition });
    // only want to dispatch GO_BACK in vanilla store locator since menu
    // store locator does not support updating the url to reflect the location
    // and using the browser history to navigate
    if (!inMenuStoreLocator && previousRoute && !intendToOrder) {
      dispatch(goBack());
    }
  };
