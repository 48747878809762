import { createSelector } from 'reselect';
import { configSelector } from './config';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import {
  CURBSIDE_FEATURE_CODE,
  OUTDOOR_FEATURE_CODE,
} from 'shared/universal/pickup-feature-codes';

import { pickupFeatureMap } from 'shared/app/state/selectors/ordering';

const orderingSelector = (state) => state?.ordering?.order;

export const currentPickupOptionSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.pickupOption
);

export const currentSelectedPickupOptionNameSelector = createSelector(
  currentPickupOptionSelector,
  (currentPickupOption) => {
    return pickupFeatureMap[currentPickupOption]?.name;
  }
);

export const previousPickupOptionSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.previous?.pickupOption
);

export const surveyInitCodeSelector = createSelector(
  configSelector,
  localeTagSelector,
  previousPickupOptionSelector,
  (config, localeTag, previousPickupOption) => {
    if (
      previousPickupOption !== CURBSIDE_FEATURE_CODE &&
      previousPickupOption !== OUTDOOR_FEATURE_CODE
    ) {
      return config?.pickupSurveyCodes?.[localeTag]?.[previousPickupOption];
    }
  }
);

export const surveyAvailableSelector = createSelector(
  configSelector,
  surveyInitCodeSelector,
  (config, initCode) => {
    if (!config.pickupSurveyEnabled) {
      return false;
    }
    return typeof initCode === 'string' && initCode !== '';
  }
);
