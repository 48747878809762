import { defineMessages } from 'react-intl';

const messages = defineMessages({
  menuNotAvailable: {
    id: 'locatorMap.menuNotAvailable',
    defaultMessage: 'Menu not available for this store.',
    description:
      "Toast message when user tries to select a store that doesn't work with menu",
  },
});

export default messages;
