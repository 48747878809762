import React from 'react';
import { FormattedMessage } from 'react-intl';

const groupTitles = {
  favorites: (
    <FormattedMessage
      defaultMessage="Favorites"
      description="Heading for favorite section of location card list"
      id="storeLocator.locationCardListSection.favorite"
    />
  ),
  previous: (
    <FormattedMessage
      defaultMessage="Previous"
      description="Heading for previous section of location card list"
      id="storeLocator.locationCardListSection.previous"
    />
  ),
  nearby: (
    <FormattedMessage
      defaultMessage="Nearby"
      description="Heading for nearby section of location card list"
      id="storeLocator.locationCardListSection.nearby"
    />
  ),
};

export default (sectionKey) => groupTitles[sectionKey];
