import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pickupOptions: {
    id: 'pickup.options',
    defaultMessage: 'Pickup options',
    description: 'Heading for pickup option page',
  },
  checkInCTA: {
    id: 'pickup.checkInCTA',
    description: 'Call to action to check in',
    defaultMessage: 'Check in',
  },
  errorMessage: {
    id: 'pickup.errorMessage',
    defaultMessage:
      'Something went wrong on our end. If you have placed an order, park in curbside pick up.',
    description: 'Message when there is an error checking in',
  },
  store: {
    id: 'pickup.store',
    defaultMessage: 'Store: {storeName}',
    description: 'Label for store used for pick up',
  },
  successHeading: {
    id: 'pickup.successHeading',
    defaultMessage: 'See you soon!',
    description: 'Success heading when submitting pick up form',
  },
  successMessage: {
    id: 'pickup.successMessage',
    defaultMessage:
      'A barista will bring your order out to you. Return to the app for order details and tip options.',
    description: 'Success message when submitting pick up form',
  },
  surveyCta: {
    id: 'pickup.surveyCta',
    defaultMessage: 'Take a survey',
    description: 'Cta for user to take survey',
  },
  surveyPrompt: {
    id: 'pickup.surveyPrompt',
    defaultMessage: 'How was your pick up experience?',
    description: 'Prompt for user to take survey',
  },
  surveyThanks: {
    id: 'pickup.surveyThanks',
    defaultMessage: 'Thanks for taking our survey 💚',
    description: 'Message to thank user for taking survey',
  },
  curbsideUnavailable: {
    id: 'pickup.curbsideUnavailable',
    defaultMessage:
      'Curbside is not available right now. Head to the entryway or drive-thru to pick up your order. See you soon!',
    description:
      'Message to inform the customer that curbside pick up is not available at the selected store.',
  },
  outdoorUnavailable: {
    id: 'pickup.outdoorUnavailable',
    defaultMessage:
      'Outdoor pickup is not available right now, head to the store or drive-thru to pick up your order. See you soon!',
    description:
      'Message to inform the customer that outdoor pick up is not available at the selected store.',
  },
  maskUp: {
    id: 'pickup.maskUp',
    defaultMessage:
      'Please follow CDC guidance and local mandates for facial coverings when visiting our stores.',
    description:
      'Message to inform the customer that they should wear their mask when picking up their order.',
  },
});

export default messages;
