import createActionTypes from 'shared/app/state/create-action-types';

export const SHOW_GENERIC_PASSWORD_ERROR = 'SHOW_GENERIC_PASSWORD_ERROR';
export const HIDE_GENERIC_PASSWORD_ERROR = 'HIDE_GENERIC_PASSWORD_ERROR';

export const {
  CONFIRM_EMAIL_CODE,
  CONFIRM_EMAIL_CODE_ERROR,
  CONFIRM_EMAIL_CODE_SUCCESS,

  FETCH_USER_COMMUNICATION_PREFERENCES,
  FETCH_USER_COMMUNICATION_PREFERENCES_SUCCESS,
  FETCH_USER_COMMUNICATION_PREFERENCES_ERROR,

  UPDATE_USER_COMMUNICATION_PREFERENCES,
  UPDATE_USER_COMMUNICATION_PREFERENCES_SUCCESS,
  UPDATE_USER_COMMUNICATION_PREFERENCES_ERROR,

  SAVE_PASSWORD,
  SAVE_PASSWORD_SUCCESS,
  SAVE_PASSWORD_ERROR,

  REQUEST_EMAIL_VALIDATION_CODE,
  REQUEST_EMAIL_VALIDATION_CODE_SUCCESS,
  REQUEST_EMAIL_VALIDATION_CODE_ERROR,

  ADD_MFA_PHONE,
  ADD_MFA_PHONE_SUCCESS,
  ADD_MFA_PHONE_ERROR,

  EDIT_MFA_PHONE,
  EDIT_MFA_PHONE_SUCCESS,
  EDIT_MFA_PHONE_ERROR,

  DELETE_MFA_PHONE,
  DELETE_MFA_PHONE_SUCCESS,
  DELETE_MFA_PHONE_ERROR,
} = createActionTypes([
  'CONFIRM_EMAIL_CODE',
  'FETCH_USER_COMMUNICATION_PREFERENCES',
  'UPDATE_USER_COMMUNICATION_PREFERENCES',
  'SAVE_PASSWORD',
  'REQUEST_EMAIL_VALIDATION_CODE',
  'ADD_MFA_PHONE',
  'EDIT_MFA_PHONE',
  'DELETE_MFA_PHONE',
]);
