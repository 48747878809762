import { validateExistence } from '@starbucks-web/pattern-library/lib/components/form-container/validators';

// checks for whole integers
const amountRegex = /^\d+$/;

export const getValidateAmount =
  (min, max) =>
  ({ value }) => {
    const isValid = amountRegex.test(value);
    const errorDetails = {
      existence: validateExistence({ value: value.toString() }).error,
      characters: !isValid || value < min || value > max,
    };

    return {
      error: Object.keys(errorDetails).some((key) => errorDetails[key]),
      errorDetails,
    };
  };
