import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@starbucks-web/pattern-library/lib/components/button';

import messages from './messages';

const PickupSurvey = ({ surveyInitCode }) => {
  const [hasSeenSurvey, setHasSeenSurvey] = useState(false);

  const showPickupSurvey = () => {
    const script = document.createElement('script');
    script.innerHTML = surveyInitCode;
    document.body.appendChild(script);
    setHasSeenSurvey(true);
  };

  return hasSeenSurvey ? (
    <p className="color-textBlackSoft text-xxs">
      <FormattedMessage {...messages.surveyThanks} />
    </p>
  ) : (
    <div className="flex flex-wrap justify-center items-center">
      <p className="color-textBlackSoft text-xxs pr2 pb2">
        <FormattedMessage {...messages.surveyPrompt} />
      </p>
      <Button
        className="mb2"
        disabled={hasSeenSurvey}
        onClick={showPickupSurvey}
      >
        <FormattedMessage {...messages.surveyCta} />
      </Button>
    </div>
  );
};

PickupSurvey.propTypes = {
  surveyInitCode: PropTypes.string.isRequired,
};

export default PickupSurvey;
