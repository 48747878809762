import PropTypes from 'prop-types';
import React from 'react';

import LocatorFilterItem from '../locator-filter-item';
import CategoryHeading from '../category-heading';

const LocatorFilterGroup = ({
  filterableFeatures,
  selectedFeatures,
  select,
  unselect,
  className,
  groupTitle,
}) => {
  return (
    <div className={className || ''}>
      {Boolean(groupTitle) && <CategoryHeading>{groupTitle}</CategoryHeading>}
      <ul>
        {filterableFeatures.map((filterItem) => {
          const active = selectedFeatures.indexOf(filterItem.code) !== -1;
          const filterItemProps = {
            ...filterItem,
            active,
            action: active ? unselect : select,
          };
          return (
            <LocatorFilterItem
              key={filterItemProps.code}
              {...filterItemProps}
            />
          );
        })}
      </ul>
    </div>
  );
};

LocatorFilterGroup.propTypes = {
  filterableFeatures: PropTypes.array.isRequired,
  selectedFeatures: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
};

export default LocatorFilterGroup;
