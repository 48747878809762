/* eslint max-params: 0 */
import ms from 'milliseconds';
import { createSelector } from 'reselect';
import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { appTimeSelector } from 'shared/app/shell';
import { pathnameSelector } from 'shared/app/state/selectors/routes';

const expirationsSelector = (state) => state.rewards.expirations;

export const shouldFetchExpirationsSelector = createSelector(
  pathnameSelector,
  expirationsSelector,
  appTimeSelector,
  (pathname, expirations, time) => {
    if (
      pathname === '/account/rewards' &&
      shouldUpdate(expirations, {
        staleTime: ms.minutes(5),
        now: time,
      })
    ) {
      return true;
    }
    return false;
  }
);

export const expirationsDataSelector = createSelector(
  expirationsSelector,
  ({ data }) => data || null
);

export const expiringStarsSortedSelector = createSelector(
  expirationsDataSelector,
  appTimeSelector,
  (expirations, time) => {
    if (!expirations) {
      return null;
    }
    return expirations.reduce(
      (acc, expiration) => {
        if (new Date(expiration.date).getTime() > time) {
          acc.expiringSoon.push(expiration);
        } else {
          acc.expired.push(expiration);
        }
        return acc;
      },
      { expired: [], expiringSoon: [] }
    );
  }
);
