import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import runSequentially from 'shared/app/utils/run-sequentially';
import { addNotification, hideBottomSheet } from 'shared/app/shell';

import {
  TRANSFER_SVC_CARD_BALANCE,
  TRANSFER_SVC_CARD_BALANCE_SUCCESS,
  TRANSFER_SVC_CARD_BALANCE_ERROR,
} from 'shared/app/bundles/svc-cards/actions';
import { TRANSFER_CARD_BALANCE as TRANSFER_CARD_BALANCE_ID } from '../../../universal/gql-operation-ids';

import { svcMessages } from 'shared/app/messages';

import { getNotificationForErrorCode } from '../errors';
import { showCodedErrorNotification } from 'shared/app/utils/show-coded-error-notification';

import RemoveCardAfterTransferDialog, {
  REMOVE_CARD_POST_TRANSFER_DIALOG_ID,
} from '../../components/remove-card-after-transfer-dialog';

export const transferCardBalance =
  ({
    amount,
    sourceCardId,
    targetCardId,
    confirmRemoveAfterwards,
    isDigital,
    openModal,
  }) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: TRANSFER_SVC_CARD_BALANCE });
    gqlFetch({
      operationId: TRANSFER_CARD_BALANCE_ID,
      variables: { amount, sourceCardId, targetCardId },
      includeRisk: true,
    })
      .then((data) => {
        const payload = data.transferCardBalance;
        if (!payload) {
          throw new Error('Failed to transfer svc balance');
        }
        const sourceSvcCard = payload[0];
        const destinationSvcCard = payload[1];

        const openRemoveCard = () => {
          openModal({
            component: RemoveCardAfterTransferDialog,
            ariaLabelledBy: REMOVE_CARD_POST_TRANSFER_DIALOG_ID,
            componentProps: {
              cardNumber: sourceSvcCard.cardNumber,
              isDigital,
              sourceCardId,
            },
          });
        };

        const successMessage = (
          <FormattedMessage
            {...svcMessages.transferBalanceSuccess}
            values={{
              amount: (
                <FormattedNumber
                  currency={destinationSvcCard.balance.currency}
                  style="currency"
                  value={amount}
                />
              ),
              lastFourDigits: destinationSvcCard.cardNumber.slice(-4),
            }}
          />
        );
        runSequentially(
          () =>
            dispatch({
              payload,
              type: TRANSFER_SVC_CARD_BALANCE_SUCCESS,
            }),
          () => dispatch(addNotification(successMessage)),
          () => dispatch(hideBottomSheet()),
          () => {
            if (confirmRemoveAfterwards) {
              openRemoveCard();
            }
          }
        );
      })
      .catch((error) => {
        const { code: errorCode } = error;
        runSequentially(
          () => dispatch({ type: TRANSFER_SVC_CARD_BALANCE_ERROR, error }),
          () =>
            dispatch(
              showCodedErrorNotification(
                { errorCode, isTransfer: { cardId: sourceCardId } },
                getNotificationForErrorCode
              )
            ),
          () => dispatch(hideBottomSheet())
        );
      });
  };
