import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import { FETCH_PREVIOUS_ORDERS } from '../actions/types';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_PREVIOUS_ORDERS,
});

export default (state = initialState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case FETCH_PREVIOUS_ORDERS:
      const { locale, storeNumber } = action.payload;
      return {
        ...state,
        locale,
        storeNumber,
      };
  }
  return state;
};
