import { isFinite } from 'lodash';

// Adapted from http://stackoverflow.com/a/13274361/1127193
const TILE_SIZE = 256;

export const getLatRadians = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;

  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

export const getZoomLevel = (mapPx, fraction) => {
  return Math.floor(Math.log(mapPx / TILE_SIZE / fraction) / Math.LN2);
};

const getBoundsZoomLevel = ({
  locationData,
  mapDimensions,
  maxZoom,
  zoomThreshold,
}) => {
  const northEastLatRadian = getLatRadians(locationData.maxLat);
  const southWestLatRadian = getLatRadians(locationData.minLat);
  const latFraction = (northEastLatRadian - southWestLatRadian) / Math.PI;

  const lngDiff = locationData.maxLng - locationData.minLng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = getZoomLevel(mapDimensions.height, latFraction);
  const lngZoom = getZoomLevel(mapDimensions.width, lngFraction);

  const result = Math.min(latZoom, lngZoom, maxZoom);

  // If resulting zoom level is not a finite number, it means that the map is too small
  // to fit the current locations. This typically means that the browser window is
  // extremely short. For these cases, we return a level just inside the zoom threshold
  // as the best possible value.
  return isFinite(result) ? result : zoomThreshold + 1;
};

export default getBoundsZoomLevel;
