import React from 'react';
import DottedUnderline from '../dotted-underline';

const SummaryLineItems = ({ lineItems }) => {
  return lineItems?.map((item, idx) => {
    const itemStyle =
      item.price < 0 ? 'color-greenStarbucks' : 'color-textBlackSoft';
    const priceStyle =
      item.price < 0 ? 'color-greenStarbucks' : 'color-textBlack';

    return item?.price ? (
      <div className={`${itemStyle} flex mt1`} key={`summary-${idx}`}>
        <div>{item.label}</div>
        <DottedUnderline className="flex-grow" />
        <div className={`${priceStyle} text-bold`} data-e2e={`${item.label}`}>
          {item.priceLabel}
        </div>
      </div>
    ) : null;
  });
};

export default SummaryLineItems;
