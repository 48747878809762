import { defineMessages } from 'react-intl';

const messages = defineMessages({
  storeCardActiveButtonLabel: {
    id: 'locationCard.storeActiveButtonLabel',
    defaultMessage: '{storeName} Selected',
    description: 'Text of button used when store is selected',
  },
  storeCardInactiveButtonLabel: {
    id: 'locationCard.storeInactiveButtonLabel',
    defaultMessage: 'Select {storeName}',
    description: 'Text of button used to select a store',
  },
});

export default messages;
