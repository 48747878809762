import ms from 'milliseconds';
import { createSelector } from 'reselect';

import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { appTimeSelector } from 'shared/app/shell';
import {
  routeParamsSelector,
  routeSelector,
} from 'shared/app/state/selectors/routes';
import { isPartnerSelector } from 'shared/app/bundles/user/state/selectors/profile';
export const giftDetailsStateSelector = (state) => state.gift.giftDetails;

export const giftDetailsCardSelector = createSelector(
  giftDetailsStateSelector,
  routeParamsSelector,
  (giftDetailsState, routeParams) =>
    routeParams && giftDetailsState[routeParams.productNumber]
);

export const starEarnRateSelector = createSelector(
  isPartnerSelector,
  (isPartner) => (isPartner ? 3 : 2)
);

/* eslint-disable max-params */
export const shouldfetchGiftDetailsSelector = createSelector(
  routeSelector,
  giftDetailsCardSelector,
  appTimeSelector,
  (route, giftDetailsCard, time) => {
    // If we aren't on cardName route, don't fetch.
    if (route !== '/gift/:productNumber') {
      return false;
    }

    // Otherwise, fetch if card data is stale.
    return shouldUpdate(giftDetailsCard, {
      staleTime: ms.minutes(15),
      now: time,
    });
  }
);
/* eslint-enable max-params */
