import React from 'react';
import { FormattedMessage } from 'react-intl';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import splitIntoArray from './split-into-array';

const ErrorContent = ({ cardNumber = '' }) => (
  <div className="flex flex-column flex-grow justify-end pb5 px5 sm-px8 sm-pb8">
    <Heading className="text-bold mb3" size="lg" tagName="h2">
      <FormattedMessage
        defaultMessage="Barcode is temporarily down"
        description="Heading on pay screen if barcode fails to load"
        id="accountCards.payOverlay.errorContent.errorHeading"
      />
    </Heading>

    <p className="text-xl mb3">
      <FormattedMessage
        defaultMessage="To pay in store, show the barcode number to your barista 👇"
        description="Text on pay screen if barcode fails to load"
        id="accountCards.payOverlay.errorContent.errorText"
      />
    </p>

    <p className="text-xl text-bold">{splitIntoArray(cardNumber).join(' ')}</p>
  </div>
);

export default ErrorContent;
