import config from 'config';
import readCookie from 'shared/app/utils/read-cookie';
import extractDomain from 'shared/universal/extract-top-level-domain';

// validate legal values as a substitute for HttpOnly
export const validateInMapOriginationSource = (source) => {
  switch (source) {
    case 'apple_maps':
    case 'google_maps':
      return source;
    default:
      return null;
  }
};

// After the 1st read, we disable the tracking flag,
// so that this inMap ordering instance is tracked
// only once, yet can be read multiple times
const disableInMapOrderEventTracking = (
  cookieName,
  { originationSource, track }
) => {
  if (!track) {
    return;
  }

  const location = window.location;
  const domain = extractDomain(location.hostname);
  const maxAge = config.get('inMapOriginationSourceLifetime');

  const updatedCookie = `${cookieName}=${`${originationSource}|false`}; domain=${domain}; path='/'; max-age=${maxAge}; samesite=lax; secure`;

  document.cookie = updatedCookie;
};

export const getInMapOriginationSourceCookie = () => {
  const cookieName = config.get('inMapOriginationSourceCookieName');
  const cookieValue = readCookie(cookieName);
  const payload = cookieValue?.split('|');

  const originationSource = validateInMapOriginationSource(
    payload?.[0] ?? null
  );
  const track = /^true$/i.test(payload?.[1]);
  const cookieContent = { originationSource, track };

  disableInMapOrderEventTracking(cookieName, { ...cookieContent });

  return cookieContent;
};
