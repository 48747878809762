import { authTransform } from './auth-stream-item';
import { unAuthTransform } from './unauth-stream-item';

const filterItems =
  (dedupeCoupons = {}) =>
  (item) => {
    // remove expiring stars card if no expiring stars
    if (
      item.type === 'rewardPointsExpiration' &&
      (item?.rewardPointsExpirationInfo?.expiringPoints ?? 0) < 1
    ) {
      return false;
    }
    // de-dupe items with same rewardCouponCode
    if (!item.rewardCouponCode) {
      return true;
    }
    if (!dedupeCoupons[item.rewardCouponCode]) {
      dedupeCoupons[item.rewardCouponCode] = true;
      return true;
    }
    return false;
  };

export default (isAuthenticated, streamData) => {
  // streamData unauth response is an array of items:
  //
  // [{item1}, {item2}]
  //
  // streamData auth response has nested keys:
  // {
  //   paging: {},
  //   streamItems: [{item1}, {item2}]
  // }
  const streamItems = isAuthenticated
    ? (streamData?.streamItems ?? [])
    : streamData || [];

  return isAuthenticated
    ? streamItems.map(authTransform).filter(filterItems())
    : streamItems.map(unAuthTransform);
};
