import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addBillingAddressSuccess: {
    id: 'accountPaymentMethod.notifications.addBillingAddressSuccess',
    defaultMessage: 'Added your new billing address',
    description:
      'Message displayed when a user successfully adds a billing address',
  },
});

export default messages;
