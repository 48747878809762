import { defineMessages } from 'react-intl';

export default defineMessages({
  outdoorMessage: {
    id: 'pickup.outdoorMessage',
    defaultMessage:
      'Look for the outdoor pickup zone and let us know when you’re here',
    description:
      'Message to inform the customer where to pickup his order outdoor',
  },
  outdoorHeading: {
    id: 'pickup.outdoorHeading',
    defaultMessage: 'Outdoor pickup',
    description: 'Heading for outdoor page',
  },
  outdoorRecommendations: {
    id: 'pickup.outdoorRecommendations',
    defaultMessage:
      'Thank you for maintaining at least 6 ft of distance from other customers',
    description: 'Covid-19 social distancing recommendations message',
  },
});
