import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import { setPlaceName } from '../../state/actions/location';
import { openFilterOverlay } from '../../state/actions/filter';
import { trackStoreFinderAddressSearchSubmit } from '../../state/actions/track-event';
import {
  hasActiveFiltersSelector,
  selectedFeaturesStateSelector,
  mapInstanceSelector,
  placeNameSelector,
} from '../../state/selectors';
import LocatorSearch from '../locator-search';

import styles from './styles.cssm';

import messages from './messages';

const LocatorControls = () => {
  const hasActiveFilters = useSelector(hasActiveFiltersSelector);
  const selectedFilters = useSelector(selectedFeaturesStateSelector);
  const mapInstance = useSelector(mapInstanceSelector);
  const placeName = useSelector(placeNameSelector);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const goToLocation = (location) => {
    trackStoreFinderAddressSearchSubmit();
    dispatch(setPlaceName(location));
  };

  // Debounce goToLocation to prevent double submission when a user highlights a Google
  // autocomplete result and presses enter. The Google autocomplete API doesn't give us any way
  // to stop event propagation so that the form doesn't submit if it's changed by selecting an
  // autocomplete value.
  const debouncedGoToLocation = debounce(goToLocation, 200);

  const getFilterAriaLabel = (filterCount) =>
    `${formatMessage(messages.filterToggleLabel)}. ${formatMessage(
      messages.activeFilters,
      {
        filterCount,
      }
    )}`;

  return (
    <div className={`sb-global-gutters py3 lg-py4 ${styles.base}`}>
      <h2 className="hiddenVisually">
        {formatMessage(messages.filterHeading)}
      </h2>
      <div className="flex">
        <LocatorSearch
          className="flex-grow"
          defaultValue={placeName}
          handleSearch={debouncedGoToLocation}
          mapInstance={mapInstance}
        />

        <div className="flex self-end">
          <Button
            aria-label={getFilterAriaLabel(selectedFilters.length)}
            className="ml4 whiteSpace-noWrap"
            data-e2e="filterButton"
            onClick={() => dispatch(openFilterOverlay())}
          >
            {formatMessage(messages.filterToggle)}
            {hasActiveFilters && <span>{` (${selectedFilters.length})`}</span>}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LocatorControls;
