import {
  FETCH_USER_COMMUNICATION_PREFERENCES,
  UPDATE_USER_COMMUNICATION_PREFERENCES,
  UPDATE_USER_COMMUNICATION_PREFERENCES_SUCCESS,
  UPDATE_USER_COMMUNICATION_PREFERENCES_ERROR,
} from '../../actions';

import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

const { reducer } = createSingleResourceReducer({
  startAction: FETCH_USER_COMMUNICATION_PREFERENCES,
  mutateAction: UPDATE_USER_COMMUNICATION_PREFERENCES,
});

const updateTopicPendingStatus = (payload, data, status) => {
  const topic = Object.keys(payload)[0];
  Object.assign(payload[topic], { pending: status });
  return Object.assign({}, data, payload);
};

export default (state, action) => {
  switch (action.type) {
    case UPDATE_USER_COMMUNICATION_PREFERENCES:
      return Object.assign({}, state, {
        data: updateTopicPendingStatus(action.payload, state.data, true),
      });
    case UPDATE_USER_COMMUNICATION_PREFERENCES_SUCCESS:
    case UPDATE_USER_COMMUNICATION_PREFERENCES_ERROR:
      return Object.assign({}, state, {
        data: updateTopicPendingStatus(action.payload, state.data, false),
      });
    default:
      return reducer(state, action);
  }
};
