/* eslint no-shadow: 0 */
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Expander from '@starbucks-web/pattern-library/lib/components/expander';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import {
  trackStreamFrapStartCheckoutClick,
  trackStreamFrapScanClick,
} from 'stream/app/state/track-event';

import {
  allTasksCompletedSelector,
  streamDisplaySelector,
} from '../../../state/selectors/stream';

import OnboardingItem from './item';
import messages from './messages';

const Onboarding = (props) => {
  const { formatMessage } = useIntl();

  // show relevant onboarding card based on user's onboarding status
  // returned in the onboarding stream card.
  const { streamItems, allTasksCompleted, isMobileViewport } = props;

  const onboardingStreamItems = streamItems.find(
    (item) => item.type === 'onboarding'
  );

  if (!onboardingStreamItems) return null;

  const tasks = {
    'Purchase': {
      description: messages.onboardingCardPayInStoreContent,
      headingTitle: messages.payInStoreTitle,
      trackingHandler: trackStreamFrapScanClick,
      imagePath: '/stream/pay-in-store.png',
    },
    'MOP': {
      description: messages.onboardingCardOrderAheadContent,
      headingTitle: messages.orderAheadTitle,
      trackingHandler: trackStreamFrapStartCheckoutClick,
      imagePath: '/stream/order-ahead.png',
    },
  };

  const onboardingCards = onboardingStreamItems.onboardingContent.items.map(
    (item, idx) => {
      const taskName = item?.task?.name;
      if (taskName === 'SvcReload') return null;
      if (taskName === 'Purchase' && !isMobileViewport) return null;

      if (taskName === 'Purchase' || taskName === 'MOP') {
        const { description, headingTitle, imagePath, trackingHandler } =
          tasks[taskName];
        return (
          <OnboardingItem
            description={formatMessage(description)}
            headingTitle={formatMessage(headingTitle)}
            idx={idx}
            imagePath={imagePath}
            key={idx}
            onClick={trackingHandler}
            {...item}
          />
        );
      }
    }
  );

  return (
    <Expander
      className="mb3"
      expandEffect="pushdown"
      isExpanded={!allTasksCompleted}
    >
      <Heading
        className="text-upper text-bold color-textBlack mb3 mt5"
        size="xs"
        tagName="h2"
      >
        {formatMessage(messages.onboardingCardsHeaderText)}
      </Heading>
      {onboardingCards}
    </Expander>
  );
};

const select = (state) => ({
  allTasksCompleted: allTasksCompletedSelector(state),
  isMobileViewport: isMobileViewportSelector(state),
  streamItems: streamDisplaySelector(state),
});

export default connect(select)(Onboarding);
