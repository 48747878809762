import { FETCH_GIFT_ORDER_DETAILS, CLEAR_GIFT_HISTORY } from '../../../actions';
import createAggregatorReducer from 'shared/app/stale-reducers/aggregator-reducer';

const { initialState, reducer } = createAggregatorReducer({
  idProp: 'id',
  startAction: FETCH_GIFT_ORDER_DETAILS,
});

export default (state = initialState, action) => {
  state = reducer(state, action);
  if (action.type === CLEAR_GIFT_HISTORY) {
    return initialState;
  }
  return state;
};
