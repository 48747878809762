import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import CategoryHeading from '../category-heading';
import Features from './features';
import messages from './messages';

// Upcoming Frictionless Drive-Thru option:
// CC = Customer Check-in
const orderAndPickUpOptionCodes = ['XO', '16', 'DT', 'CX', '17', 'CC'];

const StoreAmenities = ({ amenities }) => {
  const { formatMessage } = useIntl();

  // Filtering out the order and pick-up options
  // since they have a dedicated section on the web (unlike in native)
  const amenitiesToDisplay = amenities.filter(
    (amenity) => orderAndPickUpOptionCodes.indexOf(amenity.code) === -1
  );

  return (
    <div>
      <CategoryHeading>
        {formatMessage(messages.featuresHeading)}
      </CategoryHeading>
      <Features features={amenitiesToDisplay} />
    </div>
  );
};

StoreAmenities.propTypes = {
  amenities: PropTypes.array,
};

export default StoreAmenities;
