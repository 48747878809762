import { createSelector } from 'reselect';
import {
  storeMenuIsReadySelector,
  menuProductsByNumberAndFormSelector,
} from 'shared/app/bundles/menu';
import { getMatchingProductId } from '../../util/product-id-util';

export const cartAvailabilityNeedsToBeUpdatedSelector = (state) =>
  state?.ordering?.variables?.cartAvailabilityNeedsToBeUpdated;

export const currentCartSelector = (state) => state?.ordering?.cart?.current;

const cartQuantitySelector = createSelector(currentCartSelector, (cart) =>
  Object.values(cart).reduce((sum, cartItem) => sum + cartItem.quantity, 0)
);

// Does the cart need an update, is the new menu data completely ready,
// and do we have objects in the cart?
export const shouldUpdateCartAvailabilitySelector = createSelector(
  cartAvailabilityNeedsToBeUpdatedSelector,
  storeMenuIsReadySelector,
  cartQuantitySelector,
  (cartNeedsUpdating, menuIsReady, cartQuantity) => {
    return Boolean(cartNeedsUpdating && menuIsReady && cartQuantity > 0);
  }
);

// This selector takes products, and the items in the cart. It matches the products to each item
// and checks the availability for each. If the availability for all the cart items is the same
// as menu data, then send back an empty object.
// Otherwise, send the correct availability to the action.
export const getCartProductAvailabilitySelector = createSelector(
  menuProductsByNumberAndFormSelector,
  currentCartSelector,
  (menuProducts, cart) =>
    Object.keys(cart).reduce((acc, cartProductFormKey) => {
      const cartItemDetails = cart[cartProductFormKey];

      const cartAvailability =
        cartItemDetails?.product?.availability ||
        cartItemDetails?.product?.forms?.[0]?.availability;
      const menuProductAvailability =
        menuProducts?.[getMatchingProductId(cartItemDetails)]?.availability ??
        'NotAvailableHere';

      if (menuProductAvailability !== cartAvailability) {
        acc[cartProductFormKey] = { availability: menuProductAvailability };
      }
      return acc;
    }, {})
);
