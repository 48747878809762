import { createSelector } from 'reselect';

import {
  fullMenuSelector,
  allCategoriesSelector,
} from 'shared/app/bundles/menu';
import { routeDerivedDataSelector } from './router';

export const currentMenuSelector = createSelector(
  routeDerivedDataSelector,
  fullMenuSelector,
  (routeDerivedData, fullMenu) =>
    fullMenu?.find((childMenu) => childMenu.id === routeDerivedData?.menu)
);

export const currentCategorySelector = createSelector(
  routeDerivedDataSelector,
  allCategoriesSelector,
  (routeDerivedData, categories) =>
    categories?.find((item) => item.id === routeDerivedData.category)
);

export const currentSubcategoriesSelector = createSelector(
  currentCategorySelector,
  (category) => category?.children
);

export const currentSubcategorySelector = createSelector(
  routeDerivedDataSelector,
  currentSubcategoriesSelector,
  (routeDerivedData, subcategories) =>
    subcategories?.find(
      (subcategory) => subcategory.id === routeDerivedData.subcategory
    )
);

export const currentMenuCategoryNameSelector = createSelector(
  currentCategorySelector,
  (category) => category?.name
);
