import { defineMessages } from 'react-intl';

export const callsToAction = defineMessages({
  tryAgain: {
    id: 'ordering.notifications.tryAgain',
    defaultMessage: 'Try again',
    description:
      'Call to action asking the user to try the previous action again.',
  },
  chooseStore: {
    id: 'ordering.notifications.chooseStore',
    defaultMessage: 'Choose store',
    description: 'Call to action asking the user to choose a different store.',
  },
  chooseItem: {
    id: 'ordering.notifications.chooseItem',
    defaultMessage: 'Choose item',
    description: 'Call to action asking the user to choose a different item.',
  },
});

export const messages = defineMessages({
  offerNotApplied: {
    id: 'ordering.notifications.offerNotApplied',
    defaultMessage:
      "This coupon or reward can't be applied. Items may not qualify right now, or the store isn't currently participating.",
    description:
      'Notification message displayed when user tries to redeem a coupon or star reward but has no items in cart that are applicable or the store is not participating in the offer',
  },
});
