import React, { useState } from 'react';
import { without, intersection, groupBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Overlay from '@starbucks-web/pattern-library/lib/components/overlay';

import LocatorFilterGroup from '../locator-filter-group';
import {
  isFilterOverlayOpenSelector,
  filterableFeaturesSelector,
  selectedFeaturesStateSelector,
} from '../../state/selectors';
import { setFeatures } from '../../state/actions/selected-features';
import { closeFilterOverlay } from '../../state/actions/filter';
import FrapContainer from 'shared/app/components/frap-container';
import { trackStoreFinderFilterClick } from '../../state/actions/track-event';

import styles from './styles.cssm';
import messages from './messages';

export const LocatorFilterOverlay = ({ className = '' }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isFilterOverlayOpen = useSelector(isFilterOverlayOpenSelector);
  const filterableFeatures = useSelector(filterableFeaturesSelector);
  const selectedFeaturesInState = useSelector(selectedFeaturesStateSelector);

  const [selectedFeatures, setSelectedFeatures] = useState(
    selectedFeaturesInState
  );

  const handleApplyClick = () => {
    dispatch(setFeatures(selectedFeatures));
    dispatch(closeFilterOverlay());
  };

  const handleClose = () => {
    setSelectedFeatures(selectedFeaturesInState);
    dispatch(closeFilterOverlay());
  };

  const handleReset = () => {
    dispatch(setFeatures([]));
    setSelectedFeatures([]);
    dispatch(closeFilterOverlay());
  };

  const handleSelect = (feature) => {
    // eslint-disable-next-line camelcase
    trackStoreFinderFilterClick({ store_finder_filter_type: feature });
    setSelectedFeatures(selectedFeatures.concat(feature));
  };

  const handleUnselect = (feature) => {
    setSelectedFeatures(without(selectedFeatures, feature));
  };

  const shouldShowApply = () => {
    return !(
      intersection(selectedFeatures, selectedFeaturesInState).length ===
        selectedFeatures.length &&
      selectedFeatures.length === selectedFeaturesInState.length
    );
  };

  const renderFilterGroups = () => {
    const filterGroups = groupBy(filterableFeatures, 'category');

    return Object.keys(filterGroups).map((categoryTitle, idx) => {
      const categoryFilters = filterGroups[categoryTitle];
      const showTitle = categoryTitle !== 'undefined';

      return (
        <LocatorFilterGroup
          filterableFeatures={categoryFilters}
          groupTitle={showTitle ? categoryTitle : null}
          key={`locator-filter-group-${idx}`}
          select={handleSelect}
          selectedFeatures={selectedFeatures}
          unselect={handleUnselect}
        />
      );
    });
  };

  const closeProps = {
    ariaLabel: formatMessage(messages.overlayCloseButton),
    'data-e2e': 'overlay-close-button',
  };

  return (
    <Overlay
      alignment="leftCrate"
      className={`absolute frapPadding ${className}`}
      closeCallback={handleClose}
      closeProps={closeProps}
      containerProps={{
        'data-e2e': 'overlay-container',
      }}
      showMask={false}
      style={{ zIndex: '2' }}
      useLegacyOverlay
    >
      {!isFilterOverlayOpen ? null : (
        <div className="relative">
          <Heading
            className="text-semibold px3 sm-px6 pb4"
            size="lg"
            tagName="h2"
          >
            {formatMessage(messages.filterOverlayHeading)}
          </Heading>
          {selectedFeatures.length === 0 ? null : (
            <Button
              aria-label={formatMessage(messages.clearButtonAria)}
              className={`absolute py0 py1 ${styles.reset}`}
              data-e2e="clearAllButton"
              onClick={handleReset}
              visualStyle="textOnly"
            >
              {formatMessage(messages.clearFiltersLabel)}
            </Button>
          )}

          {renderFilterGroups()}

          <FrapContainer inHeaderCrate>
            {shouldShowApply() ? (
              <Frap
                aria-label={formatMessage(messages.applyButtonAria)}
                data-e2e="applyButton"
                onClick={handleApplyClick}
              >
                {formatMessage(messages.applyLabel)}
              </Frap>
            ) : null}
          </FrapContainer>
        </div>
      )}
    </Overlay>
  );
};

export default LocatorFilterOverlay;
