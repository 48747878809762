import { push } from 'redux-first-history';

import { fetchLocationByNumber } from 'shared/app/state/action-creators/fetch-location';
import { CONFIRM_ORDERING_UNAVAILABLE } from 'shared/app/state/action-creators/types';
import { currentRouteSelector } from 'shared/app/state/selectors/routes';

import { trackInMapOrderingEvent } from '../../util/track-inmap-order-event';
import { SELECT_STORE_MENU, SET_LOCATION_BY_NUMBER } from './types';

export const setStoreByNumber =
  ({ storeNumber } = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const currentRoute = currentRouteSelector(state);

    dispatch({ type: SET_LOCATION_BY_NUMBER });

    dispatch(fetchLocationByNumber(storeNumber)).then((selectedStore) => {
      if (!selectedStore) {
        return null;
      }

      dispatch({
        type: SELECT_STORE_MENU,
        payload: {
          location: selectedStore,
          needsConfirmation: false,
        },
      });

      trackInMapOrderingEvent();

      if (currentRoute?.query?.confirmedOrderingUnavailable === storeNumber) {
        dispatch({
          type: CONFIRM_ORDERING_UNAVAILABLE,
          payload: { storeNumber },
        });
      }

      dispatch(push('/menu'));
    });
  };
