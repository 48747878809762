import { FETCH_DIGITAL_GIFTS_CATALOG } from '../../actions';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

const { reducer, initialState } = createSingleResourceReducer({
  startAction: FETCH_DIGITAL_GIFTS_CATALOG,
});

export default (state = initialState, action) => {
  state = reducer(state, action);

  return state;
};
