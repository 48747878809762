import { sortBy } from 'lodash';

import getFormId from 'shared/app/bundles/menu/util/get-menu-product-form-id';

// Creates menu item slug in the same format as product data. IE: 406/hot
export const getMatchingProductId = (item) => {
  const formCode =
    item.product?.forms?.[0]?.formCode || item.product?.formCode || '';
  return (
    item.product?.id ||
    getFormId({ productNumber: item.product?.productNumber, formCode })
  );
};

// Makes unique ID based on details of the product.
// An item in the cart without selected options would look like: 406/hot:Grande
// One with selected options: 406/hot:Grande::82(4)(a)
// where each selectedOption is in the format
// optionProductNumber(quantity)(firstLetterOfSizeCode)
// ie productNumber 82, quantity 4, 'add'
export const slugifyCustomizedProduct = (item) => {
  const { size, selectedOptions } = item;
  const sizeCode = size?.sizeCode;
  const productId = getMatchingProductId(item);

  return sortBy(selectedOptions, [
    (option) => option?.optionProductNumber || option?.product?.productNumber,
  ]).reduce((memo, selectedOption) => {
    const selectedOptionProductNumber =
      selectedOption?.optionProductNumber ||
      selectedOption?.product?.productNumber;
    const selectedOptionValue =
      selectedOption?.value || selectedOption?.quantity || 1;
    const selectedOptionSizeCode =
      selectedOption?.size?.sizeCode ||
      selectedOption?.product?.form?.sizes?.[0]?.sizeCode;

    memo += `::${selectedOptionProductNumber}`;
    if (selectedOptionValue !== null) {
      memo += `(${selectedOptionValue})`;
    }
    if (selectedOptionSizeCode) {
      memo +=
        selectedOptionSizeCode && `(${selectedOptionSizeCode.substring(0, 1)})`;
    }
    return memo;
  }, `${productId}:${sizeCode}`);
};
