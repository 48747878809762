import React from 'react';
import Button from '@starbucks-web/pattern-library/lib/components/button/';
import { connect } from 'react-redux';
import { push as pushAction } from 'redux-first-history';

import CardBase from './card-base';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '@starbucks-web/pattern-library/lib/components/icon/';
import infoPath from '@starbucks-web/pattern-library/lib/icons/info';
import { trackStreamCardClick } from '../../state/track-event';

const messages = defineMessages({
  remainingDays: {
    id: 'stream.expiringtier.days',
    defaultMessage:
      '{displayNumber, plural, =0 {Today} =1 {# Day} other {# Days} }',
    description: 'Number of day(s) until tier expiration',
  },
});
const FAQUrl = 'https://www.starbucks.com/rewards#FAQs';

const ExpiringTierStreamItem = ({
  itemId,
  type,
  body,
  callToAction2Text,
  expires,
  idx,
  imageUrl,
  intl: { formatMessage },
  push,
  title,
}) => {
  const daysToExpire = Math.floor(
    (expires - Date.now()) / (1000 * 60 * 60 * 24)
  );
  const daystoText = formatMessage(messages.remainingDays, {
    displayNumber: daysToExpire,
  });
  const formattedBody = body.replace('{0 days}', daystoText);

  return (
    <CardBase
      body={formattedBody}
      imagePosition="left"
      imageUrl={imageUrl}
      title={title}
    >
      <span className="pr3 text-right">
        <Button className="color-black30" href={FAQUrl} visualStyle="textOnly">
          <Icon path={infoPath} size="20px" />
        </Button>
        <span
          onClick={() => {
            /* eslint-disable camelcase */
            trackStreamCardClick({
              stream_card_id: itemId,
              stream_card_type: type,
              stream_card_position: idx.toString(),
            });
            /* eslint-enable camelcase */
            push('#pay');
          }}
        >
          <Button visualStyle="positive">{callToAction2Text}</Button>
        </span>
      </span>
    </CardBase>
  );
};

const actions = {
  push: pushAction,
};

export default injectIntl(connect(null, actions)(ExpiringTierStreamItem));
