import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '@starbucks-web/pattern-library/lib/components/button/';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import { rewardsMessages } from 'shared/app/messages/rewards';
import { trackStreamCardClick } from '../../state/track-event';
import CardBase from './card-base';
import messages from './messages';

const legalTermsHeadingId = 'legalTermsHeadingId';

const LegalFooterOverlay = ({ legalFooter }) => (
  <OverlayWithCloseMessage alignment="rightThird">
    <div className="mx4">
      <Heading className="my3" id={legalTermsHeadingId} size="md" tagName="h2">
        <FormattedMessage {...rewardsMessages.termsAndConditions} />
      </Heading>
      <p>{legalFooter}</p>
    </div>
  </OverlayWithCloseMessage>
);

const InformationStreamItem = ({
  itemId,
  type,
  body,
  callToActionIsEnabled,
  callToActionIsExternalLink,
  callToActionLink,
  callToActionText,
  callToActionType,
  title,
  idx,
  imageUrl,
  imageAlt,
  legalFooter,
}) => {
  const { openModal } = useModalContext();
  const isButton = Boolean(callToActionType === 'launch bottomsheet');

  const buttonProps = {
    className: legalFooter ? '' : 'ml-auto',
    'data-e2e': 'infoCardCTA',
    target: callToActionIsExternalLink ? '_blank' : null,
    tagName: !callToActionIsExternalLink && !isButton ? Link : null,
    [callToActionIsExternalLink ? 'href' : 'to']: callToActionLink,
  };

  return (
    <CardBase
      body={body}
      imageAlt={imageAlt}
      imagePosition="top"
      imageUrl={imageUrl}
      title={title}
    >
      <div className="flex justify-between">
        {callToActionIsEnabled ? (
          <Button
            visualStyle="positive"
            {...buttonProps}
            onClick={() => {
              /* eslint-disable camelcase */
              trackStreamCardClick({
                stream_card_id: itemId,
                stream_card_type: type,
                stream_card_position: idx.toString(),
              });
              /* eslint-enable camelcase */
            }}
          >
            {callToActionText}
          </Button>
        ) : null}
        {legalFooter ? (
          <Button
            className={callToActionIsEnabled ? null : 'ml-auto'}
            onClick={() => {
              openModal({
                component: LegalFooterOverlay,
                componentProps: {
                  legalFooter,
                },
                ariaLabelledBy: legalTermsHeadingId,
              });
            }}
            visualStyle="textOnly"
          >
            <FormattedMessage {...messages.terms} />
          </Button>
        ) : null}
      </div>
    </CardBase>
  );
};

export default InformationStreamItem;
