/* Transforms products starting as a fetched favorite, fetched
 * previous order, or cart item into the shape needed to save
 * as a favorite.
 * 
 * Here is the FavoriteProductItemInput schema in Graphql,
 * this is the target shape: 
 * 
 *  productNumber: Int!
 *  formCode: String!
 *  sizeCode: String!
 *  childItems: [
 *    // Uses the schema FavoriteProductChildItemInput in Graphql:
 *    quantity: Int <-- how many of this item
 *    productNumber: Int
 *    formCode: String
 *    sizeCode: String
 *    value: String // <-- We don't use this, it's described by the API docs this way:
 *      // Used to store a custom value relevant to the childItem 
 *      // (e.g.: holds a temperature when childItem represents a custom temperature). 
 *  ]
 * 
 * Fetched favorite products have this shape:
 * 
{
    id: // a meaningless string that we disregard
    product: {
        forms: []
        productNumber: 461,
        productType: "Beverage"
    },
    childItems: [{
      categoryKeyPath: [],
      product: {},
      quantity: <number>,
      unitOfMeasure: string or null
    }]
}
 *
 * Fetched previous order items have this shape:
 * 
{
    summary: {
        subtotalAmount: 5.25,
        totalPrice: 5.25,
        discountAmount: 0
    },
    quantity: 1,
    sku: 11113626,
    product: {
        productType: "Beverage",
        productNumber: 2123158,
        forms: []
    },
    price: 5.25,
    discounts: [],
    childItems: [{
      quantity: 1,
      sku: "11112415",
      categoryKeyPath: [
          231,
          47600074
      ],
      unitOfMeasure: "pump(s)",
      product: {
          productNumber: 2123154,
          form: {
            sizes: {name: 'No Honey Blend', sizeCode: 'no', sku: '11112415'}
          }
      },
      price: null
    }]
}
 *
 * Cart items in redux have the following shape
 * 
{
    product: {
        productType: "Beverage",
        productNumber: 2123158,
        forms: []
    },
    size: {
        name: "Tall",
        sizeCode: "Tall",
        sku: "11113626"
    },
    sizeCode: "Tall",
    selectedOptions: [{
      categoryKeypath: [249, 250],
      formCode: "qty"
      optionProductNumber: 650
      size: {sizeCode: 'no', name: 'No Mocha Sauce', sku: '11052321'}
      value: 0
    }],
    quantity: 1,
    storeNumber: null,
    productImage: "https://globalassets.starbucks.com/assets/77801559b72b469583f4d484adc1bfa7.jpg?impolicy=1by1_tight_288",

    // ...and then a bunch of metadata used for tracking events
    isRecommendedProduct: false,
    isUsual: false,
    deepbrewRecommendationType: null,
    moodName: ,
    recommendationId: null,
    recommendationRank: null,
    recommendationType: null,
    productAddSource: null,
}
 */

export const transformChildItems = (selectedOptions = []) =>
  selectedOptions
    .map((option) => {
      // if item was added to cart, the redux state will use "value" for quantity
      const quantity = option?.quantity || option?.value;
      const formCode = option?.product?.form?.formCode || option?.formCode;
      const productNumber =
        option?.product?.productNumber || option?.optionProductNumber;
      const sizeCode =
        option?.product?.form?.sizes?.[0]?.sizeCode || option?.size?.sizeCode;
      const getQuantity = () => {
        if (sizeCode === 'no') {
          // sizeCode 'no' is used to indicate an option from the default
          // recipe that is being removed.
          // in the PDP/cart, this value is represented as 0,
          // but for favoriting it's 1
          return 1;
        }
        return typeof quantity === 'number' ? quantity : 1;
      };
      return {
        productNumber,
        formCode,
        sizeCode,
        quantity: getQuantity(),
        value: undefined,
      };
    })
    .sort((a, b) => a.productNumber - b.productNumber);

export const transformToFavoritingShape = (item) => {
  const childItems = item?.childItems || item?.selectedOptions;
  const productNumber = item?.product?.productNumber;
  const formCode =
    item?.product?.forms?.[0]?.formCode || item?.product?.formCode;
  const sizeCode =
    (item?.product?.forms?.[0]?.sizes?.[0]?.sizeCode ?? '') || item?.sizeCode;

  return {
    productNumber,
    formCode,
    childItems: transformChildItems(childItems),
    sizeCode,
  };
};
