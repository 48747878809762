import {
  REQUEST_LOCATION_BY_NUMBER,
  REQUEST_LOCATION_BY_NUMBER_ERROR,
} from 'shared/app/state/action-creators/types';
import {
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_GUEST_SUCCESS,
  SELECT_STORE_MENU,
} from 'ordering/app/state/actions/types';
import { REFRESH_PICKUP_STORE } from '../actions';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

const { reducer, initialState } = createSingleResourceReducer({
  startAction: REQUEST_LOCATION_BY_NUMBER,
  markStaleWhen: [
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_GUEST_SUCCESS,
    SELECT_STORE_MENU,
    REFRESH_PICKUP_STORE,
  ],
});

export default (state = initialState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case REQUEST_LOCATION_BY_NUMBER_ERROR:
      return {
        ...state,
        loading: false,
        lastFetch: Date.now(),
      };
  }
  return state;
};
