import React from 'react';
import { useIntl } from 'react-intl';

import { commonMessages } from 'shared/app/messages';
import SummaryLineItems from './summary-line-items';
import CaliTaxLabel from './cali-tax-label';
import SummaryTotal from './summary-total';

const PricingSummary = ({ pricingSummary, tipAmount = '0' }) => {
  const { formatMessage, formatNumber, formats } = useIntl();

  const { lineItems } = pricingSummary;
  const tipItem =
    tipAmount !== '0'
      ? {
          key: 'tip',
          label: formatMessage(commonMessages.tipLabel),
          price: tipAmount,
          priceLabel: formatNumber(tipAmount, formats.number.money),
        }
      : {};
  const lineItemsWithTip = [...lineItems, tipItem];

  return (
    <React.Fragment>
      <SummaryLineItems lineItems={lineItemsWithTip} />
      <SummaryTotal pricingSummary={pricingSummary} tipAmount={tipAmount} />
      <CaliTaxLabel />
    </React.Fragment>
  );
};

export default PricingSummary;
