import React from 'react';
import { connect } from 'react-redux';
import Animator from '@starbucks-web/pattern-library/lib/components/animator';
import Card from '@starbucks-web/pattern-library/lib/components/card';

import {
  hasFetchedCardsAndProfileSelector,
  showRewardsEmptyStateSelector,
} from 'shared/app/state/selectors/rewards-selectors';
import { signedInSelector } from 'shared/app/bundles/user';
import ContentCrateLoading from 'shared/app/components/content-crate-loading';
import FaderContainer from 'shared/app/components/fader-container';
import RewardsEmptyState from 'shared/app/components/rewards-empty-state';

import RewardsMemberStatus from './rewards-member-status';

export const NoDataLoadingState = () => (
  // Make a loader of similar height to rewards card in most breakpoints,
  // helps create a smoother fade-in when rewards card content loads.
  <Card
    containerClasses="flex flex-column items-center justify-center mb4"
    style={{ height: '320px' }}
  >
    <ContentCrateLoading />
  </Card>
);

export const RewardsCard = ({
  hasFetchedCardsAndProfile,
  showRewardsEmptyState,
  signedIn,
}) => {
  if (!signedIn) {
    return null;
  }

  // Rendering each of these with their own conditions rather than with a wrapper
  // component allows the Animator to fade in whatever child is being rendered
  const showRewardsStatus = () => {
    if (hasFetchedCardsAndProfile) {
      return showRewardsEmptyState ? (
        <RewardsEmptyState key="coffee-brewing-img" />
      ) : (
        <FaderContainer>
          <RewardsMemberStatus key="rewards-member-status" />
        </FaderContainer>
      );
    }
    return <NoDataLoadingState key="no-data-loading" />;
  };

  return <Animator animation="fade">{showRewardsStatus()}</Animator>;
};

const select = (state) => ({
  hasFetchedCardsAndProfile: hasFetchedCardsAndProfileSelector(state),
  showRewardsEmptyState: showRewardsEmptyStateSelector(state),
  signedIn: signedInSelector(state),
});

export default connect(select)(RewardsCard);
