import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import ExternalLink from 'shared/app/components/external-link';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import { userGeolocationSelector } from '../../state/selectors';
import { isMetricSelector } from '../../state/selectors/config';

import buildDirectionsUrl from './build-directions-url';

import messages from './messages';

export const Address = ({ addressLines }) => {
  if (!addressLines.length) {
    return null;
  }

  return (
    <p>
      {addressLines.map((line, i) => (
        <span className="block" key={i}>
          {line}
        </span>
      ))}
    </p>
  );
};

const DirectionsLink = ({ storeData, userGeolocation, formatMessage }) => {
  if (!storeData.store.address.lines.length) {
    return null;
  }

  const isGeolocated = Boolean(userGeolocation);
  const fullAddress = storeData.store.address.lines.join(', ');
  const url = buildDirectionsUrl(fullAddress, isGeolocated);

  return (
    <ExternalLink className="color-greenAccent" href={url} visualStyle="button">
      {formatMessage(messages.directionsLink)}
    </ExternalLink>
  );
};

const Distance = ({ distance, isMetric, formatMessage }) => {
  if (!distance) {
    return null;
  }
  const distanceTemplate = isMetric
    ? messages.distanceKmAway
    : messages.distanceMilesAway;

  const roundedDistance = Math.round(distance * 10) / 10;
  return (
    <p className="color-textBlackSoft" data-e2e="distance">
      {formatMessage(distanceTemplate, { distance: roundedDistance })}
    </p>
  );
};

const Phone = ({ phoneNumber, formatMessage, isMobileViewport }) => {
  if (!phoneNumber) {
    return null;
  }

  return (
    <Button
      className="mb3 lg-mxn3"
      href={`tel:${phoneNumber}`}
      visualStyle={isMobileViewport ? 'default' : 'textOnly'}
    >
      <span className="hiddenVisually">
        {formatMessage(messages.phoneLabel)}
      </span>
      <div>
        <span aria-hidden>
          {formatMessage(messages.call)}
          {`: `}
        </span>
        <span>{phoneNumber}</span>
      </div>
    </Button>
  );
};

const LocationInfo = ({ storeData }) => {
  const { distance } = storeData;
  const { formatMessage } = useIntl();

  const userGeolocation = useSelector(userGeolocationSelector);
  const isMobileViewport = useSelector(isMobileViewportSelector);
  const isMetric = useSelector(isMetricSelector);

  return (
    <div>
      <div className="pb5 grid">
        <div className="gridItem size6of12">
          <Address addressLines={storeData.store.address.lines} />
        </div>
        <div className="gridItem size6of12 text-right lg-text-left">
          <Distance
            distance={distance}
            formatMessage={formatMessage}
            isMetric={isMetric}
          />
        </div>
      </div>
      <div className="whiteSpace-noWrap">
        <div>
          <Phone
            formatMessage={formatMessage}
            isMobileViewport={isMobileViewport}
            phoneNumber={storeData.store.phoneNumber}
          />
        </div>
        <div>
          <DirectionsLink
            formatMessage={formatMessage}
            storeData={storeData}
            userGeolocation={userGeolocation}
          />
        </div>
      </div>
    </div>
  );
};

LocationInfo.propTypes = {
  storeData: PropTypes.object.isRequired,
};

export default LocationInfo;
