import React from 'react';
import { connect } from 'react-redux';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { push as pushAction } from 'redux-first-history';
import { Link } from 'react-router-dom';

import Button from '@starbucks-web/pattern-library/lib/components/button/';
import Heading from '@starbucks-web/pattern-library/lib/components/heading/';

import { expiringStarsNudgeSelector } from 'shared/app/bundles/user';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import {
  configImagesUrlSelector,
  orderingEnabledSelector,
} from 'shared/app/state/selectors/config';
import { expiringStarsHashSelector } from 'shared/app/state/selectors/rewards';
import RedeemOptionsButton from 'shared/app/components/redeem-options/redeem-options-button';
import { trackStreamCardClick } from '../../../state/track-event';

import { sharedCallsToAction } from 'shared/app/messages/ctas';
import messages from './messages';
import styles from './styles.cssm';

export const formatCardHeader = ({ expiringPoints }) => {
  return (
    <FormattedMessage
      {...messages.starsWillExpireSoon}
      values={{
        points: expiringPoints || null,
      }}
    />
  );
};

export const formatCardText = ({ expirationDate }) => {
  return (
    <FormattedMessage
      {...messages.starsWillFadeAway}
      values={{
        date: expirationDate ? (
          <FormattedDate
            day="2-digit"
            month="2-digit"
            value={new Date(expirationDate)}
            year="numeric"
          />
        ) : null,
      }}
    />
  );
};

export const expiringStarsCta = ({
  itemId,
  type,
  idx,
  isOrderingSupported,
  showOrderAction,
  isDesktop,
}) => {
  if (!isOrderingSupported && isDesktop) {
    return null;
  }
  // locale has ordering but less than 25 total stars go directly to ordering
  if (isOrderingSupported && showOrderAction) {
    return (
      <Button
        className="ml-auto mb1 mr2 md-mr3"
        data-e2e="order-cta"
        onClick={() => {
          /* eslint-disable camelcase */
          trackStreamCardClick({
            stream_card_id: itemId,
            stream_card_type: type,
            stream_card_position: idx.toString(),
          });
          /* eslint-enable camelcase */
        }}
        tagName={Link}
        to={'/menu'}
        visualStyle="positive"
      >
        <FormattedMessage {...sharedCallsToAction.order} />
      </Button>
    );
  }
  // but use "Order" button label when total stars is less than 25
  return (
    <RedeemOptionsButton
      className="mb1 mr2 md-mr3"
      clickHandler={() => {
        /* eslint-disable camelcase */
        trackStreamCardClick({
          stream_card_id: itemId,
          stream_card_type: type,
          stream_card_position: idx.toString(),
        });
        /* eslint-enable camelcase */
      }}
      showOrderLabel={showOrderAction}
    />
  );
};

export const RewardPointsExpiration = (props) => {
  const {
    itemId,
    type,
    rewardPointsExpirationInfo: { totalPoints },
    expiringStarsHash,
    expiringStarsNudge,
    idx,
    isOrderingSupported,
    isDesktop,
    imagesUrl,
    push,
  } = props;
  const { date: expirationDate, amount: expiringPoints } =
    expiringStarsNudge || {};

  const redeemPointsThreshold = 25;
  const showOrderAction = totalPoints < redeemPointsThreshold;

  const cardHeader = formatCardHeader({
    expiringPoints,
  });

  const cardText = formatCardText({
    expirationDate,
  });

  const handleDetailsClick = () => {
    push(`/account/rewards#${expiringStarsHash}`);
  };

  return (
    <div className={`${styles.card} mt3`}>
      <div className="flex justify-between" data-e2e="expiringStarsStreamCard">
        <div className="size8of12 sm-size9of12 md-size10of12 lg-size9of12 p3 sm-py7 md-py5">
          <Heading className="mb2" size="sm" tagName="h2">
            {cardHeader}
          </Heading>
          <p className="md-mt3">{cardText}</p>
          <div className="mt3">
            {expiringStarsCta({
              itemId,
              type,
              idx,
              isOrderingSupported,
              showOrderAction,
              isDesktop,
            })}
            <Button
              className="ml-auto"
              data-e2e="details-cta"
              onClick={handleDetailsClick}
            >
              <FormattedMessage {...sharedCallsToAction.details} />
            </Button>
          </div>
        </div>
        <div className="size4of12 sm-size3of12 md-size2of12 lg-size3of12">
          <img
            alt=""
            className={`sb-card__image ${styles.image}`}
            src={`${imagesUrl}/feed/stars-expiring.jpg`}
          />
        </div>
      </div>
    </div>
  );
};

const select = (state) => ({
  expiringStarsNudge: expiringStarsNudgeSelector(state),
  expiringStarsHash: expiringStarsHashSelector(state),
  imagesUrl: configImagesUrlSelector(state),
  isDesktop: !isMobileViewportSelector(state),
  isOrderingSupported: orderingEnabledSelector(state),
});

const actions = {
  push: pushAction,
};

export default connect(select, actions)(RewardPointsExpiration);
