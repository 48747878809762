import { isString, trim, zipObject } from 'lodash';

import { createSelector } from 'reselect';

import { routeSelector } from 'shared/app/state/selectors/routes';
import {
  fullMenuSelector,
  allCategoriesSelector,
} from 'shared/app/bundles/menu';

import {
  currentCategorySelector,
  currentMenuSelector,
  currentSubcategorySelector,
} from './menu';
import { productSelector } from './product-details';

const backupCrumb = ({ breadcrumbUri }, menuData, tier) => {
  const trimmedBreadcrumb = isString(breadcrumbUri)
    ? trim(breadcrumbUri, '/')
    : null;
  const splitBreadcrumb = trimmedBreadcrumb
    ? zipObject(
        ['menu', 'category', 'subcategory'],
        trimmedBreadcrumb.split('/')
      )
    : { menu: null, category: null, subcategory: null };

  const breadcrumbFromMenuData = menuData.find(
    (menu) => menu.id === splitBreadcrumb[tier]
  );

  return breadcrumbFromMenuData;
};

export const breadcrumbsSelector = createSelector(
  currentCategorySelector,
  allCategoriesSelector,
  fullMenuSelector,
  currentMenuSelector,
  productSelector,
  routeSelector,
  currentSubcategorySelector,
  // eslint-disable-next-line complexity
  (
    category,
    allCategories,
    fullMenu,
    menu,
    product,
    route,
    subcategory
    // eslint-disable-next-line max-params
  ) => {
    const menuData =
      !menu?.name && product ? backupCrumb(product, fullMenu, 'menu') : menu;
    const categoryData =
      !category?.name && product?.breadcrumbUri
        ? backupCrumb(product, allCategories, 'category')
        : category;

    if (route === '/menu/:menu/:category') {
      return {
        categoryName: categoryData?.name,
        menuName: menuData?.name,
      };
    }

    const categoryCrumb = {
      text: categoryData?.name,
      to: `/menu${categoryData?.uri}`,
    };

    if (route === '/menu/:menu/:category/:subcategory') {
      return {
        categoryCrumb,
        selectedName: subcategory?.name,
        menuName: menuData?.name,
      };
    }
    return {
      categoryCrumb,
      selectedName: product?.name,
      menuName: menuData?.name,
    };
  }
);
