import { createSelector } from 'reselect';

import { isMenuRouteSelector } from 'shared/app/state/selectors/routes';
import shouldUpdate from 'shared/app/stale-reducers/should-update';
import {
  geolocationStateSelector,
  requestGeolocation,
} from 'shared/app/bundles/geolocation';

export const shouldGeolocateSelector = createSelector(
  geolocationStateSelector,
  isMenuRouteSelector,
  (geolocationState, isMenuRoute) =>
    isMenuRoute && shouldUpdate(geolocationState)
);

export default [
  {
    selector: shouldGeolocateSelector,
    actionCreator: requestGeolocation,
  },
];
