import {
  ADD_GUEST_PAYMENT_TOKEN_SUCCESS,
  CLEAR_GUEST_PAYMENT_TOKEN,
  GUEST_PAYMENT_TOKEN_IS_EXPIRED,
} from '../../actions';

export default (state = null, action) => {
  switch (action.type) {
    case ADD_GUEST_PAYMENT_TOKEN_SUCCESS:
      return { ...action.payload };
    case CLEAR_GUEST_PAYMENT_TOKEN:
    case GUEST_PAYMENT_TOKEN_IS_EXPIRED:
      return null;
  }
  return state;
};
