import { trackEvent, trackPageView } from 'shared/app/utils/uo-tracking';

/**
 * @param {{payment_method_type: string}}
 * eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackBarcodeView = (eventAttributes) =>
  trackPageView('BARCODE_VIEW', eventAttributes);

export const trackBarcodeCardManageClick = () =>
  trackEvent('BARCODE_CARD_MANAGE_CLICK');

export const trackBarcodeCardReloadClick = () =>
  trackEvent('BARCODE_CARD_RELOAD_CLICK');

/**
 * @param {{payment_method_type: string}}
 * eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackBarcodeCardReloadSuccess = (eventAttributes) =>
  trackEvent('BARCODE_CARD_RELOAD_SUCCESS', eventAttributes);

export const trackBarcodeCardAddClick = () =>
  trackEvent('BARCODE_CARD_ADD_CLICK');

export const trackBarcodeCardAddSuccess = () =>
  trackEvent('BARCODE_CARD_ADD_SUCCESS');

/**
 * @param {{payment_method_type: string}}
 * eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackBarcodeCardMakeDefaultClick = (eventAttributes) =>
  trackEvent('BARCODE_CARD_MAKE_DEFAULT_CLICK', eventAttributes);

/**
 * @param {{payment_method_type: string}}
 * eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackBarcodeEditAutoReloadView = (eventAttributes) =>
  trackPageView('BARCODE_EDIT_AUTO_RELOAD_VIEW', eventAttributes);

/**
 * @param {{payment_method_type: string}}
 * eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackBarcodeEditAutoReloadSuccess = (eventAttributes) =>
  trackPageView('BARCODE_CARD_AUTO_RELOAD_SUCCESS', eventAttributes);
