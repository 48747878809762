export default (destAddr, geolocate) => {
  /*
    Example url formats:
    http://stackoverflow.com/questions/15042283/current-location-google-maps-link-to-directions
  */
  const pinLabel = 'Starbucks';
  const startAddr = 'Current Location';
  let url = `https://maps.google.com?daddr=[${pinLabel}],${encodeURIComponent(
    destAddr
  )}`;
  if (geolocate) {
    url += `&saddr=${encodeURIComponent(startAddr)}`;
  }
  return url;
};
