import { createSelector } from 'reselect';

const giftFormSelector = (state) => state.gift?.giftForm;

export const giftFormDataSelector = createSelector(
  giftFormSelector,
  (giftForm) => giftForm?.formData
);

export const isGroupGiftOrderSelector = createSelector(
  giftFormDataSelector,
  (formData = {}) => {
    const fields = Object.keys(formData);
    const recipients = fields.filter((field) =>
      field.includes('recipientEmail')
    );
    return recipients.length > 1;
  }
);
