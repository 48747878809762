import { createSelector } from 'reselect';

import { queryParamsSelector } from 'shared/app/state/selectors/routes';
import {
  selectedStoreSelector,
  storeIsLoadingSelector,
} from 'shared/app/state/selectors/ordering';
import { setStoreByNumber } from '../actions/store';

export const shouldFetchStoreSelector = createSelector(
  queryParamsSelector,
  selectedStoreSelector,
  storeIsLoadingSelector,
  (queryParams, selectedStore, storeIsLoading) => {
    if (
      queryParams.storeNumber &&
      selectedStore?.store?.storeNumber !== queryParams.storeNumber &&
      !storeIsLoading
    ) {
      return {
        storeNumber: queryParams.storeNumber,
      };
    }
    return false;
  }
);

export default [
  {
    selector: shouldFetchStoreSelector,
    actionCreator: setStoreByNumber,
  },
];
