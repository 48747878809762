import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import CloseButton from '@starbucks-web/pattern-library/lib/components/close-button';
import maskPath from '@starbucks-web/pattern-library/lib/icons/mask-clear';

import { showCovidMaskAdvisorySelector } from 'shared/app/state/selectors/config';
import messages from './messages';
import { useSelector } from 'react-redux';

const MaskBanner = () => {
  const showCovidMaskAdvisory = useSelector(showCovidMaskAdvisorySelector);
  if (!showCovidMaskAdvisory) {
    return null;
  }

  const [isVisible, setIsVisible] = useState(true);

  return (
    isVisible && (
      <div className="bg-greenLight py3 pl3 pr1 text-xxs flex items-center">
        <Icon className="pr3 flex-shrink-none" path={maskPath} size="40px" />
        <span className="flex-grow">
          <FormattedMessage {...messages.maskUp} />
        </span>
        <CloseButton
          ariaLabel="Close"
          className="ml3"
          onClick={() => setIsVisible(false)}
        />
      </div>
    )
  );
};

export default MaskBanner;
