'use strict';

const reStoreNumber = /\/store\/(\d+-\d+)\/?/;

const parseStoreNumber = (path) => {
  const matches = reStoreNumber.exec(path);
  if (!matches) {
    return null;
  }
  return matches[1];
};

const buildStoreUrl = (location) => {
  const slug = !location?.store?.slug ? '' : `/${location.store.slug}`;

  return `/store-locator/store/${location?.store?.storeNumber ?? ''}${slug}`;
};

module.exports = {
  parseStoreNumber,
  buildStoreUrl,
};
