import { createSelector } from 'reselect';

import { appTimeSelector, configSelector } from 'shared/app/shell';

import { mostRecentOrderTimeSelector } from '../selectors';
import { INVALIDATE_MOST_RECENT_ORDER } from '../actions/types';

export const shouldInvalidateMostRecentOrderSelector = createSelector(
  configSelector,
  mostRecentOrderTimeSelector,
  appTimeSelector,
  (config, mostRecentOrderTime, appTime) =>
    mostRecentOrderTime &&
    appTime - mostRecentOrderTime > config?.mostRecentOrderStaleAfter
);

export default [
  {
    selector: shouldInvalidateMostRecentOrderSelector,
    actionCreator: () => ({ type: INVALIDATE_MOST_RECENT_ORDER }),
  },
];
