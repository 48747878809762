import { createSelector } from 'reselect';

import { storeLocatorSelectorWithFallback } from './util';

export const nearestLocationStateSelector = createSelector(
  storeLocatorSelectorWithFallback,
  ({ nearestLocationState }) => nearestLocationState || {}
);

export const nearestLocationLoadingSelector = createSelector(
  nearestLocationStateSelector,
  ({ loading }) => Boolean(loading)
);

export const nearestLocationNotFoundSelector = createSelector(
  nearestLocationStateSelector,
  ({ nearestNotFound }) => Boolean(nearestNotFound)
);
