import React from 'react';
import { FormattedMessage } from 'react-intl';
import PickupForm from '../pickup-form';

import messages from './messages';

const OutdoorForm = ({ loading, onSubmit }) => {
  return (
    <PickupForm
      imageName="outdoor.png"
      loading={loading}
      onSubmit={onSubmit}
      pickupMessage={messages.outdoorMessage}
    >
      <p
        className="color-textBlackSoft text-center"
        style={{ fontSize: '15px' }}
      >
        <FormattedMessage {...messages.outdoorRecommendations} />
      </p>
    </PickupForm>
  );
};

export default OutdoorForm;
