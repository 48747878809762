import { defineMessages } from 'react-intl';

export default defineMessages({
  amountLabel: {
    id: 'accountCards.autoReloadBottomSheet.amountLabel',
    defaultMessage: 'Auto reload this amount',
    description: 'Label on select element to choose auto reload amount',
  },
  triggerAmountLabel: {
    id: 'accountCards.autoReloadBottomSheet.triggerAmountLabel',
    defaultMessage: 'When balance is at or below',
    description: 'Label on select element to choose trigger auto reload amount',
  },
  description: {
    id: 'accountCards.autoReloadBottomSheet.description',
    defaultMessage: 'Manage Auto reload',
    description:
      'Description of form to edit stored value card auto reload settings',
  },
  noPaymentMethodSelected: {
    id: 'accountCards.autoReloadBottomSheet.noPaymentMethodSelected',
    defaultMessage: 'To save Auto reload settings, select a payment method.',
    description: 'Label for payment method missing field error',
  },
});
