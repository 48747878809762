import config from 'config';
import {
  lazyFullPage,
  lazyContentCrate,
  lazyHeaderCrate,
} from 'shared/app/shell';
import {
  fetchUserMfaFactors,
  shouldFetchUserMfaFactorsSelector,
} from 'shared/app/bundles/user';

import {
  FETCH_USER_COMMUNICATION_PREFERENCES,
  SAVE_PASSWORD,
  UPDATE_USER_COMMUNICATION_PREFERENCES,
} from './actions';

import reducer from './state/reducers';

import { VERIFY_MFA_ACCESS } from 'shared/app/shell/state/actions';
import { VERIFY_MFA_CODE_SECURE_SESSION } from 'shared/app/bundles/mfa/actions';

const enableMfaFeatures = config.get('universal.enableMfaFeatures');
const authRequired = true;
const mfaAuthRequired = true;

const SettingsHeaderCrate = lazyHeaderCrate(
  () =>
    import(
      /* webpackChunkName: 'settings-header' */ './components/root/header-crate'
    )
);

const CommunicationPreferenceContent = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'communication-preferences-content' */ './components/communication-preferences'
    )
);

const PasswordContent = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'update-password-content' */ './components/update-password'
    )
);

const EditEmailContent = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'edit-email-content' */ './components/edit-email'
    )
);

const EnterEmailCodeContent = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'enter-email-code-content' */ './components/enter-email-code'
    )
);

const MfaAddOrEditPhoneContent = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'mfa-add-or-edit-phone-page-content' */ './components/mfa/add-or-edit-phone-page'
    )
);

const MfaContent = lazyContentCrate(
  () => import(/* webpackChunkName: 'mfa-content' */ './components/mfa')
);

let addPhoneNumberRoute = {};
let editPhoneNumberRoute = {};
let mfaPageRoute = {};

if (enableMfaFeatures) {
  addPhoneNumberRoute = {
    '/account/settings/mfa/add-phone-number': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: MfaAddOrEditPhoneContent,
      authRequired,
    },
  };

  editPhoneNumberRoute = {
    '/account/settings/mfa/edit-phone-number': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: MfaAddOrEditPhoneContent,
      authRequired,
      mfaAuthRequired,
    },
  };

  mfaPageRoute = {
    '/account/settings/mfa': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: MfaContent,
      authRequired,
      mfaAuthRequired,
    },
  };
}

const effects = [];

if (enableMfaFeatures) {
  effects.push({
    selector: shouldFetchUserMfaFactorsSelector,
    actionCreator: fetchUserMfaFactors,
  });
}

export default {
  name: 'accountSettings',
  reducer,
  routes: {
    '/account/settings/password': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: PasswordContent,
      authRequired,
      mfaAuthRequired,
    },
    '/account/settings/communication-preferences': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: CommunicationPreferenceContent,
      authRequired,
    },
    '/account/settings/edit-email/enter-code': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: EnterEmailCodeContent,
      authRequired,
    },
    '/account/settings/edit-email': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: EditEmailContent,
      authRequired,
    },
    ...addPhoneNumberRoute,
    ...editPhoneNumberRoute,
    ...mfaPageRoute,
    '/account/settings/privacy': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'privacy-content' */ './components/privacy'
          )
      ),
      fullWidthBottomDrawer: true,
      authRequired,
    },
    '/account/settings': {
      headerCrate: SettingsHeaderCrate,
      contentCrate: lazyContentCrate(
        () =>
          import(/* webpackChunkName: 'settings-content' */ './components/root')
      ),
      authRequired,
    },
  },
  effects,
  asyncActions: [
    SAVE_PASSWORD,
    FETCH_USER_COMMUNICATION_PREFERENCES,
    UPDATE_USER_COMMUNICATION_PREFERENCES,
    VERIFY_MFA_ACCESS,
    VERIFY_MFA_CODE_SECURE_SESSION,
  ],
};
