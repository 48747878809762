import { defineMessages } from 'react-intl';

import { storeMenusDidFailSelector } from 'shared/app/bundles/menu';
import { isMenuRouteSelector } from 'shared/app/state/selectors/routes';
import { showErrorNotification as showErrorNotificationAction } from 'shared/app/shell';
import { clearStore as clearStoreAction } from 'store-locator/app/state/actions/confirm-store';

const messages = defineMessages({
  menuFailureNotification: {
    id: 'ordering.notifications.menuFailure',
    defaultMessage:
      'Could not load menus for this store. Showing national menu.',
    description: `Notifies the user that the app could not load the store-specific menu and fell back to the
      national menu.`,
  },
});

export const notifyMenuFailure =
  ({
    showErrorNotification = showErrorNotificationAction,
    clearStore = clearStoreAction,
  }) =>
  (dispatch, getState) => {
    const isMenuRoute = isMenuRouteSelector(getState());
    if (isMenuRoute) {
      dispatch(
        showErrorNotification({
          messageId: messages.menuFailureNotification.id,
          useErrorLoadingIndicator: true,
        })
      );
    }
    dispatch(clearStore());
  };

export default [
  {
    selector: storeMenusDidFailSelector,
    actionCreator: notifyMenuFailure,
  },
];
