import { combineReducers } from 'redux';

import expandedStoreState from './expanded-store';
import locationState from './location';
import mapState from './map';
import nearestLocationState from './nearest-location';
import selectedFeaturesState from './selected-features';
import filterState from './filter';

const rootReducer = combineReducers({
  expandedStoreState,
  locationState,
  mapState,
  nearestLocationState,
  selectedFeaturesState,
  filterState,
});

export default rootReducer;
