import { defineMessages } from 'react-intl';

export default defineMessages({
  expandButton: {
    id: 'stream.rewardsLevelsContainer.expandButton',
    defaultMessage: 'Reward options, Rewards you can get with Stars.',
    description:
      'The aria-label for the button to describe what will be displayed.',
  },
  heading: {
    id: 'stream.rewardsLevelsContainer.heading',
    defaultMessage: 'Rewards you can get with Stars',
    description:
      'The header of the different levels of rewards that can be redeemed at different amounts of stars',
  },
});
