/* eslint-disable max-params */
import { createSelector } from 'reselect';
import {
  coordinateBoundsForLocationsSelector,
  hasLocationsSelector,
  locationQuerySelector,
  locationStateSelector,
  resultCoordinatesSelector,
} from './location';
import getBoundsZoomLevel from '../../components/locator-map/get-bounds-zoom-level';
import { mapPositionSelector, mapStateSelector } from './map';
import { configSelector, mapDefaultsSelector } from './config';
import { expandedStoreStateSelector } from './expanded-store';

export const expandedStoreCoordsSelector = createSelector(
  expandedStoreStateSelector,
  mapStateSelector,
  configSelector,
  (expandedStoreState, mapState, config) => {
    const { singleLocationZoom } = config;
    const { mapInstance, userOverride } = mapState;
    const currentZoomLevel = mapInstance?.getZoom();
    if (expandedStoreState && expandedStoreState.coordinates && !userOverride) {
      return {
        lat: expandedStoreState.coordinates.latitude,
        lng: expandedStoreState.coordinates.longitude,
        zoomLevel: currentZoomLevel || singleLocationZoom,
      };
    }
    return null;
  }
);

export const zoomLevelSelector = createSelector(
  mapStateSelector,
  mapDefaultsSelector,
  (mapState, mapDefaults) => {
    const { mapInstance, zoomLevel } = mapState;
    const currentZoomLevel = mapInstance?.getZoom();
    // During map initialization, mapInstance exists before the map is rendered,
    // causing mapInstance.getZoom() to return undefined. We use short circuiting here
    // instead of a ternary to ensure that we always return a value, either the result of
    // getZoom() or the previously assigned zoomLevel or the default
    return currentZoomLevel || zoomLevel || mapDefaults.zoom;
  }
);

export const overwrittenMapCoordsSelector = createSelector(
  mapStateSelector,
  locationStateSelector,
  mapPositionSelector,
  zoomLevelSelector,
  (mapState, locationState, mapPosition, zoomLevel) => {
    const { userOverride, preventMovement, coordinatesUpdated } = mapState;
    const { fetchAtLocation } = locationState;
    if (
      userOverride ||
      fetchAtLocation ||
      (preventMovement && coordinatesUpdated)
    ) {
      return {
        lat: mapPosition.lat,
        lng: mapPosition.lng,
        zoomLevel,
      };
    }
    return null;
  }
);

export const locationQueryCoordsSelector = createSelector(
  locationQuerySelector,
  resultCoordinatesSelector,
  zoomLevelSelector,
  (locationQuery, resultCoordinates, zoomLevel) => {
    if (!locationQuery) {
      return null;
    }

    const { lat, lng } = resultCoordinates || locationQuery;

    if (lat && lng) {
      return {
        lat,
        lng,
        zoomLevel,
      };
    }

    return null;
  }
);

export const fallbackMapCoordsSelector = createSelector(
  mapPositionSelector,
  zoomLevelSelector,
  (mapPos, zoomLevel) => ({
    lat: mapPos.lat,
    lng: mapPos.lng,
    zoomLevel,
  })
);

export const mapPositionForLocationsSelector = createSelector(
  locationQuerySelector,
  hasLocationsSelector,
  mapStateSelector,
  coordinateBoundsForLocationsSelector,
  configSelector,
  (locationQuery, hasLocations, mapState, locationSummary, config) => {
    if (!locationSummary || !locationQuery || !hasLocations) {
      return null;
    }
    const { zoomThreshold, singleLocationZoom } = config;
    const mapDiv = mapState.mapInstance ? mapState.mapInstance.getDiv() : null;
    let zoomLevel = mapState.zoomLevel || config.mapDefaults.zoom;

    if (mapDiv) {
      const mapEdgePadding = 20;
      const mapDimensions = {
        height: mapDiv.clientHeight - mapEdgePadding,
        width: mapDiv.clientWidth - mapEdgePadding,
      };

      zoomLevel = getBoundsZoomLevel({
        mapDimensions,
        // TODO: Should maxZoom be calculated dynamically based on specificty of search, etc.
        maxZoom: singleLocationZoom,
        zoomThreshold,
        locationData: locationSummary,
      });
    }

    return {
      lat: locationSummary.centerLat,
      lng: locationSummary.centerLng,
      zoomLevel,
    };
  }
);

// This is the primary export. The only reason the other selectors are exported at all is for
// unit testing purposes.
const targetMapCoordsSelector = createSelector(
  expandedStoreCoordsSelector,
  overwrittenMapCoordsSelector,
  mapPositionForLocationsSelector,
  locationQueryCoordsSelector,
  fallbackMapCoordsSelector,
  (
    expandedStoreCoords,
    overwrittenCoords,
    positionForLocations,
    locQueryCoords,
    fallbackCoords
  ) =>
    expandedStoreCoords ||
    overwrittenCoords ||
    positionForLocations ||
    locQueryCoords ||
    fallbackCoords
);

export default targetMapCoordsSelector;
