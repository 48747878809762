import { trackEvent } from 'shared/app/utils/uo-tracking';

import { getInMapOriginationSourceCookie } from './get-inmap-origination-source-cookie';

/**
 * @param {string} inMapOrderSource (apple|google),
 * @returns {Promise<void>} The event has been sent.
 *
 * event name uses lowercase letters, it is non-standard and correct
 */
/* eslint-disable camelcase */
export const trackInMapOrder = (inMapOrderSource) =>
  trackEvent('campaign_details', {
    campaign_name: 'maps_ordering',
    campaign_medium: 'maps',
    campaign_source: inMapOrderSource,
  });
/* eslint-enable camelcase */

export const trackInMapOrderingEvent = () => {
  const inMapCookie = getInMapOriginationSourceCookie();
  if (inMapCookie.track) {
    trackInMapOrder(inMapCookie.originationSource);
  }
};
