import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { push as pushAction } from 'redux-first-history';

import LegacyPaddedContentColumn from 'shared/app/components/legacy-padded-content-column';
import ContentCrateLoading from 'shared/app/components/content-crate-loading';
import { queryParamsSelector } from 'shared/app/state/selectors/routes';
import {
  firstNameSelector,
  hasFetchedProfileSelector,
  signedInSelector,
} from 'shared/app/bundles/user/state/selectors/profile';
import redirectToSigninWithReturnUrl from 'shared/app/utils/redirect-to-signin-with-return-url';

import { submitPickupForm as submitPickupFormAction } from '../../state/action-creators';
import {
  hasFetchedStoreDetailsSelector,
  outdoorPickupAvailableSelector,
} from '../../state/selectors';
import OutdoorForm from './outdoor-form';
import PickupUnavailable from '../pickup-unavailable';
import MaskBanner from '../mask-banner';

import styles from '../styles.cssm';

/* eslint-disable max-statements */
const PickupOutdoor = ({
  firstName,
  hasFetchedProfile,
  hasFetchedStoreDetails,
  push,
  queryParams,
  submitPickupForm,
  userSignedIn,
  outdoorPickupAvailable,
}) => {
  if ((userSignedIn && !hasFetchedProfile) || !hasFetchedStoreDetails) {
    return (
      <div className="height-100 bg-white pt5">
        <ContentCrateLoading />
      </div>
    );
  }

  if (!outdoorPickupAvailable) {
    return <PickupUnavailable pickupOption="outdoor" />;
  }

  const guestFirstName = get(queryParams, 'firstName', '');
  const pickupFirstName = firstName || guestFirstName;
  const [loading, setLoading] = useState(false);
  const storeNumber = get(queryParams, 'store');
  const orderToken = get(queryParams, 'orderToken', '');
  const platform = get(queryParams, 'platform', '');

  useEffect(() => {
    if (!pickupFirstName) {
      redirectToSigninWithReturnUrl();
    }
  }, []);

  const handleSubmitSuccess = () => {
    const search = platform ? `?platform=${platform}` : '';
    setLoading(false);
    push(`/pickup/outdoor/success${search}`);
  };

  const handleSubmitError = () => {
    setLoading(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    submitPickupForm({
      type: 'outdoor',
      firstName: pickupFirstName,
      storeNumber,
      orderToken,
    })
      .then(handleSubmitSuccess)
      .catch(handleSubmitError);
  };

  return (
    <React.Fragment>
      <MaskBanner />
      <LegacyPaddedContentColumn className={styles.content} size="md">
        <OutdoorForm loading={loading} onSubmit={handleSubmit} />
      </LegacyPaddedContentColumn>
    </React.Fragment>
  );
};
/* eslint-enable max-statements */

const select = (state) => ({
  firstName: firstNameSelector(state),
  hasFetchedProfile: hasFetchedProfileSelector(state),
  hasFetchedStoreDetails: hasFetchedStoreDetailsSelector(state),
  outdoorPickupAvailable: outdoorPickupAvailableSelector(state),
  queryParams: queryParamsSelector(state),
  userSignedIn: signedInSelector(state),
});

const actions = {
  push: pushAction,
  submitPickupForm: submitPickupFormAction,
};

export default connect(select, actions)(PickupOutdoor);
