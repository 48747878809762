import React, { useEffect, useRef } from 'react';

import LocationCardContainer from '../location-card-container';
import {
  LocatorErrorNotFound,
  LocatorErrorPlaceNameNotFound,
  LocatorErrorNoPrevious,
  LocatorErrorNoFavorite,
} from './locator-error-store';
import LocatorErrorNoStore from './locator-error-no-store';
import {
  LocatorErrorGeolocation,
  LocatorErrorZoomOrSearch,
  LocatorErrorOutsideZoom,
} from './locator-error-map';

const errorTypeMap = {
  'noPrevious': <LocatorErrorNoPrevious />,
  'noFavorite': <LocatorErrorNoFavorite />,
  'notFound': <LocatorErrorNotFound />,
  'placeNameNotFound': <LocatorErrorPlaceNameNotFound />,
  'noStores': <LocatorErrorNoStore />,
  'zoomedOut': <LocatorErrorOutsideZoom />,
  'searchOrZoom': <LocatorErrorZoomOrSearch />,
  'geolocation': <LocatorErrorGeolocation />,
};

export const LocatorErrorCard = ({ activeTab, errorType }) => {
  const errorCardRef = useRef(null);

  useEffect(() => {
    errorCardRef.current.scrollIntoView({ block: 'start' });
  }, [activeTab]);

  return (
    <LocationCardContainer
      className="py4 sb-global-gutters"
      data-e2e="noStoresCard"
      ref={errorCardRef}
    >
      {errorTypeMap[errorType]}
    </LocationCardContainer>
  );
};

export default LocatorErrorCard;
