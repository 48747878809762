import { createSelector } from 'reselect';
import ms from 'milliseconds';
import { sortBy, groupBy } from 'lodash';

import { isMenuRouteSelector } from 'shared/app/state/selectors/routes';
import { orderingEnabledSelector } from 'shared/app/state/selectors/config';
import { signedInSelector } from 'shared/app/bundles/user';

import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { selectedStoreShortNumberSelector } from 'shared/app/state/selectors/ordering';
import { appTimeSelector } from 'shared/app/shell';

import shouldUpdate from 'shared/app/stale-reducers/should-update';

const orderingSelector = (state) => state.ordering;

export const favoritesStateSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.favorites
);

/* eslint-disable max-params */
export const shouldFetchFavoritesSelector = createSelector(
  signedInSelector,
  orderingEnabledSelector,
  isMenuRouteSelector,
  localeTagSelector,
  selectedStoreShortNumberSelector,
  favoritesStateSelector,
  appTimeSelector,
  (
    signedIn,
    orderingEnabled,
    isMenuRoute,
    locale,
    storeNumber,
    favoritesState,
    appTime
  ) => {
    const variablesChanged =
      locale !== favoritesState?.locale ||
      storeNumber !== favoritesState?.storeNumber;
    const updateNeeded = shouldUpdate(favoritesState, {
      staleTime: ms.hours(2),
      now: appTime,
    });
    return (
      signedIn &&
      orderingEnabled &&
      isMenuRoute &&
      (variablesChanged || updateNeeded)
    );
  }
);
/* eslint-enable max-params */

export const favoriteProductsSelector = createSelector(
  favoritesStateSelector,
  (favorites) => favorites?.data ?? []
);

export const sortedAndGroupedFavoritesSelector = createSelector(
  favoriteProductsSelector,
  (favorites) => {
    const sorted = sortBy(
      favorites,
      (favorite) => favorite?.product?.forms?.[0]?.name
    );
    // items should be in the same group if they have the same name.
    return groupBy(sorted, 'product.forms[0].name');
  }
);
