import { defineMessages } from 'react-intl';

export default defineMessages({
  removeCardOnZeroBalanceAddHeading: {
    id: 'accountCards.removeCardOnZeroBalanceAddDialog.heading',
    defaultMessage: 'Remove Card?',
    description:
      'Heading option prompting removal of a post add, zero balance card',
  },
  removeCardOnZeroBalanceAddDetail: {
    id: 'accountCards.removeCardOnZeroBalanceAddDialog.detail',
    defaultMessage:
      "Your card's balance is {balance} and cannot be transferred. Would you like to remove it?",
    description:
      'Dialog to indicate to the user the card just added had a zero balance and ask them if they want to remove the card',
  },
});
